export const quoteData = `{
  "quote": {
    "headers": [
      {
        "title": "party-id",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "A unique value assigned to each customer account.",
          "Rates returned are as per the agreed terms with AMI."
        ]
      },
      {
        "title": "client-id",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "The client ID provided when registering for the API."
        ]
      },
      {
        "title": "x-api-key",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "The api key provided when registering for the API."
        ]
      },
      {
        "title": "authorization",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string(bearer-token)"
          },
          {
            "title": "Allowed Values",
            "content": "Bearer <generated access token>"
          }
        ],
        "descriptions": [
          "The client ID provided when registering for the API."
        ]
      },
      {
        "title": "Content-Type",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "application/json"
          }
        ],
        "descriptions": []
      }
    ],
    "body": [
      {
        "title": "shipmentDateTime",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          },
          {
            "title": "Format",
            "content": "yyyy-MM-dd"
          }
        ],
        "descriptions": [
          "Expected date for collection or drop in of the shipment",
          "Date entered cannot be in the past. Must be a date from current date + 7 days."
        ]
      },
      {
        "title": "customerAccountNumber",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          },
          {
            "title": "Min Length",
            "content": "1"
          },
          {
            "title": "Max Length",
            "content": "11"
          }
        ],
        "descriptions": [
          "A unique value assigned to each customer account.",
          "Rates returned are as per the agreed terms with AMI."
        ]
      },
      {
        "title": "shipmentType",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          },
          {
            "title": "Allowed Values",
            "content": "DOCS or NON_DOCS"
          }
        ],
        "descriptions": [
          "The client ID provided when registering for the API."
        ]
      },
      {
        "title": "senderDetails",
        "required": "YES",
        "criteria": [
            {
            "title": "Type",
            "content": "object"
          }
        ],
        "descriptions": [
          "Container for Sender Details"
        ],
        "subRows": [
          {
            "title": "address",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Sender Address Details"
            ],
            "copyRoute": "senderDetails.address",
            "subRows": [
              {
                "title": "countryCode",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "ISO 3166-1 alpha-2"
                  }
                ],
                "descriptions": [
                  "Sender country or territory code. Refer to the Supported Country or Territory Tables located in the Appendix."
                ],
                "link": {
                  "title": "Country Codes",
                  "url": "/api/reference-data#country-codes"
                },
                "copyRoute": "senderDetails.address.countryCode"
              },
              {
                "title": "postalCode",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "Refer to postal code reference"
                  },
                  {
                    "title": "Required*",
                    "content": "Conditional. Refer to postal code reference"
                  }
                ],
                "descriptions": [
                  "Required if country or territory utilizes postal codes.",
                  "Note: If client provides a postal code and its a non-postal code country, then ignore and do not map. Or provide null, depending on carrier."
                ],
                "link": {
                  "title": "Postal code formats",
                  "url": "/api/reference-data#postal-codes"
                },
                "copyRoute": "senderDetails.address.postalCode"
              },
              {
                "title": "city",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "1"
                  },
                  {
                    "title": "Max Length",
                    "content": "30"
                  }
                ],
                "descriptions": [
                  "Sender city"
                ],
                "copyRoute": "senderDetails.address.city"
              },
              {
                "title": "residential",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "boolean"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "true or false"
                  }
                ],
                "descriptions": [
                  "To indicate if the origin is a residential location."
                ],
                "copyRoute": "senderDetails.address.residential"
              }
            ]
          }
        ]
      },
      {
        "title": "deliveryDetails",
        "required": "YES",
        "criteria": [
            {
            "title": "Type",
            "content": "object"
          }
        ],
        "descriptions": [
          "Container for Delivery Details"
        ],
        "subRows": [
          {
            "title": "address",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Delivery Address Details"
            ],
            "copyRoute": "deliveryDetails.address",
            "subRows": [
              {
                "title": "countryCode",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "ISO 3166-1 alpha-2"
                  }
                ],
                "descriptions": [
                  "Delivery country or territory code. Refer to the Supported Country or Territory Tables located in the Appendix."
                ],
                "link": {
                  "title": "Country Codes",
                  "url": "/api/reference-data#country-codes"
                },
                "copyRoute": "deliveryDetails.address.countryCode"
              },
              {
                "title": "postalCode",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "Refer to postal code reference"
                  },
                  {
                    "title": "Required*",
                    "content": "Conditional. Refer to postal code reference"
                  }
                ],
                "descriptions": [
                  "Required if country or territory utilizes postal codes.",
                  "Note: If client provides a postal code and its a non-postal code country, then ignore and do not map. Or provide null, depending on carrier."
                ],
                "link": {
                  "title": "Postal code formats",
                  "url": "/api/reference-data#postal-codes"
                },
                "copyRoute": "deliveryDetails.address.postalCode"
              },
              {
                "title": "city",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "1"
                  },
                  {
                    "title": "Max Length",
                    "content": "30"
                  }
                ],
                "descriptions": [
                  "Delivery city"
                ],
                "copyRoute": "deliveryDetails.address.city"
              },
              {
                "title": "residential",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "boolean"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "true or false"
                  }
                ],
                "descriptions": [
                  "To indicate if the destination is a residential location."
                ],
                "copyRoute": "deliveryDetails.address.residential"
              }
            ]
          }
        ]
      },
      {
        "title": "declaredValue",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "double"
          }
        ],
        "descriptions": [
          "Required for all shipments"
        ]
      },
      {
        "title": "declaredValueCurrency",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "Required for all shipments"
        ],
        "link": {
          "title": "Currencies",
          "url": "/api/reference-data#currencies"
        }
      },
      {
        "title": "parcels",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "array"
          }
        ],
        "descriptions": [
          "Parcels Array",
          "All parcels must be in KG ( weight ) and CM ( dimensions)",
          "Or all parcels must be in LB ( weight ) and IN ( dimensions)"
        ],
        "subRows": [
          {
            "title": "dimensions",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Dimensions Object for each parcel"
            ],
            "copyRoute": "parcels.dimensions",
            "subRows": [
              {
                "title": "length",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "double"
                  },
                  {
                    "title": "Value",
                    "content": ">0"
                  }
                ],
                "descriptions": [
                  "Length of the package used to determine dimensional weight"
                ],
                "copyRoute": "parcels.dimensions.length"
              },
              {
                "title": "width",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "double"
                  },
                  {
                    "title": "Value",
                    "content": ">0"
                  }
                ],
                "descriptions": [
                  "Width of the package used to determine dimensional weight"
                ],
                "copyRoute": "parcels.dimensions.width"
              },
              {
                "title": "height",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "double"
                  },
                  {
                    "title": "Value",
                    "content": ">0"
                  }
                ],
                "descriptions": [
                  "Height of the package used to determine dimensional weight"
                ],
                "copyRoute": "parcels.dimensions.height"
              },
              {
                "title": "unit",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "double"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "IN or CM"
                  }
                ],
                "descriptions": [
                  "Unit of measurement for package dimensions.",
                  "CM - Centimeters",
                  "IN - Inches",
                  "If CM is used then KG must be used in parcel value",
                  "If IN is used then LB must be used in parcel value"
                ],
                "copyRoute": "parcels.dimensions.unit"
              }
            ]
          },
          {
            "title": "weight",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Weight Object for each parcel"
            ],
            "copyRoute": "parcels.weight",
            "subRows": [
              {
                "title": "value",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "double"
                  },
                  {
                    "title": "Value",
                    "content": ">0"
                  }
                ],
                "descriptions": [
                  "The 'dead weight' of the package"
                ],
                "copyRoute": "parcels.weight.value"
              },
              {
                "title": "unit",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Allowed Values",
                    "content": "KG or LB"
                  }
                ],
                "descriptions": [
                  "Unit of measure for package weight",
                  "KG - Kilograms",
                  "LB - Pounds",
                  "If KG is used the CM must be used in parcel dimensions",
                  "If LB is used the IN must be used in parcel dimensions"
                ],
                "copyRoute": "parcels.weight.unit"
              }
            ]
          }
        ]
      }
    ],
    "responseSuccess": [
      {
        "title": "200 Success",
        "criteria": [
          {
            "title": "Type",
            "content": "array"
          }
        ],
        "descriptions": [
          "A successful response provides an array of quote objects"
        ],
        "subRows": [
          {
            "title": "quoteId",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Indicates the unique identifier for a specific rate quotation."
            ]
          },
          {
            "title": "carrierServiceName",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Name of the service saved by AMI."
            ]
          },
          {
            "title": "carrierProductCode",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Name of the service as returned by the carrier."
            ]
          },
          {
            "title": "serviceType",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              },
              {
                "title": "Enum",
                "content": "DROP_IN | COLLECTED"
              }
            ],
            "descriptions": [
              "Represents the Service offered for the Carrier."
            ]
          },
          {
            "title": "transportMethod",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              },
              {
                "title": "Enum",
                "content": "ROAD | EXPRESS"
              }
            ],
            "descriptions": [
              "Represents the Mode of transport for the shipment."
            ]
          },
          {
            "title": "weight",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Quote weight object"
            ],
            "subRows": [
              {
                "title": "chargeable",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "object"
                  }
                ],
                "descriptions": [
                  "Quote chargeable weight object"
                ],
                "subRows": [
                  {
                    "title": "value",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "number"
                      }
                    ],
                    "descriptions": [
                      "Specifies the chargeable weight"
                    ],
                    "copyRoute": "weight.chargeable.value"
                  },
                  {
                    "title": "unit",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      }
                    ],
                    "descriptions": [
                      "Specifies the chargeable weight unit"
                    ],
                    "copyRoute": "weight.chargeable.value"
                  }
                ]
              },
              {
                "title": "actual",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "object"
                  }
                ],
                "descriptions": [
                  "Quote actual weight object"
                ],
                "subRows": [
                  {
                    "title": "value",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "double"
                      }
                    ],
                    "descriptions": [
                      "Specifies the actual weight"
                    ],
                    "copyRoute": "weight.actual.value"
                  },
                  {
                    "title": "unit",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      }
                    ],
                    "descriptions": [
                      "Specifies the actual weight unit"
                    ],
                    "copyRoute": "weight.actual.value"
                  }
                ]
              }
            ]
          },
          {
            "title": "transitTImeEstimate",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Indicate the the estimated transit time in days."
            ]
          },
          {
            "title": "deliveryDateEstimate",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Indicate the the estimated delivery date."
            ]
          },
          {
            "title": "sameDayCollectionCutOffTime",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Indicates the collection cut off time."
            ]
          },
          {
            "title": "pickupEarliestTime",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Indicates the pick up earliest time."
            ]
          },
          {
            "title": "pickupLatestTime",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Indicates the pick up latest time."
            ]
          },
          {
            "title": "discount",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Discount object"
            ],
            "subRows": [
              {
                "title": "amount",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "object"
                  }
                ],
                "descriptions": [
                  "Discount amount object"
                ],
                "subRows": [
                  {
                    "title": "value",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "double"
                      }
                    ],
                    "descriptions": [
                      "The total discount applied."
                    ],
                    "copyRoute": "discount.amount.value"
                  },
                  {
                    "title": "currency",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      }
                    ],
                    "descriptions": [
                      "Indicates the currency associated with the discount amount."
                    ],
                    "link": {
                      "title": "Currencies",
                      "url": "/api/reference-data#currencies"
                    },
                    "copyRoute": "discount.amount.currency"
                  }
                ]
              }
            ]
          },
          {
            "title": "charges",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "The total of amount of all surcharges."
            ],
            "subRows": [
              {
                "title": "value",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "double"
                  }
                ],
                "descriptions": [
                  "The total of amount of all surcharges."
                ],
                "copyRoute": "charges.value"
              },
              {
                "title": "currency",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Indicates the currency associated with the charges amount."
                ],
                "link": {
                  "title": "Currencies",
                  "url": "/api/reference-data#currencies"
                },
                "copyRoute": "charges.currency"
              },
              {
                "title": "required",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "array"
                  }
                ],
                "descriptions": [
                  "All required surcharges. Refer to appendix for list of surcharges that can be returned."
                ],
                "subRows": [
                  {
                    "title": "value",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "double"
                      }
                    ],
                    "descriptions": [
                      "The value of the surcharge."
                    ],
                    "copyRoute": "charges.required.value"
                  },
                  {
                    "title": "currency",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      }
                    ],
                    "descriptions": [
                      "Indicates the currency associated with the surcharge amount."
                    ],
                    "link": {
                      "title": "Currencies",
                      "url": "/api/reference-data#currencies"
                    },
                    "copyRoute": "charges.required.currency"
                  },
                  {
                    "title": "type",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      }
                    ],
                    "descriptions": [
                      "Indicates the type of surcharge. Refer to appendix for list of surcharges."
                    ],
                    "copyRoute": "charges.required.type"
                  },
                  {
                    "title": "vat",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "double"
                      }
                    ],
                    "descriptions": [
                      "The vat amount associated with the surcharge. Domestic shipments only."
                    ],
                    "copyRoute": "charges.required.vat"
                  }
                ]
              },
              {
                "title": "optional",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "array"
                  }
                ],
                "descriptions": [
                  "All optional surcharges. Refer to appendix for list of surcharges that can be returned."
                ],
                "subRows": [
                  {
                    "title": "value",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "double"
                      }
                    ],
                    "descriptions": [
                      "The value of the surcharge."
                    ],
                    "copyRoute": "charges.optional.value"
                  },
                  {
                    "title": "currency",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      }
                    ],
                    "descriptions": [
                      "Indicates the currency associated with the surcharge amount."
                    ],
                    "link": {
                      "title": "Currencies",
                      "url": "/api/reference-data#currencies"
                    },
                    "copyRoute": "charges.optional.currency"
                  },
                  {
                    "title": "type",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      }
                    ],
                    "descriptions": [
                      "Indicates the type of surcharge. Refer to appendix for list of surcharges."
                    ],
                    "copyRoute": "charges.optional.type"
                  },
                  {
                    "title": "vat",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "double"
                      }
                    ],
                    "descriptions": [
                      "The vat amount associated with the surcharge. Domestic shipments only."
                    ],
                    "copyRoute": "charges.optional.vat"
                  }
                ]
              }
            ]
          },
          {
            "title": "totalCost",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Total Cost Object"
            ],
            "subRows": [
              {
                "title": "value",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "double"
                  }
                ],
                "descriptions": [
                  "Total cost of the shipment."
                ],
                "copyRoute": "totalCost.value"
              },
              {
                "title": "currency",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Indicates the currency associated with the total cost value."
                ],
                "link": {
                  "title": "Currencies",
                  "url": "/api/reference-data#currencies"
                },
                "copyRoute": "totalCost.currency"
              }
            ]
          },
          {
            "title": "rate",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Freight rate object"
            ],
            "subRows": [
              {
                "title": "value",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "double"
                  }
                ],
                "descriptions": [
                  "The freight rate amount."
                ],
                "copyRoute": "rate.value"
              },
              {
                "title": "currency",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Indicates the currency associated with the rate value. Refer to appendix for list of currency values."
                ],
                "link": {
                  "title": "Currencies",
                  "url": "/api/reference-data#currencies"
                },
                "copyRoute": "rate.currency"
              }
            ]
          }
        ]
      }
    ],
    "responseError": [
      {
        "title": "1001",
        "descriptions": [
          "Shipment Date and Time",
          "must not be null"
        ]
      },
      {
        "title": "1002",
        "descriptions": [
          "Invalid form at",
          "Shipment Date and Time"
        ]
      },
      {
        "title": "1003",
        "descriptions": [
          "Shipment Date and Time",
          "shipment date cannot be in the past or more than 7 days in the future"
        ]
      },
      {
        "title": "1011",
        "descriptions": [
          "Shipper/Customer Account Number",
          "must not be null"
        ]
      },
      {
        "title": "1012",
        "descriptions": [
          "length must be between 1 and 11"
        ]
      },
      {
        "title": "1021",
        "descriptions": [
          "Type of Shipment",
          "must not be null"
        ]
      },
      {
        "title": "1022",
        "descriptions": [
          "invalid value"
        ]
      },
      {
        "title": "1031",
        "descriptions": [
          "Sender Details",
          "must not be null"
        ]
      },
      {
        "title": "1041",
        "descriptions": [
          "Sender Address",
          "must not be null"
        ]
      },
      {
        "title": "1051",
        "descriptions": [
          "Sender Address Country Code",
          "must not be null"
        ]
      },
      {
        "title": "1052",
        "descriptions": [
          "Sender Address Country Code",
          "must be one of"
        ]
      },
      {
        "title": "1061",
        "descriptions": [
          "Sender Address Postal Code",
          "must not be null"
        ]
      },
      {
        "title": "1062",
        "descriptions": [
          "Sender Address Postal Code",
          "must be in the format"
        ]
      },
      {
        "title": "1071",
        "descriptions": [
          "Sender Address City",
          "must not be null"
        ]
      },
      {
        "title": "1072",
        "descriptions": [
          "Sender Address City",
          "length must be between 1 and 30"
        ]
      },
      {
        "title": "1081",
        "descriptions": [
          "Sender Address Residential Indicator",
          "must not be null"
        ]
      },
      {
        "title": "1082",
        "descriptions": [
          "Sender Address Residential Indicator",
          "must be 'true' or 'false'"
        ]
      },
      {
        "title": "1091",
        "descriptions": [
          "Delivery Details",
          "must not be null"
        ]
      },
      {
        "title": "1101",
        "descriptions": [
          "Delivery Address",
          "must not be null"
        ]
      },
      {
        "title": "1111",
        "descriptions": [
          "Delivery Address Country Code",
          "must not be null"
        ]
      },
      {
        "title": "1112",
        "descriptions": [
          "Delivery Address Country Code",
          "must be one of"
        ]
      },
      {
        "title": "1121",
        "descriptions": [
          "Delivery Address Postal Code",
          "must not be null"
        ]
      },
      {
        "title": "1122",
        "descriptions": [
          "Delivery Address Postal Code",
          "must be in the format"
        ]
      },
      {
        "title": "1131",
        "descriptions": [
          "Delivery Address City",
          "must not be null"
        ]
      },
      {
        "title": "1132",
        "descriptions": [
          "Delivery Address City",
          "length must be between 1 and 30"
        ]
      },
      {
        "title": "1141",
        "descriptions": [
          "Delivery Address Residential Indicator",
          "must not be null"
        ]
      },
      {
        "title": "1142",
        "descriptions": [
          "Delivery Address Residential Indicator",
          "must be 'true' or 'false'"
        ]
      },
      {
        "title": "1151",
        "descriptions": [
          "Parcels",
          "must not be null"
        ]
      },
      {
        "title": "1152",
        "descriptions": [
          "Parcels",
          "must not be empty"
        ]
      },
      {
        "title": "1161",
        "descriptions": [
          "Parcel Dimensions",
          "must not be null"
        ]
      },
      {
        "title": "1171",
        "descriptions": [
          "Parcel Length",
          "must not be null"
        ]
      },
      {
        "title": "1172",
        "descriptions": [
          "Parcel Length",
          "must be a number equal to or greater than 1"
        ]
      },
      {
        "title": "1173",
        "descriptions": [
          "Parcel Length",
          "must be upto 2 digits after the decimal"
        ]
      },
      {
        "title": "1181",
        "descriptions": [
          "Parcel Width",
          "must not be null"
        ]
      },
      {
        "title": "1182",
        "descriptions": [
          "Parcel Width",
          "must be a number equal to or greater than 1"
        ]
      },
      {
        "title": "1183",
        "descriptions": [
          "Parcel Width",
          "must be upto 2 digits after the decimal"
        ]
      },
      {
        "title": "1191",
        "descriptions": [
          "Parcel height",
          "must not be null"
        ]
      },
      {
        "title": "1192",
        "descriptions": [
          "Parcel height",
          "must be a number equal to or greater than 1"
        ]
      },
      {
        "title": "1193",
        "descriptions": [
          "Parcel height",
          "must be upto 2 digits after the decimal"
        ]
      },
      {
        "title": "1201",
        "descriptions": [
          "Unit of Measurement",
          "must not be null"
        ]
      },
      {
        "title": "1202",
        "descriptions": [
          "Unit of Measurement",
          "must be one of"
        ]
      },
      {
        "title": "1211",
        "descriptions": [
          "Parcel",
          "must not be null"
        ]
      },
      {
        "title": "1212",
        "descriptions": [
          "Parcel",
          "parcel units must be either all metric or all imperial."
        ]
      },
      {
        "title": "1213",
        "descriptions": [
          "Parcel",
          "must be upto 2 digits after the decimal"
        ]
      },
      {
        "title": "1221",
        "descriptions": [
          "Weight",
          "must not be null"
        ]
      },
      {
        "title": "1222",
        "descriptions": [
          "Weight",
          "must be greater than 0"
        ]
      },
      {
        "title": "1231",
        "descriptions": [
          "Weight",
          "must not be null"
        ]
      },
      {
        "title": "1232",
        "descriptions": [
          "Weight",
          "must be one of"
        ]
      }
    ]
  }
}`