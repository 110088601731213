import React, { useState } from 'react';
import './WhiteLabelContentPage.scss';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { useAppSelector } from '../../../../app/hooks';
import WlContentPageBanners from './wl-content-page-banners/WlContentPageBanners';

const WhiteLabelContentPage = () => {

  const customer = useAppSelector(customerDetailsSelector);

  const [activeSection, setActiveSection] = useState<'BANNERS' | 'FAQS'>('BANNERS');

  return (
    <div className="wl-content-page">
      <h2>Content</h2>

      <div className="wl-content-page__nav">
        <div
          className={
            activeSection === "BANNERS" ? "wl-content-page__nav__item wl-content-page__nav__item--active" : "wl-content-page__nav__item"
          }
          onClick={() => setActiveSection('BANNERS')}
        >Announcement Banners</div>
        {/* <div
          className={
            activeSection === "FAQS" ? "wl-content-page__nav__item wl-content-page__nav__item--active" : "wl-content-page__nav__item"
          }
          onClick={() => setActiveSection('FAQS')}
        >FAQs</div> */}
      </div>

      {activeSection === "BANNERS" && <WlContentPageBanners />}
      {/* {activeSection === "FAQS" && <WlContentPageFaqs customer={childCustomer} />} */}
    </div>
  )
}

export default WhiteLabelContentPage;