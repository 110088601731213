export const currencyCodes = [
  {
      "code": "TND",
      "dollarExchangeRate": 3.088005,
      "name": "Tunisian Dinar"
  },
  {
      "code": "LTL",
      "dollarExchangeRate": 2.95274,
      "name": "Lithuanian Litas"
  },
  {
      "name": "Turkish Lira",
      "code": "TRL"
  },
  {
      "code": "DOP",
      "dollarExchangeRate": 56.888217,
      "name": "Dominican Peso"
  },
  {
      "code": "NZD",
      "dollarExchangeRate": 1.614225,
      "name": "New Zealand Dollar"
  },
  {
      "code": "BDT",
      "dollarExchangeRate": 109.451242,
      "name": "Bangladesh Taka"
  },
  {
      "code": "ILS",
      "dollarExchangeRate": 3.67155,
      "name": "Israeli Shekel"
  },
  {
      "code": "ARS",
      "dollarExchangeRate": 800.455775,
      "name": "Argentinian Peso"
  },
  {
      "name": "Portuguese Escudo",
      "code": "PTE"
  },
  {
      "code": "MDL",
      "dollarExchangeRate": 17.74543,
      "name": "Moldovan Leu"
  },
  {
      "code": "TMT",
      "dollarExchangeRate": 3.51,
      "name": "Turkmenistani Manat"
  },
  {
      "code": "HRK",
      "dollarExchangeRate": 6.98177,
      "name": "Croatian Kuna"
  },
  {
      "code": "PHP",
      "dollarExchangeRate": 55.704503,
      "name": "Philippine Peso"
  },
  {
      "code": "INR",
      "dollarExchangeRate": 83.229196,
      "name": "Indian Rupee"
  },
  {
      "code": "HNL",
      "dollarExchangeRate": 24.536856,
      "name": "Honduran Lempira"
  },
  {
      "code": "GIP",
      "dollarExchangeRate": 0.783286,
      "name": "Gibraltar Pound"
  },
  {
      "name": "Venezuelan Bolivar",
      "code": "VEB"
  },
  {
      "code": "TTD",
      "dollarExchangeRate": 6.75485,
      "name": "Trinidad And Tobago Dollar"
  },
  {
      "code": "BAM",
      "dollarExchangeRate": 1.78046,
      "name": "Bosnia And Herzegovina Mark"
  },
  {
      "code": "CLP",
      "dollarExchangeRate": 865.619811,
      "name": "Chilean Peso"
  },
  {
      "code": "GGP",
      "dollarExchangeRate": 0.783286,
      "name": "Guernsey Pound"
  },
  {
      "code": "AED",
      "dollarExchangeRate": 3.672575,
      "name": "United Arab Emirates Dirham"
  },
  {
      "code": "BMD",
      "dollarExchangeRate": 1,
      "name": "Bermudian Dollar"
  },
  {
      "code": "KRW",
      "dollarExchangeRate": 1296.449761,
      "name": "South Korean Won"
  },
  {
      "code": "LVL",
      "dollarExchangeRate": 0.60489,
      "name": "Latvian Lats"
  },
  {
      "code": "JPY",
      "dollarExchangeRate": 141.990498,
      "name": "Japanese Yen"
  },
  {
      "code": "FJD",
      "dollarExchangeRate": 2.21195,
      "name": "Fijian Dollar"
  },
  {
      "code": "COP",
      "dollarExchangeRate": 3966.25,
      "name": "Colombian Peso"
  },
  {
      "code": "JEP",
      "dollarExchangeRate": 0.783286,
      "name": "Jersey Pound"
  },
  {
      "code": "BRL",
      "dollarExchangeRate": 4.915699,
      "name": "Brazilian Real"
  },
  {
      "code": "AZN",
      "dollarExchangeRate": 1.705819,
      "name": "Azerbaijani Manat"
  },
  {
      "code": "MOP",
      "dollarExchangeRate": 8.002585,
      "name": "Macanese Pataca"
  },
  {
      "code": "TWD",
      "dollarExchangeRate": 31.265501,
      "name": "New Taiwan Dollar"
  },
  {
      "code": "PAB",
      "dollarExchangeRate": 0.995011,
      "name": "Balboa"
  },
  {
      "code": "ERN",
      "dollarExchangeRate": 15,
      "name": "Eritrean Nakfa"
  },
  {
      "code": "CRC",
      "dollarExchangeRate": 523.091209,
      "name": "Costa Rican Colon"
  },
  {
      "code": "MKD",
      "dollarExchangeRate": 56.096207,
      "name": "Macedonian Denar"
  },
  {
      "code": "SGD",
      "dollarExchangeRate": 1.32866,
      "name": "Singapore Dollar"
  },
  {
      "code": "RUB",
      "dollarExchangeRate": 89.747499,
      "name": "Russian Ruble"
  },
  {
      "code": "MXN",
      "dollarExchangeRate": 17.20612,
      "name": "Mexican Peso"
  },
  {
      "code": "ANG",
      "dollarExchangeRate": 1.793221,
      "name": "Netherlands Antillean Guilder"
  },
  {
      "code": "FKP",
      "dollarExchangeRate": 0.783286,
      "name": "Falkland Islands Pound"
  },
  {
      "name": "Italian Lira",
      "code": "ITL"
  },
  {
      "code": "EUR",
      "dollarExchangeRate": 0.910505,
      "name": "Euro"
  },
  {
      "code": "CHF",
      "dollarExchangeRate": 0.867435,
      "name": "Swiss Franc"
  },
  {
      "code": "CAD",
      "dollarExchangeRate": 1.339605,
      "name": "Canadian Dollar"
  },
  {
      "code": "KWD",
      "dollarExchangeRate": 0.30756,
      "name": "Kuwaiti Dinar"
  },
  {
      "code": "VND",
      "dollarExchangeRate": 24260,
      "name": "Vietnam Dong"
  },
  {
      "code": "GEL",
      "dollarExchangeRate": 2.689765,
      "name": "Georgian Lari"
  },
  {
      "code": "XPF",
      "dollarExchangeRate": 110.999944,
      "name": "CFP Franc"
  },
  {
      "code": "USD",
      "dollarExchangeRate": 1,
      "name": "United States Dollar"
  },
  {
      "code": "SAR",
      "dollarExchangeRate": 3.751331,
      "name": "Saudi Riyal"
  },
  {
      "code": "KYD",
      "dollarExchangeRate": 0.829153,
      "name": "Cayman Islands Dollar"
  },
  {
      "code": "PKR",
      "dollarExchangeRate": 278.599416,
      "name": "Pakistan Rupee"
  },
  {
      "code": "XOF",
      "dollarExchangeRate": 597.155134,
      "name": "CFA Franc Bceao"
  },
  {
      "name": "Spanish Peseta",
      "code": "ESP"
  },
  {
      "code": "UZS",
      "dollarExchangeRate": 12296.193797,
      "name": "Uzbekistani Som"
  },
  {
      "code": "EGP",
      "dollarExchangeRate": 30.931957,
      "name": "Egyptian Pound"
  },
  {
      "code": "KZT",
      "dollarExchangeRate": 454.477592,
      "name": "Kazakhstani Tenge"
  },
  {
      "name": "Malagasy Franc",
      "code": "MGF"
  },
  {
      "code": "DKK",
      "dollarExchangeRate": 6.788725,
      "name": "Danish Krone"
  },
  {
      "code": "XCD",
      "dollarExchangeRate": 2.70255,
      "name": "East Caribbean Dollar"
  },
  {
      "code": "CZK",
      "dollarExchangeRate": 22.195063,
      "name": "Czech Koruna"
  },
  {
      "code": "ZAR",
      "dollarExchangeRate": 18.348303,
      "name": "South African Rand"
  },
  {
      "code": "AUD",
      "dollarExchangeRate": 1.493451,
      "name": "Australian Dollar"
  },
  {
      "code": "MMK",
      "dollarExchangeRate": 2089.451692,
      "name": "Myanmar Kyat"
  },
  {
      "code": "NOK",
      "dollarExchangeRate": 10.512875,
      "name": "Norwegian Krone"
  },
  {
      "name": "Uruguayan Peso",
      "code": "UYP"
  },
  {
      "code": "TJS",
      "dollarExchangeRate": 10.931116,
      "name": "Tajikistani Somoni"
  },
  {
      "code": "KGS",
      "dollarExchangeRate": 89.079068,
      "name": "Kyrgyzstani Som"
  },
  {
      "code": "CDF",
      "dollarExchangeRate": 2655.000254,
      "name": "Congolese Franc"
  },
  {
      "code": "AWG",
      "dollarExchangeRate": 1.8025,
      "name": "Aruban Florin"
  },
  {
      "code": "IDR",
      "dollarExchangeRate": 15492.65,
      "name": "Indonesian Rupiah"
  },
  {
      "code": "GTQ",
      "dollarExchangeRate": 7.780822,
      "name": "Guatemalan Quetzal"
  },
  {
      "code": "MYR",
      "dollarExchangeRate": 4.667503,
      "name": "Malaysian Ringgit"
  },
  {
      "code": "HKD",
      "dollarExchangeRate": 7.804965,
      "name": "Hong Kong Dollar"
  },
  {
      "name": "Netherlands Guilder",
      "code": "NLG"
  },
  {
      "code": "RSD",
      "dollarExchangeRate": 106.695976,
      "name": "Serbian Dinar"
  },
  {
      "code": "CNY",
      "dollarExchangeRate": 7.065966,
      "name": "China Yuan"
  },
  {
      "code": "MNT",
      "dollarExchangeRate": 3446.328256,
      "name": "Mongolian Tugrik"
  },
  {
      "code": "SVC",
      "dollarExchangeRate": 8.697785,
      "name": "El Salvador Colon"
  },
  {
      "code": "BOB",
      "dollarExchangeRate": 6.874954,
      "name": "Bolivian Boliviano"
  },
  {
      "code": "SEK",
      "dollarExchangeRate": 10.269605,
      "name": "Swedish Krona"
  },
  {
      "code": "PYG",
      "dollarExchangeRate": 7293.78135,
      "name": "Paraguayan Guarani"
  },
  {
      "code": "JMD",
      "dollarExchangeRate": 154.77892,
      "name": "Jamaica Dollar"
  },
  {
      "code": "ISK",
      "dollarExchangeRate": 137.029976,
      "name": "Icelandic Krona"
  },
  {
      "code": "GBP",
      "dollarExchangeRate": 0.784102,
      "name": "Pound Sterling"
  },
  {
      "name": "Luxembourg Franc",
      "code": "LUF"
  },
  {
      "code": "THB",
      "dollarExchangeRate": 34.837998,
      "name": "Thai Baht"
  }
]

export const countryCodes =
  [
    {
        "name": "Vietnam",
        "code": "VN"
    },
    {
        "name": "Switzerland",
        "code": "CH"
    },
    {
        "name": "United States",
        "code": "US"
    },
    {
        "name": "Italy",
        "code": "IT"
    },
    {
        "name": "Vanuatu",
        "code": "VU"
    },
    {
        "name": "Seychelles",
        "code": "SC"
    },
    {
        "name": "Madagascar",
        "code": "MG"
    },
    {
        "name": "Croatia",
        "code": "HR"
    },
    {
        "name": "Estonia",
        "code": "EE"
    },
    {
        "name": "China",
        "code": "CN"
    },
    {
        "name": "Niger",
        "code": "NE"
    },
    {
        "name": "Slovakia",
        "code": "SK"
    },
    {
        "name": "Timor Leste",
        "code": "TL"
    },
    {
        "name": "Haiti",
        "code": "HT"
    },
    {
        "name": "Senegal",
        "code": "SN"
    },
    {
        "name": "Bosnia And Herzegovina",
        "code": "BA"
    },
    {
        "name": "Grenada",
        "code": "GD"
    },
    {
        "name": "Nepal",
        "code": "NP"
    },
    {
        "name": "Brazil",
        "code": "BR"
    },
    {
        "name": "Saint Pierre And Miquelon",
        "code": "PM"
    },
    {
        "name": "Western Sahara",
        "code": "EH"
    },
    {
        "name": "Bolivia",
        "code": "BO"
    },
    {
        "name": "Faroe Islands",
        "code": "FO"
    },
    {
        "name": "Equatorial Guinea",
        "code": "GQ"
    },
    {
        "name": "Togo",
        "code": "TG"
    },
    {
        "name": "Samoa",
        "code": "WS"
    },
    {
        "name": "Aruba",
        "code": "AW"
    },
    {
        "name": "India",
        "code": "IN"
    },
    {
        "name": "Pakistan",
        "code": "PK"
    },
    {
        "name": "Burundi",
        "code": "BI"
    },
    {
        "name": "United Kingdom",
        "code": "GB"
    },
    {
        "name": "Armenia",
        "code": "AM"
    },
    {
        "name": "Georgia",
        "code": "GE"
    },
    {
        "name": "Chile",
        "code": "CL"
    },
    {
        "name": "Guatemala",
        "code": "GT"
    },
    {
        "name": "Sint Maarten Dutch Part",
        "code": "SX"
    },
    {
        "name": "Indonesia",
        "code": "ID"
    },
    {
        "name": "Japan",
        "code": "JP"
    },
    {
        "name": "Mongolia",
        "code": "MN"
    },
    {
        "name": "Nicaragua",
        "code": "NI"
    },
    {
        "name": "Christmas Island",
        "code": "CX"
    },
    {
        "name": "Kyrgyzstan",
        "code": "KG"
    },
    {
        "name": "Bulgaria",
        "code": "BG"
    },
    {
        "name": "Micronesia Federated States Of",
        "code": "FM"
    },
    {
        "name": "Palestine State Of",
        "code": "PS"
    },
    {
        "name": "Austria",
        "code": "AT"
    },
    {
        "name": "Paraguay",
        "code": "PY"
    },
    {
        "name": "Brunei Darussalam",
        "code": "BN"
    },
    {
        "name": "Mexico",
        "code": "MX"
    },
    {
        "name": "Svalbard And Jan Mayen",
        "code": "SJ"
    },
    {
        "name": "Netherlands Antilles",
        "code": "AN"
    },
    {
        "name": "British Indian Ocean Territory",
        "code": "IO"
    },
    {
        "name": "Ghana",
        "code": "GH"
    },
    {
        "name": "Guadeloupe",
        "code": "GP"
    },
    {
        "name": "Monaco",
        "code": "MC"
    },
    {
        "name": "Saint Vincent And The Grenadines",
        "code": "VC"
    },
    {
        "name": "Argentina",
        "code": "AR"
    },
    {
        "name": "Libya",
        "code": "LY"
    },
    {
        "name": "Honduras",
        "code": "HN"
    },
    {
        "name": "Angola",
        "code": "AO"
    },
    {
        "name": "Gibraltar",
        "code": "GI"
    },
    {
        "name": "Anguilla",
        "code": "AI"
    },
    {
        "name": "South Africa",
        "code": "ZA"
    },
    {
        "name": "Fiji",
        "code": "FJ"
    },
    {
        "name": "Bermuda",
        "code": "BM"
    },
    {
        "name": "Belgium",
        "code": "BE"
    },
    {
        "name": "Iraq",
        "code": "IQ"
    },
    {
        "name": "Sweden",
        "code": "SE"
    },
    {
        "name": "Finland",
        "code": "FI"
    },
    {
        "name": "Portugal",
        "code": "PT"
    },
    {
        "name": "Latvia",
        "code": "LV"
    },
    {
        "name": "Malta",
        "code": "MT"
    },
    {
        "name": "Canary Islands",
        "code": "IC"
    },
    {
        "name": "Uzbekistan",
        "code": "UZ"
    },
    {
        "name": "Macao",
        "code": "MO"
    },
    {
        "name": "Peru",
        "code": "PE"
    },
    {
        "name": "Australia",
        "code": "AU"
    },
    {
        "name": "Montenegro",
        "code": "ME"
    },
    {
        "name": "Aland Islands",
        "code": "AX"
    },
    {
        "name": "Ukraine",
        "code": "UA"
    },
    {
        "name": "Curacao",
        "code": "CW"
    },
    {
        "name": "Virgin Islands US",
        "code": "VI"
    },
    {
        "name": "Holy See Vatican City State",
        "code": "VA"
    },
    {
        "name": "Dominica",
        "code": "DM"
    },
    {
        "name": "Nigeria",
        "code": "NG"
    },
    {
        "name": "Cocos Keeling Islands",
        "code": "CC"
    },
    {
        "name": "Republic Of Korea",
        "code": "KR"
    },
    {
        "name": "Ecuador",
        "code": "EC"
    },
    {
        "name": "Liechtenstein",
        "code": "LI"
    },
    {
        "name": "Ethiopia",
        "code": "ET"
    },
    {
        "name": "Saint Martin French Part",
        "code": "MF"
    },
    {
        "name": "Sierra Leone",
        "code": "SL"
    },
    {
        "name": "Somalia",
        "code": "SO"
    },
    {
        "name": "Central African Republic",
        "code": "CF"
    },
    {
        "name": "Romania",
        "code": "RO"
    },
    {
        "name": "Lithuania",
        "code": "LT"
    },
    {
        "name": "Norfolk Island",
        "code": "NF"
    },
    {
        "name": "Turkmenistan",
        "code": "TM"
    },
    {
        "name": "Serbia",
        "code": "RS"
    },
    {
        "name": "Thailand",
        "code": "TH"
    },
    {
        "name": "French Southern Territories",
        "code": "TF"
    },
    {
        "name": "Andorra",
        "code": "AD"
    },
    {
        "name": "Bahrain",
        "code": "BH"
    },
    {
        "name": "Netherlands",
        "code": "NL"
    },
    {
        "name": "Greece",
        "code": "GR"
    },
    {
        "name": "Germany",
        "code": "DE"
    },
    {
        "name": "Zimbabwe",
        "code": "ZW"
    },
    {
        "name": "French Polynesia",
        "code": "PF"
    },
    {
        "name": "Mauritania",
        "code": "MR"
    },
    {
        "name": "Malaysia",
        "code": "MY"
    },
    {
        "name": "Wallis And Futuna",
        "code": "WF"
    },
    {
        "name": "Bouvet",
        "code": "BV"
    },
    {
        "name": "Gambia",
        "code": "GM"
    },
    {
        "name": "Singapore",
        "code": "SG"
    },
    {
        "name": "American Samoa",
        "code": "AS"
    },
    {
        "name": "Burkina Faso",
        "code": "BF"
    },
    {
        "name": "Cape Verde",
        "code": "CV"
    },
    {
        "name": "Saint Lucia",
        "code": "LC"
    },
    {
        "name": "Uruguay",
        "code": "UY"
    },
    {
        "name": "Liberia",
        "code": "LR"
    },
    {
        "name": "Guernsey",
        "code": "GG"
    },
    {
        "name": "Tajikistan",
        "code": "TJ"
    },
    {
        "name": "Qatar",
        "code": "QA"
    },
    {
        "name": "Barbados",
        "code": "BB"
    },
    {
        "name": "Zambia",
        "code": "ZM"
    },
    {
        "name": "Cyprus",
        "code": "CY"
    },
    {
        "name": "Antarctica",
        "code": "AQ"
    },
    {
        "name": "Papua New Guinea",
        "code": "PG"
    },
    {
        "name": "Greenland",
        "code": "GL"
    },
    {
        "name": "Belarus",
        "code": "BY"
    },
    {
        "name": "Turkey",
        "code": "TR"
    },
    {
        "name": "Congo",
        "code": "CG"
    },
    {
        "name": "Lesotho",
        "code": "LS"
    },
    {
        "name": "Tuvalu",
        "code": "TV"
    },
    {
        "name": "Comoros",
        "code": "KM"
    },
    {
        "name": "Guam",
        "code": "GU"
    },
    {
        "name": "Saint Kitts And Nevis",
        "code": "KN"
    },
    {
        "name": "Colombia",
        "code": "CO"
    },
    {
        "name": "Mozambique",
        "code": "MZ"
    },
    {
        "name": "Israel",
        "code": "IL"
    },
    {
        "name": "Kuwait",
        "code": "KW"
    },
    {
        "name": "Cayman Islands",
        "code": "KY"
    },
    {
        "name": "United States Minor Outlying Isl",
        "code": "UM"
    },
    {
        "name": "Niue",
        "code": "NU"
    },
    {
        "name": "New Caledonia",
        "code": "NC"
    },
    {
        "name": "Trinidad And Tobago",
        "code": "TT"
    },
    {
        "name": "Jordan",
        "code": "JO"
    },
    {
        "name": "Lao Peoples Democratic Republic",
        "code": "LA"
    },
    {
        "name": "Myanmar",
        "code": "MM"
    },
    {
        "name": "Namibia",
        "code": "NA"
    },
    {
        "name": "Kenya",
        "code": "KE"
    },
    {
        "name": "Marshall Islands",
        "code": "MH"
    },
    {
        "name": "Northern Mariana Islands",
        "code": "MP"
    },
    {
        "name": "Dominican Republic",
        "code": "DO"
    },
    {
        "name": "Philippines",
        "code": "PH"
    },
    {
        "name": "Falkland Islands Malvinas",
        "code": "FK"
    },
    {
        "name": "Ireland",
        "code": "IE"
    },
    {
        "name": "Mayotte",
        "code": "YT"
    },
    {
        "name": "Saint Helena",
        "code": "SH"
    },
    {
        "name": "Kosovo",
        "code": "KV"
    },
    {
        "name": "Algeria",
        "code": "DZ"
    },
    {
        "name": "Rwanda",
        "code": "RW"
    },
    {
        "name": "Tanzania United Republic Of",
        "code": "TZ"
    },
    {
        "name": "Gabon",
        "code": "GA"
    },
    {
        "name": "Ivory Coast",
        "code": "CI"
    },
    {
        "name": "Czech Republic",
        "code": "CZ"
    },
    {
        "name": "El Salvador",
        "code": "SV"
    },
    {
        "name": "Swaziland",
        "code": "SZ"
    },
    {
        "name": "Guinea",
        "code": "GN"
    },
    {
        "name": "Montserrat",
        "code": "MS"
    },
    {
        "name": "Palau",
        "code": "PW"
    },
    {
        "name": "San Marino",
        "code": "SM"
    },
    {
        "name": "Kiribati",
        "code": "KI"
    },
    {
        "name": "Nauru",
        "code": "NR"
    },
    {
        "name": "Reunion",
        "code": "RE"
    },
    {
        "name": "Hong Kong",
        "code": "HK"
    },
    {
        "name": "Kazakhstan",
        "code": "KZ"
    },
    {
        "name": "Martinique",
        "code": "MQ"
    },
    {
        "name": "Slovenia",
        "code": "SI"
    },
    {
        "name": "Tokelau",
        "code": "TK"
    },
    {
        "name": "Guinea Bissau",
        "code": "GW"
    },
    {
        "name": "Cambodia",
        "code": "KH"
    },
    {
        "name": "Bahamas",
        "code": "BS"
    },
    {
        "name": "Suriname",
        "code": "SR"
    },
    {
        "name": "Antigua And Barbuda",
        "code": "AG"
    },
    {
        "name": "Benin",
        "code": "BJ"
    },
    {
        "name": "Morocco",
        "code": "MA"
    },
    {
        "name": "Taiwan Province Of China",
        "code": "TW"
    },
    {
        "name": "Saint Barthelemy",
        "code": "BL"
    },
    {
        "name": "Oman",
        "code": "OM"
    },
    {
        "name": "Afghanistan",
        "code": "AF"
    },
    {
        "name": "France",
        "code": "FR"
    },
    {
        "name": "Virgin Islands British",
        "code": "VG"
    },
    {
        "name": "Bhutan",
        "code": "BT"
    },
    {
        "name": "Denmark",
        "code": "DK"
    },
    {
        "name": "Iceland",
        "code": "IS"
    },
    {
        "name": "Egypt",
        "code": "EG"
    },
    {
        "name": "Cameroon",
        "code": "CM"
    },
    {
        "name": "Cook Islands",
        "code": "CK"
    },
    {
        "name": "Bonaire Sint Eustatius And Saba",
        "code": "BQ"
    },
    {
        "name": "Mauritius",
        "code": "MU"
    },
    {
        "name": "Spain",
        "code": "ES"
    },
    {
        "name": "Azerbaijan",
        "code": "AZ"
    },
    {
        "name": "Hungary",
        "code": "HU"
    },
    {
        "name": "Moldova Republic Of",
        "code": "MD"
    },
    {
        "name": "Chad",
        "code": "TD"
    },
    {
        "name": "Sri Lanka",
        "code": "LK"
    },
    {
        "name": "Poland",
        "code": "PL"
    },
    {
        "name": "Heard Island And Mcdonald Islands",
        "code": "HM"
    },
    {
        "name": "Republic Of North Macedonia",
        "code": "MK"
    },
    {
        "name": "Eritrea",
        "code": "ER"
    },
    {
        "name": "Panama",
        "code": "PA"
    },
    {
        "name": "Albania",
        "code": "AL"
    },
    {
        "name": "Tonga",
        "code": "TO"
    },
    {
        "name": "Lebanon",
        "code": "LB"
    },
    {
        "name": "Jamaica",
        "code": "JM"
    },
    {
        "name": "Puerto Rico",
        "code": "PR"
    },
    {
        "name": "Bangladesh",
        "code": "BD"
    },
    {
        "name": "Malawi",
        "code": "MW"
    },
    {
        "name": "Turks And Caicos Islands",
        "code": "TC"
    },
    {
        "name": "South Georgia And Sth Sandwich Isl",
        "code": "GS"
    },
    {
        "name": "Guyana",
        "code": "GY"
    },
    {
        "name": "Canada",
        "code": "CA"
    },
    {
        "name": "Uganda",
        "code": "UG"
    },
    {
        "name": "Luxembourg",
        "code": "LU"
    },
    {
        "name": "Costa Rica",
        "code": "CR"
    },
    {
        "name": "Pitcairn",
        "code": "PN"
    },
    {
        "name": "New Zealand",
        "code": "NZ"
    },
    {
        "name": "Saudi Arabia",
        "code": "SA"
    },
    {
        "name": "French Guiana",
        "code": "GF"
    },
    {
        "name": "United Arab Emirates",
        "code": "AE"
    },
    {
        "name": "Solomon Islands",
        "code": "SB"
    },
    {
        "name": "Congo The Democratic Republic",
        "code": "CD"
    },
    {
        "name": "Maldives",
        "code": "MV"
    },
    {
        "name": "Belize",
        "code": "BZ"
    },
    {
        "name": "Jersey",
        "code": "JE"
    },
    {
        "name": "Isle Of Man",
        "code": "IM"
    },
    {
        "name": "Djibouti",
        "code": "DJ"
    },
    {
        "name": "Botswana",
        "code": "BW"
    },
    {
        "name": "Democratic Peoples Republic Of Korea",
        "code": "KP"
    },
    {
        "name": "Tunisia",
        "code": "TN"
    },
    {
        "name": "Norway",
        "code": "NO"
    },
    {
        "name": "Mali",
        "code": "ML"
    },
    {
        "name": "Sao Tome And Principe",
        "code": "ST"
    }
]