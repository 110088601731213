import React, { useState } from 'react';
import './Switch.scss';

const AMISwitch: React.FC<{
  titles: string[]
  position: number
  onToggle: (value: number) => void
}> = ({
  titles,
  position,
  onToggle
}) => {

  const toggleSwitch = () => {
    onToggle(position === 0 ? 1 : 0);
  }

  return (
    <div
      className="switch"
      onClick={toggleSwitch}
    >
      <p>{titles[0]}</p>

      <div className={position ? "switch__toggle switch__toggle--1" : "switch__toggle switch__toggle--0"}></div>

      <p>{titles[1]}</p>
    </div>
  )
}

export default AMISwitch;