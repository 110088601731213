import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { customerDetailsSelector } from '../../features/customerSlice';
import './ContactPage.scss';
import { contactArray } from './utils';

const ContactPage = () => {

  const customer = useAppSelector(customerDetailsSelector);
  const [orderedContactDetails, setOrderedContactDetails] = useState<any>([]);

  useEffect(() => {
    setHomeContactDetailsToTop();
  }, []);

  const setHomeContactDetailsToTop = () => {
    const contactArrayCopy = [...contactArray];
    let searchString = '';

    switch (customer.customerCountryCode) {
      case 'AU':
        searchString = 'Australia';
        break;
      case 'GB':
        searchString = 'United Kingdom';
        break;
      case 'US':
        searchString = 'USA';
        break;
      case 'NZ':
        searchString = 'New Zealand';
        break;
      case 'ZA':
        searchString = 'South Africa';
        break;
    }
  
    const contact = contactArrayCopy.find((contact: string) => {
      return contact.includes(searchString)
    }) as string;

    const contactIndex = contactArrayCopy.indexOf(contact);
    contactArrayCopy.splice(contactIndex, 1);
    contactArrayCopy.unshift(contact);

    setOrderedContactDetails(contactArrayCopy);
  }

  return (
    <div className="contact-page vertical-card">
      <h1 className="card-header">Contact Us</h1>
      <p>If you experience any difficulties with this site or want to find out more about the services we offer, please contact us through the appropriate details listed below.</p>
      
      {orderedContactDetails.map((contactDetail: string, index: number) => {
        return (
          <div className="horizontal-card" key={index}>
          <div className="contact-page__contact-entry">
            <div dangerouslySetInnerHTML={{__html: contactDetail}}></div>
          </div>
        </div>
        )
      })}
    </div>
  )
}

export default ContactPage;