import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './Select.scss';
import AMIOption, { AMISelectOption } from './option/Option';
import { AMISpinner } from '../UiComponents';

interface Props {
  name: string,
  disabled?: boolean,
  size?: 'small' | 'large',
  style?: React.CSSProperties,
  className?: string,
  id?: string,
  options: {title: string, value: string | number, disabled?: boolean}[],
  defaultValue?: AMISelectOption,
  isSearchable?: boolean,
  tabIndex?: number,
  isSpinning?: boolean,
  isSpinningMessage?: string,
  hideClearButton?: boolean,
  placeholder?: string,
  onChange?: (event: any) => void,
  customEvent?: () => void
}

const AMISelect: React.FC<Props> = ({
  name,
  disabled,
  size = 'small',
  style,
  className,
  id,
  options,
  defaultValue,
  isSearchable,
  tabIndex,
  isSpinning,
  isSpinningMessage,
  hideClearButton = false,
  placeholder,
  onChange,
  customEvent
}) => {

  const [isFocused, setIsFocused] = useState(false);
  const [searchString, setSearchString] = useState<string | undefined>(defaultValue ? defaultValue.title : '');
  const [filteredOptions, setFilteredOptions] = useState(options);

  const getClasses = () => {
    let classes = `${className} ami-select `;

    if (disabled) {
      classes = classes.concat('ami-select--disabled ');
    }

    if (size === 'large') {
      classes = classes.concat('ami-select--large ');
    }

    return classes;
  }

  const selectClasses = getClasses();

  const getDropdownClass = () => {
    if (isFocused) {
      return 'ami-select__dropdown';
    } else {
      return 'ami-select__dropdown--hidden'
    }
  }

  const dropDownClass = getDropdownClass();

  const handleSelect = (event: any) => {
    setSearchString(event.title);
    if (event.value !== 'CUSTOM_EVENT') {
      onChange?.(event);
    }

    if (event.value === 'CUSTOM_EVENT') {
      customEvent?.();
    }

    setIsFocused(false);
  }

  const onSearch = (event: any) => {
    setSearchString(event.target.value)
    setActiveIndex(0);
    setFilteredOptions(options.filter((option) => {
      return option.title.toLowerCase().includes(event.target.value.toLowerCase());
    }))
  }

  useEffect(() => {
    setFilteredOptions(options);
  }, [options])

  useEffect(() => {
    setSearchString(defaultValue ? defaultValue.title : '')
  }, [defaultValue])

  const [activeIndex, setActiveIndex] = useState(-1);

  const onKeyDown = (event: any) => {
    if(event.keyCode === 40) {
      setSearchString(filteredOptions[activeIndex + 1].title);
      setActiveIndex(activeIndex + 1);
    } else if (event.keyCode === 38) {
      setSearchString(filteredOptions[activeIndex - 1].title);
      setActiveIndex(activeIndex - 1);
    } else if (event.keyCode === 13 || event.keyCode === 9) {
      if (activeIndex > -1) {
        if (filteredOptions.length > 0) {
          handleSelect(filteredOptions[activeIndex]);
        }
      }
      setIsFocused(false);
    }
  }

  const highlightCorrectOption = () => {
    const currentOption = options.find((option) => {
      return option.value === defaultValue?.value
    })
    if (currentOption) {
      const index = options.indexOf(currentOption);
      setActiveIndex(index);
    }
  }

  const onFocus = () => {
    setIsFocused(true);
    highlightCorrectOption();
    setFilteredOptions(options);
  }

  const onClear = () => {
    setSearchString('')
    setActiveIndex(0);
    setFilteredOptions(options);
    onChange?.({
      title: '',
      value: ''
    });
  }

  const Mask: React.FC<{onClick: () => void}> = ({onClick}) => {
    return <div className="full-screen-clear-mask" onClick={onClick}/>
  }

  return (
    <>
      {isFocused
        && (
          ReactDOM.createPortal(
            <Mask onClick={() => setIsFocused(false)} />,
            document.getElementById('full-screen-clear-mask') as Element
          )
        )
      }
      <input
        name={name}
        tabIndex={tabIndex}
        className={selectClasses}
        id={id}
        style={style}
        disabled={disabled}
        onKeyDown={(event) => onKeyDown(event)}
        value={searchString}
        onFocus={() => onFocus()}
        readOnly={!isSearchable}
        autoComplete="do-not-autofill"
        onChange={onSearch}
        placeholder={placeholder}
      ></input>

      {
        searchString
        && !hideClearButton
        && (
          <span
            className="ami-select__clear-btn"
            style={size === "small"
              ? {marginTop: '-6px', marginLeft: '1px'}
              : {}
            }
            onClick={onClear}
          >X</span>
        )
      }

      {isFocused
        && (
          <datalist
            id="list"
            className={dropDownClass}
            style={{display: 'block'}}
          >
            {isSpinning
              && (
                <div className="ami-select__spinner">
                  <AMISpinner
                    style={{
                      width: "40px",
                      height: "40px"
                    }} /> {isSpinningMessage}
                </div>
              )
            }
            {!isSpinning && filteredOptions.map((opt, index) => {
              return (
                <AMIOption
                  value={opt}
                  isActive={activeIndex === index}
                  key={index}
                  style={opt.value === 'CUSTOM_EVENT' ? {color: 'var(--primary)'} : {color: 'var(--text)'}}
                  onSelect={handleSelect}
                  disabled={opt.disabled}
                >{opt.title}</AMIOption>
              )
            })}
          </datalist>
        )
      }
    </>
  )
}

export default AMISelect;