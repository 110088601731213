import React, { useEffect, useState } from 'react';
import './WeightBreak.scss';
import { AMIFormElement, AMIInput } from '../../../../../../../ui-components/UiComponents';
import { Distribution, MarkupType, PerShipmentType } from '../Utils_CustomerPageMarkups';

const WeightBreak: React.FC<{
  weightValue: string;
  currency: string;
  weightBreak: any;
  isFirst: boolean;
  isLast: boolean;
  shipmentType: PerShipmentType;
  markupType: MarkupType;
  showErrors: boolean;
  onWeightBreakChange: (shipmentType: PerShipmentType, field: 'upTo' | 'from' | 'markup', event: any) => void
}> = ({
  weightValue,
  currency,
  weightBreak,
  isFirst,
  isLast,
  shipmentType,
  markupType,
  showErrors,
  onWeightBreakChange
}) => {

  const [symbol, setSymbol] = useState('%');

  useEffect(() => {
    setSymbol(markupType === MarkupType.FLAT_AMOUNT ? currency : '%')
  }, [])

  if (isFirst) return (
    <>
      <div className="weight-break">
        <AMIFormElement
          label={`Up to (${weightValue})`}
          errorMessage={showErrors && !+weightBreak.upTo && +weightBreak.upTo <= 0 ? "> 0" : ""}
        >
          <AMIInput
            value={weightBreak.upTo}
            type="number"
            onChange={(event) => onWeightBreakChange(shipmentType, "upTo", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement
          label={`Markup (${symbol})`}
          errorMessage={showErrors && !+weightBreak.markup ? "Required" : ""}
        >
          <AMIInput
            value={weightBreak.markup}
            type="number"
            onChange={(event) => onWeightBreakChange(shipmentType, "markup", event.target.value)}
          />
        </AMIFormElement>
      </div>
      <p>Between {weightBreak.from + weightValue} and {weightBreak.upTo + weightValue} markup shipment by {weightBreak.markup}{symbol}</p>
    </>
  )

  if (isLast) return (
    <>
      <div className="weight-break">
        <AMIFormElement
          label={`Above (${weightValue})`}
        >
          <AMIInput
            disabled
            value={weightBreak.from}
            onChange={(event) => onWeightBreakChange(shipmentType, "from", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement
          label={`Markup (${symbol})`}
          errorMessage={showErrors && !+weightBreak.markup ? "Required" : ""}
        >
          <AMIInput
            value={weightBreak.markup}
            type="number"
            onChange={(event) => onWeightBreakChange(shipmentType, "markup", event.target.value)}
          />
        </AMIFormElement>
      </div>
      <p>Above {weightBreak.from + weightValue} markup shipment by {weightBreak.markup}{symbol}</p>
    </>
  )

  return (
    <>
      <div className="weight-break">
        <AMIFormElement
          label={`From (${weightValue})`}
        >
          <AMIInput
            disabled
            value={weightBreak.from}
            onChange={(event) => onWeightBreakChange(shipmentType, "from", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement
          label={`To (${weightValue})`}
          errorMessage={
            showErrors && (!+weightBreak.upTo || +weightBreak.upTo < +weightBreak.from) ? ">= from" : ""
          }
        >
          <AMIInput
            value={weightBreak.upTo}
            type="number"
            onChange={(event) => onWeightBreakChange(shipmentType, "upTo", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement
          label={`Markup (${symbol})`}
          errorMessage={showErrors && !+weightBreak.markup ? "Required" : ""}
        >
          <AMIInput
            value={weightBreak.markup}
            type="number"
            onChange={(event) => onWeightBreakChange(shipmentType, "markup", event.target.value)}
          />
        </AMIFormElement>

      </div>
      <p>Between {weightBreak.from + weightValue} and {weightBreak.upTo + weightValue} markup shipment by {weightBreak.markup}{symbol}</p>
    </>
  )
}

export default WeightBreak;