import React, { useEffect, useState } from 'react';
import { AMIFormElement, AMIInput, AMISelect, AMISpinner } from '../../../../../ui-components/UiComponents';
import TaxNumber from '../../../../../components/tax-numbers/TaxNumbers';
import { addTaxNumber, deleteTaxNumber } from '../utils';
import { useAppSelector } from '../../../../../app/hooks';
import { appDataSelector } from '../../../../../features/appDataSlice';
import { TemplateForm } from '../../../../../utilities/TemplateHandler';
import { getCountryInfo } from '../../../../../utilities/APIUtilities';
import { formatCounties } from '../../../../../utilities/HelperUtilities';

const TemplateModalConsigneeDetails: React.FC<{
  template: TemplateForm;
  updateConsigneeDetails: (field: string, value: any) => void;
}> = ({
  template,
  updateConsigneeDetails
}) => {

  const appData = useAppSelector(appDataSelector);
  const data = template.consignee;
  const taxNumbers = data.taxNumbers;
  const [handlingCountryInfo, setHandlingCountryInfo] = useState(false);
  const [counties, setCounties] = useState<any>([]);

  useEffect(() => {
    if (template.countries.destination.value) handleCountryInfo();
  }, [])

  const handleCountryInfo = async() => {
    setHandlingCountryInfo(true);
    const countryInfo = await getCountryInfo(appData.apiConfig, template.countries.destination.value);
    if (countryInfo?.countiesStates?.length > 0) {
      setCounties(formatCounties(countryInfo.countiesStates));
    }
    setHandlingCountryInfo(false);
  }

  if (handlingCountryInfo) {
    return (
      <div className="template-modal-spinner">
        <AMISpinner />
        <p>Fetching country info...</p>
      </div>
    )
  }

  if (!template.countries.destination.value) {
    return (
      <div className="template-modal-collection-details">
        <p>A destination country must be selected under the Countries tab before populating Collection details.</p>
      </div>
    )
  }

  return (
    <div className="template-modal-consignee-details">
      <AMIFormElement label="Full Contact Name">
        <AMIInput
          name="consigneeContactName"
          size="large"
          value={data.contactName}
          onChange={(event) => updateConsigneeDetails("contactName", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Company Name">
        <AMIInput
          name="consigneeCompanyName"
          size="large"
          value={data.companyName}
          onChange={(event) => updateConsigneeDetails("companyName", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Country"
        className="template-modal__country"
      >
        <AMISelect
          name="consignee-country"
          size="large"
          isSearchable
          defaultValue={template.countries.destination}
          options={appData.countries}
          disabled
          hideClearButton
          onChange={(event) => updateConsigneeDetails("country", event)}
        />
      </AMIFormElement>

      <AMIFormElement label="Address Line 1">
        <AMIInput
          name="consigneeAddressLine1"
          size="large"
          value={data.addressLine1}
          onChange={(event) => updateConsigneeDetails("addressLine1", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Address Line 2">
        <AMIInput
          name="consigneeAddressLine2"
          size="large"
          value={data.addressLine2}
          onChange={(event) => updateConsigneeDetails("addressLine2", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Address Line 3">
        <AMIInput
          name="consigneeAddressLine3"
          size="large"
          value={data.addressLine3}
          onChange={(event) => updateConsigneeDetails("addressLine3", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="City/Town">
        <AMIInput
          name="consigneeCityTown"
          size="large"
          value={template.countries.destinationCityTown ? template.countries.destinationCityTown : data.cityTown}
          disabled={!!template.countries.destinationCityTown}
          onChange={(event) => updateConsigneeDetails("cityTown", event.target.value)}
        />
      </AMIFormElement>

      {counties.length === 0 && (
        <AMIFormElement label="County/State/Province">
          <AMIInput
            name="consigneeCountyStateProvince"
            size="large"
            value={data.countyStateProvince}
            onChange={(event) => updateConsigneeDetails('countyStateProvince', event.target.value)}
          />
        </AMIFormElement>
      )}

      {counties.length > 0 && (
        <AMIFormElement label="County/State/Province">
          <AMISelect
            name="consigneeCountyStateProvince"
            size="large"
            isSearchable
            options={counties}
            onChange={(event) => updateConsigneeDetails('countyStateProvince', event.value)}
            defaultValue={counties.find((item: any) => {
              return item.value === data.countyStateProvince
            })}
          />
        </AMIFormElement>
      )}

      <AMIFormElement label="Postal/Zip Code">
        <AMIInput
          name="consigneePostalCode"
          size="large"
          value={template.countries.destinationPostalCode ? template.countries.destinationPostalCode : data.postalCode}
          disabled={!!template.countries.destinationPostalCode}
          onChange={(event) => updateConsigneeDetails("postalCode", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Telephone Number">
        <AMIInput
          name="consigneeTelephoneNumber"
          size="large"
          value={data.telephoneNumber}
          onChange={(event) => updateConsigneeDetails("telephoneNumber", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Email">
        <AMIInput
          name="consigneeEmail"
          size="large"
          type="email"
          value={data.email}
          onChange={(event) => updateConsigneeDetails("email", event.target.value)}
        />
      </AMIFormElement>

      {taxNumbers.map((element: any, index: number) => {
        return (
          <div className="tax-numbers-wrapper" key={index}>
            <TaxNumber
              element={element}
              index={index}
              allElements={taxNumbers}
              booking={template}
              detailsContainer="consignee"
              updateElements={(event) => updateConsigneeDetails("taxNumbers", event)}
              onDelete={() => updateConsigneeDetails("taxNumbers", deleteTaxNumber(taxNumbers, index))}
            />
          </div>
        )
      })}

      {<p className="tax-numbers-add-text" onClick={() => updateConsigneeDetails("taxNumbers", addTaxNumber(taxNumbers))}>+ Add another tax number</p>}

      <AMIFormElement
        label="Additional Information / Special Instructions"
        style={{gridColumn: '1 / 3'}}
      >
        <AMIInput
          name="consigneeAdditionalInfo"
          size="large"
          value={data.additionalInfo}
          onChange={(event) => updateConsigneeDetails("additionalInfo", event.target.value)}
        />
      </AMIFormElement>
    </div>
  )

}

export default TemplateModalConsigneeDetails;