import { Booking, BrokerDetails, CollectionDetails, ConsigneeDetails, Country, Currency, ImperialMetric, Piece, ShipmentType, TermsOfSale } from "../features/bookingSlice";
import { PackageLocation } from "./APIUtilities";
import {v4 as uuidv4} from 'uuid';

type Commodity = {
  commodityCode: string;
  description: string;
  countryOfOrigin: string;
  quantity: {
    value: string;
    unit: string
  };
  unitPrice: {
    value: string;
    currency: string
  };
  weight: {
    value: string;
    unit: string
  };
}

type TemplateShipperDetails = {
  contactName: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  countyStateProvince: string;
  cityTown: string;
  postalCode: string;
  country: Country;
  telephoneNumber: string;
  email: string;
  taxNumbers: [
    {
      type: string,
      value: string
    }
  ];
  aes: string,
  edn: string,
  trueShipper: boolean;
}

export type Template = {
  id: string;
  name: string;
  variant: "SINGLE" | "BULK";
  isDefault: boolean;
  data: {
    origin: Country;
    originCityTown: string;
    originPostalCode: string;
    originResidential: boolean;
    destination: Country;
    destinationCityTown: string;
    destinationPostalCode: string;
    destinationResidential: boolean;
    shipmentType: ShipmentType;
    pieces: Piece[];
    imperialMetric: ImperialMetric;
    totalShipmentValue: string;
    preferredCurrency: Currency;
    insure: boolean;
    ddp: boolean;
    hawb: string;
    contentDescription: string;
    countryOfOrigin: Country;
    reasonForExport: string;
    collectionDetails: CollectionDetails;
    shipperDetails: TemplateShipperDetails;
    trueShipper: boolean;
    consigneeDetails: ConsigneeDetails;
    brokerDetails: BrokerDetails;
    emailPreference: string;
    labelTypePreference: string;
    commodities: Commodity[];
  }
}

export type TemplateForm = {
  id: string;
  name: string;
  variant: 'SINGLE' | 'BULK';
  isDefault: boolean;
  countries: {
    origin: Country;
    originCityTown: string;
    originPostalCode: string;
    originResidential: boolean;
    destination: Country;
    destinationCityTown: string;
    destinationPostalCode: string;
    destinationResidential: boolean;
  },
  piecesSection: {
    shipmentType: ShipmentType;
    imperialMetric: ImperialMetric;
    totalShipmentValue: string;
    preferredCurrency: Currency;
    pieces: Piece[];
  }
  shipment: {
    insure: boolean;
    ddp: boolean;
    hawb: string;
    contentDescription: string;
    countryOfOrigin: Country;
    reasonForExport: string;
  },
  collection: CollectionDetails;
  shipper: TemplateShipperDetails;
  consignee: ConsigneeDetails;
  broker: BrokerDetails;
  commodities: Commodity[];
  preferences: {
    email: string;
    labelType: string;
  }
}

export const BLANK_FORM_TEMPLATE = {
  id: '',
  name: '',
  variant: 'SINGLE',
  isDefault: false,
  countries: {
    origin: {
      title: '',
      value: '',
    },
    originCityTown: '',
    originPostalCode: '',
    originResidential: false,
    destination: {
      title: '',
      value: '',
    },
    destinationCityTown: '',
    destinationPostalCode: '',
    destinationResidential: false,
  },
  piecesSection: {
    shipmentType: ShipmentType.NON_DOCS,
    imperialMetric: ImperialMetric.METRIC,
    totalShipmentValue: '',
    preferredCurrency: {
      title: '£ GBP',
      value: 'GBP',
      symbol: '£'
    },
    pieces: [{
      weight: '',
      length: '',
      width: '',
      height: ''
    }],
  },
  shipment: {
    insure: false,
    ddp: false,
    hawb: '',
    contentDescription: '',
    countryOfOrigin: {
      title: '',
      value: '',
    },
    reasonForExport: '',
  },
  collection: {
    pickUpFrom: '',
    pickUpTo: '',
    contactName: '',
    companyName: '',
    residential: false,
    packageLocation: PackageLocation.NONE,
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    countyStateProvince: '',
    cityTown: '',
    postalCode: '',
    telephoneNumber: '',
    email: '',
    additionalInfo: '',
  },
  shipper: {
    contactName: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    countyStateProvince: '',
    cityTown: '',
    postalCode: '',
    country: {
      title: '',
      value: '',
    },
    telephoneNumber: '',
    email: '',
    taxNumbers: [
      {
        type: '',
        value: ''
      }
    ],
    aes: '',
    edn: '',
    trueShipper: true
  },
  consignee: {
    contactName: '',
    companyName: '',
    residential: false,
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    countyStateProvince: '',
    cityTown: '',
    postalCode: '',
    telephoneNumber: '',
    email: '',
    sendEmail: false,
    taxNumbers: [
      {
        type: '',
        value: ''
      }
    ],
    additionalInfo: ''
  },
  broker: {
    brokerSelected: false,
    contactName: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    countyStateProvince: '',
    cityTown: '',
    country: {
      title: '',
      value: '',
    },
    postalCode: '',
    telephoneNumber: '',
    email: '',
    taxNumbers: [
      {
        type: '',
        value: ''
      }
    ],
  },
  commodities: [{
    commodityCode: '',
    description: '',
    quantity: {
      value: '',
      unit: ''
    },
    unitPrice: {
      value: '',
      currency: ''
    },
    weight: {
      value: '',
      unit: ''
    },
  }],
  preferences: {
    email: '',
    labelType: '',
  }
}

export function convertTemplateToAppState(template: any, bookingState: Booking) {
  // Takes a template, which may or may not match the template Type above,
  // and converts it to the current bookingState.
  let newState: Booking = JSON.parse(JSON.stringify(bookingState));
  if (template.origin) newState.origin = {...bookingState.origin, ...template.origin};
  if (template.originCityTown) newState.originCityTown = template.originCityTown;
  if (template.originPostalCode) newState.originPostalCode = template.originPostalCode;
  if (template.originResidential) newState.originResidential = template.originResidential;
  if (template.destination) newState.destination = {...bookingState.destination, ...template.destination};
  if (template.destinationCityTown) newState.destinationCityTown = template.destinationCityTown;
  if (template.destinationPostalCode) newState.destinationPostalCode = template.destinationPostalCode;
  if (template.destinationResidential) newState.destinationResidential = template.destinationResidential;
  if (template.shipmentType) newState.shipmentType = template.shipmentType;
  if (template.pieces) newState.pieces = populatePieces(template.pieces);
  if (template.imperialMetric) newState.imperialMetric = template.imperialMetric;
  if (template.totalShipmentValue) newState.totalShipmentValue = template.totalShipmentValue;
  if (template.preferredCurrency) newState.preferredCurrency = {...bookingState.preferredCurrency, ...template.preferredCurrency};
  if (template.insure) newState.insure = template.insure;
  if (template.ddp) newState.customsDetails.invoiceDetails.termsOfSale = TermsOfSale.DDP;
  if (template.hawb) newState.hawb = template.hawb;
  if (template.contentDescription) newState.contentDescription = template.contentDescription;
  if (template.countryOfOrigin) newState.countryOfOrigin = {...bookingState.countryOfOrigin, ...template.countryOfOrigin};
  if (template.reasonForExport) newState.reasonForExport = template.reasonForExport;
  if (template.collectionDetails) newState.collectionDetails = {...bookingState.collectionDetails, ...template.collectionDetails};
  if (template.shipperDetails) newState.shipperDetails = {...bookingState.shipperDetails, ...template.shipperDetails};
  if (template.trueShipper) newState.trueShipper = template.trueShipper;
  if (template.consigneeDetails) newState.consigneeDetails = {...bookingState.consigneeDetails, ...template.consigneeDetails};
  if (template.brokerDetails) newState.brokerDetails = {...bookingState.brokerDetails, ...template.brokerDetails};
  if (template.freightCharge) newState.freightCharge = template.freightCharge;
  if (template.emailPreference) newState.preferences.email = template.emailPreference;
  if (template.labelTypePreference) newState.preferences.labelType = template.labelTypePreference;
  if (template.termsOfSale) newState.customsDetails.invoiceDetails.termsOfSale = template.termsOfSale;
  if (template.cpc) newState.customsDetails.invoiceDetails.cpc = template.cpc;
  if (template.commodities) newState.customsDetails.commodities = populateCommodities(template.commodities);
  return newState;
}

export function populatePieces(pieces: any) {
  const newPieces: Piece[] = [];
  for (const piece of pieces) {
    let newPiece: Piece = {
      weight: '',
      length: '',
      width: '',
      height: ''
    };

    if (piece.weight) newPiece.weight = piece.weight;
    if (piece.length) newPiece.length = piece.length;
    if (piece.width) newPiece.width = piece.width;
    if (piece.height) newPiece.height = piece.height;
    newPieces.push(newPiece);
  }
  return newPieces;
}

export function populateCommodities(commodities: any[]) {
  const blankCommodity = {
    commodityCode: '',
    description: '',
    countryOfOrigin: '',
    quantity: {
      value: '',
      unit: ''
    },
    unitPrice: {
      value: '',
      currency: ''
    },
    weight: {
      value: '',
      unit: ''
    }
  }

  const newCommodities: Commodity[] = [];
  for (const commodity of commodities) {
    let newCommodity = {...blankCommodity};

    if (commodity.commodityCode) newCommodity.commodityCode = commodity.commodityCode;
    if (commodity.description) newCommodity.description = commodity.description;
    if (commodity.countryOfOrigin) newCommodity.countryOfOrigin = commodity.countryOfOrigin;
    if (commodity.quantity) newCommodity.quantity = commodity.quantity;
    if (commodity.unitPrice) newCommodity.unitPrice = commodity.unitPrice;
    if (commodity.weight) newCommodity.weight = commodity.weight;

    newCommodities.push(newCommodity);
  }
  return newCommodities;
}

export function convertToTemplateFromBooking(booking: Booking, name: string, variant: string) {
  // Saves a template to the current TemplateType standard after a successful booking.
  return ({
    id: uuidv4(),
    name,
    variant,
    isDefault: false,
    data: {
      origin: booking.origin,
      originCityTown: booking.originCityTown,
      originPostalCode: booking.originPostalCode,
      originResidential: booking.originResidential,
      destination: booking.destination,
      destinationCityTown: booking.destinationCityTown,
      destinationPostalCode: booking.destinationPostalCode,
      destinationResidential: booking.destinationResidential,
      shipmentType: booking.shipmentType,
      pieces: booking.pieces,
      imperialMetric: booking.imperialMetric,
      totalShipmentValue: booking.totalShipmentValue,
      preferredCurrency: booking.preferredCurrency,
      insure: booking.insure,
      ddp: booking.customsDetails.invoiceDetails.termsOfSale === TermsOfSale.DDP,
      hawb: booking.hawb,
      contentDescription: booking.contentDescription,
      countryOfOrigin: booking.countryOfOrigin,
      reasonForExport: booking.reasonForExport,
      collectionDetails: booking.collectionDetails,
      shipperDetails: convertBookingShipperToTemplate(booking),
      trueShipper: booking.trueShipper,
      consigneeDetails: booking.consigneeDetails,
      brokerDetails: booking.brokerDetails,
      emailPreference: booking.preferences.email,
      labelTypePreference: booking.preferences.labelType,
      commodities: convertBookingCommoditiesToTemplate(booking.customsDetails.commodities),
    }
  }) as Template;
}

function convertBookingShipperToTemplate(booking: Booking) {
  const { contactName, companyName, addressLine1, addressLine2, addressLine3, countyStateProvince, cityTown, postalCode, country, telephoneNumber, email, taxNumbers, exportComplianceStatement} = booking.shipperDetails;
  return {
    contactName,
    companyName,
    addressLine1,
    addressLine2,
    addressLine3,
    countyStateProvince,
    cityTown,
    postalCode,
    country,
    telephoneNumber,
    email,
    taxNumbers,
    aes: exportComplianceStatement.aes,
    edn: exportComplianceStatement.edn,
    trueShipper: booking.trueShipper,
  } as TemplateShipperDetails;
}

function convertBookingCommoditiesToTemplate(commodities: any) {
  return commodities.map((el: any) => {
    return ({
      commodityCode: el.commodityCode,
      description: el.description,
      quantity: el.quantity,
      unitPrice: el.unitPrice,
      weight: el.weight,
    })
  })
}

export function convertFormToTemplate(form: TemplateForm) {
  // Converts the form from a newly created/updated template to the current Template format
  const { id, name, variant, isDefault, countries, piecesSection, shipment, collection, shipper, consignee, broker, commodities, preferences} = form;

  return ({
    id: id ? id : uuidv4(),
    name,
    variant,
    isDefault,
    data: {
      origin: countries?.origin,
      originCityTown: countries?.originCityTown,
      originPostalCode: countries?.originPostalCode,
      originResidential: countries?.originResidential,
      destination: countries?.destination,
      destinationCityTown: countries?.destinationCityTown,
      destinationPostalCode: countries?.destinationPostalCode,
      destinationResidential: countries?.destinationResidential,
      shipmentType: piecesSection?.shipmentType,
      pieces: piecesSection?.pieces,
      imperialMetric: piecesSection?.imperialMetric,
      totalShipmentValue: piecesSection?.totalShipmentValue,
      preferredCurrency: piecesSection?.preferredCurrency,
      insure: shipment?.insure,
      ddp: shipment?.ddp,
      hawb: shipment?.hawb,
      contentDescription: shipment?.contentDescription,
      countryOfOrigin: shipment?.countryOfOrigin,
      reasonForExport: shipment?.reasonForExport,
      collectionDetails: collection,
      shipperDetails: shipper,
      trueShipper: shipper?.trueShipper,
      consigneeDetails: consignee,
      brokerDetails: broker,
      emailPreference: preferences?.email,
      labelTypePreference: preferences?.labelType,
      commodities: commodities,
    }
  }) as Template;
}

export function convertTemplateToForm(template: Template) {
  // Converts a saved template to the edit template form structure
  const { id, name, variant, isDefault, data } = template;

  const form: TemplateForm = JSON.parse(JSON.stringify(BLANK_FORM_TEMPLATE));

  form.id = id;
  form.name = name;
  form.variant = variant;
  form.isDefault = isDefault;
  if (data.origin) form.countries.origin = data.origin;
  if (data.originCityTown) form.countries.originCityTown = data.originCityTown;
  if (data.originPostalCode) form.countries.originPostalCode = data.originPostalCode;
  if (data.originResidential) form.countries.originResidential = data.originResidential;
  if (data.destination) form.countries.destination = data.destination;
  if (data.destinationCityTown) form.countries.destinationCityTown = data.destinationCityTown;
  if (data.destinationPostalCode) form.countries.destinationPostalCode = data.destinationPostalCode;
  if (data.destinationResidential) form.countries.destinationResidential = data.destinationResidential;
  if (data.shipmentType) form.piecesSection.shipmentType = data.shipmentType;
  if (data.imperialMetric) form.piecesSection.imperialMetric = data.imperialMetric;
  if (data.totalShipmentValue) form.piecesSection.totalShipmentValue = data.totalShipmentValue;
  if (data.preferredCurrency) form.piecesSection.preferredCurrency = data.preferredCurrency;
  if (data.pieces) form.piecesSection.pieces = populatePieces(data.pieces);
  if (data.insure) form.shipment.insure = data.insure;
  if (data.ddp) form.shipment.ddp = data.ddp;
  if (data.hawb) form.shipment.hawb = data.hawb;
  if (data.contentDescription) form.shipment.contentDescription = data.contentDescription;
  if (data.countryOfOrigin) form.shipment.countryOfOrigin = data.countryOfOrigin;
  if (data.reasonForExport) form.shipment.reasonForExport = data.reasonForExport;
  if (data.collectionDetails) form.collection = {...form.collection, ...data.collectionDetails};
  if (data.shipperDetails) form.shipper = {...form.shipper, ...data.shipperDetails};
  if (data.consigneeDetails) form.consignee = {...form.consignee, ...data.consigneeDetails};
  if (data.brokerDetails) form.broker = {...form.broker, ...data.brokerDetails};
  if (data.commodities) form.commodities = populateCommodities(data.commodities);
  if (data.emailPreference) form.preferences.email = data.emailPreference;
  if (data.labelTypePreference) form.preferences.labelType = data.labelTypePreference;

  return form;
}