export const checkForPartialCompleteness = (commodity: any, dispatch: any, appDataActions: any) => {
  const error = (
    commodity.commodityCode
    || commodity.description
    || commodity.weight.value
    || commodity.quantity.value
    || commodity.unitPrice.value
  ) && (
    !commodity.commodityCode
    || !commodity.description
    || !commodity.weight.value
    || !commodity.quantity.value
    || !commodity.unitPrice.value
  )

  dispatch(appDataActions.setCommodityPartialCompleteError(error))
}