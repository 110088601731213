import 'core-js/stable'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './theme.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';
import Loading from './Loading';

const appVersion = packageJson.version;
// const isProduction = process.env.NODE_ENV === 'production';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <div id="full-screen-mask"/>
      <div id="full-screen-clear-mask"/>
      <Provider store={store}>
        <CacheBuster
            currentVersion={appVersion}
            isEnabled={true} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
            loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
            metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
        >
        <App />
        </CacheBuster>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
