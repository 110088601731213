import { configureStore } from '@reduxjs/toolkit';
import { appDataSlice } from '../features/appDataSlice';
import { bookingSlice } from '../features/bookingSlice';
import { customerSlice } from '../features/customerSlice';
import { loadState } from "./browser-storage";
import { manifestSlice } from '../features/manifestSlice';

export const store = configureStore({
  reducer: {
    booking: bookingSlice.reducer,
    appData: appDataSlice.reducer,
    customer: customerSlice.reducer,
    manifest: manifestSlice.reducer
  },
  preloadedState: loadState(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
