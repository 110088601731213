import React, { useEffect, useState } from 'react';
import './CustomerPageDetails.scss';
import { AMIButton, AMIFormElement, AMIInput, Icon } from '../../../../../../ui-components/UiComponents';

const CustomerPageDetails: React.FC<{
  customer: any;
}> = ({
  customer
}) => {

  const [newAddress, setNewAddress] = useState({...customer.address});
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  useEffect(() => {
    if (JSON.stringify(newAddress) !== JSON.stringify(customer.address)) setShowSaveBtn(true);
    else setShowSaveBtn(false);
  }, [newAddress])

  const onAddressFieldChange = (field: string, value: string) => {
    setNewAddress({
      ...newAddress,
      [field]: value
    })
  }

  const onSave = () => {
    console.log('Save');
  }

  return (
    <div className="customer-page-details">
      <h4>Contact Details</h4>

      <form>
        <AMIFormElement
          label="Company Name"
        >
          <AMIInput
            name="companyName"
            value={customer["party-full-name"]}
            size="large"
            disabled
            onChange={(event) => onAddressFieldChange("party-full-name", event.target.value)}
          />
        </AMIFormElement>

        {/* <AMIFormElement
          label="Contact Name"
        >
          <AMIInput
            name="contactName"
            value={newAddress.contactName}
            size="large"
            onChange={(event) => onAddressFieldChange("contactName", event.target.value)}
          />
        </AMIFormElement> */}

        <AMIFormElement
          label="Address Line 1"
        >
          <AMIInput
            name="addressLine1"
            value={newAddress.addr1}
            size="large"
            disabled
            onChange={(event) => onAddressFieldChange("addr1", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Address Line 2"
        >
          <AMIInput
            name="addressLine2"
            value={newAddress.addr2}
            size="large"
            disabled
            onChange={(event) => onAddressFieldChange("addr2", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Address Line 3"
        >
          <AMIInput
            name="addressLine3"
            value={newAddress.addr3}
            size="large"
            disabled
            onChange={(event) => onAddressFieldChange("addr3", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement
          label="City/Town"
        >
          <AMIInput
            name="cityTown"
            value={newAddress.city}
            size="large"
            disabled
            onChange={(event) => onAddressFieldChange("city", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement
          label="County/State/Province"
        >
          <AMIInput
            name="countyStateProvince"
            value={newAddress.state}
            size="large"
            disabled
            onChange={(event) => onAddressFieldChange("state", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Country Code"
        >
          <AMIInput
            name="countryCode"
            value={newAddress.country}
            size="large"
            disabled
            onChange={(event) => onAddressFieldChange("country", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Postal Code"
        >
          <AMIInput
            name="postalCode"
            value={newAddress['postal-code']}
            size="large"
            disabled
            onChange={(event) => onAddressFieldChange("postal-code", event.target.value)}
          />
        </AMIFormElement>

        <div className="blank" />

        {/* <AMIFormElement
          label="Telephone"
        >
          <AMIInput
            name="telephone"
            value={newAddress.telephone}
            size="large"
            onChange={(event) => onAddressFieldChange("telephone", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Email"
        >
          <AMIInput
            name="email"
            value={newAddress.email}
            size="large"
            onChange={(event) => onAddressFieldChange("email", event.target.value)}
          />
        </AMIFormElement> */}
      </form>

      {showSaveBtn && (
        <AMIButton
          variant="primary"
          size="large"
          onClick={onSave}
        >
          Save Address
        </AMIButton>
      )}
    </div>
  )
}

export default CustomerPageDetails;