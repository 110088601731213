export interface FAQ {
  title: string;
  content: string;
}

export const faqs = [
  {
    title: 'What details do I have to input for each shipment I want to book?',
    content: '<ul>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
              '</ul>'
  },
  {
    title: 'What are the rules about what type of things I can send?',
    content: '<ul>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
              '</ul>'
  },
  {
    title: 'What are the rules about what type of things I can send?',
    content: '<ul>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
                '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
              '</ul>'
  }
]

export const usFaqs = [
  {
    title: 'How do the collection times work?',
    content: 'The pick up time needs to be half an hour past the current time at the Origin.'
  },
  {
    title: 'What details do I have to input for each shipment I want to book?',
    content: '' +
      '<ul>' +
        '<li>Complete shipper and consignee details (Required: address including postcode if applicable, contact name and telephone number) PO BOX numbers are acceptable.</li>' +
        '<li>Pro Forma / Commercial Invoice (Required for all countries) must contain the below:<br>' +
        'Name & Address shipper (or headed paper)<br>' +
        'Consignee full address including telephone no. & zip code<br>' +
        'Detailed description of contents<br>' +
        'Country of manufacture<br>' +
        'Itemised value, total value<br>' +
        'Currency used<br>' +
        'If value > $2500 then IES ITN# and commodity code (manufacturer produced harmonising code)<br>' +
        'For assistance with commodity codes, please visit http://hts.usitc.gov/ or browse and select from the harmonized commodity lookup tool' +
        '</li>' +
      '</ul>'
  },
  {
    title: 'What are the DDP terms?',
    content: 'Duty and Tax invoices are due for payment upon receipt and cannot be disputed, amended nor discounted once billed. DTP charges are due as billed to us from Destination.  Please note for clearance delayed shipments, if storage charges are incurred, they will also be billed back as they are part of the destination charges. Payment is due within 7 days of billing. Duty/Tax billing can take up to 6 months to be billed, however the average time frame is 4-6 weeks. There is a $20 DTP Processing Fee assessed for all DTP Shipments. Duty/taxes will always be billed separate from the transportation charges. Please confirm you agree with the above.'
  },
  {
    title: 'What are the rules about the things I can send?',
    content: 'Prohibited items are as follows:' +
      '            <ul>' +
      '                <li>Antiques</li>' +
      '                <li>Cash</li>' +
      '                <li>Firearms</li>' +
      '                <li>Jewellery</li>' +
      '                <li>Perishables</li>' +
      '                <li>Seeds</li>' +
      '                <li>Blank travellers cheques</li>' +
      '                <li>Champagne</li>' +
      '                <li>Foodstuffs</li>' +
      '                <li>Plants</li>' +
      '                <li>Shipside spares</li>' +
      '                <li>Bonds</li>' +
      '                <li>Human remains</li>' +
      '                <li>Livestock</li>' +
      '                <li>Pornography</li>' +
      '                <li>Works of art</li>' +
      '                <li>Bullion</li>' +
      '                <li>Ivory</li>' +
      '                <li>Negotiable stocks</li>' +
      '                <li>Precious stones</li>' +
      '                <li>Credit Cards</li>' +
      '            </ul>' +
      '            Restricted items are as follows:' +
      '            <ul>' +
      '                <li>Conference exhibition - Shipments - at some destinations require consignee broker clearance</li>' +
      '                <li>Pharmaceuticals (prescription or non-prescription)</li>' +
      '                <li>Liquids</li>' +
      '            </ul>'
  },
  {
    title: 'Is there a weight limit on packages?',
    content: 'You can retrieve quotes up to 150 lbs per piece directly from our site. We can handle heavier shipments however weight limits vary according to destination, please contact customer support for further assistance.'
  },
  {
    title: 'How do I calculate the volumetric weight of the packages?',
    content: 'In inches, multiply the height x width x depth and divide by 139<br>' +
      'In cms, multiply the height x width x depth and divide by 5000'
  },
  {
    title: 'What do I do once my booking is complete?',
    content: 'Attach the generated label(s) to your package(s) using a clear pouch. Label should be properly attached for transportation handling. Include three (3) copies of the commercial invoice inside the clear pouch as well.'
  },
  {
    title: 'Can I request a pickup from my clients address?',
    content: 'Yes, enter the pickup address in the shipper section when creating your booking.'
  },
  {
    title: 'What areas can you pick up from?',
    content: 'Pickups can be made from almost anywhere in the U.S.A. Some remote areas are not serviceable or may require early or next day pick up, please contact customer support to verify if your area is serviced.'
  },
  {
    title: 'How is the quoted price calculated?',
    content: 'Quotes are calculated by actual weight except when a shipment volumes. Shipments are then quoted based on chargeable volume weight.'
  },
  {
    title: 'Is the quoted price the same for the entire destination country selected?',
    content: 'Yes, except for when a shipment is in a remote area.'
  },
  {
    title: 'Does the transit time include public holidays and weekends?',
    content: 'No - transit times are estimated and based on business days only.'
  },
  {
    title: 'Can I print the labels from any printer?',
    content: 'Yes, any laser printer which produces good quality output can be used.'
  },
  {
    title: 'Is the collection time window I selected guaranteed?',
    content: 'In most cases yes, however remote areas, peak hours, weather conditions and holidays may affect pick up schedules.'
  },
  {
    title: 'Can I schedule a pickup on public holidays, weekends or after hours?',
    content: 'Pickups are made Monday through Friday only. Pickups and deliveries are not serviced on public holidays. Most areas have extended hours for pickups however pickups cannot be made after the cut-off time.'
  },
  {
    title: 'Can I track my shipment online?',
    content: 'Yes, you can track your shipment from the Track section on our site or you can setup tracking notifications from the My Settings section for email updates.'
  },
  {
    title: 'Can I book over the phone?',
    content: 'Please contact customer service for assistance with placing your booking.'
  },
  {
    title: 'How can I cancel a booking?',
    content: 'Please contact customer service via email for assistance.'
  },
  {
    title: 'What do I do if something goes wrong with the delivery?',
    content: 'Please contact customer service for any shipment status updates or assistance.'
  },
  {
    title: 'How should I package my shipment?',
    content: 'Several forms of packaging are accepted; Always ensure packaging is suitable to withstand the transit journey.'
  },
  {
    title: 'What documents do I need for customs?',
    content: 'A pro-forma or commercial invoice is required for all shipments.'
  },
  {
    title: 'Can we book IPR/OPR or Temporary Export on this service?',
    content: 'No, we are not handling these type of shipments at this time.'
  },
  {
    title: 'Which browser should I be using?',
    content: 'Modern web browsers, such as Chrome, Firefox and Internet Explorer 8, are more compliant with modern web standards and provide a better online experience.<br>' +
      'In producing our website, we have adopted the modern standards, but we recognize that some of our users might have older browsers, and even though we have sought to make our website available to all of our users, some might still see things displayed on the screen in a way that seems incorrect.<br>' +
      'If you are having difficulties viewing things as clearly as you would like, we do recommend that you contact your IT Systems Administrator to ask about upgrading to a modern standard-compliant web browser.<br>'
  },
  {
    title: 'What exception code do you use for shipments that don’t need to an ITN number?',
    content: 'This link is for the definition of the FTR codes <a href="https://www.census.gov/foreign-trade/regulations/ftrletters/FTR_LETTERS_03142018.pdf">https://www.census.gov/foreign-trade/regulations/ftrletters/FTR_LETTERS_03142018.pdf</a><br>' +
      'No EEI 30.36<br>' +
      'No EEI 30.37(a)<br>' +
      'No EEI 30.37(b)<br>' +
      'No EEI 30.37(c)<br>' +
      'No EEI 30.37(d)<br>' +
      'No EEI 30.37(e)<br>' +
      'No EEI 30.37(f)<br>' +
      'No EEI 30.37(g)<br>' +
      'No EEI 30.37(h)<br>' +
      'No EEI 30.37(i)<br>' +
      'No EEI 30.37(j)<br>' +
      'No EEI 30.37(k)<br>' +
      'No EEI 30.37(l)<br>' +
      'No EEI 30.37(m)<br>' +
      'No EEI 30.37(n)<br>' +
      'No EEI 30.37(o)<br>' +
      'No EEI 30.37(p)<br>' +
      'No EEI 30.37(q)<br>' +
      'No EEI 30.37(r)<br>' +
      'No EEI 30.37(s)<br>' +
      'No EEI 30.37(t)<br>' +
      'No EEI 30.37(u)<br>' +
      'No EEI 30.37(v)<br>' +
      'No EEI 30.37(w)<br>' +
      'No EEI 30.37(x)<br>' +
      'No EEI 30.37(y)(1)<br>' +
      'No EEI 30.37(y)(2)<br>' +
      'No EEI 30.37(y)(3)<br>' +
      'No EEI 30.37(y)(4)<br>' +
      'No EEI 30.37(y)(5)<br>' +
      'No EEI 30.37(y)(6)<br>' +
      'No EEI 30.39<br>' +
      'No EEI 30.40(a)<br>' +
      'No EEI 30.40(b)<br>' +
      'No EEI 30.40(c).<br>' +
      'No EEI 30.2(d)(1)<br>' +
      'No EEI 30.2(d)(2)<br>' +
      'No EEI 30.2(d)(3)<br>' +
      'No EEI 30.2(d)(4)<br>' +
      'No EEI 30.2(d)(5)<br>'
  }
]