import React from "react";
import "./Footer.scss";
import packageJS from '../../../package.json'
import { useLocation } from "react-router-dom";

const Footer: React.FC<{}> = () => {

  const location = useLocation().pathname;

  return (
    <div className="footer">
      <div className="footer__contact-details">
        © Air Menzies International Ltd {new Date().getFullYear()} | Version: {packageJS.version}
      </div>

      <div className="footer__links">
        <a href="https://airmenzies.com/policies/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
        |
        {location !== "/auth" &&<a href="/faq" target="_blank" rel="noreferrer">FAQ</a>}
        |
        <a href="/contact" target="_blank" rel="noreferrer">Contact</a>
      </div>
    </div>
  )
};

export default Footer;