import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { Booking, QuoteObj, TermsOfSale } from '../../features/bookingSlice';
import { customerDetailsSelector } from '../../features/customerSlice';
import { calculateBasePrice, getECSData, getOptionalChargeCost, OptionalCharge, snakeCaseConvertor } from '../../utilities/HelperUtilities';
import './PriceTable.scss';
import { TradeRoute, getTradeRoute } from '../../utilities/RulesEngineUtilities';

export const PriceTable: React.FC<{
  booking: Booking,
  total: number
}> = ({
  booking,
  total
}) => {

  const customer = useAppSelector(customerDetailsSelector);
  const currencySymbol = customer.countryOfResidence.currencySymbol;
  const quote = booking.selectedQuote;
  const insure = booking.insure;



  const basePrice = calculateBasePrice(customer, quote);

  const [priceFlash, setPriceFlash] = useState(false);

  const [ecsData, setECSData] = useState<any>({type: "", amount: ""});

  useEffect(() => {
    setECSData(getECSData(booking));

    setPriceFlash(true);
    setTimeout(() => setPriceFlash(false), 500)
  }, [total]);

  return (
    <div className="price-table">
      <p>Base Price: <span>{currencySymbol}{basePrice.toFixed(2)}</span></p>
      {quote.charges.required?.map((charge: any) => {
        return (
          <p
            className="price-table--sub-font"
            key={charge.type}
          >
            {snakeCaseConvertor(charge.type)}
            <span>+ {currencySymbol}{charge.value.toFixed(2)}</span>
          </p>
        )
      })}

      {getTradeRoute(customer, booking) === TradeRoute.DOMESTIC && booking.origin.value === 'AU' && booking.selectedQuote.vat.value && (
        <p className="price-table__margin-top">GST <span>{booking.preferredCurrency.symbol}{booking.selectedQuote.vat.value}</span></p>
      )}

      {insure && (
        <p className="price-table__margin-top">
          Insurance:
          <span>{currencySymbol}{getOptionalChargeCost(quote, OptionalCharge.INSURANCE)?.toFixed(2)}</span>
        </p>
      )}

      {booking.customsDetails.invoiceDetails.termsOfSale === TermsOfSale.DDP && (
        <p className="price-table__margin-top">
          DDP:
          <span>{currencySymbol}{getOptionalChargeCost(booking.selectedQuote, OptionalCharge.DELIVERED_DUTY_PAID)?.toFixed(2)}</span>
        </p>
      )}

      {booking.shipperDetails.generateECS && (
        <p className="price-table__margin-top">
          {ecsData.type}<span>{currencySymbol}{ecsData.amount}</span>
        </p>
      )}

      {quote.discount.amount.value !== 0
        && customer.countryOfResidence.value !== "GB"
        && (
          <p className="price-table__margin-top">
            Discount:
            <span>- {currencySymbol}{quote.discount.amount.value.toFixed(2)}</span>
          </p>
        )
      }

      <p className="price-table__total-title price-table__margin-top">
        Total:
        <span
          className="price-table__total-value"
          style={
            priceFlash
            ? {animation: 'flash .2s infinite'}
            : {animation: 'none'}
          }
        >{currencySymbol}{total.toFixed(2)}</span>
      </p>
    </div>
  )
}

export default PriceTable;