import { Template } from "../../../../utilities/TemplateHandler";

export function addTaxNumber (taxNumbers: any) {
  const taxNumbersCopy = [...taxNumbers];
  taxNumbersCopy.push({
    type: '',
    value: ''
  });
  return taxNumbersCopy;
}

export function deleteTaxNumber (taxNumbers: any, index: number)  {
  if (taxNumbers.length > 1) {
    const taxNumbersCopy = [...taxNumbers];
    taxNumbersCopy.splice(index, 1);
    return taxNumbersCopy;
  }
}

export function clearDefaultTemplates(templates: Template[]) {
  return templates.map(el => {
    return ({
      ...el,
      isDefault: false
    })
  })
}