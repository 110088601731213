export const contactArray = [
  `<p class="card-header">USA</p>
  <div class="contact-page__contact-entry__details">
    <div class="contact-page__contact-entry__address">
      <h3>Postal Address</h3>
      <p>Air Menzies International Inc</p>
      <p>Suite 100</p>
      <p>2520 West Airfield Drive</p>
      <p>Dallas/Fort Worth Airport</p>
      <p>TX</p>
      <p>75261</p>
    </div>

    <div class="contact-page__contact-entry__contact-details">
      <h3>Telephone</h3>
      <p>469 629 0411</p>
      <p>469 629 0413</p>

      <h3>Email</h3>
      <p>quick2shipus@airmenzies.com</p>
    </div>
  </div>`,
  `<p class="card-header">Australia</p>
    <div class="contact-page__contact-entry__details">
      <div class="contact-page__contact-entry__address">
        <h3>Postal Address</h3>
        <p>Air Menzies International (Aust)</p>
        <p>Pty Ltd</p>
        <p>Unit 2, 35 Qantas Drive</p>
        <p>Eagle Farm</p>
        <p>Queensland</p>
        <p>4009</p>
      </div>

      <div class="contact-page__contact-entry__contact-details">
        <h3>Telephone</h3>
        <p>1300AMIC2S (1300-264-227)</p>

        <h3>Email</h3>
        <p>Click2ShipAus@airmenzies.com</p>
      </div>
    </div>`,
    `<p class="card-header">New Zealand</p>
    <div class="contact-page__contact-entry__details">
      <div class="contact-page__contact-entry__address">
        <h3>Postal Address</h3>
        <p>Air Menzies International (NZ) Ltd</p>
        <p>23 Tom Pearce Drive</p>
        <p>Airport Oaks</p>
        <p>Auckland</p>
        <p>2022</p>
      </div>

      <div class="contact-page__contact-entry__contact-details">
        <h3>Telephone</h3>
        <p>+64 9 256 4242</p>

        <h3>Email</h3>
        <p>Click2ShipNZ@airmenzies.com</p>
      </div>
    </div>`,
    `<p class="card-header">South Africa</p>
    <div class="contact-page__contact-entry__details">
      <div class="contact-page__contact-entry__address">
        <h3>Postal Address</h3>
        <p>AMI House</p>
        <p>Sky Park</p>
        <p>1 Bonaero Drive</p>
        <p>Bonaero Park</p>
        <p>Kempton Park</p>
        <p>Gauteng</p>
      </div>

      <div class="contact-page__contact-entry__contact-details">
        <h3>Telephone</h3>
        <p>+27 11 979 3900</p>

        <h3>Email</h3>
        <p>Click2ShipZA@airmenzies.com</p>
      </div>
    </div>`,
    `<p class="card-header">United Kingdom</p>
    <div class="contact-page__contact-entry__details">
      <div class="contact-page__contact-entry__address">
        <h3>Postal Address</h3>
        <p>Air Menzies International</p>
        <p>Unit 2B</p>
        <p>Polar Park</p>
        <p>Bath Road</p>
        <p>Heathrow</p>
        <p>UB7 0DG</p>
      </div>

      <div class="contact-page__contact-entry__contact-details">
        <h3>Telephone</h3>
        <p>+44 (0)208 564 5000</p>
      </div>
    </div>`
]