import React, { useEffect, useState } from 'react';
import './CustomerPage.scss';
import CustomerPageDetails from './customer-page-details/CustomerPageDetails';
import CustomerPageMarkups from './customer-page-markups/CustomerPageMarkups';
import { AMIAlert, AMISpinner } from '../../../../../ui-components/UiComponents';
import { getChildCustomerData } from '../../../../../utilities/APIUtilities';
import { useAppSelector } from '../../../../../app/hooks';
import { appDataSelector } from '../../../../../features/appDataSlice';
import { customerDetailsSelector } from '../../../../../features/customerSlice';
import { IndirectCustomerData } from '../../WhiteLabelUtils';

const CustomerPage: React.FC<{
  basicCustomer: any;
  active: boolean;
}> = ({
  basicCustomer,
  active
}) => {

  const apiConfig = useAppSelector(appDataSelector).apiConfig;
  const customer = useAppSelector(customerDetailsSelector);

  const [activeSection, setActiveSection] = useState<'DETAILS' | 'MARKUPS' | 'LOGINS'>('DETAILS');
  const [childCustomer, setChildCustomer] = useState<IndirectCustomerData>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (active && !childCustomer) {
      getAndSetCustomer();
    }
  }, [active])

  const getAndSetCustomer = async() => {
    setErrorMessage('');
    const response: any = await getChildCustomerData(apiConfig, customer.creditCheck.tmffPartyId, basicCustomer.partyId);
    if (!response) setErrorMessage('Sorry, we could not retrieve this information at the moment. Please refresh to try again.')
    else setChildCustomer(response.data);
  }

  if (!active) {
    return (
      <div className="customer-page customer-page--closed">
        <h3>{basicCustomer.companyName}</h3>
        <p>Party ID: {basicCustomer.partyId}</p>
      </div>
    )
  }

  if (active && !childCustomer && !errorMessage) {
    return (
      <div className="customer-page customer-page">
        <h3>{basicCustomer.companyName}</h3>
        <p>Party ID: {basicCustomer.partyId}</p>

        <hr />
        <div className="customer-page__spinner">
          <AMISpinner />
          <p>Fetching customer...</p>
        </div>
      </div>
    )
  }

  if (active && errorMessage) {
    return (
      <div className="customer-page customer-page">
        <h3>{basicCustomer.companyName}</h3>
        <p>Party ID: {basicCustomer.partyId}</p>

        <hr />
        <AMIAlert variant="error">{errorMessage}</AMIAlert>
      </div>
    )
  }

  return (
    <div className="customer-page">
      <h3>{basicCustomer.companyName}</h3>
      <p>Party ID: {basicCustomer.partyId}</p>

      <hr />

      <div className="customer-page__nav">
        <div
          className={
            activeSection === "DETAILS" ? "customer-page__nav__item customer-page__nav__item--active" : "customer-page__nav__item"
          }
          onClick={() => setActiveSection('DETAILS')}
        >Details</div>
        <div
          className={
            activeSection === "MARKUPS" ? "customer-page__nav__item customer-page__nav__item--active" : "customer-page__nav__item"
          }
          onClick={() => setActiveSection('MARKUPS')}
        >Markups</div>
        <div
          className={
            activeSection === "LOGINS" ? "customer-page__nav__item customer-page__nav__item--active" : "customer-page__nav__item"
          }
          onClick={() => setActiveSection('LOGINS')}
        >Logins</div>
      </div>

      {activeSection === "DETAILS" && <CustomerPageDetails customer={childCustomer} />}
      {activeSection === "MARKUPS" && <CustomerPageMarkups customer={childCustomer} />}
    </div>
  )
}

export default CustomerPage;