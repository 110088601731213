import React, { useRef, useState } from 'react';
import './Checkbox.scss';
import { preventSpaceBarScrolling } from '../../utilities/HelperUtilities';

const AMICheckbox: React.FC<{
  id?: string,
  className?: string,
  style?: React.CSSProperties,
  checked: boolean,
  text: string,
  disabled?: boolean,
  onChange: (event: any, checked: boolean) => void
}> = ({
  id,
  className,
  style,
  checked,
  text,
  disabled,
  onChange,
  children
}) => {

  const divRef = useRef<HTMLDivElement>(null);

  const [additionalStyle, setAdditionalStyle] = useState({})

  const onCheck = (event: any, keyCode?: string) => {
    if (!disabled) {
      const isAConfirmKey = keyCode === 'Enter' || keyCode === 'NumpadEnter' || keyCode === 'Space';
      if (!keyCode || isAConfirmKey) onChange(event, !checked);
    }
  }

  return (
    <div
      id={id}
      className={
        disabled
          ? `ami-checkbox ami-checkbox--disabled ${className}`
          : `ami-checkbox ami-checkbox ${className}`
      }
      onFocus={() => setAdditionalStyle({border: '2px solid var(--primary)'})}
      onBlur={() => setAdditionalStyle(checked ? {border: '1px solid var(--primary)'} : {border: 'var(--text) solid 1px'})}
      onKeyDown={(event) => preventSpaceBarScrolling(event)}
      onKeyUp={(event) => onCheck(event, event.code)}
      ref={divRef}
    >{text ? text : children}
      <input
        type="checkbox"
        checked={checked}
        onChange={() => null}
      />
      <span
        onClick={(event) => onCheck(event)}
        className="checkmark"
        style={{
          ...style,
          ...additionalStyle
        }}
      ></span>
    </div>
  )
}

export default AMICheckbox;