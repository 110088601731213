import React, { useEffect, useState } from 'react';
import AMISteps, { ActivePage } from '../../../steps/Steps';
import './QuotesPage.scss';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { bookingSelector, ImperialMetric, QuoteObj } from '../../../features/bookingSlice';
import { appDataActions, appDataSelector, PageStatus } from '../../../features/appDataSlice';
import { getCarrierName } from '../../../utilities/HelperUtilities';
import { AMIAlert, AMIPopover, AMISpinner, Icon } from '../../../ui-components/UiComponents';
import { customerDetailsSelector, QuoteFilterCarrier, QuoteFilterType } from '../../../features/customerSlice';
import { getTimes, populateCarrierCards, populateTimeFrames, showTime } from './utilities';
import { getCurrencyConversionRate } from '../../../utilities/APIUtilities';
import QuoteFilter from './quote-filter/QuoteFilter';
import QuoteSummary from './quote-summary/QuoteSummary';
import CarrierContainer from './carrier-container/CarrierContainer';
import CarrierContainerFedexOverweight from './carrier-container/CarrierContainerFedexOverweight';
import { Banner, retrieveBanners } from '../../../utilities/ContentRetrieverUtilities';

const QuotesPage = () => {

  const dispatch = useAppDispatch();
  const appData = useAppSelector(appDataSelector);
  const booking = useAppSelector(bookingSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const quotes = booking.quotes;
  const {quoteSort, isReQuoting, apiConfig} = appData;

  const [visibleTimeFrames, setVisibleTimeFrames] = useState<number[]>([0, 1, 2, 3]);
  const [sameDayQuotes, setSameDayQuotes] = useState<QuoteObj[]>();
  const [nextDayQuotes, setNextDayQuotes] = useState<QuoteObj[]>();
  const [twoDayQuotes, setTwoDayQuotes] = useState<QuoteObj[]>();
  const [threeDayQuotes, setThreeDayQuotes] = useState<QuoteObj[]>();
  const [filteredQuotes, setFilteredQuotes] = useState<any>([]);
  const [carriersWithQuotes, setCarriersWithQuotes] = useState<any>([]);
  const [showFedexOverweightCard, setShowFedexOverweightCard] = useState<boolean>(false);
  const [banners, setBanners] = useState<undefined | Banner[]>();
  const [isTimeDisclaimerVisible, setIsTimeDisclaimerVisible] = useState({
    sameDay: false,
    nextDay: false,
    twoDay: false,
    threeDay: false
  });

  useEffect(() => {
    filterQuotes();
  }, [customer.quoteFilters, quotes]);

  useEffect(() => {
    if (quoteSort) {
      populateTimeFrames(
        filteredQuotes,
        booking,
        customer,
        setSameDayQuotes,
        setNextDayQuotes,
        setTwoDayQuotes,
        setThreeDayQuotes,
        'MAIN_PAGE',
      );
    } else setCarriersWithQuotes(populateCarrierCards(filteredQuotes, booking, customer));
  }, [filteredQuotes, quoteSort])


  useEffect(() => {
    handleInsuranceDisplay();
    calculateFedexOverweightCard();

    retrieveBanners('announcement-banner-quote-page', customer)
    .then((result: any) => {
      setBanners(result)
    })
    .catch((error: any) => console.error(error));
  }, [])

  const calculateFedexOverweightCard = () => {
    let show = false;

    if (customer.countryOfResidence.value === 'GB') {
      const isImperial = booking.imperialMetric === ImperialMetric.IMPERIAL;
      const weightBreak = isImperial ? 149.91 : 68;
      const girthBreak = isImperial ? 129.92 : 330;

      for (const piece of booking.pieces) {
        if (+piece.weight >= weightBreak) show = true;
        const girth = ((+piece.length + +piece.width) * 2) + +piece.height;
        if (girth > girthBreak) show = true;
      }
    }

    setShowFedexOverweightCard(show);
  }

  const handleInsuranceDisplay = async() => {
    if (customer.countryOfResidence.value !== 'GB') {
      dispatch(appDataActions.updateField({field: 'displayInsurance', value: true}));
      return;
    }

    let shipmentValue = +booking.totalShipmentValue;

    if (booking.preferredCurrency.value !== customer.countryOfResidence.currencyCode) {
      const rate = await getCurrencyConversionRate(apiConfig, booking.preferredCurrency.value, customer.countryOfResidence.currencyCode);
      shipmentValue = +booking.totalShipmentValue * rate;
    }

    if (shipmentValue < 10) dispatch(appDataActions.updateField({field: 'displayInsurance', value: false}));
    else dispatch(appDataActions.updateField({field: 'displayInsurance', value: true}));
  }

  const filterQuotes = () => {
    let filteredQuotes = [...quotes];
    const filters = customer.quoteFilters;

    if (filters.type !== QuoteFilterType.ALL) {
      filteredQuotes = filteredQuotes.filter((quote: QuoteObj) => {
        return quote.serviceType === filters.type;
      })
    }

    if (filters.carrier !== QuoteFilterCarrier.ALL) {
      filteredQuotes = filteredQuotes.filter((quote: QuoteObj) => {
        return getCarrierName(quote.quoteId) === filters.carrier;
      })
    }

    if (getTimes(quotes).length > 0 && filters.time !== 'ALL') {
      filteredQuotes = filteredQuotes.filter((quote: QuoteObj) => {
        return showTime(quote) && dayjs(quote.deliveryDateEstimate).format('hh:mm') === filters.time;
      })
    }

    setFilteredQuotes(filteredQuotes);
  }

  const onEyeClick = (timeFrame: number) => {
    let visibleTimeFramesCopy = [...visibleTimeFrames];

    if (visibleTimeFrames.includes(timeFrame)) visibleTimeFramesCopy = visibleTimeFramesCopy.filter(x => x !== timeFrame);
    else visibleTimeFramesCopy.push(timeFrame);

    setVisibleTimeFrames(visibleTimeFramesCopy);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (appData.quotePageStatus !== PageStatus.COMPLETE) {
      dispatch(appDataActions.setQuotePageStatus(PageStatus.IN_PROGRESS));
    }
  }, [])

  const getTimeDisclaimer = (section: 'sameDay' | 'nextDay' | 'twoDay' | 'threeDay') => {
    return (
      <span
        onMouseEnter={() => setIsTimeDisclaimerVisible({
          ...isTimeDisclaimerVisible,
          [section]: true
        })}
        onMouseLeave={() => setIsTimeDisclaimerVisible({
          ...isTimeDisclaimerVisible,
          [section]: false
        })}
      >
        (est.
        <Icon
          name="CircleQuestion"
          color="var(--primary)"
          style={{marginLeft: '4px'}}
        />
        <AMIPopover isVisible={isTimeDisclaimerVisible[section]}>
          All timings are estimated and can be affected by delays including customs clearance, weather and social situations.
        </AMIPopover>
        )
      </span>
    )
  }

  return (
    <>
      <AMISteps activePage={ActivePage.QUOTE} />

      <div className="quotes-page">

        <QuoteFilter quotes={quotes} />
        <QuoteSummary />

        {customer.countryOfResidence.value === "GB" && (
          <a
            href="mailto: expressspotrates@airmenzies.com"
            className="quotes-page__marketing-image"
          >
            <img src="/marketing/GB-marketing-image.png" />
          </a>
        )}

        {isReQuoting && (
          <div className="re-quoting-container">
            <AMISpinner />
            <p>Please wait while we fetch your new quotes</p>
          </div>
        )}
        {isReQuoting && <div className="full-screen-mask"></div> }

        {!isReQuoting && (
          <div className="quotes-page__quotes-container">

            {!customer.creditCheck.credit && (
              <AMIAlert
                variant="warning"
                className="quotes-page__quotes-container__alert"
              >
                <p><strong>You are out of credit, please contact Credit Control.</strong></p>
                <p>If you have recently made a payment, please wait for your credit status to update. If you are still unable to place a booking, please get in contact with us.</p>
              </AMIAlert>
            )}

            {banners && banners.length > 0 && banners.map((banner: Banner, index: number) => {
              return (
                <AMIAlert
                  variant={banner.config.announcementType}
                  key={index}
                >
                  <p><strong>{banner.title}</strong></p>
                  <div className="faq-content" dangerouslySetInnerHTML={{__html: banner.content}}></div>
                </AMIAlert>
              )
            })}

            {filteredQuotes.length === 0 && (
              <AMIAlert variant="warning">The selected filters have filtered out all returned quotes. Please remove the selected filters or return to the previous page.</AMIAlert>
            )}

            {!quoteSort && carriersWithQuotes && (
              <div className="time-frame">
                <div className="time-frame__title">
                  <div className="time-frame__title__main">
                    <p>Cost - Low to High</p>
                    {customer.countryOfResidence.value === "NL" && <span className="time-frame__title__ex-vat">prices are ex VAT</span>}
                  </div>
                  <Icon
                    name={
                      visibleTimeFrames.includes(0)
                      ? "Eye"
                      : "EyeCross"
                    }
                    color="var(--secondary)"
                    style={{
                      width: "24px",
                      height: "24px",
                      cursor: "pointer"
                    }}
                    onClick={() => onEyeClick(0)}
                  />
                </div>

                <div className={visibleTimeFrames.includes(0) ? "time-frame__quotes" : "time-frame__quotes--hidden"}>
                  {carriersWithQuotes?.map((carrierWithQuotes: any, index: number) => {
                    return (
                      <CarrierContainer key={index + carrierWithQuotes.carrierName} carrierWithQuotes={carrierWithQuotes}/>
                    )
                  })}
                </div>
              </div>
            )}

            {quoteSort === 1 && sameDayQuotes && sameDayQuotes.length > 0 && (
              <div className="time-frame">
                <div className="time-frame__title">
                  <div className="time-frame__title__main">
                    <div><p>Same Day Delivery</p>&nbsp;
                      {getTimeDisclaimer('sameDay')}
                    </div>
                    {customer.countryOfResidence.value === "NL" && <span className="time-frame__title__ex-vat">prices are ex VAT</span>}
                  </div>
                  <Icon
                  name={
                    visibleTimeFrames.includes(0)
                    ? "Eye"
                    : "EyeCross"
                  }
                    color="var(--secondary)"
                    style={{
                      width: "24px",
                      height: "24px",
                      cursor: "pointer"
                    }}
                    onClick={() => onEyeClick(0)}
                  />
                </div>

                <div className={visibleTimeFrames.includes(0) ? "time-frame__quotes" : "time-frame__quotes--hidden"}>
                  {sameDayQuotes?.map((carrierWithQuotes: any, index: number) => {
                    return <CarrierContainer key={index + carrierWithQuotes.carrierName} carrierWithQuotes={carrierWithQuotes}/>
                  })}
                </div>
              </div>
            )}

            {quoteSort === 1 && nextDayQuotes && nextDayQuotes.length > 0 && (
              <div className="time-frame">
              <div className="time-frame__title">
                <div className="time-frame__title__main">
                  <div><p>Next Day Delivery</p>&nbsp;
                  {getTimeDisclaimer('nextDay')}
                  </div>
                  {customer.countryOfResidence.value === "NL" && <span className="time-frame__title__ex-vat">prices are ex VAT</span>}
                </div>
                  <Icon
                    name={
                      visibleTimeFrames.includes(1)
                      ? "Eye"
                      : "EyeCross"
                    }
                    color="var(--secondary)"
                    style={{
                      width: "24px",
                      height: "24px",
                      cursor: "pointer"
                    }}
                    onClick={() => onEyeClick(1)}
                  />
                </div>

                <div className={visibleTimeFrames.includes(1) ? "time-frame__quotes" : "time-frame__quotes--hidden"}>
                  {nextDayQuotes?.map((carrierWithQuotes: any, index: number) => {
                    return <CarrierContainer key={index + carrierWithQuotes.carrierName} carrierWithQuotes={carrierWithQuotes}/>
                  })}
                </div>
              </div>
            )}

            {quoteSort === 1 && twoDayQuotes && twoDayQuotes.length > 0 && (
              <div className="time-frame">
              <div className="time-frame__title">
                <div className="time-frame__title__main">
                  <div><p>2 Day Delivery</p>&nbsp;
                  {getTimeDisclaimer('twoDay')}
                  </div>
                  {customer.countryOfResidence.value === "NL" && <span className="time-frame__title__ex-vat">prices are ex VAT</span>}
                </div>
                  <Icon
                    name={
                      visibleTimeFrames.includes(2)
                      ? "Eye"
                      : "EyeCross"
                    }
                    color="var(--secondary)"
                    style={{
                      width: "24px",
                      height: "24px",
                      cursor: "pointer"
                    }}
                    onClick={() => onEyeClick(2)}
                  />
                </div>
                <div className={visibleTimeFrames.includes(2) ? "time-frame__quotes" : "time-frame__quotes--hidden"}>
                  {twoDayQuotes?.map((carrierWithQuotes: any, index: number) => {
                    return <CarrierContainer key={index + carrierWithQuotes.carrierName} carrierWithQuotes={carrierWithQuotes}/>
                  })}
                </div>
              </div>
            )}

            {quoteSort === 1 && threeDayQuotes && threeDayQuotes.length > 0 && (
              <div className="time-frame">
                <div className="time-frame__title">
                  <div className="time-frame__title__main">
                    <div><p>3+ Day Delivery</p>&nbsp;
                    {getTimeDisclaimer('threeDay')}
                    </div>
                    {customer.countryOfResidence.value === "NL" && <span className="time-frame__title__ex-vat">prices are ex VAT</span>}
                  </div>
                  <Icon
                    name={
                      visibleTimeFrames.includes(3)
                      ? "Eye"
                      : "EyeCross"
                    }
                    color="var(--secondary)"
                    style={{
                      width: "24px",
                      height: "24px",
                      cursor: "pointer"
                    }}
                    onClick={() => onEyeClick(3)}
                  />
                </div>
                <div className={visibleTimeFrames.includes(3) ? "time-frame__quotes" : "time-frame__quotes--hidden"}>

                  {threeDayQuotes?.map((carrierWithQuotes: any, index: number) => {
                    return <CarrierContainer key={index + carrierWithQuotes.carrierName} carrierWithQuotes={carrierWithQuotes}/>
                  })}
                </div>
              </div>
            )}

            {showFedexOverweightCard && (<CarrierContainerFedexOverweight />)}
          </div>
        )}
      </div>
    </>
  )
}

export default QuotesPage;