import dayjs from "dayjs";
import { Booking, QuoteObj } from "../../../features/bookingSlice";
import { FedexProductCode, getCarrierName } from "../../../utilities/HelperUtilities";
import { CustomerDetails } from "../../../features/customerSlice";
import { TradeRoute, getTradeRoute } from "../../../utilities/RulesEngineUtilities";

const ONE_DAY_IN_MILLISECONDS = 86_400_000;

export const showTime = (quote: QuoteObj) => {
  const time = quote.deliveryDateEstimate.slice(-8, -3);
  return !(time === '23:59' || time === '00:00');
}

export const getTimes = (quotes: QuoteObj[]) => {
  const times: string[] = [];
  for (const quote of quotes) {
    if (showTime(quote)) {
      const time = dayjs(quote.deliveryDateEstimate).format('hh:mm');
      if (!times.includes(time)) times.push(time);
    }
  }
  return times;
}

export const getCarriers = (quotes: QuoteObj[]) => {
  const carriers: string[] = [];
  for (const quote of quotes) {
    const carrierName = getCarrierName(quote.quoteId);
    if (!carriers.includes(carrierName)) carriers.push(carrierName);
  }
  return carriers;
}

export const populateTimeFrames = (
  filteredQuotes: any,
  booking: any,
  customer: CustomerDetails,
  setSameDayQuotes: any,
  setNextDayQuotes: any,
  setTwoDayQuotes: any,
  setThreeDayQuotes: any,
  variant: 'DROP_DOWN' | 'MAIN_PAGE'
) => {
  let tempSameDayQuotes = [];
  let tempNextDayQuotes = [];
  let tempTwoDayQuotes = [];
  let tempThreeDayQuotes = [];

  for (const quote of filteredQuotes) {
    const nextDayService = quote.carrierProductCode === FedexProductCode.PRIORITY_OVERNIGHT || quote.carrierProductCode === FedexProductCode.STANDARD_OVERNIGHT;
    const twoDayService = quote.carrierProductCode === FedexProductCode.FEDEX_2_DAY || quote.carrierProductCode === FedexProductCode.FEDEX_2_DAY_AM;

    if (quote.transitTimeEstimate === '0') tempSameDayQuotes.push(quote);
    else if (checkIfSameDay(quote, 1, booking) || nextDayService) tempNextDayQuotes.push(quote);
    else if (checkIfSameDay(quote, 2, booking) || twoDayService) tempTwoDayQuotes.push(quote);
    else tempThreeDayQuotes.push(quote);
  }

  tempSameDayQuotes.sort((a, b) => a.totalCost.value - b.totalCost.value)
  tempNextDayQuotes.sort((a, b) => a.totalCost.value - b.totalCost.value)
  tempTwoDayQuotes.sort((a, b) => a.totalCost.value - b.totalCost.value)
  tempThreeDayQuotes.sort((a, b) => a.totalCost.value - b.totalCost.value)

  if (variant === 'DROP_DOWN') {
    setSameDayQuotes(tempSameDayQuotes);
    setNextDayQuotes(tempNextDayQuotes);
    setTwoDayQuotes(tempTwoDayQuotes);
    setThreeDayQuotes(tempThreeDayQuotes);
  } else {
    setSameDayQuotes(populateCarrierCards(tempSameDayQuotes, booking, customer));
    setNextDayQuotes(populateCarrierCards(tempNextDayQuotes, booking, customer));
    setTwoDayQuotes(populateCarrierCards(tempTwoDayQuotes, booking, customer));
    setThreeDayQuotes(populateCarrierCards(tempThreeDayQuotes, booking, customer));
  }
}

export const getTimeFrameWeekendAdjusted = (quote: any, timeFrame: number, booking: Booking) => {
  const deliveryDate = dayjs(quote.deliveryDateEstimate);
  let newTimeFrame = timeFrame;
  let dateToCheck = deliveryDate;
  const SATURDAY = 6;
  const SUNDAY = 0;

  if (dateToCheck.day() === SATURDAY || dateToCheck.day() === SUNDAY) newTimeFrame--;

  while (dateToCheck.isAfter(dayjs(booking.readyDate))) {
    if (dateToCheck.day() === SATURDAY) newTimeFrame++;
    if (dateToCheck.day() === SUNDAY) newTimeFrame++;
    dateToCheck = dateToCheck.subtract(1, 'day');
  }

  return newTimeFrame;
}

export const checkIfSameDay = (quote: any, timeFrame: number, booking: any) => {
  let timeFrameWeekendAdjusted = getTimeFrameWeekendAdjusted(quote, timeFrame, booking);
  return dayjs(new Date(quote.deliveryDateEstimate).getTime() - (ONE_DAY_IN_MILLISECONDS * timeFrameWeekendAdjusted)).isSame(dayjs(booking.readyDate), 'day');
}

export const calculateQuoteCost = (
  quote: QuoteObj,
  booking: Booking,
  customer: CustomerDetails,
  insured?: boolean,
  insuranceCost?: number,
  ddpSelected?: boolean,
  ddpCost?: number
) => {
  const quotePrice = quote.totalCost.value;
  const insurance = insured && insuranceCost ? insuranceCost : 0;
  const ddp = ddpSelected && ddpCost ? ddpCost : 0;
  const vat = quote.vat.value;

  let totalCost = (quotePrice + insurance + ddp);
  if (getTradeRoute(customer, booking) === TradeRoute.DOMESTIC && booking.origin.value === 'AU' && vat) totalCost += vat;

  return totalCost.toFixed(2);
}

const getLowestBasePrice = (quotes: QuoteObj[], booking: Booking, customer: CustomerDetails) => {
  let lowestPrice;
  for (const quote of quotes) {
    const cost = calculateQuoteCost(quote, booking, customer);
    if (!lowestPrice) lowestPrice = cost;
    else if (+cost < +lowestPrice) lowestPrice = cost;
  }

  return lowestPrice as string;
}

export const populateCarrierCards = (quotes: QuoteObj[], booking: Booking, customer: CustomerDetails) => {
  const carriers: any[] = [];
  for (const quote of quotes) {
    if (!carriers.includes(getCarrierName(quote.quoteId))) carriers.push(getCarrierName(quote.quoteId));
  }
  const carriersWithQuotes = carriers.map((carrier: any) => {
    return {
      carrierName: carrier,
      lowestPrice: '',
      quotes: [] as QuoteObj[]
    }
  })
  for (const quote of quotes) {
    for (const carrier of carriersWithQuotes) {
      if (getCarrierName(quote.quoteId) === carrier.carrierName) carrier.quotes.push(quote);
    }
  }

  for (const carrier of carriersWithQuotes) {
    carrier.lowestPrice = getLowestBasePrice(carrier.quotes, booking, customer);
  }

  return carriersWithQuotes.sort((a: any, b: any) => {
    return +a.lowestPrice - +b.lowestPrice;
  })
}