import React, { useRef, useState } from 'react';
import './WhiteLabelUpload.scss';
import { AMIAlert, AMIButton, Icon } from '../../../../../ui-components/UiComponents';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { appDataActions, appDataSelector } from '../../../../../features/appDataSlice';

const WhiteLabelUpload: React.FC<{
  title: string;
  blurb: string;
  variant: 'LOGO' | 'BANNER';
  url: string;
}> = ({
  title,
  blurb,
  variant,
  url
}) => {

  const dispatch = useAppDispatch();
  const appData = useAppSelector(appDataSelector);
  const inputRef = useRef<any>();

  const [dragActive, setDragActive] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const onUpload = () => {
    setUploadError('');
    inputRef.current.click();
  }

  const onDelete = () => {
    if (variant === 'LOGO') {
      dispatch(appDataActions.setTemporaryLogo({
        name: '',
        content: null
      }))
    } else {
      dispatch(appDataActions.setTemporaryBanner({
        name: '',
        content: null
      }))
    }
  }

  const handleDrag = (event: any) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  }

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    const image = event.dataTransfer.files[0];
    if (image) handleOnChange(image);
  };

  const onDragOver = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  }

  const handleOnChange = (file: any) => {
    if (file) {
      const fileName = file.name.replace(/\s+/g, "");
      const rawType = file.type;
      const type = rawType.split('/')[1];
      const MAX_FILE_SIZE_BYTES = variant === 'LOGO' ? 512307 : 1363148;
      if (variant === 'LOGO' && file.size > MAX_FILE_SIZE_BYTES) {
        setUploadError('File size is too large. Please upload a file less than 0.5MB');
        return;
      }
      if (variant === 'BANNER' && file.size > MAX_FILE_SIZE_BYTES) {
        setUploadError('File size is too large. Please upload a file less than 1.3MB');
        return;
      }
      if (variant === 'LOGO' && type !== 'png') {
        setUploadError('File is an unsupported format. Please upload a .png file with a transparent background');
        return;
      }
      const bannerTypes = ['png', 'gif', 'tiff', 'jpeg', 'jpg'];
      if (variant === 'BANNER' && !bannerTypes.includes(type)) {
        setUploadError('File is an unsupported format. Please upload a .png, .gif, .tiff, .jpeg, or .jpg.');
        return;
      }

      const reader = new FileReader();

      reader.onload = function () {
        const base64String = (reader.result as string)?.replace("data:", "")
          .replace(/^.+,/, "");

          if (variant === "LOGO") {
            dispatch(appDataActions.setTemporaryLogo({
              name: fileName,
              content: base64String,
              url: window.URL.createObjectURL(file)
            }))
          }

          if (variant === "BANNER") {
            dispatch(appDataActions.setTemporaryBanner({
              name: fileName,
              content: base64String,
              url: window.URL.createObjectURL(file)
            }))
          }
      }
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="white-label-upload">
      <h3>{title}</h3>
      {uploadError && (
        <AMIAlert
          variant="error"
          className="white-label-upload__alert"
        >{uploadError}</AMIAlert>
      )}
      <p className="white-label-upload__blurb">{blurb}</p>
      <div
        className={
          dragActive
          ? "drag-and-drop-box drag-and-drop-box--active"
          : "drag-and-drop-box"
        }
        onDragEnter={handleDrag}
        onDrop={handleDrop}
        onDragOver={onDragOver}
        onDragLeave={handleDrag}
      >
        Drag & Drop Here to Upload
      </div>

      <input
        ref={inputRef}
        className="upload-input-btn"
        type="file"
        accept={variant === "LOGO" ? ".png" : ".png,.gif,.tiff,.jpeg,.jpg"}
        onChange={(event: any) => handleOnChange(event.target.files[0])}
      />

      <div className="white-label-upload__btns">
        <AMIButton
          variant='primary'
          size="large"
          className="upload-button"
          onClick={onUpload}
        >
          <Icon
            name="Upload"
            color='var(--textLight)'
          />
          Select File
        </AMIButton>
        {appData.temporaryLogo?.content && (
          <AMIButton
            variant='default'
            size="large"
            className="upload-button"
            onClick={onDelete}
          >
            Remove
          </AMIButton>
        )}
      </div>

      {variant === "LOGO" && appData.temporaryLogo?.url && (
        <img src={appData.temporaryLogo.url} />
      )}

      {variant === "LOGO" && !appData.temporaryLogo?.url && url && (
        <img src={url} />
      )}

      {variant === "BANNER" && appData.temporaryBanner?.url && (
        <img src={appData.temporaryBanner.url} />
      )}

      {variant === "BANNER" && !appData.temporaryBanner?.url && url && (
        <img src={url} />
      )}
    </div>
  )
}

export default WhiteLabelUpload;