import React, { useEffect, useState } from 'react';
import { AMIFormElement, AMIInput, AMISelect, AMISpinner } from '../../../../../ui-components/UiComponents';
import { formatCounties, selectTitleFormatter } from '../../../../../utilities/HelperUtilities';
import { times } from '../../../../single-booking/details-page/collection-details/utils';
import { getCountryInfo, PackageLocation } from '../../../../../utilities/APIUtilities';
import { useAppSelector } from '../../../../../app/hooks';
import { appDataSelector } from '../../../../../features/appDataSlice';
import { TemplateForm } from '../../../../../utilities/TemplateHandler';

const TemplateModalCollectionDetails: React.FC<{
  template: TemplateForm;
  updateCollectionDetails: (field: string, value: any) => void;
}> = ({
  template,
  updateCollectionDetails
}) => {

  const appData = useAppSelector(appDataSelector);
  const data = template.collection;

  const [collectionToTimes, setCollectionToTimes] = useState(times);
  const [pickUpToIndex, setPickUpToIndex] = useState<number>(0);
  const [handlingCountryInfo, setHandlingCountryInfo] = useState(false);
  const [counties, setCounties] = useState<any>([]);

  useEffect(() => {
    resetPickUpTo();
    if (template.countries.origin.value) handleCountryInfo();
  }, [data.pickUpFrom])

  const changePickUpFromTime = (event: any) => {
    updateCollectionDetails('pickUpFrom', event.value);

    const pickUpFromIndex = times.findIndex((time) => {
      return event.value === time.value;
    })
    const pickUpToIndex = pickUpFromIndex + 4;

    const newTimes = times.slice(pickUpToIndex);
    setCollectionToTimes(newTimes);

    setPickUpToIndex(pickUpToIndex);
  }

  const resetPickUpTo = () => {
    const pickUpToReduxIndex = times.findIndex((time) => {
      return time.value === data.pickUpTo;
    })
    if (pickUpToIndex > pickUpToReduxIndex) {
      updateCollectionDetails('pickUpTo', '');
    }
  }

  const handleCountryInfo = async() => {
    setHandlingCountryInfo(true);
    const countryInfo = await getCountryInfo(appData.apiConfig, template.countries.origin.value);
    if (countryInfo?.countiesStates?.length > 0) {
      setCounties(formatCounties(countryInfo.countiesStates));
    }
    setHandlingCountryInfo(false);
  }

  if (handlingCountryInfo) {
    return (
      <div className="template-modal-spinner">
        <AMISpinner />
        <p>Fetching country info...</p>
      </div>
    )
  }

  if (!template.countries.origin.value) {
    return (
      <div className="template-modal-collection-details">
        <p>An origin country must be selected under the Countries tab before populating Collection details.</p>
      </div>
    )
  }

  return (
    <div className="template-modal-collection-details">
      <AMIFormElement label="Pick up from">
        <AMISelect
          name="pickUpFrom"
          size="large"
          options={times.slice(0, -4)}
          defaultValue={{
            title: data.pickUpFrom.slice(0,5),
            value: data.pickUpFrom
          }}
          onChange={(event) => changePickUpFromTime(event)}
        />

      </AMIFormElement>

      <AMIFormElement label="Pick up to">
        <AMISelect
          name="pickUpTo"
          placeholder="Required"
          size="large"
          options={collectionToTimes}
          defaultValue={{
            title: data.pickUpTo?.slice(0,5),
            value: data.pickUpTo
          }}
          onChange={(event) => updateCollectionDetails('pickUpTo', event.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Full Contact Name">
        <AMIInput
          name="collectionContactName"
          size="large"
          value={data.contactName}
          onChange={(event) => updateCollectionDetails('contactName', event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Company Name">
        <AMIInput
          name="collectionCompanyName"
          size="large"
          value={data.companyName}
          onChange={(event) => updateCollectionDetails('companyName', event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Package Location">
        <AMISelect
          name="collectionPackageLocation"
          size="large"
          defaultValue={{
            title: selectTitleFormatter(data.packageLocation),
            value: data.packageLocation
          }}
          options={[
            {title: 'Front', value: PackageLocation.FRONT},
            {title: 'None', value: PackageLocation.NONE},
            {title: 'Rear', value: PackageLocation.REAR},
            {title: 'Side', value: PackageLocation.SIDE}
          ]}
          onChange={(event) => updateCollectionDetails('packageLocation', event.value)}
        />
      </AMIFormElement>

      <div className="spacer"></div>

      <AMIFormElement
        label="Country"
        className="template-modal__country"
      >
        <AMISelect
          name="collection-country"
          size="large"
          isSearchable
          defaultValue={template.countries.origin ? template.countries.origin : template.shipper.country}
          options={appData.countries}
          disabled
          hideClearButton
        />
      </AMIFormElement>

      <AMIFormElement label="Address Line 1">
        <AMIInput
          name="collectionAddressLine1"
          size="large"
          value={data.addressLine1}
          onChange={(event) => updateCollectionDetails('addressLine1', event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Address Line 2">
        <AMIInput
          name="collectionAddressLine2"
          size="large"
          value={data.addressLine2}
          onChange={(event) => updateCollectionDetails('addressLine2', event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Address Line 3">
        <AMIInput
          name="collectionAddressLine3"
          size="large"
          value={data.addressLine3}
          onChange={(event) => updateCollectionDetails('addressLine3', event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="City/Town">
        <AMIInput
          name="collectionCityTown"
          size="large"
          value={template.countries.originCityTown ? template.countries.originCityTown : data.cityTown}
          disabled={!!template.countries.originCityTown}
        />
      </AMIFormElement>

      {counties.length === 0 && (
        <AMIFormElement label="County/State/Province">
          <AMIInput
            name="collectionCountyStateProvince"
            size="large"
            value={data.countyStateProvince}
            onChange={(event) => updateCollectionDetails('countyStateProvince', event.target.value)}
          />
        </AMIFormElement>
      )}

      {counties.length > 0 && (
        <AMIFormElement label="County/State/Province">
          <AMISelect
            name="collectionCountyStateProvince"
            size="large"
            isSearchable
            options={counties}
            onChange={(event) => updateCollectionDetails('countyStateProvince', event.value)}
            defaultValue={counties.find((item: any) => {
              return item.value === data.countyStateProvince
            })}
          />
        </AMIFormElement>
      )}


      <AMIFormElement label="Postal/Zip Code">
        <AMIInput
          name="collectionPostalCode"
          size="large"
          value={template.countries.originPostalCode ? template.countries.originPostalCode : data.postalCode}
          disabled={!!template.countries.originPostalCode}
          onChange={(event) => updateCollectionDetails('postalCode', event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Telephone Number">
        <AMIInput
          name="collectionTelephoneNumber"
          size="large"
          value={data.telephoneNumber}
          onChange={(event) => updateCollectionDetails('telephoneNumber', event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Email">
        <AMIInput
          name="collectionEmail"
          size="large"
          type="email"
          value={data.email}
          onChange={(event) => updateCollectionDetails('email', event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Additional Information / Special Instructions"
        style={{gridColumn: '1 / 3'}}
      >
        <AMIInput
          name="collectionAdditionalInfo"
          size="large"
          value={data.additionalInfo}
          onChange={(event) => updateCollectionDetails('additionalInfo', event.target.value)}
        />
      </AMIFormElement>
    </div>
  )
}

export default TemplateModalCollectionDetails;