import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { AMIPopConfirm, AMISpinner, Icon } from '../../../../ui-components/UiComponents';
import { customerActions, customerDetailsSelector } from '../../../../features/customerSlice';
import { setTemplateData } from '../../../../utilities/APIUtilities';
import './TemplateTableLine.scss';
import TemplateModal from '../template-modal/TemplateModal';
import { appDataSelector } from '../../../../features/appDataSlice';
import { createTemplateRequestBody } from '../utils';

const TemplateTableLine: React.FC<{
  template: any,
  index: number
}> = ({
  template,
  index
}) => {

  const customer = useAppSelector(customerDetailsSelector);
  const apiConfig = useAppSelector(appDataSelector).apiConfig;
  const dispatch = useAppDispatch();
  const popConfirmTarget = document.getElementById('delete-template-icon');

  const [isDeleting, setIsDeleting] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeletePopConfirm, setShowDeletePopConfirm] = useState(false);
  const [showRemoveTagPopConfirm, setShowRemoveTagPopConfirm] = useState(false);

  const onDelete = async() => {
    setShowDeletePopConfirm(false);
    setIsDeleting(true);
    const id = template.id;
    const updatedTemplates = customer.templates.filter(el => el.id !== id);
    const requestBody = createTemplateRequestBody(customer, updatedTemplates);
    const response = await setTemplateData(requestBody, apiConfig)

    if (response?.status === 200) {
      dispatch(customerActions.deleteTemplateById(id));
    } else {
      window.alert("An error occurred while trying to delete this template. Please try again.");
    }

    setIsDeleting(false);
  }

  const onRemoveTagConfirm = async() => {
    const id = template.id;
    let updatedTemplates = JSON.parse(JSON.stringify(customer.templates));
    for (const el of updatedTemplates) {
      if (el.id === id) el.isDefault = false;
    }

    const requestBody = createTemplateRequestBody(customer, updatedTemplates);
    const response = await setTemplateData(requestBody, apiConfig)

    if (response?.status === 200) {
      dispatch(customerActions.setTemplates(updatedTemplates))
    } else {
      window.alert("An error occurred while trying to update this template. Please try again.")
    }

    setShowRemoveTagPopConfirm(false);
  }

  return (
    <div
      className="account-templates__template"
      key={index}
    >
      <div className={isDeleting ? "account-templates__template__text account-templates__template__text--disabled" : "account-templates__template__text"}>
        {template.name}
        {template.isDefault && (
          <div
            className="account-templates__template__text__tag"
            onClick={() => setShowRemoveTagPopConfirm(true)}
          >
            {template.variant === "SINGLE" ? "Default Single BookingTemplate" : "Default Bulk Booking Template"}
            <AMIPopConfirm
              parentElement={popConfirmTarget}
              isVisible={showRemoveTagPopConfirm}
              position="centerScreen"
              onCancel={() => setShowRemoveTagPopConfirm(false)}
              onConfirm={() => onRemoveTagConfirm()}
            >
              <p>Stop <strong>{template.name}</strong> being the default template?</p>
            </AMIPopConfirm>
          </div>
        )}
      </div>
      <div className={
        isDeleting
          ? "account-templates__template__icons account-templates__template__disabled"
          : "account-templates__template__icons"
      }>
        <Icon
          name="Edit"
          style={{
            width: '16px',
            marginRight: '8px'
          }}
          onClick={() => setShowEditModal(true)}
        />
        <Icon
          id="delete-address-icon"
          name="Trash"
          style={{width: '16px'}}
          onClick={() => setShowDeletePopConfirm(true)}
        />

        <AMIPopConfirm
          parentElement={popConfirmTarget}
          isVisible={showDeletePopConfirm}
          position="centerScreen"
          onCancel={() => setShowDeletePopConfirm(false)}
          onConfirm={onDelete}
        >
          <p>Please confirm you wish to delete this template.</p>
        </AMIPopConfirm>
      </div>
      {isDeleting && (
        <div className="account-templates__template__spinner">
          <AMISpinner
            style={{
              height: '32px',
              width: '32px'
            }}
          />
        </div>
      )}

      {showEditModal && (
        <TemplateModal
          existingTemplate={template}
          close={() => setShowEditModal(false)}
        ></TemplateModal>
      )}
    </div>
  )
}

export default TemplateTableLine;