import {
  browserVersion,
  isChrome,
  isEdge,
  isElectron,
  isFirefox,
  isMobile,
  isOpera,
  isSafari,
  isMacOs,
  isWindows
} from "react-device-detect";
import { MinimumSupportedMajorBrowserVersions } from "./MinimumSupportedMajorBrowserVersions";

export const checkBrowser = () => {
  if (isMobile) {
    return true;
  }
  if (isChrome && browserVersion && parseInt(browserVersion) >= MinimumSupportedMajorBrowserVersions.Chrome) {
    return true;
  }
  if (isFirefox && browserVersion && parseInt(browserVersion) >= MinimumSupportedMajorBrowserVersions.Firefox) {
    return true;
  }
  if (isEdge) {
    return true
  }
  if (isOpera  && browserVersion && parseInt(browserVersion) >= MinimumSupportedMajorBrowserVersions.Opera) {
    return true;
  }
  if (isSafari && isMac() && browserVersion && parseInt(browserVersion) >= MinimumSupportedMajorBrowserVersions.Safari) {
    return true;
  }
  if (isSafari && isWindows) {
    return false;
  }
  // Required for running Cypress tests
  return isElectron ? true : false;
}

export const isMac = () => {
  return isMacOs;
}

export const isSafariBrowser = () => {
  return isSafari;
}

