import dayjs from 'dayjs';
import { WlBookingHistoryItem } from '../WlBookingsPage-Utils';

export function exportCSV(bookings: any) {
  let rows = [['Booking Date',	'Your Ref',	'AMI Ref', 'Carrier Ref', 'Company',	'Carrier',	'AMI Cost',	'Percentage Increase',	'Increased Amount',	'Shipper Price']];
  for (const row of bookings) {
    rows.push(Object.values(row));
  }

  const csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
  const encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
}

export function mapBookingsForCSV(bookings: WlBookingHistoryItem[]) {
  return bookings.map((el: WlBookingHistoryItem) => {
    return ({
      bookingDate: el.shipmentDate,
      yourRef: el.customerShippingReference.replace(/,/g, ''),
      amiRef: el.amiReference.replace(/,/g, ''),
      carrierRef: el.carrierShippingReference.replace(/,/g, ''),
      company: el.companyName.replace(/,/g, ''),
      carrier: el.carrierName.replace(/,/g, ''),
      amiCost: el.directCustomerCost.value,
      percentageIncrease: el.markupApplied.percentage ? el.markupApplied.markup : 'n/a',
      increasedAmount: el.markup.value,
      shipperPrice: el.indirectCustomerCost.value
    })
  })
}

export function filterBookingsByDateRange(bookings: any, dateFrom: number | undefined, dateTo: number | undefined) {
  return bookings.filter((el: any) => {
    const from = dateFrom ? dayjs(dateFrom) : dayjs('2000-01-01');
    const to = dateTo ? dayjs(dateTo) : dayjs();
    return (
      (dayjs(el.bookingDate).isAfter(from) || dayjs(el.bookingDate).isSame(from))
      && (dayjs(el.bookingDate).isBefore(to) || dayjs(el.bookingDate).isSame(to))
    )
  })
}