import React from 'react';
import AMIButton from '../button/Button';
import './PopConfirm.scss';

const AMIPopConfirm: React.FC<{
  parentElement: HTMLElement | null,
  isVisible: boolean,
  position?: 'top' | 'bottom' | 'centerScreen',
  style?: React.CSSProperties,
  id?: string,
  onCancel: () => void,
  onConfirm: () => void
}> = ({
  parentElement,
  isVisible,
  position,
  style,
  id,
  onCancel,
  onConfirm,
  children
}) => {

  const top = parentElement ? parentElement?.offsetTop : 0;
  const left = parentElement ? parentElement?.offsetLeft : 0;
  const height = parentElement?.getBoundingClientRect().height;

  let popoverStyle = {
    ...style,
    opacity: isVisible ? 1 : 0,
    zIndex: isVisible ? 1 : -1,
    top: `${
      position === 'bottom'
      ? top + 8
      : top - 8
    }px`,
    left: `${left}px`,
    transform: 'translateY(-100%)'
  }

  if (position === 'bottom') {
    popoverStyle = {
      ...popoverStyle,
      transform: `translateY(${height}px)`,
    }
  }

  if (position === 'centerScreen') {
    popoverStyle = {
      ...popoverStyle,
      position: 'absolute',
      left: '0',
      marginLeft: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }

  const handleCancelClick = (event: any) => {
    event.stopPropagation();
    onCancel();
  }

  const handleConfirmClick = (event: any) => {
    event.stopPropagation();
    onConfirm();
  }

  return (
    <div
      className="pop-confirm"
      id={id}
      style={popoverStyle}
    >
      {children}
      <div className="pop-confirm__button-container">
        <AMIButton
          variant="default"
          size="small"
          onClick={handleCancelClick}
        >
          Cancel
        </AMIButton>
        <AMIButton
          variant="primary"
          size="small"
          onClick={handleConfirmClick}
        >
          Confirm
        </AMIButton>
      </div>
    </div>
  )
}

export default AMIPopConfirm;