import React, { useEffect, useState } from 'react';
import './DropInDetails.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { AMIFormElement, AMISelect, AMISpinner } from '../../../../ui-components/UiComponents';
import { appDataActions, appDataSelector } from '../../../../features/appDataSlice';
import { bookingActions, bookingSelector } from '../../../../features/bookingSlice';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { getLocations } from '../../../../utilities/APIUtilities';
import { getCarrierName, getUpsUrl } from '../../../../utilities/HelperUtilities';
import { retrieveAMIDropInLocations } from "../../../../utilities/ContentRetrieverUtilities";

const DropInDetails = () => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const dropInDetails = booking.dropInDetails;
  const customer = useAppSelector(customerDetailsSelector);
  const carrier = getCarrierName(booking.selectedQuote.quoteId);
  const apiConfig = useAppSelector(appDataSelector).apiConfig;
  const origin = booking.origin.value;

  const [dropInLocations, setDropInLocations] = useState<any>([]);
  const [isFetchingLocations, setIsFetchingLocations] = useState(true);

  useEffect(() => {
    if (carrier !== 'ups' && origin !== 'NL') fetchAndSetLocations();
    else setIsFetchingLocations(false);
  }, [])

  const fetchAndSetLocations = async() => {
    let requestBody = {

    }
    if (carrier === 'fedex') {
      requestBody = {
        address: {
          countryCode: booking.origin.value,
          city: booking.originCityTown
        }
      }
    } else {
      requestBody = {
        address: {
          countryCode: booking.origin.value,
          postalCode: booking.originPostalCode
        }
      }
    }

    let locations = await getLocations(requestBody, carrier, apiConfig);

    const amiLocations = await retrieveAMIDropInLocations(booking, customer);

    if (amiLocations) locations = locations.concat(amiLocations);

    if (locations && locations.length > 0) {
      locations = locations.map((location: any) => {
        return ({
          id: Math.floor((Math.random() * 100000) + 1),
          companyName: location.addressAncillaryDetail.displayName,
          addressLine1: location.address.streetLines[0],
          addressLine2: location.address.streetLines[1],
          countyStateProvince: location.address.stateOrCounty,
          cityTown: location.address.city,
          postalCode: location.address.postalCode,
          countryCode: location.address.countryCode,
          telephoneNumber: location.contactDetails?.telephone
        })
      });
    }

    setDropInLocations(locations);
    resetDropInLocation(locations);
    setIsFetchingLocations(false);
  }

  const resetDropInLocation = (locations: any) => {
    const existingLocation = locations.find((location: any) => {
      return location.companyName === dropInDetails.companyName
    })

    if (!existingLocation) {
      dispatch(bookingActions.resetDropInDetails());
      dispatch(appDataActions.setDropInSelected(false));
    }
  }

  const changeDropInLocation = (event: any) => {
    try {
      if (event.value) {
        const location = dropInLocations.filter((location: any) => location.id === event.value)[0];
        dispatch(bookingActions.setDropInLocationDetails(location));
        dispatch(appDataActions.setDropInSelected(true));
      } else {
        dispatch(bookingActions.resetDropInDetails());
        dispatch(appDataActions.setDropInSelected(false));
      }
    } catch (error: any) {console.error(`Drop-in onChange drop-in location event: ${event}`)}
  }

  const formatDropInLocationForSelect = (location: any, index: number) => {
    let title = '';
    title = title + (location.companyName ? location.companyName + ', ' : '');
    title = title + (location.addressLine1 ? location.addressLine1 + ', ' : '');
    title = title + (location.cityTown ? location.cityTown + ', ' : '');
    title = title + (location.countyStateProvince ? location.countyStateProvince + ', ' : '');
    title = title + (location.postalCode ? location.postalCode + ', ' : '');
    title = title + (location.countryCode ? location.countryCode : '');

    return {
      title,
      value: location.id,
      key: location.id
    }
  }

  return (
    <div
      className="drop-in-details horizontal-card"
      id="drop-in-details"
    >

      <p className="horizontal-card__title">Drop In Details</p>

      {carrier === "ups" && (
        <p>Please click <a href={getUpsUrl(booking)} target="_blank" rel="noreferrer">here</a> to find your nearest drop in point</p>
      )}

      {carrier === "fedex" && origin === "NL" && (
        <p>Please click <a href="https://local.fedex.com/en-nl" target="_blank" rel="noreferrer">here</a> to find your nearest drop in point</p>
      )}

      {carrier === "dhl" && origin === "NL" && (
        <p>Please click <a href="https://locator.dhl.com/" target="_blank" rel="noreferrer">here</a> to find your nearest drop in point</p>
      )}

      {isFetchingLocations && <AMISpinner className="drop-in-details__spinner" />}

      {!isFetchingLocations
        && dropInLocations.length < 1
        && carrier !== "ups"
        && origin !== "NL"
        && <p>We are unable to find a nearby location at the moment. Please drop your shipment into your nearest AMI receiving centre.</p>
      }

      {!isFetchingLocations && dropInLocations.length > 0 && (
        <AMIFormElement label="Drop In Location">
          {dropInLocations.length > 0
          && <AMISelect
            name="dropInLocation"
            size="large"
            isSearchable
            defaultValue={formatDropInLocationForSelect(dropInDetails, 1)}
            options={dropInLocations.map((location: any, index: number) => {
              return formatDropInLocationForSelect(location, index);
            })}
            onChange={changeDropInLocation}
          />
          }
      </AMIFormElement>
      )}

      {dropInDetails.id && <div className="drop-in-details__details-container">
        <p className="drop-in-details__details-container__company-name">{dropInDetails.companyName}</p>
        <p>{dropInDetails.addressLine1}</p>
        <p>{dropInDetails.addressLine2}</p>
        <p>{dropInDetails.cityTown}</p>
        <p>{dropInDetails.countyStateProvince}</p>
        <p>{dropInDetails.postalCode}</p>
        <p>{dropInDetails.countryCode}</p>
        <p className="drop-in-details__details-container__telephone-number">Tel: {dropInDetails.telephoneNumber}</p>
      </div>}

    </div>
  )
}

export default DropInDetails;