import React, { useEffect, useRef, useState } from 'react';
import './AddEditAddressModal.scss';
import { AMIAlert, AMIButton, AMIFormElement, AMIInput, AMIModal, AMISelect, AMISpinner } from '../../../../ui-components/UiComponents';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { appDataSelector } from '../../../../features/appDataSlice';
import { addAddress, getCountryInfo, updateAddress } from '../../../../utilities/APIUtilities';
import { Country } from '../../../../features/bookingSlice';
import { customerActions, customerDetailsSelector } from '../../../../features/customerSlice';
import { createCountryObject, formatCounties, generateStreetLinesArray, trimIfString } from '../../../../utilities/HelperUtilities';
import { isBetweenXAndXCharacters, isValidEmail, isValidPostalCode, isValidTelephone } from '../../../../utilities/ValidationUtilities';

const AddEditAddressModal: React.FC<{
  existingAddress?: any,
  close: () => any
}> = ({
  existingAddress,
  close
}) => {

  const appData = useAppSelector(appDataSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const dispatch = useAppDispatch();
  const apiConfig = appData.apiConfig;
  const alertRef = useRef<any>(null);

  const [address, setAddress] = useState<any>({
    contactName: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    cityTown: '',
    countyStateProvince: '',
    postalCode: '',
    country: {
      title: '',
      value: ''
    },
    telephoneNumber: '',
    email: ''
  });
  const [isCountrySpinning, setIsCountrySpinning] = useState(false);
  const [formattedCounties, setFormattedCounties] = useState<any>([]);
  const [postalCodeRegex, setPostalCodeRegex] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showSaveAddressError, setShowSaveAddressError] = useState(false);
  const [ready, setReady] = useState(false);

  let countryInfoFailCount = 0;

  useEffect(() => {
    const populatedAddress = populateAddress();
    if (existingAddress) {
      onCountryChange(
        createCountryObject(appData.countries, existingAddress.userDetails.address.countryCode),
        populatedAddress
      );
    } else {
      setReady(true);
    }
  }, [])

  const populateAddress = () => {
    let newAddress;
    if (existingAddress) {
      const {address, contactDetails, notificationDetails} = existingAddress.userDetails;
      const addressToSet = {
        contactName: contactDetails.name,
        companyName: address.organisation,
        addressLine1: address.streetLines[0],
        addressLine2: address.streetLines[1] ? address.streetLines[1] : '',
        addressLine3: address.streetLines[2] ? address.streetLines[2] : '',
        cityTown: address.city,
        countyStateProvince: address.stateOrCounty,
        postalCode: address.postalCode,
        country: createCountryObject(appData.countries, address.countryCode),
        telephoneNumber: contactDetails.telephone,
        email: notificationDetails.email
      }
      setAddress(addressToSet)
      newAddress = addressToSet;
    } else {
      const addressToSet = {
        contactName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        cityTown: '',
        countyStateProvince: '',
        postalCode: '',
        country: {
          title: '',
          value: ''
        },
        telephoneNumber: '',
        email: ''
      }
      setAddress(addressToSet);
      newAddress = addressToSet;
    }

    return newAddress;
  }

  const onCountryChange = async(
    countryObject: Country,
    populatedAddress?: any
  ) => {
    setIsCountrySpinning(true);
    const countryInfo = await getCountryInfo(apiConfig, countryObject.value);

    if (!countryInfo?.postcodeFormat && countryInfoFailCount < 2) {
      countryInfoFailCount++;
      onCountryChange(countryObject);
      return;
    }

    if (
      !countryInfo
      || countryInfoFailCount > 2
      || countryInfo.postcodeFormat === 'Country not postcode-format enabled.'
    ) {
      setPostalCodeRegex('');
    } else {
      setPostalCodeRegex(countryInfo.postcodeFormat);
    }

    if (populatedAddress) {
      setAddress({
        ...populatedAddress,
        country: countryObject
      })
    } else {
      setAddress({
        ...address,
        countyStateProvince: '',
        country: countryObject
      })
    }

    if (countryInfo?.countiesStates?.length > 0) {
      setFormattedCounties(formatCounties(countryInfo.countiesStates));
    } else {
      setFormattedCounties([]);
    }
    setIsCountrySpinning(false);
    setReady(true);
  }

  const onFieldChange = (field: string, value: string) => {
    setAddress({
      ...address,
      [field]: value
    })
  }

  const errHandler = {
    contactName: {
      criteria: isBetweenXAndXCharacters(address.contactName, 3, 35),
      message: 'Between 3 and 35 characters'
    },
    companyName: {
      criteria: isBetweenXAndXCharacters(address.companyName, 3, 35),
      message: 'Between 3 and 35 characters'
    },
    addressLine1: {
      criteria: isBetweenXAndXCharacters(address.addressLine1, 3, 35),
      message: 'Between 3 and 35 characters'
    },
    addressLine2: {
      criteria: address.addressLine2
        ? isBetweenXAndXCharacters(address.addressLine2, 3, 35)
        : true,
      message: 'Between 3 and 35 characters.'
    },
    addressLine3: {
      criteria: address.addressLine3
        ? isBetweenXAndXCharacters(address.addressLine3, 3, 35)
        : true,
      message: 'Between 3 and 35 characters.'
    },
    countyStateProvince: {
      criteria: formattedCounties.length > 0
        ? address.countyStateProvince
        : true,
      message: 'Required'
    },
    postalCode: {
      criteria: postalCodeRegex
        ? address.postalCode && isValidPostalCode(address.postalCode, postalCodeRegex)
        : true,
      message: 'Required. Must be a valid postal code'
    },
    cityTown: {
      criteria: address.cityTown,
      message: 'Between 3 and 35 characters'
    },
    country: {
      criteria: address.country.value,
      message: 'Required'
    },
    telephoneNumber: {
      criteria: isValidTelephone(
            address.telephoneNumber,
            address.country.value === 'US'
          ),
      message: address.country.value === 'US'
        ? 'Must be 10 digits' : 'Must be between 10 and 15 digits'
    },
    email: {
      criteria: address.email && isValidEmail(address.email),
      message: 'Required. Must be a valid email.'
    }
  }

  const onSave = async() => {
    setShowSaveAddressError(false);
    setShowErrors(false);
    setIsSaving(true);

    for (const error in errHandler) {
      if (!(errHandler as any)[error].criteria) {
        setShowErrors(true);
        setIsSaving(false);
        return;
      }
    }

    let newAddress = {
      branchId: customer.creditCheck.branchId,
      userDetails: {
        contactDetails: {
          name: trimIfString(address.contactName),
          telephone: trimIfString(address.telephoneNumber)
        },
        address: {
          organisation: trimIfString(address.companyName),
          countryCode: trimIfString(address.country.value),
          postalCode: trimIfString(address.postalCode),
          streetLines: generateStreetLinesArray(address.addressLine1, address.addressLine2, address.addressLine3),
          city: trimIfString(address.cityTown),
          stateOrCounty: address.countyStateProvince?.value ? trimIfString(address.countyStateProvince.value) : address.countyStateProvince
        },
        notificationDetails: {
          email: trimIfString(address.email),
          mobile: trimIfString(address.telephoneNumber)
        }
      }
    } as any;

    if (existingAddress) {
      const addressWithId = {...newAddress, id: existingAddress.id};
      const response = await updateAddress(addressWithId, apiConfig) as any;
      if (response?.message === 'OK') {
        dispatch(customerActions.updateSavedAddress(addressWithId));
      } else {
        handleAddressError();
        return;
      }
    } else {
      const id = await addAddress(newAddress, apiConfig) as any;

      if (id) {
        newAddress.id = id;
        dispatch(customerActions.addSavedAddress(newAddress));
      } else {
        handleAddressError();
        return;
      }
    }

    if (!showSaveAddressError) {
      setShowErrors(false);
      setIsSaving(false);
      close();
    }
  }

  const handleAddressError = () => {
    setShowSaveAddressError(true);
    setIsSaving(false);
    alertRef.current.scrollIntoView({block: 'nearest', behavior: 'smooth'});
  }

  return (
    <AMIModal
      className="add-edit-address-modal"
      title={
        existingAddress
          ? "Edit Address"
          : "Add Address"
      }
      width={'80%'}
      buttons={[
        <AMIButton
          key={Math.random()}
          variant='default'
          size="large"
          onClick={close}
        >Cancel
        </AMIButton>,
        <AMIButton
          key={Math.random()}
          variant='primary'
          size="large"
          isSpinning={isSaving}
          onClick={onSave}
        >Save
        </AMIButton>
      ]}
      close={() => close()}
    >
      {!ready && <div className="add-edit-address-modal__spinner"><AMISpinner /></div>}
      {ready && (
        <form>
          <AMIFormElement
            label="Country*"
            className="add-edit-address-modal__country"
            errorMessage={
              showErrors
              && !errHandler.country.criteria
              ? errHandler.country.message
              : ''
            }
          >
            <AMISelect
              name="country"
              size="large"
              isSearchable
              isSpinning={isCountrySpinning}
              defaultValue={address.country}
              options={appData.countries}
              onChange={(event) => onCountryChange(event)}
            />
          </AMIFormElement>

          <AMIFormElement
            label="Company Name*"
            errorMessage={
              showErrors
              && !errHandler.companyName.criteria
              ? errHandler.companyName.message
              : ''
            }
          >
            <AMIInput
              name="company-name"
              size="large"
              value={address.companyName}
              onChange={(event) => onFieldChange('companyName', event.target.value)}
            />
          </AMIFormElement>

          <AMIFormElement
            label="Contact Name*"
            errorMessage={
              showErrors
              && !errHandler.contactName.criteria
              ? errHandler.contactName.message
              : ''
            }
          >
            <AMIInput
              name="contact-name"
              size="large"
              value={address.contactName}
              onChange={(event) => onFieldChange('contactName', event.target.value)}
            />
          </AMIFormElement>

          <AMIFormElement
            label="Address Line 1*"
            errorMessage={
              showErrors
              && !errHandler.addressLine1.criteria
              ? errHandler.addressLine1.message
              : ''
            }
          >
            <AMIInput
              name="address-line-1"
              size="large"
              value={address.addressLine1}
              onChange={(event) => onFieldChange('addressLine1', event.target.value)}
            />
          </AMIFormElement>

          <AMIFormElement
            label="Address Line 2"
            errorMessage={
              showErrors
              && !errHandler.addressLine2.criteria
              ? errHandler.addressLine2.message
              : ''
            }
          >
            <AMIInput
              name="address-line-2"
              size="large"
              value={address.addressLine2}
              onChange={(event) => onFieldChange('addressLine2', event.target.value)}
            />
          </AMIFormElement>

          <AMIFormElement
            label="Address Line 3"
            errorMessage={
              showErrors
              && !errHandler.addressLine3.criteria
              ? errHandler.addressLine3.message
              : ''
            }
          >
            <AMIInput
              name="address-line-3"
              size="large"
              value={address.addressLine3}
              onChange={(event) => onFieldChange('addressLine3', event.target.value)}
            />
          </AMIFormElement>

          <AMIFormElement
            label="City/Town*"
            errorMessage={
              showErrors
              && !errHandler.cityTown.criteria
              ? errHandler.cityTown.message
              : ''
            }
          >
            <AMIInput
              name="city-town"
              size="large"
              value={address.cityTown}
              onChange={(event) => onFieldChange('cityTown', event.target.value)}
            />
          </AMIFormElement>

          {formattedCounties.length > 0 && (
            <AMIFormElement
              label="County/State/Province*"
              errorMessage={
                showErrors
                && !errHandler.countyStateProvince.criteria
                ? errHandler.countyStateProvince.message
                : ''
              }
            >
              <AMISelect
                name="county-state-province"
                size="large"
                isSearchable
                options={formattedCounties}
                defaultValue={formattedCounties.find((item: any) => {
                  return item.value === address.countyStateProvince
                })}
                onChange={(event) => onFieldChange('countyStateProvince', event)}
              />
            </AMIFormElement>
          )}

          {formattedCounties.length === 0 && (
            <AMIFormElement
              label="County/State/Province"
              errorMessage={
                showErrors
                && !errHandler.countyStateProvince.criteria
                ? errHandler.countyStateProvince.message
                : ''
              }
            >
              <AMIInput
                name="county-state-province"
                size="large"
                value={address.countyStateProvince}
                onChange={(event) => onFieldChange('countyStateProvince', event.target.value)}
              />
            </AMIFormElement>
          )}

          <AMIFormElement
            label={postalCodeRegex ? "Postal Code*" : "Postal Code"}
            errorMessage={
              showErrors
              && !errHandler.postalCode.criteria
              ? errHandler.postalCode.message
              : ''
            }
          >
            <AMIInput
              name="postal-code"
              size="large"
              value={address.postalCode}
              onChange={(event) => onFieldChange('postalCode', event.target.value)}
            />
          </AMIFormElement>

          <AMIFormElement
            label="Telephone Number*"
            errorMessage={
              showErrors
              && !errHandler.telephoneNumber.criteria
              ? errHandler.telephoneNumber.message
              : ''
            }
          >
            <AMIInput
              name="telephone-number"
              size="large"
              value={address.telephoneNumber}
              onChange={(event) => onFieldChange('telephoneNumber', event.target.value)}
            />
          </AMIFormElement>

          <AMIFormElement
            label="Email*"
            errorMessage={
              showErrors
              && !errHandler.email.criteria
              ? errHandler.email.message
              : ''
            }
          >
            <AMIInput
              name="email"
              size="large"
              value={address.email}
              onChange={(event) => onFieldChange('email', event.target.value)}
            />
          </AMIFormElement>
        </form>
      )}

      {showSaveAddressError && (
        <div
          ref={alertRef}
          className="add-edit-address-modal__saved-address-error"
        >
          <AMIAlert variant="error">
            <p>An error ocurred while trying to save this address, please try again or contact customers services.</p>
          </AMIAlert>
        </div>
      )}
    </AMIModal>
  )
}

export default AddEditAddressModal;