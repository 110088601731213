import React, { useEffect, useState } from 'react';
import { AMIInput } from '../../../ui-components/UiComponents';
import TemplateTableLine from './template-table-line/TemplateTableLine';
import './AccountTemplates.scss';
import TemplateModal from './template-modal/TemplateModal';
import { useAppSelector } from '../../../app/hooks';
import { customerDetailsSelector } from '../../../features/customerSlice';

const AccountTemplates = () => {

  const customer = useAppSelector(customerDetailsSelector);
  const templates = customer.templates;

  const [filteredTemplates, setFilteredTemplates] = useState<any>(templates);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    sortTemplates(templates);
  }, [templates])

  const sortTemplates = (templates: any) => {
    const sortedTemplates = JSON.parse(JSON.stringify(templates));
    sortedTemplates.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setFilteredTemplates(sortedTemplates);
  }

  const onFilter = (searchString: string) => {
    const query = searchString.toLowerCase()
    if (templates && query.length > 0) {
      const filteredResult = templates.filter((template: any) => {
        return (
          template.name.toLowerCase().includes(query)
        )
      })
      sortTemplates(filteredResult);
    } else {
      sortTemplates(templates);
    }
  }

  return (
    <div className="account-templates">
      <h2>Templates</h2>

      <>
        <div className="account-templates__top-row">
          <AMIInput
            name="account-templates-filter"
            placeholder="Filter..."
            onChange={(event) => onFilter(event.target.value)}
          />
          <div
            className="account-templates__top-row__add-new-template-btn"
            onClick={() => setShowModal(true)}
          >
            + Add Template
          </div>
        </div>

        <div className="account-templates__table">
          {filteredTemplates && filteredTemplates.map((template: any, index: number) => {
            return (
              <div className="template-wrapper" key={index}>
                <TemplateTableLine
                  template={template}
                  index={index}
                />
              </div>
            )
          })}
        </div>

        {showModal && <TemplateModal close={() => setShowModal(false)}/>}

      </>
    </div>
  )
}

export default AccountTemplates;