import { Country } from "../../../features/bookingSlice";
import { generateStreetLinesArray } from "../../../utilities/HelperUtilities";

export const formatAddressToSave = (customer: any, address: any, country: Country, postalCode: string) => {
  return {
    branchId: customer.creditCheck.branchId,
    userDetails: {
      contactDetails: {
        name: address.contactName,
        telephone: address.telephoneNumber
      },
      address: {
        organisation: address.companyName,
        countryCode: country.value,
        postalCode,
        streetLines: generateStreetLinesArray(
          address.addressLine1,
          address.addressLine2,
          address.addressLine3 ? address.addressLine3 : ''
        ),
        city: address.cityTown,
        stateOrCounty: address.countyStateProvince?.value ? address.countyStateProvince.value : address.countyStateProvince
      },
      notificationDetails: {
        email: address.email,
        mobile: address.telephoneNumber
      }
    }
  } as any;
}