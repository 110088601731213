import React from 'react';
import './InfoBox.scss';

const AMIInfoBox: React.FC<{
  title: string,
  className?: string,
  id?: string
}> = ({
  title,
  className,
  id,
  children
}) => {

  return (
    <div
      className={className + ' info-box'}
      id={id}
    >
      <h1>{title}</h1>
      {children}
    </div>
  )
};

export default AMIInfoBox;