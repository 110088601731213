import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { AMIAlert, AMICheckbox, AMIFormElement, AMIInput, AMISelect } from '../../../../ui-components/UiComponents';
import TaxNumber from '../../../../components/tax-numbers/TaxNumbers';
import { appDataActions, appDataSelector } from '../../../../features/appDataSlice';
import { bookingActions, bookingSelector } from '../../../../features/bookingSlice';
import { formatCounties, getPostalCodeRegex, trimTelephone } from '../../../../utilities/HelperUtilities';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { getCountryInfo } from '../../../../utilities/APIUtilities';
import SavedAddressSelect, { DetailSection } from '../../../../components/saved-address-select/SavedAddressSelect';
import { compareAddresses } from '../utilities';
import { extractRule } from '../../../../utilities/RulesEngineUtilities';

const BrokerSelect: React.FC<{
  errHandler: any;
  customRules: any;
}> = ({
  errHandler,
  customRules
}) => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const appData = useAppSelector(appDataSelector);
  const showErrors = appData.showDetailsPageErrors;
  const { taxNumbers, brokerSelected } = booking.brokerDetails;
  const counties = appData.brokerCounties;
  const branchId = customer.creditCheck.branchId;
  const apiConfig = appData.apiConfig;

  const [savedAddressSnapshot, setSavedAddressSnapshot] = useState<any>();
  const [disableSaveAddressCheckbox, setDisableSavedAddressCheckbox] = useState(false);
  const [preSelectBroker, setPreSelectBroker] = useState(false);

  useEffect(() => {
    setDisableSavedAddressCheckbox(compareAddresses(
      booking.brokerDetails,
      booking.brokerDetails.country,
      customer.creditCheck.branchId,
      savedAddressSnapshot
    ))
  }, [booking.brokerDetails]);

  useEffect(() => {
    const preSelectBroker = extractRule(customRules, 'canaryIslandsBroker')?.value;

    setPreSelectBroker(preSelectBroker);
    if (preSelectBroker) {
      dispatch(bookingActions.updateBrokerDetail({
        field: 'brokerSelected',
        value: true
      }));
    }
  }, [customRules])

  const handleCountryChange = async(countryCode: string, address?: any) => {
    const countryInfo = await getCountryInfo(apiConfig, countryCode);

    const regex = getPostalCodeRegex(countryInfo);

    dispatch(appDataActions.updateField({
      field: 'brokerPostalCodeRegex',
      value: regex
    }))

    if (countryInfo.countiesStates.length > 0) {
      const formattedCounties = formatCounties(countryInfo.countiesStates);
      dispatch(appDataActions.updateField({
        field: 'brokerCounties',
        value: formattedCounties
      }))
    } else {
      dispatch(appDataActions.updateField({
        field: 'brokerCounties',
        value: []
      }))
    }

    if (address?.countyStateProvince) {
      dispatch(bookingActions.updateBrokerDetail({
        field: 'countyStateProvince',
        value: address.countyStateProvince
      }))
    } else {
      dispatch(bookingActions.updateBrokerDetail({
        field: 'countyStateProvince',
        value: ''
      }))
    }
  }

  const handleBrokerCheck = () => {
    dispatch(bookingActions.updateBrokerDetail({
      field: 'brokerSelected',
      value: !brokerSelected
    }))
  }

  const changeBrokerDetails = (field: string, event: any) => {
    let value = event.value;
    if (field === 'telephoneNumber') {
      value = trimTelephone(value);
    }
    if (field === 'country') {
      value = event;
      handleCountryChange(event.value);
    }
    dispatch(bookingActions.updateBrokerDetail({
      field,
      value: value
    }))
  }

  const handleAddTaxNumber = () => {
    dispatch(bookingActions.addBrokerTaxNumber(
      {
        type: '',
        value: ''
      }
    ));
  }

  const updateTaxNumbers = (event: any) => {
    dispatch(bookingActions.updateBrokerTaxNumbers(
      [...event]
    ));
  }

  const deleteTaxNumber = (index: number) => {
    if (taxNumbers.length > 1) {
      const taxNumbersCopy = [...taxNumbers];
      taxNumbersCopy.splice(index, 1);
      dispatch(bookingActions.updateBrokerTaxNumbers(taxNumbersCopy));
    }
  }

  const onSaveAddressCheck = (address: any) => {
    handleCountryChange(address.country.value, address);
    setSavedAddressSnapshot(address);
  }

  return (
    <div
      className="broker-select-details horizontal-card"
      id="broker-details"
    >

    <p className="horizontal-card__title">Broker Select Option</p>

    <AMICheckbox
      text="This shipment requires broker information."
      className="details-page__checkbox"
      disabled={preSelectBroker}
      checked={brokerSelected}
      onChange={handleBrokerCheck}
    />

    {preSelectBroker && <AMIAlert
      variant="warning"
      style={{
        gridColumn: "1 / 3",
        marginBottom: "24px"
      }}
    >
      <p>Please be advised that you need to appoint your own customs broker to clear the shipment. If the shipment is not cleared it would be destroyed as there is no return policy from Canary Islands.</p>
    </AMIAlert>}

    {brokerSelected && <>

    <SavedAddressSelect
        section={DetailSection.BROKER_DETAILS}
        onChange={(event: any) => onSaveAddressCheck(event)}
    ></SavedAddressSelect>

    <AMIFormElement
      label="Full Contact Name"
      errorMessage={
        showErrors
        && !errHandler.brokerDetails.contactName.criteria
        ? errHandler.brokerDetails.contactName.message
        : ''
      }
    >
      <AMIInput
        name="brokerContactName"
        placeholder="Required"
        size="large"
        value={booking.brokerDetails.contactName}
        onChange={(event) => changeBrokerDetails('contactName', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Company Name"
      errorMessage={
        showErrors
        && !errHandler.brokerDetails.companyName.criteria
        ? errHandler.brokerDetails.companyName.message
        : ''
      }
    >
      <AMIInput
        name="brokerCompanyName"
        placeholder="Required"
        size="large"
        value={booking.brokerDetails.companyName}
        onChange={(event) => changeBrokerDetails('companyName', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Country"
      className="details-page__country"
      errorMessage={
        showErrors
        && !errHandler.brokerDetails.country.criteria
        ? errHandler.brokerDetails.country.message
        : ''
      }
    >
      <AMISelect
        name="brokerCountry"
        placeholder="Required"
        size="large"
        isSearchable
        defaultValue={booking.brokerDetails.country}
        options={appData.countries}
        onChange={(event) => changeBrokerDetails('country', event)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Address Line 1"
      errorMessage={
        showErrors
        && !errHandler.brokerDetails.addressLine1.criteria
        ? errHandler.brokerDetails.addressLine1.message
        : ''
      }
    >
      <AMIInput
        name="brokerAddressLine1"
        placeholder="Required"
        size="large"
        value={booking.brokerDetails.addressLine1}
        onChange={(event) => changeBrokerDetails('addressLine1', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Address Line 2"
      errorMessage={
        showErrors
        && !errHandler.brokerDetails.addressLine2.criteria
        ? errHandler.brokerDetails.addressLine2.message
        : ''
      }
    >
      <AMIInput
        name="brokerAddressLine2"
        size="large"
        value={booking.brokerDetails.addressLine2}
        onChange={(event) => changeBrokerDetails('addressLine2', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Address Line 3"
      errorMessage={
        showErrors
        && !errHandler.brokerDetails.addressLine3.criteria
        ? errHandler.brokerDetails.addressLine3.message
        : ''
      }
    >
      <AMIInput
        name="brokerAddressLine3"
        size="large"
        value={booking.brokerDetails.addressLine3}
        onChange={(event) => changeBrokerDetails('addressLine3', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="City/Town"
      errorMessage={
        showErrors
        && !errHandler.brokerDetails.cityTown.criteria
        ? errHandler.brokerDetails.cityTown.message
        : ''
      }
    >
      <AMIInput
        name="brokerCityTown"
        placeholder="Required"
        size="large"
        value={booking.brokerDetails.cityTown}
        onChange={(event) => changeBrokerDetails('cityTown', event.target)}
      />
    </AMIFormElement>

    {counties.length === 0 && (
      <AMIFormElement
        label="County/State/Province"
        errorMessage={
          showErrors
          && !errHandler.brokerDetails.countyStateProvince.criteria
          ? errHandler.brokerDetails.countyStateProvince.message
          : ''
        }
      >
        <AMIInput
          name="brokerCountyStateProvince"
          size="large"
          value={booking.brokerDetails.countyStateProvince}
          onChange={(event) => changeBrokerDetails('countyStateProvince', event.target)}
        />
      </AMIFormElement>
    )}

    {counties.length > 0 && (
      <AMIFormElement
        label="County/State/Province"
        errorMessage={
          showErrors
          && !errHandler.brokerDetails.countyStateProvince.criteria
          ? errHandler.brokerDetails.countyStateProvince.message
          : ''
        }
      >
        <AMISelect
          name="brokerCountyStateProvince"
          placeholder="Required"
          size="large"
          isSearchable
          options={counties}
          onChange={changeBrokerDetails.bind(null, 'countyStateProvince')}
          defaultValue={counties.find((item: any) => {
            return item.value === booking.brokerDetails.countyStateProvince
          })}
        />
      </AMIFormElement>
    )}

    <AMIFormElement
      label="Postal/Zip Code"
      errorMessage={
        showErrors
        && !errHandler.brokerDetails.postalCode.criteria
        ? errHandler.brokerDetails.postalCode.message
        : ''
      }
    >
      <AMIInput
        name="brokerPostalCode"
        placeholder={appData.brokerPostalCodeRegex ? "Required" : ""}
        size="large"
        value={booking.brokerDetails.postalCode}
        onChange={(event) => changeBrokerDetails('postalCode', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Telephone Number"
      errorMessage={
        showErrors
        && !errHandler.brokerDetails.telephoneNumber.criteria
        ? errHandler.brokerDetails.telephoneNumber.message
        : ''
      }
    >
      <AMIInput
        name="brokerTelephoneNumber"
        placeholder="Required"
        size="large"
        type="number"
        value={booking.brokerDetails.telephoneNumber}
        onChange={(event) => changeBrokerDetails('telephoneNumber', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Email"
      errorMessage={
        showErrors
        && !errHandler.brokerDetails.email.criteria
        ? errHandler.brokerDetails.email.message
        : ''
      }
    >
      <AMIInput
        name="brokerEmail"
        placeholder="Required"
        size="large"
        type="email"
        value={booking.brokerDetails.email}
        onChange={(event) => changeBrokerDetails('email', event.target)}
      />
    </AMIFormElement>

    {taxNumbers.map((element: any, index: number) => {
        return (
          <div className="tax-numbers-wrapper" key={index}>
            <TaxNumber
              element={element}
              index={index}
              allElements={taxNumbers}
              booking={booking}
              detailsContainer="brokerDetails"
              customRules={customRules}
              updateElements={(event) => updateTaxNumbers(event)}
              onDelete={() => deleteTaxNumber(index)}
            />
          </div>
        )
      })}

      <p className="tax-numbers-add-text" onClick={handleAddTaxNumber}>+ Add another tax number</p>
    </>
    }

    {brokerSelected && (
      <AMIFormElement className="save-address__checkbox">
        <AMICheckbox
          text=""
          disabled={disableSaveAddressCheckbox || !branchId}
          checked={appData.saveBrokerAddress}
          onChange={() => dispatch(appDataActions.setBrokerSaveAddressCheckbox())}
        >
          {branchId && <p>Save this address for future use?</p>}
          {!branchId && <p>Save this address for future use? (Please contact <a href="/contact" target="_blank" rel="noreferrer">customer services</a> to enable address book features)</p>}
        </AMICheckbox>
      </AMIFormElement>
    )}

  </div>
  )
}

export default BrokerSelect;