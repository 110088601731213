import { ElectronicTradeDocumentType } from "../../../../../features/bookingSlice";

export const uploadDocumentOptions = [
  {
    title: 'Commercial Invoice',
    value: ElectronicTradeDocumentType.COMMERCIAL_INVOICE
  },
  {
    title: 'Certificate of Origin',
    value: ElectronicTradeDocumentType.CERTIFICATE_OF_ORIGIN
  },
  {
    title: 'Customs Declaration',
    value: ElectronicTradeDocumentType.CUSTOMS_DECLARATION
  },
  {
    title: 'Proforma',
    value: ElectronicTradeDocumentType.PRO_FORMA_INVOICE
  }
]