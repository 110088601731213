import React, { useState } from 'react';
import { AMICheckbox, AMIFormElement, AMIInput, AMIPopover, AMISelect, AMISpinner, Icon } from '../../../../../ui-components/UiComponents';
import { useAppSelector } from '../../../../../app/hooks';
import { appDataSelector } from '../../../../../features/appDataSlice';
import TaxNumber from '../../../../../components/tax-numbers/TaxNumbers';
import { addTaxNumber, deleteTaxNumber } from '../utils';
import { TemplateForm } from '../../../../../utilities/TemplateHandler';
import { getCountryInfo } from '../../../../../utilities/APIUtilities';
import { formatCounties } from '../../../../../utilities/HelperUtilities';

const TemplateModalShipperDetails: React.FC<{
  template: TemplateForm;
  updateShipperDetails: (field: string, value: any) => void;
}> = ({
  template,
  updateShipperDetails,
}) => {

  const appData = useAppSelector(appDataSelector);
  const data = template.shipper;
  const taxNumbers = data.taxNumbers;

  const [aesPopoverVisible, setAesPopoverVisible] = useState(false);
  const [handlingCountryInfo, setHandlingCountryInfo] = useState(false);
  const [counties, setCounties] = useState<any>([]);

  const handleCountryInfo = async(country: any) => {
    setHandlingCountryInfo(true);
    const countryInfo = await getCountryInfo(appData.apiConfig, country.value);
    if (countryInfo?.countiesStates?.length > 0) {
      setCounties(formatCounties(countryInfo.countiesStates));
    }
    updateShipperDetails('country', country)
    setHandlingCountryInfo(false);
  }

  if (handlingCountryInfo) {
    return (
      <div className="template-modal-spinner">
        <AMISpinner />
        <p>Fetching country info...</p>
      </div>
    )
  }

  return (
    <div className="template-modal-shipper-details">
      <AMIFormElement
        className="shipment-details__form-element shipment-details__checkbox"
        style={{gridColumn: "1 / 3", marginBottom: "48px"}}
      >
        <AMICheckbox
          text="True Shipper"
          checked={!!data.trueShipper}
          onChange={() => updateShipperDetails("trueShipper", !data.trueShipper)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Full Contact Name"
      >
        <AMIInput
          name="shipperContactName"
          size="large"
          value={data.contactName}
          onChange={(event) => updateShipperDetails("contactName", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Company Name"
      >
        <AMIInput
          name="shipperCompanyName"
          size="large"
          value={data.companyName}
          onChange={(event) => updateShipperDetails("companyName", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Country"
        className="template-modal__country"
      >
        <AMISelect
          name="shipperCountry"
          size="large"
          isSearchable
          defaultValue={data.country}
          options={appData.countries}
          onChange={(event) => handleCountryInfo(event)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Address Line 1"
      >
        <AMIInput
          name="shipperAddressLine1"
          size="large"
          value={data.addressLine1}
          onChange={(event) => updateShipperDetails("addressLine1", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Address Line 2"
      >
        <AMIInput
          name="shipperAddressLine2"
          size="large"
          value={data.addressLine2}
          onChange={(event) => updateShipperDetails("addressLine2", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Address Line 3"
      >
        <AMIInput
          name="shipperAddressLine3"
          size="large"
          value={data.addressLine3}
          onChange={(event) => updateShipperDetails("addressLine3", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="City/Town"
      >
        <AMIInput
          name="shipperCityTown"
          size="large"
          value={data.cityTown}
          onChange={(event) => updateShipperDetails("cityTown", event.target.value)}
        />
      </AMIFormElement>

      {counties.length === 0 && (
        <AMIFormElement label="County/State/Province">
          <AMIInput
            name="shipperCountyStateProvince"
            size="large"
            value={data.countyStateProvince}
            onChange={(event) => updateShipperDetails('countyStateProvince', event.target.value)}
          />
        </AMIFormElement>
      )}

      {counties.length > 0 && (
        <AMIFormElement label="County/State/Province">
          <AMISelect
            name="shipperCountyStateProvince"
            size="large"
            isSearchable
            options={counties}
            onChange={(event) => updateShipperDetails('countyStateProvince', event.value)}
            defaultValue={counties.find((item: any) => {
              return item.value === data.countyStateProvince
            })}
          />
        </AMIFormElement>
      )}

      <AMIFormElement
        label="Postal/Zip Code"
      >
        <AMIInput
          name="collectionPostalCode"
          size="large"
          value={data.postalCode}
          onChange={(event) => updateShipperDetails("postalCode", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Telephone Number"
      >
        <AMIInput
          name="shipperTelephoneNumber"
          size="large"
          value={data.telephoneNumber}
          onChange={(event) => updateShipperDetails("telephoneNumber", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Email"
      >
        <AMIInput
          name="shipperEmail"
          size="large"
          type="email"
          value={data.email}
          onChange={(event) => updateShipperDetails("email", event.target.value)}
        />
      </AMIFormElement>

      {taxNumbers.map((element: any, index: number) => {
        return (
          <div className="tax-numbers-wrapper" key={index}>
            <TaxNumber
              element={element}
              index={index}
              allElements={taxNumbers}
              booking={template}
              detailsContainer="shipper"
              updateElements={(event) => updateShipperDetails("taxNumbers", event)}
              onDelete={() => updateShipperDetails("taxNumbers", deleteTaxNumber(taxNumbers, index))}
            />
          </div>
        )
      })}

      {<p className="tax-numbers-add-text" onClick={() => updateShipperDetails("taxNumbers", addTaxNumber(taxNumbers))}>+ Add another tax number</p>}

      <AMIFormElement
        label={(
          <div>
            AES/ITN or exemption code
            <span
              onMouseEnter={() => setAesPopoverVisible(true)}
              onMouseLeave={() => setAesPopoverVisible(false)}
            >
              <Icon
                name="CircleQuestion"
                color="#932682"
                style={{marginLeft: "4px"}}
              />
              <AMIPopover
                isVisible={aesPopoverVisible}
              >
                <p style={{marginBottom: "12px"}}>AES numbers are to be entered in the format AESXyyyymmddxxxxxx - Example: <strong>AESX2022011412345678</strong></p>
                <p>Exemtion codes are to be entered in the format No EEI xx.xx(x)(x) - example: <strong>No EEI 30.37(a)</strong></p>
              </AMIPopover>
            </span>
          </div>
        )}
      >
        <AMIInput
          name="shipperAesNumber"
          size="large"
          value={data.aes}
          onChange={(event) => updateShipperDetails("aes", event.target.value)}
        />
      </AMIFormElement>

      {data.country.value === "AU" && (
        <AMIFormElement
          label="Export Declaration Number (EDN)"
        >
          <AMIInput
            name="EDN"
            size="large"
            value={data.edn}
            onChange={(event) => updateShipperDetails("edn", event.target.value)}
          />
        </AMIFormElement>
      )}

    </div>
  )
}

export default TemplateModalShipperDetails;