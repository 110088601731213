import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import AMIRadio from '../../../../ui-components/radio/Radio';
import { QuoteObj } from '../../../../features/bookingSlice';
import { customerActions, customerDetailsSelector, QuoteFilterType } from '../../../../features/customerSlice';
import { formatCarrierName } from '../../../../utilities/HelperUtilities';
import { getCarriers, getTimes } from '../utilities';
import './QuoteFilter.scss';
import AMISwitch from '../../../../ui-components/switch/Switch';
import { appDataActions, appDataSelector } from '../../../../features/appDataSlice';

const QuoteFilter: React.FC<{
  quotes: QuoteObj[]
}> = ({
  quotes
}) => {

  const dispatch = useAppDispatch();
  const customer = useAppSelector(customerDetailsSelector);
  const quoteSort = useAppSelector(appDataSelector).quoteSort;

  const [times, setTimes] = useState<string[]>([]);
  const [carriers, setCarriers] = useState<string[]>([]);

  useEffect(() => {
    setTimes(getTimes(quotes));
    setCarriers(getCarriers(quotes));
  }, []);

  const onChange = (block: string, value: any) => {
    dispatch(customerActions.updateQuoteFilters({block, value}))
  }

  const onSortToggle = (event: number) => {
    dispatch(appDataActions.setQuoteSort(event));
  }

  return (
    <div className="quote-filter vertical-card">

      <div className="quote-filter__filter-switch">
        <AMISwitch
          titles={["Cost", "Time"]}
          position={quoteSort}
          onToggle={onSortToggle}
        />
      </div>

      <div className="quote-filter__filter-block">

        <p className="quote-filter__heading">Filter by Type</p>
        <AMIRadio
          text="All"
          className="quote-filter__radio"
          checked={customer.quoteFilters.type === QuoteFilterType.ALL}
          onChange={() => onChange("type", QuoteFilterType.ALL)}
        />
        <AMIRadio
          text="Collection"
          className="quote-filter__radio"
          checked={customer.quoteFilters.type === QuoteFilterType.COLLECTED}
          onChange={() => onChange("type", QuoteFilterType.COLLECTED)}
        />
        <AMIRadio
          text="Drop In"
          className="quote-filter__radio"
          checked={customer.quoteFilters.type === QuoteFilterType.DROP_IN}
          onChange={() => onChange("type", QuoteFilterType.DROP_IN)}
        />
      </div>

      <div className="quote-filter__filter-block">
        <p className="quote-filter__heading">Filter by Carrier</p>
        <AMIRadio
          className="quote-filter__radio"
          text="All"
          checked={customer.quoteFilters.carrier === "ALL"}
          onChange={() => onChange("carrier", "ALL")}
        />
        {carriers.map((carrier: string, index: number) => {
          return (
            <div key={index}>
              <AMIRadio
                className="quote-filter__radio"
                text={formatCarrierName(carrier)}
                checked={customer.quoteFilters.carrier === carrier}
                onChange={() => onChange("carrier", carrier)}
              />
            </div>
          )
        })}
      </div>

      {times.length > 0 && (
        <div className="quote-filter__filter-block">
          <p className="quote-filter__heading">Filter by Delivery Time</p>
          <AMIRadio
            className="quote-filter__radio"
            text="All"
            checked={customer.quoteFilters.time === "ALL"}
            onChange={() => onChange("time", "ALL")}
          />
          {times.map((time: string, index: number) => {
            return (
              <div key={index}>
                <AMIRadio
                  className="quote-filter__radio"
                  text={time}
                  checked={customer.quoteFilters.time === time}
                  onChange={() => onChange("time", time)}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default QuoteFilter;