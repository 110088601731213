import React, { useState } from 'react';
import CustomerPage from './customer-page/CustomerPage';
import { appDataSelector } from '../../../../features/appDataSlice';
import { WhiteLabel, customerDetailsSelector } from '../../../../features/customerSlice';
import { useAppSelector } from '../../../../app/hooks';

const fakeData = [
  {
    companyName: 'Company A',
    accountNumber: '12345678',
    address: {
      companyName: 'Company A',
      contactName: 'Greg',
      addressLine1: 'A street',
      addressLine2: 'Another place',
      addressLine3: 'Address line 3',
      cityTown: 'New York',
      countyStateProvince: 'NY',
      countryCode: 'US',
      postalCode: '10101',
      telephone: '1234567890',
      email: 'test@test.com'
    }
  },
  {
    companyName: 'Company B',
    accountNumber: '87654321',
    address: {
      companyName: 'Company B',
      contactName: 'Brenda',
      addressLine1: 'B street',
      addressLine2: 'Another place',
      addressLine3: 'Address line 3',
      cityTown: 'New York',
      countyStateProvince: 'NY',
      countryCode: 'US',
      postalCode: '10101',
      telephone: '1234567890',
      email: 'test@test.com'
    }
  }
]

const fakeCustomer = {
  customerDetails: {
    creditCheck: {
      whiteLabel: {
        isParent: true,
        children: [{
            partyId: "USLRINT002",
            companyName: "child company 1"
          },
          {
            partyId: "USLRINT003",
            companyName: "child company 2"
          }
      ]
      }
    }
  }
}

const WhiteLabelCustomersPage = () => {

  const customer = useAppSelector(customerDetailsSelector);
  const whiteLabel = customer.creditCheck.whiteLabel as WhiteLabel;

  const [activeCustomer, setActiveCustomer] = useState<any>();

  return (
    <div className="customers-page">
      <h2>Customers</h2>

      {whiteLabel.children.map((el: any) => {
        return (
          <div onClick={() => setActiveCustomer(el)} key={el.partyId}>
            <CustomerPage
              basicCustomer={el}
              active={el.partyId === activeCustomer?.partyId}
            />
          </div>
        )
      })}
    </div>
  )
}

export default WhiteLabelCustomersPage;