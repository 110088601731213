import React, { useEffect, useState } from 'react';
import AMIModal from '../../../../ui-components/modal/Modal';
import AMIDescription from '../../../../ui-components/description/Description';
import AMIDescriptionItem from '../../../../ui-components/description-item/DescriptionItem';
import {
  manifestActions,
  manifestSelector,
  SenderContactDetails
} from "../../../../features/manifestSlice";
import {
  useAppDispatch,
  useAppSelector
} from "../../../../app/hooks";
import AMIButton from '../../../../ui-components/button/Button';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { getCountryInfo } from '../../../../utilities/APIUtilities';
import { isLessThan10Characters, isValidPostalCode } from '../../../../utilities/ValidationUtilities';
import { AMICheckbox, AMISpinner } from '../../../../ui-components/UiComponents';
import { appDataSelector } from '../../../../features/appDataSlice';

const SenderDetailsModal: React.FC<{
  close: () => void,
}> = ({
  close
}) => {

  const dispatch = useAppDispatch();
  const customer = useAppSelector(customerDetailsSelector);
  const sender = useAppSelector(manifestSelector).senderContactDetails;
  const apiConfig = useAppSelector(appDataSelector).apiConfig;
  const [previousSenderDetails, setPreviousSenderDetails] = useState<SenderContactDetails>();
  const [showErrors, setShowErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [countryFetchFailCount, setCountryFetchFailCount] = useState(0);
  const [postalCodeRegex, setPostalCodeRegex] = useState('');

  const {width} = useWindowDimensions();

  useEffect(() => {
    handleModalLoad();
  }, [])

  const handleModalLoad = async() => {
    setIsLoading(true);
    const countryInfo = await getCountryInfo(apiConfig, customer.countryOfResidence.value);
    let regex = '';

    if (!countryInfo?.postcodeFormat && countryFetchFailCount < 2) {
      setCountryFetchFailCount(countryFetchFailCount + 1);
      handleModalLoad();
      return;
    }

    if (countryInfo && countryInfo.postcodeFormat !== 'Country not postcode-format enabled.') regex = countryInfo.postcodeFormat;

    setPostalCodeRegex(regex);
    setPreviousSenderDetails({...sender});
    setIsLoading(false);
  }


  const senderModalErrorHandler = {
    name: {
      criteria: sender.name.length > 2
        && sender.name.length < 36,
        message: 'Field must be between 3 and 35 characters'
    },
    organisation: {
      criteria: sender.organisation.length > 2
        && sender.organisation.length < 36,
      message: 'Field must be between 3 and 35 characters'
    },
    addressLine1: {
      criteria: sender.addressLine1.length > 2
        && sender.addressLine1.length < 36,
      message: 'Field must be between 3 and 35 characters'
    },
    cityTown: {
      criteria: sender.cityTown.length > 2
        && sender.cityTown.length < 36,
      message: 'Field must be between 3 and 35 characters'
    },
    countryISO: {
      criteria: sender.countryISO.length === 2,
      message: 'Field must be 2 characters long'
    },
    postalCode: {
      criteria: postalCodeRegex
        ? sender.postalCode
          && isValidPostalCode(sender.postalCode, postalCodeRegex)
        : isLessThan10Characters(sender.postalCode),
      message: postalCodeRegex
        ? 'Required. Must be a valid Postal/Zip Code and max 10 characters'
        : 'Must be a valid Postal/Zip Code and max 10 characters'
    },
    telephone: {
      criteria: sender.telephone.length > 5
        && sender.telephone.length < 16,
      message: 'Field must be between 6 and 15 digits'
    },
  }

  const onCancel = () => {
    dispatch(manifestActions.setSenderContactDetails({...previousSenderDetails}));
    setShowErrors(false);
    close();
  }

  const trimTelephone = () => {
    const trimmedTelNumber = sender.telephone.replace(/[^0-9]/g, '');
    dispatch(manifestActions.setSenderContactDetails({
      ...sender,
      telephone: trimmedTelNumber
    }))
  }

  const onAccept = () => {
    trimTelephone();
    setShowErrors(true);
    for (const error in senderModalErrorHandler) {
      // @ts-ignore
      if (!senderModalErrorHandler[error].criteria) {
        return;
      }
    }
    close();
  }

  const senderDetailsModalButtons = [
    <AMIButton
      key={Math.random()}
      variant='default'
      size="large"
      onClick={onCancel}
    >Cancel
    </AMIButton>,
    <AMIButton
      key={Math.random()}
      variant='primary'
      size="large"
      onClick={onAccept}
    >Accept
    </AMIButton>
  ]

  return (
    <AMIModal
      title="Sender Details"
      width="80%"
      buttons={senderDetailsModalButtons}
      close={onCancel}
    >
      {isLoading && <AMISpinner style={{display: "block", margin: '24px auto'}}/>}

      {!isLoading &&
      <>
        <AMIDescription columns={width < 900 ? 1 : 2}>
          <AMIDescriptionItem
            label="Name"
            placeholder="Required"
            error={
              showErrors
              && !senderModalErrorHandler.name.criteria
              ? senderModalErrorHandler.name.message
              : ''
            }
            onChange={(event) => dispatch(manifestActions.setSenderContactDetails({
              ...sender,
              name: event.target.value
            }))}
            editable={true}
            inputName="name"
          >
            {sender.name}
          </AMIDescriptionItem>

          <AMIDescriptionItem
            label="Organisation"
            placeholder="Required"
            error={
              showErrors
              && !senderModalErrorHandler.organisation.criteria
              ? senderModalErrorHandler.organisation.message
              : ''
            }
            onChange={(event) => dispatch(manifestActions.setSenderContactDetails({
              ...sender,
              organisation: event.target.value
            }))}
            editable={true}
            inputName="organisation"
          >
            {sender.organisation}
          </AMIDescriptionItem>

          <AMIDescriptionItem
            label="Address Line 1"
            placeholder="Required"
            error={
              showErrors
              && !senderModalErrorHandler.addressLine1.criteria
              ? senderModalErrorHandler.addressLine1.message
              : ''
            }
            onChange={(event) => dispatch(manifestActions.setSenderContactDetails({
              ...sender,
              addressLine1: event.target.value
            }))}
            editable={true}
            inputName="addressLine1"
          >
            {sender.addressLine1}
          </AMIDescriptionItem>

          <AMIDescriptionItem
            label="Address Line 2"
            onChange={(event) => dispatch(manifestActions.setSenderContactDetails({
              ...sender,
              addressLine2: event.target.value
            }))}
            editable={true}
            inputName="addressLine2"
          >
            {sender.addressLine2}
          </AMIDescriptionItem>

          <AMIDescriptionItem
            label="City/Town"
            placeholder="Required"
            error={
              showErrors
              && !senderModalErrorHandler.cityTown.criteria
              ? senderModalErrorHandler.cityTown.message
              : ''
            }
            onChange={(event) => dispatch(manifestActions.setSenderContactDetails({
              ...sender,
              cityTown: event.target.value
            }))}
            editable={true}
            inputName="cityTown"
          >
            {sender.cityTown}
          </AMIDescriptionItem>

          <AMIDescriptionItem
            label="County/State/Province"
            onChange={(event) => dispatch(manifestActions.setSenderContactDetails({
              ...sender,
              countyStateProvince: event.target.value
            }))}
            editable={true}
            inputName="countyStateProvince"
          >
            {sender.countyStateProvince}
          </AMIDescriptionItem>

          <AMIDescriptionItem
            label="Country (ISO 2)"
            placeholder="Required"
            error={
              showErrors
              && !senderModalErrorHandler.countryISO.criteria
              ? senderModalErrorHandler.countryISO.message
              : ''
            }
            onChange={(event) => dispatch(manifestActions.setSenderContactDetails({
              ...sender,
              countryISO: event.target.value
            }))}
            editable={true}
            inputName="countryISO"
          >
            {sender.countryISO}
          </AMIDescriptionItem>

          <AMIDescriptionItem
            label="Postal Code"
            placeholder={postalCodeRegex ? "Required" : ""}
            error={
              showErrors
              && !senderModalErrorHandler.postalCode.criteria
              ? senderModalErrorHandler.postalCode.message
              : ''
            }
            onChange={(event) => dispatch(manifestActions.setSenderContactDetails({
              ...sender,
              postalCode: event.target.value
            }))}
            editable={true}
            inputName="postalCode"
          >
            {sender.postalCode}
          </AMIDescriptionItem>

          <AMIDescriptionItem
            label="Telephone Number"
            placeholder="Required"
            error={
              showErrors
              && !senderModalErrorHandler.telephone.criteria
              ? senderModalErrorHandler.telephone.message
              : ''
            }
            onChange={(event) => dispatch(manifestActions.setSenderContactDetails({
              ...sender,
              telephone: event.target.value
            }))}
            editable={true}
            inputName="telephone"
          >
            {sender.telephone}
          </AMIDescriptionItem>
        </AMIDescription>

        <AMICheckbox
          text="Residential address?"
          style={{marginTop: "12px"}}
          onChange={() => dispatch(manifestActions.setSenderContactDetails({
            ...sender,
            residential: !sender.residential
          }))}
          checked={sender.residential}
        />
        </>
      }

    </AMIModal>
  )
}

export default SenderDetailsModal;