import React, { useEffect, useState } from 'react';
import './AccountAddresses.scss';
import { AMIAlert, AMIFormElement, AMIInput, AMISelect, AMISpinner } from '../../../ui-components/UiComponents';
import { customerDetailsSelector } from '../../../features/customerSlice';
import { useAppSelector } from '../../../app/hooks';
import AddressTableLine from './address-line/AddressTableLine';
import AddEditAddressModal from './add-edit-address-modal/AddEditAddressModal';
import { getAddressesByCountry } from '../../../utilities/APIUtilities';
import { appDataSelector } from '../../../features/appDataSlice';
import { Country } from '../../../features/bookingSlice';

const AccountAddresses = () => {

  const customer = useAppSelector(customerDetailsSelector);
  const appData = useAppSelector(appDataSelector);
  const apiConfig = appData.apiConfig;

  const [addresses, setAddresses] = useState<any>([]);
  const [filteredAddresses, setFilteredAddresses] = useState<any>([]);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [country, setCountry] = useState<Country>();

  useEffect(() => {
    setFilteredAddresses(addresses);
  }, [addresses])

  const getAndSetAddresses = async(countryCode: string) => {
    const addresses = await getAddressesByCountry(customer.creditCheck.branchId, apiConfig, countryCode);
    const mappedAddresses = addresses.map((address: any) => address.request);
    setAddresses(mappedAddresses);
    setIsFetching(false);
  }

  const onFilter = (searchString: string) => {
    if (addresses) {
      const query = searchString.toLowerCase()
      const filteredResult = addresses.filter((savedAddress: any) => {
        const {address, contactDetails} = savedAddress.userDetails;
        return (
          address.organisation.toLowerCase().includes(query)
          || contactDetails.name.toLowerCase().includes(query)
          || address.streetLines[0].toLowerCase().includes(query)
          || address.streetLines[1]?.toLowerCase().includes(query)
          || address.streetLines[2]?.toLowerCase().includes(query)
          || address.city.toLowerCase().includes(query)
          || address.postalCode.toLowerCase().includes(query)
          || address.countryCode.toLowerCase().includes(query)
        )
      })
      setFilteredAddresses(filteredResult);
    }
  }

  const onCountryChange = (event: any) => {
    setIsFetching(true);
    setCountry(event);
    getAndSetAddresses(event.value);
  }

  return (
    <div className="account-addresses">
      <h2>Addresses</h2>

      <AMIFormElement label="Please select a country to begin">
        <AMISelect
          name="field-1"
          size="large"
          isSearchable
          options={appData.countries}
          isSpinning={isFetching}
          style={{width: "222px"}}
          onChange={(event) => onCountryChange(event)}
        />
      </AMIFormElement>

      <hr />

      {!customer.creditCheck.branchId && (
        <AMIAlert variant="warning">
          <p>Please contact <a href="/contact" target="_blank" rel="noreferrer">customer services</a> to enable address book features.</p>
        </AMIAlert>
      )}

      {customer.creditCheck.branchId && isFetching && <AMISpinner />}

      {customer.creditCheck.branchId && !isFetching && (
        <>
          <div className="account-addresses__top-row">
            {filteredAddresses.length > 0 && (
              <AMIInput
                name="account-address-filter"
                placeholder="Filter..."
                onChange={(event) => onFilter(event.target.value)}
              />
            )}
            <div
              className="account-addresses__top-row__add-new-address-btn"
              onClick={() => setShowAddressModal(true)}
            >
              + Add Address
            </div>
          </div>

          <div className="account-addresses__table">
            {filteredAddresses && filteredAddresses.map((address: any, index: number) => {
              return (
                <div className="address-wrapper" key={index}>
                  <AddressTableLine
                    savedAddress={address}
                    index={index}
                  />
                </div>
              )
            })}
          </div>

          {showAddressModal && <AddEditAddressModal close={() => setShowAddressModal(false)}/>}

        </>
      )}

      {!isFetching && filteredAddresses.length < 1 && country && (
        <AMIAlert variant="warning">
          Sorry, we could not find any addresses saved for <strong>{country.title}</strong>. If you believe there should be saved addresses then please contact us directly to investigate.
        </AMIAlert>
      )}
    </div>
  )
}

export default AccountAddresses;