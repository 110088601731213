import React, { useEffect, useRef, useState } from 'react';
import Badge from '../../../ui-components/badge/Badge';
import './ApiTableRow.scss';
import CopyRoute from '../copy-route/CopyRoute';

const ApiTableRow: React.FC<{
  rowInfo: any
  isSubRow?: boolean
  searchValue: string
  currentApiElement: any
  style?: any
  expandParentEl?: () => void
}> = ({
  rowInfo,
  isSubRow,
  searchValue,
  currentApiElement,
  style,
  expandParentEl
}) => {

  const expandChevron = <svg className="api-grid__table__chevron" viewBox="0 0 24 24" x="0" y="0"><polygon points="17.3 8.3 12 13.6 6.7 8.3 5.3 9.7 12 16.4 18.7 9.7 "></polygon></svg>;
  const [isExpanded, setIsExpanded] = useState(false);
  const [rowClassName, setRowClassName] = useState('api-table-row');
  const [titleFound, setTitleFound] = useState(false);
  const [descriptionFound, setDescriptionFound] = useState(false);
  const [hover, setHover] = useState(false);
  const isExpandable = rowInfo.subRows?.length > 0;

  const ref = useRef(null);
  const descriptionRef = useRef<any>(null);
  const titleRef = useRef<any>(null);

  const onClick = (event: any) => {
    event?.stopPropagation();
    if (isExpandable) setIsExpanded(!isExpanded);
  }

  const getClassName = () => {
    if (isSubRow) setRowClassName(isExpanded ? 'api-table-row api-table-row--sub-row api-table-row--sub-row--expanded' : 'api-table-row api-table-row--sub-row');
    else setRowClassName(isExpanded ? 'api-table-row api-table-row--expanded' : 'api-table-row');
  }

  useEffect(() => {
    getClassName();
  }, [isExpanded])

  useEffect(() => {
    let titleFound = false;
    let descriptionFound = false;
    if (searchValue.length > 2) {
      if (rowInfo.title.toLowerCase().includes(searchValue)) titleFound = true;

      for (const description of rowInfo.descriptions) {
        if (description.toLowerCase().includes(searchValue)) descriptionFound = true;
      }

      setTitleFound(titleFound);
      setDescriptionFound(descriptionFound);

      if ((titleFound || descriptionFound) && expandParentEl) {
        expandParentEl();
      }
    } else {
      setTitleFound(false);
      setDescriptionFound(false);
    }
  }, [searchValue])

  const expandElement = () => {
    setIsExpanded(true);
    if (expandParentEl) expandParentEl();
  }

  return (
    <div
      className={rowClassName}
      ref={ref}
      style={style}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(event: any) => onClick(event)}
    >
      <div
        style={titleFound && titleRef.current?.innerHTML.split(" ")[0] === currentApiElement?.innerHTML.split(" ")[0] ? {fontWeight: "bold"} : {fontWeight: "revert"}}
        className={titleFound ? "api-table-row__found" : ""}
        ref={titleRef}
      >
        {rowInfo.title} {isExpandable && expandChevron}
        {rowInfo.required === "YES" && <><br/><span>required</span></>}
        {rowInfo.required === "CONDITIONAL" && <><br/><span>conditionally required</span></>}
      </div>
      <div>
        {rowInfo.criteria?.map((el: any, index: number) => {
          return (
            <div key={index}>
              <Badge>{el.title}: <strong>{el.content}</strong></Badge>
            </div>
          )
        })}

        {rowInfo.descriptions && rowInfo.descriptions.map((el: any, index: number) => {
          return <div
            key={index}
            style={descriptionFound && descriptionRef.current === currentApiElement ? {fontWeight: "bold"} : {fontWeight: "revert"}}
            className={descriptionFound ? "api-table-row__found" : ""}
            ref={descriptionRef}
          >{el}</div>
        })}

        {rowInfo.link && (
          <a href={rowInfo.link.url} target="_blank" rel="noreferrer">See list of {rowInfo.link.title}</a>
        )}
      </div>
      {rowInfo.copyRoute && hover && <CopyRoute route={rowInfo.copyRoute} />}
      {isExpandable && rowInfo.subRows.map((row: any, index: number) => {
        return (
          <ApiTableRow
            key={index}
            style={isExpanded ? {display: "grid"} : {display: "none"}}
            rowInfo={row}
            isSubRow
            searchValue={searchValue}
            expandParentEl={expandElement}
            currentApiElement={currentApiElement}
          >
          </ApiTableRow>
        )
      })}
    </div>
  )
}

export default ApiTableRow;