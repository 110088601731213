export const headerCode = `headers: {
  "party-id": "GBXRIBT001",
  "client-id": "6789aa",
  "x-api-key": "EdX2C9Yeku3dVLSaYe2RI2iBnFXoSEgB11gsOwSX",
  "authorization": \`Bearer \${accessToken}\`,
  "Content-Type": "application/json"
}`

export const bodyCode = `body: {
  quoteId: "fedex-8b4cdc8f-8392-421d-abd8-576754cebd8",
  bookingConfirmationEmail: "example@example.com",
  customerCloseTime: "17:00",
  shipmentDateTime: "2023-09-18'T'12:00:00X",
  unitOfMeasurement: "METRIC",
  unitOfCurrency: "£",
  senderDetails: {
    address: {
      isCollectionAddress: true,
      organisation: "Example Org",
      streetLines: [
        "1234",
        "Bakersfield"
      ],
      stateOrCounty: "AR",
      countryCode: "US",
      postalCode: "90310",
      city: "New York",
      residential: true
    },
    contactDetails: {
      name: "John",
      telephone: "1234567891"
    },
    notificationDetails: {
      email: "example@example.com",
      mobile: "1234567891"
    }
  },
  deliveryDetails: {
    address: {
      organisation: "Example Org",
      streetLines: [
        "1234",
        "Bakersfield"
      ],
      stateOrCounty: "AR",
      countryCode: "US",
      postalCode: "90310",
      city: "New York",
      residential: true
    },
    contactDetails: {
      name: "John",
      telephone: "1234567891"
    },
    notificationDetails: {
      email: "example@example.com",
      mobile: "1234567891"
    }
  },
  collectionDetails: {
    address: {
      organisation: "Example Org",
      streetLines: [
        "1234",
        "Bakersfield"
      ],
      stateOrCounty: "AR",
      countryCode: "US",
      postalCode: "90310",
      city: "New York",
      residential: true
    },
    contactDetails: {
      name: "John",
      telephone: "1234567891"
    },
    notificationDetails: {
      email: "example@example.com",
      mobile: "1234567891"
    }
  },
  customsDetails: {
    exportComplianceStatement: "AESX20230918555555",
    statementType: "DEPARTMENT_OF_STATE",
    amount: {
      value: 5014.78,
      currency: "USD"
    },
    invoiceDetails: {
      declarationStatement: "example statement",
      termsOfSale: "DAP",
      taxDetails: [
        {
          type: "VAT",
          party: "SENDER",
          value: "123456"
        }
      ],
      exportReason: "GIFT",
      specialInstructions: "Custom text field"
    },
    importerOfRecordDetails: {
      deliveryAddress: true,
      contactDetails: {
        name: "example name",
        telephone: "1234567891"
      },
      address: {
        organisation: "example organisation",
        countryCode: "US",
        postcode: "12345",
        streetLines: [
          "Street Line 1",
          "Street Line 2"
        ],
        town: "New York",
        county: "New York"
      }
    }
    electronicTradeDetails: {
      requestedDocuments: {
        documentContent: "asdfasdfasdfasdf",
        documentDate: "2023-09-19",
        invoiceReference: "example reference",
        contentType: "png",
        type: "COMMERCIAL_INVOICE"
      }
    },
    commodities: [
      {
        commodityCode: "555.333",
        description: "Clothes",
        countryOfOrigin: "US",
        quantity: {
          value: 5,
          unit: "PCS"
        },
        unitPrice: {
          value: 479.78,
          currency: "USD"
        },
        weight: {
          value: 5.50,
          unit: "LB"
        }
      }
    ],
    parcels: [
      {
        dimensions: {
          length: 5,
          width: 5.5,
          height: 7.1,
          unit: "IN"
        },
        weight: {
          value: 5.6,
          unit: "LB"
        }
      }
    ],
    customerShippingReference: "exampleReference",
    contentDescription: "Clothes",
    deliveryInstructions: "Fragile",
    specialService: [
      "INSURANCE",
      "DELIVERED_DUTY_PAID"
    ],
    shipmentType: "NON_DOCS",
    label: {
      type: "PDF",
      size: "A4"
    }
  },
  brokerDetails: {
    address: {
      organisation: "Example Org",
      streetLines: [
        "1234",
        "Bakersfield"
      ],
      stateOrCounty: "AR",
      countryCode: "US",
      postalCode: "90310",
      city: "New York"
    },
    contactDetails: {
      name: "John",
      telephone: "1234567891"
    },
    notificationDetails: {
      email: "example@example.com",
      mobile: "1234567891"
    }
  }
}`

export const exampleSuccessResponse = `[
  {
    "amiReference": "AMIGB1234567890",
    "shipmentId": "123456789",
    "alerts": [],
    "consignmentDetailsList": [
      {
        "consignmentNumber": null,
        "parcelNumbers": [
          "JD014600004922589631"
        ]
      }
    ],
    "consolidated": null,
    "pickupConfirmationCode": {if collected shipment},
    "pickupMessage": {if collected shipment},
    "regionId": "GBX"
  }
]`

export const exampleErrorResponses = {
  1000: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1000",
        "message": "must not be null",
        "fieldName": "quoteId",
        "value": null
      }
    ]
  }`,
  1010: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "must not be null for country code US",
        "code": "1010",
        "fieldName": "deliveryDetails.address.stateOrCounty",
        "value": null
      }
    ]
  }`,
  1100: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "must be 'true' or 'false'",
        "code": "1100",
        "fieldName": "deliveryDetails.address.residential",
        "value": ""
      }
    ]
  }`,
  1200: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "must be a number",
        "code": "1200",
        "fieldName": "parcels[0].dimensions.height",
        "value": "one"
      }
    ]
  }`,
  1210: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "must be up to 2 decimal places only",
        "code": "1210",
        "fieldName": "parcels[0].dimensions.height",
        "value": "10.123"
      }
    ]
  }`,
  1220: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "must be between 1 and 3",
        "code": "1220",
        "fieldName": "parcels[0].dimensions.height",
        "value": "5"
      }
    ]
  }`,
  1230: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "must be equal to or greater than 1",
        "code": "1230",
        "fieldName": "parcels[0].dimensions.height",
        "value": "0"
      }
    ]
  }`,
  1240: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "must be equal to or less than 4",
        "code": "1240",
        "fieldName": "parcels[0].dimensions.height",
        "value": "10"
      }
    ]
  }`,
  1300: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1300",
        "message": "must be one of",
        "values": ["NON_DOCS", "DOCS"]
        "fieldName": "shipmentType",
        "value": "<invalid value>"
      }
    ]
  }`,
  1310: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "must be one of for country code MX",
        "code": "1310",
        "fieldName": "deliveryDetails.address.stateOrCounty",
        "value": "L",
        "values": [
            "AG",
            "BC",
            "BS",
            "CM",
            "CS",
            "CH",
            "DF",
            "CO",
            "CL",
            "DG",
            "EM",
            "GT",
            "GR",
            "HG",
            "JA",
            "MI",
            "MO",
            "NA",
            "NL",
            "OA",
            "PU",
            "QE",
            "QR",
            "SL",
            "SI",
            "SO",
            "TB",
            "TM",
            "TL",
            "VE",
            "YU",
            "ZA"
        ]
      }
    ]
  }`,
  1320: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "must be one of when label.type is PDF",
        "code": "1320",
        "fieldName": "label.size",
        "value": "STOCK_4X6",
        "values": [
            "A4",
            "US_LETTER"
        ]
      }
    ]
  }`,
  1400: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "length must be between 3 and 35",
        "code": "1400",
        "fieldName": "deliveryDetails.address.organisation",
        "value": "",
        "min": 3,
        "max": 35
      }
    ]
  }`,
  1500: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "must be in the format",
        "code": "1500",
        "fieldName": "deliveryDetails.notificationDetails.email",
        "value": "m@c+.com",
        "format": "^[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
      }
    ]
  }`,
  1510: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "must be in the format for country code US",
        "code": "1510",
        "fieldName": "deliveryDetails.address.postalCode",
        "value": "INVALID",
        "format": "^\\d{5}([ \\-]\\d{4})?$"
      }
    ]
  }`,
  1600: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "must not be empty",
        "code": "1600",
        "fieldName": "deliveryDetails.address.streetLines",
        "value": []
      }
    ]
  }`,
  1610: `{
    "message": "Bad Request",
    "errors": [
      {
        "message": "size must be between 1 and 3",
        "code": "1610",
        "fieldName": "deliveryDetails.address.streetLines",
        "value": [
          "Delivery Street Line 1",
          "Delivery Street Line 2",
          "Delivery Street Line 3",
          "Delivery Street Line 4"
        ],
        "min": 1,
        "max": 3
      }
    ]
  }`
}