import React from 'react';
import {  AMICheckbox, AMIFormElement, AMIInput, AMISelect } from '../../../../../ui-components/UiComponents';
import { useAppSelector } from '../../../../../app/hooks';
import { appDataSelector } from '../../../../../features/appDataSlice';
import { TemplateForm } from '../../../../../utilities/TemplateHandler';

const TemplateModalCountries: React.FC<{
  template: TemplateForm
  updateCountries: (field: string, value: any) => void
}> = ({
  template,
  updateCountries
}) => {

  const appData = useAppSelector(appDataSelector);
  const countries = appData.countries;
  const data = template.countries

  return (
    <div className="template-modal-general">
      <div className="template-modal__form__to-from">
        <AMIFormElement label="Send From Origin">
          <AMISelect
            name="field-1"
            size="large"
            isSearchable
            style={{
              width: '100%',
            }}
            defaultValue={data.origin}
            options={countries}
            isSpinningMessage={
              countries.length < 1
                ? 'Fetching countries'
                : 'Processing'
            }
            onChange={(event) => updateCountries("origin", event)}
          />
        </AMIFormElement>

        <AMIFormElement label="City/Town">
          <AMIInput
            name="send-from-origin-city-town"
            size="large"
            style={{
              width: '100%',
            }}
            value={data.originCityTown}
            onChange={(event) => updateCountries("originCityTown", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement label="Postal/Zip Code">
          <AMIInput
            name="send-from-origin-postal-code"
            size="large"
            style={{
              width: '100%',
            }}
            value={data.originPostalCode}
            onChange={(event) => updateCountries("originPostalCode", event.target.value)}
          />
        </AMIFormElement>

        <AMICheckbox
          className="template-modal__form__to-from__checkbox"
          checked={data.originResidential}
          text="Residential address?"
          onChange={() => updateCountries("originResidential", !data.originResidential)}
        />

        <AMIFormElement label="Send To Destination">
          <AMISelect
            name="field-2"
            size="large"
            isSearchable
            style={{
              width: '100%',
            }}
            defaultValue={data.destination}
            options={countries}
            isSpinningMessage={
              countries.length < 1
                ? 'Fetching countries'
                : 'Processing'
            }
            onChange={(event) => updateCountries("destination", event)}
          />
        </AMIFormElement>

        <AMIFormElement label="City/Town">
          <AMIInput
            name="send-from-destination-city-town"
            size="large"
            style={{
              width: '100%',
            }}
            value={data.destinationCityTown}
            onChange={(event) => updateCountries("destinationCityTown", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement label="Postal/Zip Code">
          <AMIInput
            name="send-from-destination-postal-code"
            size="large"
            style={{
              width: '100%',
            }}
            value={data.destinationPostalCode}
            onChange={(event) => updateCountries("destinationPostalCode", event.target.value)}
          />
        </AMIFormElement>

        <AMICheckbox
          className="template-modal__form__to-from__checkbox"
          checked={data.destinationResidential}
          text="Residential address?"
          onChange={() => updateCountries("destinationResidential", !data.destinationResidential)}
        />

      </div>
    </div>
  )
}

export default TemplateModalCountries;