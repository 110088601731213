import React, { useEffect, useState } from 'react';
import './WlContentPageBanners.scss';
import { Banner, retrieveBanners } from '../../../../../utilities/ContentRetrieverUtilities';
import { useAppSelector } from '../../../../../app/hooks';
import { customerDetailsSelector } from '../../../../../features/customerSlice';
import dayjs from 'dayjs';
import { AMISpinner, AMITable, Icon } from '../../../../../ui-components/UiComponents';
import { setWlBanners } from '../../../../../utilities/APIUtilities';
import { appDataSelector } from '../../../../../features/appDataSlice';
import WlBannerEdit from './wl-banner-edit/WlBannerEdit';
import { v4 as uuid } from 'uuid';

const WlContentPageBanners = () => {

  const customer = useAppSelector(customerDetailsSelector);
  const appData = useAppSelector(appDataSelector);

  const [banners, setBanners] = useState<any[]>([]);
  const [activeBanners, setActiveBanners] = useState<any[]>([]);
  const [pendingBanners, setPendingBanners] = useState<any[]>([]);
  const [expiredBanners, setExpiredBanners] = useState<any[]>([]);
  const [isSpinning, setIsSpinning] = useState(true);
  const [bannerToUpdate, setBannerToUpdate] = useState<Banner | null>(null);

  useEffect(() => {
    getAndSetBanners();
  }, [])

  const getAndSetBanners = async() => {
    let allBanners: any[] = []
    const homePageBanners = await retrieveBanners('announcement-banner-home-page', customer, true);
    const quotePageBanners = await retrieveBanners('announcement-banner-quote-page', customer, true);
    if (homePageBanners?.length > 0) allBanners.push(
      ...homePageBanners.map((banner: Banner) => {
        return {
          ...banner,
          page: 'home'
        }
      })
    );
    if (quotePageBanners?.length > 0) allBanners.push(
      ...quotePageBanners.map((banner: Banner) => {
        return {
          ...banner,
          page: 'quote'
        }
      })
    );
    setBanners(allBanners);


    const activeBanners = [];
    const pendingBanners = [];
    const expiredBanners = [];

    for (const banner of allBanners) {
      const fromIsValid = dayjs(banner.config.displayFrom).isBefore(dayjs(), 'day') || dayjs(banner.config.displayFrom).isSame(dayjs(), 'day');
      const toIsValid = dayjs(banner.config.displayUntil).isAfter(dayjs(), 'day') || dayjs(banner.config.displayFrom).isSame(dayjs(), 'day');

      if (fromIsValid && toIsValid) activeBanners.push(banner);
      if (!fromIsValid) pendingBanners.push(banner);
      if (!toIsValid) expiredBanners.push(banner);
    }

    setActiveBanners(activeBanners);
    setPendingBanners(pendingBanners);
    setExpiredBanners(expiredBanners);

    setIsSpinning(false);
  }

  const onTrashClick = async(banner: Banner) => {
    setIsSpinning(true);
    const updatedBanners = banners.filter(el => el.page === banner.page && el !== banner);
    if (banner.page) await setWlBanners(appData.apiConfig, customer.customerCountryCode, banner.page, customer.creditCheck.tmffPartyId, updatedBanners);
    getAndSetBanners();
  }

  const onEditClick = (banner: Banner) => {
    setBannerToUpdate(banner);
  }

  const save = async(banner: Banner) => {
    setIsSpinning(true);
    const bannersCopy = [...banners];
    let index = bannersCopy.findIndex((el: Banner) => {return el.id === bannerToUpdate?.id});

    if (index < 0) bannersCopy.push(banner);
    else bannersCopy.splice(index, 1, banner);

    const bannersByPage = bannersCopy.filter(el => el.page === banner.page);
    const response = await setWlBanners(appData.apiConfig, customer.customerCountryCode, banner.page!, customer.creditCheck.tmffPartyId, bannersByPage);
    if (response !== "Content successfully updated") return false;
    else {
      getAndSetBanners();
      return true;
    }
  }

  const onCreateNewClick = () => {
    setBannerToUpdate({
      id: uuid(),
      title: '',
      content: '',
      config: {
        announcementType: 'info',
        displayFrom: '',
        displayUntil: ''
      },
      page: 'home'
    })
  }

  if (bannerToUpdate) return (
    <WlBannerEdit
      banner={bannerToUpdate}
      save={async(event) => save(event)}
      close={() => setBannerToUpdate(null)}
    />
  )


  return (
    <div className="wl-content-page-banners">

      {isSpinning && (
        <div className="wl-content-page-banners__spinner">
          <div className="full-screen-mask"></div>
          <AMISpinner />
            <p>Updating...</p>
        </div>
      )}

      <p
        className="wl-content-page-banners__add"
        onClick={onCreateNewClick}
      >
        + Create New Banner
      </p>

      {activeBanners.length > 0 && (
        <div className="wl-content-page-banners__banner-section">
          <h2>Active Banners</h2>
          <AMITable>
            <thead>
              <tr>
                <th>Title</th>
                <th>Type</th>
                <th>Page</th>
                <th>Date From</th>
                <th>Date To</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {activeBanners.map((banner: Banner, index: number) => {
                return (
                  <tr key={banner.title + index}>
                    <td>{banner.title}</td>
                    <td>{banner.config.announcementType}</td>
                    <td>{banner.page}</td>
                    <td>{banner.config.displayFrom}</td>
                    <td>{banner.config.displayUntil}</td>
                    <td>
                      <div>
                        <Icon
                          name="Edit"
                          style={{marginRight: "8px", cursor: "pointer"}}
                          onClick={() => onEditClick(banner)}
                        />
                        <Icon
                          name="Trash"
                          style={{cursor: "pointer"}}
                          onClick={() => onTrashClick(banner)}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </AMITable>
        </div>
      )}

      {pendingBanners.length > 0 && (
        <div className="wl-content-page-banners__banner-section">
          <h2>Pending Banners</h2>
          <AMITable>
            <thead>
              <tr>
                <th>Title</th>
                <th>Type</th>
                <th>Page</th>
                <th>Date From</th>
                <th>Date To</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {pendingBanners.map((banner: Banner, index: number) => {
                return (
                  <tr key={banner.title + index}>
                    <td>{banner.title}</td>
                    <td>{banner.config.announcementType}</td>
                    <td>{banner.page}</td>
                    <td>{banner.config.displayFrom}</td>
                    <td>{banner.config.displayUntil}</td>
                    <td>
                      <div>
                        <Icon
                          name="Edit"
                          style={{marginRight: "8px", cursor: "pointer"}}
                          onClick={() => onEditClick(banner)}
                        />
                        <Icon
                          name="Trash"
                          style={{cursor: "pointer"}}
                          onClick={() => onTrashClick(banner)}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </AMITable>
        </div>
      )}

      {expiredBanners.length > 0 && (
        <div className="wl-content-page-banners__banner-section">
          <h2>Expired Banners</h2>
          <AMITable>
            <thead>
              <tr>
                <th>Title</th>
                <th>Type</th>
                <th>Page</th>
                <th>Date From</th>
                <th>Date To</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {expiredBanners.map((banner: Banner, index: number) => {
                return (
                  <tr key={banner.title + index}>
                    <td>{banner.title}</td>
                    <td>{banner.config.announcementType}</td>
                    <td>{banner.page}</td>
                    <td>{banner.config.displayFrom}</td>
                    <td>{banner.config.displayUntil}</td>
                    <td>
                      <div>
                        <Icon
                          name="Edit"
                          style={{marginRight: "8px", cursor: "pointer"}}
                          onClick={() => onEditClick(banner)}
                        />
                        <Icon
                          name="Trash"
                          style={{cursor: "pointer"}}
                          onClick={() => onTrashClick(banner)}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </AMITable>
        </div>
      )}

    </div>
  )
}

export default WlContentPageBanners;