import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { Icon } from '../../../../ui-components/UiComponents';
import PriceTable from '../../../../components/price-table/PriceTable';
import { Booking } from '../../../../features/bookingSlice';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { calculateBookingTotalCost, getCarrierImage, roundUpToNearestPointFive, snakeCaseConvertor } from '../../../../utilities/HelperUtilities';
import './SummarySlider.scss';

const SummarySlider: React.FC<{
  booking: Booking;
}> = ({
  booking
}) => {

  const customer = useAppSelector(customerDetailsSelector);
  const currencySymbol = customer.countryOfResidence.currencySymbol;

  const [isSliderExpanded, setIsSliderExpanded] = useState(true);

  const { width, height } = useWindowDimensions();

  const parentEl = document.getElementById('details-page__main-container');
  const top = height > 650 ? parentEl?.offsetTop + 'px' : '140px';
  const selectedQuote = booking.selectedQuote;
  const total = calculateBookingTotalCost(selectedQuote, booking, customer);
  const kgLb = booking.quotes[0].weight.actual.unit === 'LB' ? 'lb' : 'kg';

  useEffect(() => {
    width < 1361 ? setIsSliderExpanded(false) : setIsSliderExpanded(true);
  }, [width])

  const sliderClassName = isSliderExpanded ? 'summary-slider' : 'summary-slider--contracted';

  return (
    <div
      className={sliderClassName}
      style={{
        top: top
      }}
      onClick={() => setIsSliderExpanded(!isSliderExpanded)}
    >
      <img
        src={getCarrierImage(booking.selectedQuote.quoteId)}
        alt="Carrier Logo"
      />

      <p className="summary-slider__service-title">{snakeCaseConvertor(selectedQuote.carrierProductCode)}</p>

      <div className="summary-slider__to-from-container">
        <span>{booking.origin.title}</span>
        <Icon
          name="Parcel"
          color="var(--secondary)"
          style={{
            width: '24px',
            height: '24px'
          }}
        />
        <span>{booking.destination.title}</span>

      </div>

      <div className="quote-details-summary">
        {customer.countryOfResidence.value === "NL" && <p className="summary-slider__ex-vat">(prices are ex VAT)</p>}
        {+booking.totalShipmentValue !== 0 && <p>Shipment Value: <span>{booking.preferredCurrency.symbol}{(+booking.totalShipmentValue).toFixed(2)}</span></p>}
        <p>Weight: <span>{selectedQuote.weight.actual.value}{kgLb}</span></p>
        <p>Chargeable Weight: <span>{roundUpToNearestPointFive(selectedQuote.weight.chargeable.value)}{kgLb}</span></p>
      </div>

      <PriceTable
        booking={booking}
        total={total}
      />

      {!isSliderExpanded && <div className="summary-slider--contracted__total-container">
        <p>Total:</p>
        <p>{currencySymbol}{total.toFixed(2)}</p>
      </div>}

      <>
        {!isSliderExpanded && <div className="summary-slider__expand-icon">
          <Icon
            name="Eye"
            color="var(--secondary)"
          />
          <p>Expand</p>
        </div>}
        {isSliderExpanded && <div className="summary-slider__expand-icon">
          <Icon
            name="EyeCross"
            color="var(--secondary)"
          />
          <p>Hide</p>
        </div>}
      </>
    </div>
  )
}

export default SummarySlider;