import React from "react";
import './Button.scss';
import AMISpinner from '../spinner/Spinner';

interface Props {
  variant?: 'default' | 'primary' | 'secondary',
  type?: 'button' | 'reset' | 'submit' ,
  disabled?: boolean,
  size?: 'small' | 'large' | 'oversized',
  style?: React.CSSProperties,
  className?: string,
  id?: string,
  iconColor?: string,
  isSpinning?: boolean,
  onClick: (event: any) => void
}

const AMIButton: React.FC<Props> = ({
  variant,
  type,
  disabled,
  size,
  style,
  className,
  id,
  isSpinning,
  children,
  onClick
}) => {

  const getClasses = () => {
    let classes = `${className} btn `;

    if (variant && !disabled) {
      classes = classes.concat(`btn-${variant} `);
    }

    if (disabled) {
      classes = classes.concat('btn-disabled ');
    }

    if (isSpinning) {
      classes = classes.concat('btn-is-spinning ')
    }

    if (size === 'large') {
      classes = classes.concat('btn-large ');
    }

    if (size === 'oversized') {
      classes = classes.concat('btn-oversized ');
    }

    return classes;
  }

  const buttonClasses = getClasses();

  let spinnerVariant: 'white' | 'multi-color' = 'white';

  if (variant === 'default' || !variant) {
    spinnerVariant = 'multi-color';
  }

  return (
    <>
      <button
        onClick={onClick}
        className={buttonClasses}
        id={id}
        style={style}
        disabled={disabled}
        type={type}
      >
        {!isSpinning && children}

        {isSpinning
        && size !== 'large'
        && <>
          <AMISpinner
            variant={spinnerVariant}
            style={{width: '20px', height: '20px', marginRight: '4px'}}
          /> {children}
        </>}

        {isSpinning
        && size === 'large'
        && <>
          <AMISpinner
            variant={spinnerVariant}
            style={{width: '30px', height: '30px', marginRight: '4px'}}
          /> {children}
        </>}
      </button>
    </>
  )
};

export default AMIButton;