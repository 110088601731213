import React from 'react';
import './PreviewWidget.scss';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { appDataSelector } from '../../features/appDataSlice';

const PreviewWidget = () => {

  const location = useLocation().pathname;
  const { previewMode } = useAppSelector(appDataSelector);

  if (!previewMode || location === '/account/white-label/customise') return <></>;

  return (
    <div className="preview-widget">
      <h4><strong>Preview</strong></h4>
      <p>You are previewing our site with your branding applied. This is what the site will look like to your customers.</p>
      <p>Click <a href="/account/white-label/customise"><strong>here</strong></a> to go back to the customise page.</p>
    </div>
  )
}

export default PreviewWidget;