import { CsvBooking } from "../options-page/ManifestBookingRequestMapper";
import React from 'react';

export interface csvValidationResult {
  status: 'SUCCESS'| 'FAILURE',
  errors: string[]
}

let returnValue: csvValidationResult = {
  status: 'SUCCESS',
  errors: []
};

const domesticColumns = [
  {
    title: 'SHIPMENT_REFERENCE',
    type: 'STRING',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'SHIPMENT_TYPE',
    type: 'STRING',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'ITEM_NAME',
    type: 'STRING',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'VALUE',
    type: 'NUMBER',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'WEIGHT',
    type: 'NUMBER',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'LENGTH',
    type: 'NUMBER',
    min: null,
    max: null,
    mandatory: false
  },
  {
    title: 'WIDTH',
    type: 'NUMBER',
    min: null,
    max: null,
    mandatory: false
  },
  {
    title: 'HEIGHT',
    type: 'NUMBER',
    min: null,
    max: null,
    mandatory: false
  },
  {
    title: 'ORGANISATION',
    type: 'STRING',
    min: 3,
    max: 35,
    mandatory: true
  },
  {
    title: 'NAME',
    type: 'STRING',
    min: 3,
    max: 35,
    mandatory: true
  },
  {
    title: 'ADDRESS_LINE_1',
    type: 'STRING',
    min: 3,
    max: 35,
    mandatory: true
  },
  {
    title: 'ADDRESS_LINE_2',
    type: 'STRING',
    min: 3,
    max: 35,
    mandatory: false
  },
  {
    title: 'CITY/TOWN',
    type: 'STRING',
    min: null,
    max: 35,
    mandatory: true
  },
  {
    title: 'COUNTY/STATE/PROVINCE',
    type: 'STRING',
    min: null,
    max: 35,
    mandatory: false
  },
  {
    title: 'POSTAL_CODE',
    type: 'STRING',
    min: null,
    max: 10,
    mandatory: false
  },
  {
    title: 'COUNTRY_CODE',
    type: 'STRING',
    min: null,
    max: 2,
    mandatory: true
  },
  {
    title: 'RESIDENTIAL',
    type: 'STRING',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'TELEPHONE',
    type: 'NUMBER',
    min: 6,
    max: 15,
    mandatory: true
  },
  {
    title: 'EMAIL',
    type: 'STRING',
    min: null,
    max: 50,
    mandatory: true
  }
]

const internationalColumns = [
  {
    title: 'SHIPMENT_REFERENCE',
    type: 'STRING',
    min: 2,
    max: null,
    mandatory: true
  },
  {
    title: 'SHIPMENT_TYPE',
    type: 'STRING',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'ITEM_NAME',
    type: 'STRING',
    min: 2,
    max: null,
    mandatory: true
  },
  {
    title: 'VALUE',
    type: 'NUMBER',
    min: 2,
    max: null,
    mandatory: true
  },
  {
    title: 'WEIGHT',
    type: 'NUMBER',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'LENGTH',
    type: 'NUMBER',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'WIDTH',
    type: 'NUMBER',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'HEIGHT',
    type: 'NUMBER',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'ORGANISATION',
    type: 'STRING',
    min: 2,
    max: 35,
    mandatory: true
  },
  {
    title: 'NAME',
    type: 'STRING',
    min: 2,
    max: 35,
    mandatory: true
  },
  {
    title: 'ADDRESS_LINE_1',
    type: 'STRING',
    min: 2,
    max: 35,
    mandatory: true
  },
  {
    title: 'ADDRESS_LINE_2',
    type: 'STRING',
    min: 2,
    max: 35,
    mandatory: false
  },
  {
    title: 'CITY/TOWN',
    type: 'STRING',
    min: 2,
    max: 35,
    mandatory: true
  },
  {
    title: 'COUNTY/STATE/PROVINCE',
    type: 'STRING',
    min: 2,
    max: 2,
    mandatory: false
  },
  {
    title: 'POSTAL_CODE',
    type: 'STRING',
    min: null,
    max: 10,
    mandatory: false
  },
  {
    title: 'COUNTRY_CODE',
    type: 'STRING',
    min: 2,
    max: 2,
    mandatory: true
  },
  {
    title: 'RESIDENTIAL',
    type: 'STRING',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'TELEPHONE',
    type: 'NUMBER',
    min: 6,
    max: 15,
    mandatory: true
  },
  {
    title: 'EMAIL',
    type: 'STRING',
    min: 3,
    max: 50,
    mandatory: true
  },
  {
    title: 'DDP',
    type: 'STRING',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'INSURANCE',
    type: 'STRING',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'SHIPPER_TAX_TYPE',
    type: 'STRING',
    min: null,
    max: null,
    mandatory: false
  },
  {
    title: 'SHIPPER_TAX_NO',
    type: 'STRING',
    min: 2,
    max: null,
    mandatory: false
  },
  {
    title: 'CNEE_TAX_TYPE',
    type: 'STRING',
    min: null,
    max: null,
    mandatory: false
  },
  {
    title: 'CNEE_TAX_NO',
    type: 'STRING',
    min: 2,
    max: null,
    mandatory: false
  },
  {
    title: 'COMMODITY_CODE',
    type: 'STRING',
    min: 2,
    max: null,
    mandatory: false
  },
  {
    title: 'COMMODITY_DESCRIPTION',
    type: 'STRING',
    min: 2,
    max: null,
    mandatory: true
  },
  {
    title: 'COMMODITY_WEIGHT',
    type: 'NUMBER',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'COMMODITY_PIECES',
    type: 'NUMBER',
    min: null,
    max: null,
    mandatory: true
  },
  {
    title: 'COMMODITY_VALUE_PIECE',
    type: 'NUMBER',
    min: null,
    max: null,
    mandatory: false
  },
  {
    title: 'INVOICE_NUMBER',
    type: 'STRING',
    min: 2,
    max: null,
    mandatory: true
  },
  {
    title: 'INVOICE_DATE',
    type: 'STRING',
    min: null,
    max: null,
    mandatory: true
  }
]

export const domesticGuidelines = [
  {
    title: 'SHIPMENT_REFERENCE',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    unit: null,
    description: 'Your reference (HAWB) for the shipment line. If the same reference is used on more than one line it will generate a multi piece shipment. In this case all address and contact details must be identical.'
  },
  {
    title: 'SHIPMENT_TYPE',
    mandatory: 'Yes',
    format: 'DOCS or NON_DOCS',
    unit: null,
    description: 'Content type for the shipment line. For a multi piece shipment must be all DOCS or NON_DOCS'
  },
  {
    title: 'ITEM_NAME',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    unit: null,
    description: 'The description of the item being shipped'
  },
  {
    title: 'VALUE',
    mandatory: 'Yes',
    unit: 'Select at next step',
    description: 'The total value of the items in the shipment line.'
  },
  {
    title: 'WEIGHT',
    mandatory: 'Yes',
    unit: 'LB or KG. Select at next step. Metric or Imperial (must be same as dimensions)',
    description: 'The weight of the package'
  },
  {
    title: 'LENGTH',
    mandatory: 'Yes',
    unit: 'IN or CM. Select at next step. Metric or Imperial (must be same as weight)',
    description: 'The length of the package'
  },
  {
    title: 'WIDTH',
    mandatory: 'Yes',
    unit: 'IN or CM. Select at next step. Metric or Imperial (must be same as weight)',
    description: 'The width of the package'
  },
  {
    title: 'HEIGHT',
    mandatory: 'Yes',
    unit: 'IN or CM. Select at next step. Metric or Imperial (must be same as weight)',
    description: 'The height of the package. Max 2 decimal places.'
  },
  {
    title: 'ORGANISATION',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    unit: null,
    description: 'The name of the recipient organisation.'
  },
  {
    title: 'NAME',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    unit: null,
    description: 'The name of the recipient'
  },
  {
    title: 'ADDRESS_LINE_1',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    unit: null,
    description: 'Address line 1 of the delivery address'
  },
  {
    title: 'ADDRESS_LINE_2',
    mandatory: 'No',
    format: 'Min 2 characters',
    unit: null,
    description: 'Address line 2 of the delivery address'
  },
  {
    title: 'CITY/TOWN',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    unit: null,
    description: 'The city or town of the delivery address'
  },
  {
    title: 'COUNTY/STATE/PROVINCE',
    mandatory: 'For United States, Canada and India addresses',
    format: '2 letter state code',
    unit: null,
    description: 'The county, state or province of the delivery address'
  },
  {
    title: 'POSTAL_CODE',
    mandatory: 'For postal code countries',
    unit: null,
    description: 'The postal code of the delivery address'
  },
  {
    title: 'COUNTRY_CODE',
    mandatory: 'Yes',
    format: `2 letter ISO format. See <a href='/api/reference-data#country-codes' target="_blank">list</a>.`,
    unit: null,
    description: 'The country code of the delivery address.'
  },
  {
    title: 'RESIDENTIAL',
    mandatory: 'Yes',
    format: 'YES or NO',
    description: 'If the recipient address is residential then enter YES, otherwise NO.'
  },
  {
    title: 'TELEPHONE',
    mandatory: 'Yes',
    format: 'Min 10 numbers. Exactly 10 numbers only for US.',
    unit: null,
    description: 'The telephone number of the recipient.'
  },
  {
    title: 'EMAIL',
    mandatory: 'Yes',
    format: 'Must be valid email',
    unit: null,
    description: 'The email address of the recipient. For notifications.'
  },
]

export const internationalGuidelines = [
  {
    title: 'SHIPMENT_REFERENCE',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    description: 'Your reference (HAWB) for the shipment line. If the same reference is used on more than one line it will generate a multi piece shipment. In this case all address and contact details must be identical.'
  },
  {
    title: 'SHIPMENT_TYPE',
    mandatory: 'Yes',
    format: 'DOCS or NON_DOCS',
    description: 'Content type for the shipment line. For a multi piece shipment must be all DOCS or NON_DOCS'
  },
  {
    title: 'ITEM_NAME',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    description: 'The description of the item being shipped'
  },
  {
    title: 'VALUE',
    mandatory: 'Yes',
    unit: 'Selected at next step',
    description: 'The total value of the items in the shipment'
  },
  {
    title: 'WEIGHT',
    mandatory: 'Yes',
    unit: 'LB or KG. Selected at next step. Metric or Imperial (must be same as dimensions)',
    description: 'The weight of the package'
  },
  {
    title: 'LENGTH',
    mandatory: 'Yes',
    unit: 'IN or CM. Selected at next step. Metric or Imperial (must be same as weight)',
    description: 'The length of the package'
  },
  {
    title: 'WIDTH',
    mandatory: 'Yes',
    unit: 'IN or CM. Selected at next step. Metric or Imperial (must be same as weight)',
    description: 'The width of the package'
  },
  {
    title: 'HEIGHT',
    mandatory: 'Yes',
    unit: 'Unit: IN or CM. Selected at next step. Metric or Imperial (must be same as weight)',
    description: 'The height of the package. Max 2 decimal places.'
  },
  {
    title: 'ORGANISATION',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    description: 'The name of the recipient organisation'
  },
  {
    title: 'NAME',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    description: 'The name of the recipient'
  },
  {
    title: 'ADDRESS_LINE_1',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    description: 'Address line 1 of the delivery address'
  },
  {
    title: 'ADDRESS_LINE_2',
    mandatory: 'No',
    format: 'Min 2 characters',
    description: 'Address line 2 of the delivery address'
  },
  {
    title: 'CITY/TOWN',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    description: 'The city or town of the delivery address'
  },
  {
    title: 'COUNTY/STATE/PROVINCE',
    mandatory: 'For United States, Canada and India addresses',
    format: '2 letter state code',
    description: 'The country, state or province of the delivery address'
  },
  {
    title: 'POSTAL_CODE',
    mandatory: 'For postal code countries',
    description: 'The postal code of the delivery address'
  },
  {
    title: 'COUNTRY_CODE',
    mandatory: 'Yes',
    format: `2 letter ISO format. See <a href='/api/reference-data#country-codes' target="_blank">list</a>.`,
    description: 'The country code of the delivery address.'
  },
  {
    title: 'RESIDENTIAL',
    mandatory: 'Yes',
    format: 'YES or NO',
    description: 'If the recipient address is residential then enter YES, otherwise NO.'
  },
  {
    title: 'TELEPHONE',
    mandatory: 'Yes',
    format: 'Min 10 numbers. Exactly 10 numbers only for US.',
    description: 'The telephone number of the recipient.'
  },
  {
    title: 'EMAIL',
    mandatory: 'Yes',
    format: 'Must be a valid email',
    description: 'The email address of the recipient. For notifications.'
  },
  {
    title: 'DDP',
    mandatory: 'Yes',
    format: 'YES or NO',
    description: 'If you require shipment line to be shipped with duties pre-paid enter YES, otherwise NO. There is a charge for this service.'
  },
  {
    title: 'INSURANCE',
    mandatory: 'Yes',
    format: 'YES or NO',
    description: 'If you wish your shipment to be insured please enter YES, otherwise NO. There is a charge for this service.'
  },
  {
    title: 'SHIPPER_TAX_TYPE',
    mandatory: 'Conditional',
    format: `One off this <a href='/api/reference-data#tax-number-types' target="_blank">list</a>.`,
    description: 'Shipper\'s tax number type'
  },
  {
    title: 'SHIPPER_TAX_NO',
    mandatory: 'Conditional',
    format: 'Min 2 characters',
    description: 'Shippers tax number of type entered'
  },
  {
    title: 'CNEE_TAX_TYPE',
    mandatory: 'Conditional',
    format: `One off this <a href='/api/reference-data#tax-number-types' target="_blank">list</a>.`,
    description: `Consignee tax number type`
  },
  {
    title: 'CNEE_TAX_NO',
    mandatory: 'Conditional',
    format: 'Min 2 characters',
    description: 'Consignee\'s tax number of type entered'
  },
  {
    title: 'COMMODITY_CODE',
    mandatory: 'Conditional. Mandatory for international shipments outside the EU.',
    format: 'Min 2 characters',
    description: 'Commodity code for shipment line'
  },
  {
    title: 'COMMODITY_DESCRIPTION',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    description: 'Commodity description for shipment line'
  },
  {
    title: 'COMMODITY_WEIGHT',
    mandatory: 'Yes',
    format: 'LB or KG. Selected at next step. Metric or Imperial (must be same as weight/dimensions)',
    description: 'Commodity weight for shipment line'
  },
  {
    title: 'COMMODITY_PIECES',
    mandatory: 'Yes',
    format: 'Integer - whole number',
    description: 'Number of pieces of the commodity in the shipment line'
  },
  {
    title: 'COMMODITY_VALUE_PIECE',
    mandatory: 'Conditional',
    format: 'Number max 2 decimal places',
    description: 'Value per piece of the commodity.'
  },
  {
    title: 'INVOICE_NUMBER',
    mandatory: 'Yes',
    format: 'Min 2 characters',
    description: 'Reference on the invoice supplied with the shipment'
  },
  {
    title: 'INVOICE_DATE',
    mandatory: 'Yes',
    format: 'YYYY-MM-DD',
    description: 'Date on the invoice supplied with the shipment'
  }
]

export const taxNumberTypes = [
  'EORI',
  'VAT',
  'DAN',
  'US_FED_TAX',
  'US_STATE_TAX',
  'REX',
  'DRIV_LIC',
  'NAT_ID',
  'PASSPORT',
  'MANF_ID',
  'IOSS',
  'OSEAS_REGD',
  'FOREIGN_UK_VAT',
  'AUS_GST',
  'ECOMM_VAT',
  'FTZ',
  'EMP_ID_NO',
  'GST',
  'SSN',
  'DUNS',
  'BR_CNPJ',
  'BR_CPF',
  'BR_IE',
  'BR_RG',
  'BR_PASSPORT'
]

export const resetValidationResult = () => {
  returnValue = {
    status: 'SUCCESS',
    errors: []
  };
}

export const csvGroupedValidation = (data: any) => {
  for (const group in data) {
    const bar = data[group][0];

    for (const piece of data[group]) {
      const index = data[group].indexOf(piece);


      if (index !== 0) {
        if (piece['shipment_type'] !== bar['shipment_type']) returnValue.errors.push(`SHIPMENT_TYPE in ${piece['shipment_reference']} is not uniform across all pieces`);
        if (piece['name'] !== bar['name']) returnValue.errors.push(`NAME in ${piece['shipment_reference']} is not uniform across all pieces`);
        if (piece['organisation'] !== bar['organisation']) returnValue.errors.push(`ORGANISATION in ${piece['shipment_reference']} is not uniform across all pieces`);
        if (piece['address_line_1'] !== bar['address_line_1']) returnValue.errors.push(`ADDRESS_LINE_1 in ${piece['shipment_reference']} is not uniform across all pieces`);
        if (piece['address_line_2'] !== bar['address_line_2']) returnValue.errors.push(`ADDRESS_LINE_2 in ${piece['shipment_reference']} is not uniform across all pieces`);
        if (piece['city_town'] !== bar['city_town']) returnValue.errors.push(`CITY_TOWN in ${piece['shipment_reference']} is not uniform across all pieces`);
        if (piece['county_state_province'] !== bar['county_state_province']) returnValue.errors.push(`COUNTY_STATE_PROVINCE in ${piece['shipment_reference']} is not uniform across all pieces`);
        if (piece['postal_code'] !== bar['postal_code']) returnValue.errors.push(`POSTAL_CODE in ${piece['shipment_reference']} is not uniform across all pieces`);
        if (piece['country_code'] !== bar['country_code']) returnValue.errors.push(`COUNTRY_CODE in ${piece['shipment_reference']} is not uniform across all pieces`);
        if (piece['telephone'] !== bar['telephone']) returnValue.errors.push(`TELEPHONE in ${piece['shipment_reference']} is not uniform across all pieces`);
        if (piece['email'] !== bar['email']) returnValue.errors.push(`EMAIL in ${piece['shipment_reference']} is not uniform across all pieces`);
      }
    }
  }
  returnValue.errors.length > 0 ? returnValue.status = 'FAILURE' : returnValue.status = 'SUCCESS';
  return returnValue;
}

export const internationalCsvValidation = (data: string[]): csvValidationResult => {
  checkIfFileIsEmpty(data);
  if (returnValue.errors.length > 0) return returnValue;

  checkInternationalHeaders(data);
  if (returnValue.errors.length > 0) return returnValue;

  checkFieldData(data, 'INTERNATIONAL');
  returnValue.errors.length > 0 ? returnValue.status = 'FAILURE' : returnValue.status = 'SUCCESS';
  return returnValue;
}

export const domesticCsvValidation = (data: string[]): csvValidationResult => {
  checkIfFileIsEmpty(data);
  if (returnValue.errors.length > 0) {
    return returnValue;
  }

  checkDomesticHeaders(data);
  if (returnValue.errors.length > 0) {
    return returnValue;
  }

  checkFieldData(data, 'DOMESTIC');
  returnValue.errors.length > 0 ? returnValue.status = 'FAILURE' : returnValue.status = 'SUCCESS';
  return returnValue;
};

const checkIfFileIsEmpty = (data: string[]) => {
  if (data.length < 2) {
    returnValue = {
      status: 'FAILURE',
      errors: ['The chosen file is empty. Please ensure the CSV file is populated and saved, then try again.']
    }
  }
}

const checkDomesticHeaders = (data: string[]) => {
  const csvHeaders = data[0];

  if (csvHeaders.length > domesticColumns.length) {
    returnValue = {
      status: 'FAILURE',
      errors: ['Header Error: Too many headers found in the chosen file. Please match your uploaded file to the template provided.']
    }
  }

  if (csvHeaders.length < domesticColumns.length) {
    returnValue = {
      status: 'FAILURE',
      errors: ['Header Error: Too few headers found in the chosen file. Please match your uploaded file to the template provided.']
    }
  }

  domesticColumns.forEach((column, index) => {
    if (column.title.toLowerCase() !== csvHeaders[index]?.toLowerCase()) {
      returnValue.status = 'FAILURE';
      returnValue.errors.push(`Header Error: Found ${csvHeaders[index]} where ${column.title} should be.`);
    }
  })
}

const checkInternationalHeaders = (data: string[]) => {
  const csvHeaders = data[0];

  if (csvHeaders.length > internationalColumns.length) {
    returnValue = {
      status: 'FAILURE',
      errors: ['Header Error: Too many headers found in the chosen file. Please match your uploaded file to the template provided.']
    }
  }

  if (csvHeaders.length < internationalColumns.length) {
    returnValue = {
      status: 'FAILURE',
      errors: ['Header Error: Too few headers found in the chosen file. Please match your uploaded file to the template provided.']
    }
  }

  internationalColumns.forEach((column, index) => {
    if (column.title.toLowerCase() !== csvHeaders[index]?.toLowerCase()) {
      returnValue.status = 'FAILURE';
      returnValue.errors.push(`Header Error: Found ${csvHeaders[index]} where ${column.title} should be.`);
    }
  })
}

const trimTelephone = (csvHeaders: any, dataRows: any) => {
  const telephoneIndex = csvHeaders.indexOf('TELEPHONE');
  for (const row of dataRows) {
    row[telephoneIndex] = row[telephoneIndex].replace(/[^0-9]/g, '');
  }
}

const checkFieldData = (data: string[], type: 'DOMESTIC' | 'INTERNATIONAL') => {
  const csvHeaders = data[0];
  const dataRows = data.slice(1);
  const columns = type === 'DOMESTIC' ? domesticColumns : internationalColumns;

  trimTelephone(csvHeaders, dataRows);

  dataRows.forEach((row: any, rowIndex: number) => {
    if (row.length > columns.length) {
      returnValue.status = 'FAILURE'
      returnValue.errors.push(`Too many fields found on row ${rowIndex + 2}.`)
      return;
    }

    if (row.length < columns.length) {
      returnValue.status = 'FAILURE'
      returnValue.errors.push(`Too few fields found on row ${rowIndex + 2}.`)
      return;
    }

    row.forEach((value: string, columnIndex: number) => {
      const column = columns[columnIndex];

      if (!value && !column.mandatory) return;

      if (!value && column.mandatory) {
        returnValue.errors.push(`${csvHeaders[columnIndex]} on row ${rowIndex + 2}. Field cannot be empty.`);
        return;
      }

      if (column.type === 'NUMBER') {
        if (isNaN(+value)) returnValue.errors.push(`${csvHeaders[columnIndex]} on row ${rowIndex + 2}. Field must contain numbers only - no symbols or words permitted.`);
      }

      if (column.max && value.length > column.max) {
          if (column.type === 'STRING') returnValue.errors.push(`${csvHeaders[columnIndex]} on row ${rowIndex + 2}. Field value is too long. Maximum length is ${column.max} characters`);
          if (column.type === 'NUMBER') returnValue.errors.push(`${csvHeaders[columnIndex]} on row ${rowIndex + 2}. Field value is too long. Maximum length is ${column.max} digits`);
        }

      if (column.min && value.length < column.min) {
        if (column.type === 'STRING') returnValue.errors.push(`${csvHeaders[columnIndex]} on row ${rowIndex + 2}. Field value is too short. Minimum length is ${column.min} characters`);
        if (column.type === 'NUMBER') returnValue.errors.push(`${csvHeaders[columnIndex]} on row ${rowIndex + 2}. Field value is too short. Minimum length is ${column.min} digits`)
      }

      if (column.title === 'SHIPMENT_TYPE') {
        if (value !== "DOCS" && value !== "NON_DOCS") returnValue.errors.push(`SHIPMENT_TYPE value on row ${rowIndex + 2} is not supported. This field must be either DOCS or NON_DOCS`);
      }

      if (column.title === 'SHIPPER_TAX_TYPE') {
        if (!taxNumberTypes.includes(value)) returnValue.errors.push(`SHIPMENT_TAX_TYPE value on row ${rowIndex + 2} is not supported. This field must be one of the following: ${taxNumberTypes.join(', ')}`);
      }

      if (column.title === 'CNEE_TAX_TYPE') {
        if (!taxNumberTypes.includes(value)) returnValue.errors.push(`CNEE_TAX_TYPE value on row ${rowIndex + 2} is not supported. This field must be one of the following: ${taxNumberTypes.join(', ')}`);
      }

      if (column.title === 'INVOICE_DATE') {
        if (value.length !== 10 || isNaN(Date.parse(value))) returnValue.errors.push(`INVOICE_DATE on row ${rowIndex + 2} is an invalid date format. Please format as YYYY-MM-DD`)
      }

      if (column.title === 'DDP' || column.title === 'INSURANCE' || column.title === 'RESIDENTIAL') {
        if (value.toUpperCase() !== 'YES' && value.toUpperCase() !== 'NO') returnValue.errors.push(`${column.title} value on row ${rowIndex + 2} is not supported. This field must be either YES or NO`)
      }
    })
  })
}

export const groupByShipmentReference = (csvData: CsvBooking[]) => {
  const keyForGrouping = "shipment_reference";
  return csvData.reduce((result: any, next) => {
    const key = next[keyForGrouping];
    result[key] = result[key]?.length ? [...result[key], next] : [next];
    return result;
  }, {});
}

export const mapCsvData = (data: string[]) => {
  const mappedData = data.map((row: any) => {
    return row
  })

  const headers: string[] = [];
  for (const header of mappedData[0]) {
    let headerItem = header.replace(/\//g, '_');
    headers.push(headerItem.toLowerCase());
  }

  mappedData.splice(0, 1);

  const formattedRows = [];
  for (let row of mappedData) {
    row = row.reduce((prevValue: any, currValue: string, index: number) => ({ ...prevValue, [headers[index]]: currValue}), {})

    for (const field in row) {
      const column = internationalColumns.find(el => el.title.toLowerCase() === field);
      const type = column?.type.toUpperCase();
      const title = column?.title;

      if (type === 'NUMBER' && title !== 'TELEPHONE') row[field] = Number(row[field]);
      if (title === 'DDP' || title === 'INSURANCE' || title === 'RESIDENTIAL') row[field] = row[field] === 'YES' ? true : false;
    }
    formattedRows.push(row);
  }

  return formattedRows;
}

export enum csvUploadStateEnum {
  DEFAULT = 'DEFAULT',
  SUCCESS = 'SUCCESS',
  UPLOADING = 'UPLOADING',
  ERROR = 'ERROR'
}

export const csvError = {text: 'Upload Failed', color: 'var(--error)'};
export const csvSuccess = {text: 'Upload Successful', color: 'var(--success)'};
export const csvUploading = {text: 'Uploading CSV'};
export const csvDomesticDefault = {text: <span>Upload<br /><strong>Domestic</strong><br />CSV</span>};
export const csvInternationalDefault = {text: <span>Upload<br /><strong>International</strong><br />CSV</span>};