import React from 'react';
import './InsuranceCard.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { AMICheckbox } from '../../../../ui-components/UiComponents';
import { bookingActions, bookingSelector } from '../../../../features/bookingSlice';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { getOptionalChargeCost, OptionalCharge } from '../../../../utilities/HelperUtilities';

const InsuranceCard = () => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const currencySymbol = customer.countryOfResidence.currencySymbol;

  const handleCheck = () => {
    const value = !booking.insure;
    dispatch(bookingActions.updateField({
      field: 'insure',
      value
    }));
  }

  return (
    <div className="insurance-card horizontal-card">
      <p className="horizontal-card__title">Protect your delivery</p>

      <p className="insurance-card__description-text">We can insure your delivery for only <strong>{currencySymbol}{getOptionalChargeCost(booking.selectedQuote, OptionalCharge.INSURANCE)?.toFixed(2)}</strong>.</p>

      <AMICheckbox
        text=""
        checked={booking.insure}
        onChange={handleCheck}
      >
        {booking.insure
          ? <p>Your delivery is <span className="insurance-card__insure-label-span">Insured</span></p>
          : <p>Delivery <span className="insurance-card__do-not-insure-label-span">not insured</span>. Check this box to add insurance.</p>
        }
      </AMICheckbox>

    </div>
  )
}

export default InsuranceCard;