import React from "react";
import { ReactComponent as Checklist } from'./svgs/checklist.svg';
import { ReactComponent as ChevronDown } from'./svgs/chevron-down.svg';
import { ReactComponent as ChevronLeft } from'./svgs/chevron-left.svg';
import { ReactComponent as ChevronLeftDouble } from'./svgs/chevron-left-double.svg';
import { ReactComponent as ChevronRight } from'./svgs/chevron-right.svg';
import { ReactComponent as ChevronRightDouble } from'./svgs/chevron-right-double.svg';
import { ReactComponent as ChevronUp } from'./svgs/chevron-up.svg';
import { ReactComponent as CircleCheck } from'./svgs/circle-check.svg';
import { ReactComponent as CircleExclamation } from'./svgs/circle-exclamation.svg';
import { ReactComponent as CircleQuestion } from'./svgs/circle-question.svg';
import { ReactComponent as Close } from'./svgs/close.svg';
import { ReactComponent as Copy } from'./svgs/copy.svg';
import { ReactComponent as Download } from'./svgs/download.svg';
import { ReactComponent as Edit } from'./svgs/edit.svg';
import { ReactComponent as Express } from'./svgs/express.svg';
import { ReactComponent as External } from'./svgs/external.svg';
import { ReactComponent as Eye } from'./svgs/eye.svg';
import { ReactComponent as EyeCross } from'./svgs/eye-cross.svg';
import { ReactComponent as Filter } from'./svgs/filter.svg';
import { ReactComponent as Geo } from'./svgs/geo.svg';
import { ReactComponent as Hamburger } from'./svgs/hamburger.svg';
import { ReactComponent as Parcel } from'./svgs/parcel.svg';
import { ReactComponent as PlaneOutline } from'./svgs/plane-outline.svg';
import { ReactComponent as PlaneSolid } from'./svgs/plane-solid.svg';
import { ReactComponent as Print } from'./svgs/print.svg';
import { ReactComponent as Refresh } from'./svgs/refresh.svg';
import { ReactComponent as Save } from'./svgs/save.svg';
import { ReactComponent as Search } from'./svgs/search.svg';
import { ReactComponent as ShieldCheck } from'./svgs/shield-check.svg';
import { ReactComponent as ShieldCross } from'./svgs/shield-cross.svg';
import { ReactComponent as SortAscend } from'./svgs/sort-ascend.svg';
import { ReactComponent as SortBoth } from'./svgs/sort-both.svg';
import { ReactComponent as SortDescend } from'./svgs/sort-descend.svg';
import { ReactComponent as Spinner } from'./svgs/spinner.svg';
import { ReactComponent as Switch } from'./svgs/switch.svg';
import { ReactComponent as SwitchLeftRight } from'./svgs/switch-left-right.svg';
import { ReactComponent as Trash } from'./svgs/trash.svg';
import { ReactComponent as Truck } from'./svgs/truck.svg';
import { ReactComponent as Upload } from'./svgs/upload.svg';
import { ReactComponent as User } from'./svgs/user.svg';
import styles from './Icon.module.scss';

const Icon: React.FC<{
  name:
    'Checklist' |
    'ChevronDown' |
    'ChevronLeft' |
    'ChevronLeftDouble' |
    'ChevronRight' |
    'ChevronRightDouble' |
    'ChevronUp' |
    'CircleCheck' |
    'CircleExclamation' |
    'CircleQuestion' |
    'Close' |
    'Copy' |
    'Download' |
    'Edit' |
    'Express' |
    'External' |
    'Eye' |
    'EyeCross' |
    'Filter' |
    'Geo' |
    'Hamburger' |
    'Parcel' |
    'PlaneOutline' |
    'PlaneSolid' |
    'Print' |
    'Refresh' |
    'Save' |
    'Search' |
    'ShieldCheck' |
    'ShieldCross' |
    'SortAscend' |
    'SortBoth' |
    'SortDescend' |
    'Spinner' |
    'Switch' |
    'SwitchLeftRight' |
    'Trash' |
    'Truck' |
    'Upload' |
    'User',

  style?: React.CSSProperties,
  color?: string,
  id?: string,
  onClick?: () => void
}> = ({
  name,
  style,
  color = 'var(--text)',
  id,
  onClick
}) => {

  return (
    <div className={styles.iconBtnWrapper}>
      {name === 'Checklist' && <Checklist className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'ChevronDown' && <ChevronDown className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'ChevronLeft' && <ChevronLeft className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'ChevronLeftDouble' && <ChevronLeftDouble className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'ChevronRight' && <ChevronRight className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'ChevronRightDouble' && <ChevronRightDouble className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'ChevronUp' && <ChevronUp className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'CircleCheck' && <CircleCheck className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === "CircleExclamation" && <CircleExclamation className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === "CircleQuestion" && <CircleQuestion className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick} />}
      {name === "Close" && <Close className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick} />}
      {name === "Copy" && <Copy className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick} />}
      {name === 'Download' && <Download className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Edit' && <Edit className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Express' && <Express className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'External' && <External className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Eye' && <Eye className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'EyeCross' && <EyeCross className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Filter' && <Filter className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Geo' && <Geo className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Hamburger' && <Hamburger className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Parcel' && <Parcel className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'PlaneOutline' && <PlaneOutline className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'PlaneSolid' && <PlaneSolid className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Print' && <Print className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Refresh' && <Refresh className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Save' && <Save className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Search' && <Search className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'ShieldCheck' && <ShieldCheck className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'ShieldCross' && <ShieldCross className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'SortAscend' && <SortAscend className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'SortBoth' && <SortBoth className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'SortDescend' && <SortDescend className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Spinner' && <Spinner className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Switch' && <Switch className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'SwitchLeftRight' && <SwitchLeftRight className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Trash' && <Trash className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Truck' && <Truck className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'Upload' && <Upload className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
      {name === 'User' && <User className={styles.icon} style={{fill: color, ...style}} id={id} onClick={onClick}/>}
    </div>
  )
};

export default Icon;