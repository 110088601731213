export const labelData = `{
  "label": {
    "headers": [
      {
        "title": "party-id",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "A unique value assigned to each customer account."
        ]
      },
      {
        "title": "client-id",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "The client ID provided when registering for the API."
        ]
      },
      {
        "title": "x-api-key",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "The api key provided when registering for the API."
        ]
      },
      {
        "title": "authorization",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string(bearer-token)"
          },
          {
            "title": "Allowed Values",
            "content": "Bearer <generated access token>"
          }
        ],
        "descriptions": [
          "The client ID provided when registering for the API."
        ]
      }
    ],
    "responseSuccess": [
      {
        "title": "200 Success",
        "descriptions": [
          "Thermal labels will be returned in 2 different formats. If the booking generates 1 label that will be returned in a single ZPL (.zpl) file. For multiple thermal labels all labels will be returned in a ZIP ( .zip ) file. Label type and filename are returned within headers Content-Type and content-disposition."
        ]
      }
    ],
    "responseError": [
      {
        "title": "404",
        "descriptions": [
          "Label does not exist."
        ]
      },
      {
        "title": "202",
        "descriptions": [
          "Label/s are still being processed"
        ]
      }
    ]
  }
}`