import React, { ChangeEvent } from 'react';
import './DescriptionItem.scss';

const AMIDescriptionItem: React.FC<{
  label: any,
  error?: string,
  editable?: boolean,
  inputName?: string,
  placeholder?: string,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  children?: string
}> = ({
  label,
  error,
  editable,
  inputName,
  placeholder,
  onChange,
  children
}) => {

  const itemClass = error ? 'ami-description-item ami-description-item--error' : 'ami-description-item';

  return (
    <div>
      <div className={itemClass}>
        <div className="ami-description-item__label">{label}</div>
        {
          !editable &&
          <div
            className="ami-description-item__value ami-description-item__value--not-editable"
          >{children}</div>
        }

        {
          editable &&
          <div
            className="ami-description-item__value ami-description-item__value--editable"
          >
            <input
              name={inputName}
              value={children}
              placeholder={children ? children : placeholder}
              onChange={onChange}
            ></input>
          </div>
        }
      </div>
      {error && <p className="ami-description-item__error">{error}</p>}
    </div>
  )
}

export default AMIDescriptionItem;