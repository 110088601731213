import React, { useEffect } from 'react';
import './Steps.scss';
import Step from './step/Step';
import { appDataSelector, appDataActions, PageStatus } from '../features/appDataSlice';
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { useHistory, useLocation } from 'react-router-dom';
import { bookingActions } from '../features/bookingSlice';

export enum ActivePage {
  START = 'START',
  QUOTE = 'QUOTE',
  DETAILS = 'DETAILS',
  CONFIRM = 'CONFIRM',
  FINISH = 'FINISH',
}

const AMISteps: React.FC<{activePage: ActivePage}> = ({activePage}) => {

  const history = useHistory();
  const location = useLocation().pathname;

  const dispatch = useAppDispatch();
  const appData = useAppSelector(appDataSelector);

  useEffect(() => {
    dispatch(appDataActions.setActivePage(activePage));
  }, [activePage])

  const handleStepChange = (page: ActivePage) => {
    if (location === '/finish') {
      dispatch(bookingActions.resetState());
      dispatch(appDataActions.resetStateWithConditions());
    }
    let route = '/single-booking';
    switch(page) {
      case 'START':
        route = '/single-booking'
        break;
      case 'QUOTE':
        route = '/quote'
        break;
      case 'DETAILS':
        route = '/details'
        break;
      case 'CONFIRM':
        route = '/confirm'
        break;
      case 'FINISH':
        route = '/finish'
        break;
    }
    history.push(route);
  };

  const steps = [
    {
      title: 'Start',
      isActive: appData.activePage === ActivePage.START ? true : false,
      status: PageStatus.COMPLETE,
      clickable: true,
      lines: false,
      page: ActivePage.START
    },
    {
      title: 'Quote',
      isActive: appData.activePage === ActivePage.QUOTE ? true : false,
      status: appData.quotePageStatus,
      clickable: location === '/finish'
        || appData.quotePageStatus === PageStatus.TODO
          ? false
          : true,
      lines: true,
      page: ActivePage.QUOTE
    },
    {
      title: 'Details',
      isActive: appData.activePage === ActivePage.DETAILS ? true : false,
      status: appData.detailsPageStatus,
      clickable: location === '/finish'
      || appData.detailsPageStatus === PageStatus.TODO
        ? false
        : true,
      lines: true,
      page: ActivePage.DETAILS
    },
    {
      title: 'Confirm',
      isActive: appData.activePage === ActivePage.CONFIRM ? true : false,
      status: appData.confirmPageStatus,
      clickable: location === '/finish'
      || appData.confirmPageStatus === PageStatus.TODO
        ? false
        : true,
      lines: true,
      page: ActivePage.CONFIRM
    },
    {
      title: 'Finish',
      isActive: appData.activePage === ActivePage.FINISH ? true : false,
      status: PageStatus.TODO,
      clickable: false,
      lines: true,
      page: ActivePage.FINISH
    },
  ]

  return (
    <div className="steps">
      {steps.map((step) => {
        return <Step
                  key={step.title}
                  title={step.title}
                  isActive={step.isActive}
                  status={step.status}
                  clickable={step.clickable}
                  lines={step.lines}
                  onClick={handleStepChange.bind(null, step.page)}
                />
      })}
    </div>
  )
};

export default AMISteps;