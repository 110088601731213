import React, { useEffect, useState } from 'react';
import './CarrierContainer.scss';
import { AMIModal, AMIPopover, Icon } from '../../../../ui-components/UiComponents';
import { OptionalCharge, checkFedexFreeDDP, getCarrierImage, getCarrierName, getOptionalChargeCost, snakeCaseConvertor } from '../../../../utilities/HelperUtilities';
import { useAppSelector } from '../../../../app/hooks';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { appDataSelector } from '../../../../features/appDataSlice';
import QuoteCourierInfoModal from '../quote/quote-courier-info-modal/QuoteCourierInfoModal';
import { QuoteObj, TermsOfSale, bookingSelector } from '../../../../features/bookingSlice';
import Quote from '../quote/Quote';
import { calculateQuoteCost, populateTimeFrames } from '../utilities';
import dayjs from 'dayjs';

const CarrierContainer: React.FC<{carrierWithQuotes: any}> = ({carrierWithQuotes}) => {

  const customer = useAppSelector(customerDetailsSelector);
  const appData = useAppSelector(appDataSelector);
  const booking = useAppSelector(bookingSelector);
  const quotes = carrierWithQuotes.quotes;
  const firstQuote = quotes[0];
  const ddpCost = getOptionalChargeCost(firstQuote, OptionalCharge.DELIVERED_DUTY_PAID);
  const fedexFreeDDP = checkFedexFreeDDP(firstQuote, customer);
  const currencySymbol = customer.countryOfResidence.currencySymbol;
  const displayInsurance = appData.displayInsurance;
  const quoteSort = appData.quoteSort;
  const carrier = getCarrierName(firstQuote.quoteId)

  const [showDropDown, setShowDropDown] = useState(false);
  const [ddpSelected, setDDPSelected] = useState(false);
  const [insured, setInsured] = useState(false);
  const [insuranceCost, setInsuranceCost] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sameDayQuotes, setSameDayQuotes] = useState<QuoteObj[]>();
  const [nextDayQuotes, setNextDayQuotes] = useState<QuoteObj[]>();
  const [twoDayQuotes, setTwoDayQuotes] = useState<QuoteObj[]>();
  const [threeDayQuotes, setThreeDayQuotes] = useState<QuoteObj[]>();
  const [lowestPrice, setLowestPrice] = useState<any>();
  const [transportMethods, setTransportMethods] = useState<any>([]);
  const [serviceTypes, setServiceTypes] = useState<any>([]);
  const [deliveryDates, setDeliveryDates] = useState<any>([]);
  const [collectionQuotes, setCollectionQuotes] = useState<any>([]);
  const [dropInQuotes, setDropInQuotes] = useState<any>([]);

  useEffect(() => {
    if (displayInsurance) setInsuranceCost(getOptionalChargeCost(firstQuote, OptionalCharge.INSURANCE));
    if (booking.insure) setInsured(true);
    if (booking.customsDetails.invoiceDetails.termsOfSale === TermsOfSale.DDP) setDDPSelected(true);
  }, [])

  useEffect(() => {
    if (!quoteSort) {
      populateTimeFrames(
        quotes,
        booking,
        customer,
        setSameDayQuotes,
        setNextDayQuotes,
        setTwoDayQuotes,
        setThreeDayQuotes,
        'DROP_DOWN',
      );
    } else populateQuoteTypes()

    getDeliveryDate();
    getTransportMethod();
    getServiceType();
  }, [quotes, quoteSort])

  useEffect(() => {
    getLowestPrice();
  }, [insured, ddpSelected, quotes]);

  const populateQuoteTypes = () => {
    const collectionQuotes = [];
    const dropInQuotes = [];

    for (const quote of quotes) {
      if (quote.serviceType === 'DROP_IN') dropInQuotes.push(quote);
      else collectionQuotes.push(quote);
    }

    setCollectionQuotes(collectionQuotes);
    setDropInQuotes(dropInQuotes);
  }

  const getDeliveryDate = () => {
    const dates: any[] = [];
    for (const quote of quotes) {
      const SUNDAY = 0;
      const SATURDAY = 6

      let deliveryDateRaw = dayjs(quote.deliveryDateEstimate);

      if (deliveryDateRaw.day() === SATURDAY) deliveryDateRaw = deliveryDateRaw.add(2, 'day');
      if (deliveryDateRaw.day() === SUNDAY) deliveryDateRaw = deliveryDateRaw.add(1, 'day');

      const deliveryDate = dayjs(deliveryDateRaw).format('ddd DD MMM');

      if (!dates.includes(deliveryDate)) dates.push(deliveryDate);

      if (dates.length > 0) {
        if (dayjs(deliveryDate).isBefore(dates[0])) dates.unshift(deliveryDate);
        if (dayjs(deliveryDate).isAfter(dates[dates.length - 1])) dates.push(deliveryDate);
      } else dates.push(deliveryDate);
    }

    setDeliveryDates(dates[0] + ' - ' + dates[dates.length - 1]);
  }

  const getTransportMethod = () => {
    const methods: any[] = [];
    for (const quote of quotes) {
      if (!methods.includes(snakeCaseConvertor(quote.transportMethod))) methods.push(snakeCaseConvertor(quote.transportMethod));
    }
    setTransportMethods(methods.join(' & '));
  }

  const getServiceType = () => {
    const serviceType: any[] = [];
    for (const quote of quotes) {
      if (!serviceType.includes(snakeCaseConvertor(quote.serviceType))) serviceType.push(snakeCaseConvertor(quote.serviceType));
    }
    setServiceTypes(serviceType.join(' & '));
  }

  const toggleModal = (event: any) => {
    event?.stopPropagation()
    setIsModalOpen(!isModalOpen);
  }

  const getLowestPrice = () => {
    let lowestPrice;
    for (const quote of quotes) {
      const totalCost = calculateQuoteCost(
        quote,
        booking,
        customer,
        insured,
        insuranceCost,
        ddpSelected,
        ddpCost
      );

      if (!lowestPrice) lowestPrice = totalCost;
      else if (+totalCost < +lowestPrice) lowestPrice = totalCost;
    }

    setLowestPrice(lowestPrice);
  }

  const [isTimeDisclaimerVisible, setIsTimeDisclaimerVisible] = useState(false);

  return (
    <div className="carrier-container">

      <div className="carrier-container__extras">
        {ddpCost !== 0 &&
        (
          <div
            className="carrier-ddp-block"
            style={
              ddpSelected
                ? {background: 'var(--primary)'}
                : {background: 'var(--tertiary)'}
            }
            onClick={() => setDDPSelected(!ddpSelected)}
          >
            {ddpSelected && <p>DDP added {currencySymbol}{ddpCost?.toFixed(2)}</p>}
            {!ddpSelected && <p>Add DDP <br /> from {currencySymbol}{ddpCost?.toFixed(2)}</p>}
          </div>
        )}
{/* WHILE FEDEX IS BEING FORCED TO 0 FOR PROMOTIONAL ZERO COST DDP: */}
        {fedexFreeDDP && (
          <div
            className="carrier-ddp-block"
            style={{background: 'var(--primary)'}}
            onClick={() => setDDPSelected(!ddpSelected)}
          >
            {ddpSelected && <p>DDP added</p>}
            {!ddpSelected && <p>Add DDP with no charges</p>}
          </div>
        )}
{/* END */}
        {insuranceCost !== 0 && (
          <div
            className="carrier-insurance-block"
            style={
              insured
                ? {background: 'var(--primary)'}
                : {background: 'var(--secondary50)'}
            }
            onClick={() => setInsured(!insured)}
          >
            {insured && <p>Insurance <br /> added {currencySymbol}{insuranceCost?.toFixed(2)}</p>}
            {!insured && <p>Add insurance <br /> from {currencySymbol}{insuranceCost?.toFixed(2)}</p>}
          </div>
        )}
      </div>
      <div
        className="carrier vertical-card"
        onMouseOver={() => setShowDropDown(true)}
        onMouseLeave={() => setShowDropDown(false)}
        style={showDropDown ? {zIndex: "2"} : {zIndex: "auto"}}
      >
        <div
          className="carrier__info"
          onClick={toggleModal}
        >i</div>

        <div className="carrier__grid">
          <div className="carrier__grid__img-cont">
            <img
              src={getCarrierImage(quotes[0].quoteId)}
              alt="Logo"
            />

            <p className="carrier__grid__base-price">From {currencySymbol +lowestPrice}</p>

          </div>

          <div className="carrier__grid__text-content">
            <div className="carrier__grid__text-content__item">
              <div className="carrier__grid__text-content__item__title">Estimated Delivery Date
                <span
                  onMouseEnter={() => setIsTimeDisclaimerVisible(true)}
                  onMouseLeave={() => setIsTimeDisclaimerVisible(false)}
                >
                  <Icon
                    name="CircleQuestion"
                    color="var(--primary)"
                    style={{marginLeft: '4px'}}
                  />
                  <AMIPopover isVisible={isTimeDisclaimerVisible}>
                    All timings are estimated and can be affected by delays including customs clearance, weather and social situations.
                  </AMIPopover>
                </span>
              </div>
              <p>{deliveryDates}</p>
            </div>
            <div className="carrier__grid__text-content__item">
              <p className="carrier__grid__text-content__item__title">Transport Methods</p>
              <p>{transportMethods}</p>
            </div>
            <div className="carrier__grid__text-content__item">
              <p className="carrier__grid__text-content__item__title">Service Types</p>
              <p>{serviceTypes}</p>
            </div>
          </div>

        </div>

        <div className="carrier__select">
          Select Service
          <Icon
            name="ChevronDown"
            color="var(--primary)"
            style={{
              transform: "translate(4px, 4px)"
            }}
          />
        </div>

        {quoteSort === 1 && (
          <div className="carrier-container__drop-down">
            {collectionQuotes && collectionQuotes.length > 0 && (
              <>
                <p className="carrier-container__drop-down__header">Collection Services</p>
                {collectionQuotes.map((quote: QuoteObj) => {
                  return (
                    <Quote
                      key={quote.quoteId}
                      quote={quote}
                      insured={insured}
                      ddpSelected={ddpSelected}
                      insuranceCost={insuranceCost}
                      showServiceType={!quoteSort}
                      booking={booking}
                    />
                  )
                })}
              </>
            )}

            {dropInQuotes && dropInQuotes.length > 0 && (
              <>
                <p className="carrier-container__drop-down__header">Drop In Services</p>
                {dropInQuotes.map((quote: QuoteObj) => {
                  return (
                    <Quote
                      key={quote.quoteId}
                      quote={quote}
                      insured={insured}
                      ddpSelected={ddpSelected}
                      insuranceCost={insuranceCost}
                      showServiceType={!quoteSort}
                      booking={booking}
                    />
                  )
                })}
              </>
            )}
          </div>
        )}

        {quoteSort === 0 && (
          <div className="carrier-container__drop-down">

            {sameDayQuotes && sameDayQuotes.length > 0 && (
              <>
                <p className="carrier-container__drop-down__header">Same Day Delivery</p>
                {sameDayQuotes.map((quote: QuoteObj) => {
                  return (
                    <Quote
                      key={quote.quoteId}
                      quote={quote}
                      insured={insured}
                      ddpSelected={ddpSelected}
                      insuranceCost={insuranceCost}
                      showServiceType={!quoteSort}
                      booking={booking}
                    />
                  )
                })}
              </>
            )}

            {nextDayQuotes && nextDayQuotes.length > 0 && (
              <>
                <p className="carrier-container__drop-down__header">Next Day Delivery</p>
                {nextDayQuotes.map((quote: QuoteObj) => {
                  return (
                    <Quote
                      key={quote.quoteId}
                      quote={quote}
                      insured={insured}
                      ddpSelected={ddpSelected}
                      insuranceCost={insuranceCost}
                      showServiceType={!quoteSort}
                      booking={booking}
                    />
                  )
                })}
              </>
            )}

            {twoDayQuotes && twoDayQuotes.length > 0 && (
              <>
                <p className="carrier-container__drop-down__header">2 Day Delivery</p>
                {twoDayQuotes.map((quote: QuoteObj) => {
                  return (
                    <Quote
                      key={quote.quoteId}
                      quote={quote}
                      insured={insured}
                      ddpSelected={ddpSelected}
                      insuranceCost={insuranceCost}
                      showServiceType={!quoteSort}
                      booking={booking}
                    />
                  )
                })}
              </>
            )}

            {threeDayQuotes && threeDayQuotes.length > 0 && (
              <>
                <p className="carrier-container__drop-down__header">3+ Day Delivery</p>
                {threeDayQuotes.map((quote: QuoteObj) => {
                  return (
                    <Quote
                      key={quote.quoteId}
                      quote={quote}
                      insured={insured}
                      ddpSelected={ddpSelected}
                      insuranceCost={insuranceCost}
                      showServiceType={!quoteSort}
                      booking={booking}
                    />
                  )
                })}
              </>
            )}
          </div>
        )}
      </div>

      {showDropDown && <div className="carrier-container__drop-down-mask"></div>}

      {isModalOpen && <AMIModal
        title="Carrier Information"
        className='courier-modal'
        width="80%"
        close={() => toggleModal(event)}
      >
        <QuoteCourierInfoModal
          quoteDetails={firstQuote}
          booking={booking}
          customer={customer}
        />
      </AMIModal>}
    </div>
  )
}

export default CarrierContainer;