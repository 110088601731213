export function isValidAddressField(value: string) {
  return value.length <= 35 && value.length >= 3;
}

export function isValidEmail(value: string) {
  return new RegExp(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i).test(value)
  && value.length >= 3
  && value.length <= 50;
}

export function isValidTelephone(value: string, isUS: boolean) {
  if (isUS) return value.length === 10 && +value > 0;
  else return value.length >=10 && value.length <= 15 && +value > 0;
}

export function isLessThan10Characters(value: string) {
  return value?.length <= 10;
}

export function isValidPostalCode(value: string, regex: string) {
  return new RegExp(regex).test(value.toUpperCase()) && isLessThan10Characters(value);
}

export function isBetweenXAndXCharacters(value: string, lowerValue: number, upperValue: number) {
  return value.length >= lowerValue && value.length <= upperValue
}

export function isValidShortAddressCode(value: string) : boolean {
  return new RegExp(/^[A-Z]{4}\d{4}$/i).test(value) && value.length <= 8;
}