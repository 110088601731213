import React, { useEffect } from 'react';
import './Commodity.scss';
import { AMIFormElement, AMIInput, AMISelect, Icon } from '../../../../../../ui-components/UiComponents';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { bookingActions, bookingSelector, ImperialMetric } from '../../../../../../features/bookingSlice';
import { appDataActions, appDataSelector } from '../../../../../../features/appDataSlice';
import { checkForPartialCompleteness } from './utils';
import { removePeriodsFromString } from '../../../../../../utilities/HelperUtilities';
import {extractRule} from "../../../../../../utilities/RulesEngineUtilities";

const Commodity: React.FC<{
  commodity: any,
  index: number,
  isRequired: any,
  onCopy: (pieceValues: any) => void,
  customRules?: any,
  templateCurrency?: any,
  template?: any,
  updateCommodities?: any,
  onDelete: () => void
}> = ({
  commodity,
  index,
  isRequired,
  onCopy,
  customRules,
  templateCurrency,
  template,
  updateCommodities,
  onDelete
}) => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const commodities = booking.customsDetails.commodities;
  const appData = useAppSelector(appDataSelector);
  const showErrors = appData.showDetailsPageErrors;
  const partialCompleteError = appData.commodityPartialCompleteError;
  const preferredCurrency = templateCurrency ? templateCurrency : booking.preferredCurrency;

  useEffect(() => {
    checkForPartialCompleteness(commodity, dispatch, appDataActions);
  }, [commodity])

  const checkCommodityCode = (code: string) => {
    const codeWithoutPeriods = removePeriodsFromString(code);
    return codeWithoutPeriods.length >= 3 && codeWithoutPeriods.length <= 18
  }

  const errHandler = {
    code: {
      criteria: isRequired
        ? (
          commodity.commodityCode
          && checkCommodityCode(commodity.commodityCode)
        )
        : true,
      message: 'Must be 3 to 18 characters long'
    },
    description: {
      criteria: isRequired
        ? (
          commodity.description
          && commodity.description.length <= 45
        )
        : true,
      message: 'Required. Max length 45 characters'
    },
    weight: {
      criteria: isRequired
        ? commodity.weight.value
        && +commodity.weight.value > 0
        : true,
      message: 'Required'
    },
    quantityUnit: {
      criteria: isRequired
        ? commodity.quantity.unit
        : true,
      message: 'Required'
    },
    quantity: {
      criteria: isRequired || extractRule(customRules,'docsShipmentValueRequired')?.value
        ? (
          commodity.quantity.value
          && +commodity.quantity.value > 0
          && +commodity.quantity.value <= 1000000000
        )
        : true,
      message: 'Required'
    },
    value: {
      criteria: isRequired || extractRule(customRules, 'docsShipmentValueRequired')?.value
        ? (
          commodity.unitPrice.value
          && +commodity.unitPrice.value > 0
        )
        : true,
      message: 'Required'
    },
  }

  const onChange = (field: string, event: any) => {
    let updatedCommodity;
    if (field === 'commodityCode' || field === 'description') {
      updatedCommodity = {
        ...commodity,
        [field]: event.target.value
      }
    } else if (field === 'unitPrice') {
      updatedCommodity = {
        ...commodity,
        [field]: {
          value: event.target.value,
          currency: booking.preferredCurrency.value
        }
      }
    } else if (field === 'quantity') {
      updatedCommodity = {
        ...commodity,
        [field]: {
          value: event.target.value,
          unit: 'PCS'
        }
      }
    } else if (field === 'weight') {
      updatedCommodity = {
        ...commodity,
        [field]: {
          value: event.target.value,
          unit: booking.imperialMetric === ImperialMetric.IMPERIAL
            ? 'LB'
            : 'KG'
        }
      }
    }

    const commoditiesCopy = template ? [...template.commodities] : [...commodities];
    commoditiesCopy.splice(index, 1, updatedCommodity);
    if (template) updateCommodities(commoditiesCopy);
    else dispatch(bookingActions.setCommodities(commoditiesCopy));
  }

  const onValuePerPieceBlur = (value: string) => {
    const roundedPrice = Math.round((+value + Number.EPSILON) * 100) / 100;
    const updatedCommodity = {
      ...commodity,
      unitPrice: {
        value: roundedPrice ? roundedPrice.toFixed(2).toString() : +value,
        currency: booking.preferredCurrency.value
      }
    }

    const commoditiesCopy = [...commodities];
    commoditiesCopy.splice(index, 1, updatedCommodity);
    dispatch(bookingActions.setCommodities(commoditiesCopy));
  }

  useEffect(() => {
    for (const field in errHandler) {
      if (!(errHandler as any)[field].criteria) {
        dispatch(appDataActions.setDetailsPageCommoditiesErrors(true));
        return;
      }
    }
    dispatch(appDataActions.setDetailsPageCommoditiesErrors(false));
  }, [commodities])

  return (
    <div className="commodity">
      {showErrors
        && partialCompleteError
        && <p className="commodity__error-message">Commodity fields must all be filled in or all left blank</p>
      }

      <AMIFormElement
        label="Code"
        className="commodity__code"
        errorMessage={
          showErrors
          && !errHandler.code.criteria
          ? errHandler.code.message
          : ''
        }
      >
        <AMIInput
          name="code"
          placeholder={isRequired
            ? "Required"
            : ''
          }
          size="large"
          type="number"
          min="0"
          value={commodity.commodityCode}
          onChange={(event) => onChange('commodityCode', event)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Description"
        className="commodity__description"
        errorMessage={
          showErrors
          && !errHandler.description.criteria
          ? errHandler.description.message
          : ''
        }
      >
        <AMIInput
          name="description"
          placeholder={isRequired
            ? "Required"
            : ''
          }
          size="large"
          value={commodity.description}
          onChange={(event) => onChange('description', event)}
        />
      </AMIFormElement>

      <AMIFormElement
        label={booking.imperialMetric === ImperialMetric.IMPERIAL ? "Weight (lb)" : "Weight (kg)"}
        className="commodity__weight"
        errorMessage={
          showErrors
          && !errHandler.weight.criteria
          ? errHandler.weight.message
          : ''
        }
      >
        <AMIInput
          name="weight"
          placeholder={isRequired
            ? "Required"
            : ''
          }
          size="large"
          type="number"
          min="0"
          value={commodity.weight.value}
          onChange={(event) => onChange('weight', event)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Number of Pieces"
        className="commodity__number-of-pieces"
        errorMessage={
          showErrors
          && !errHandler.quantity.criteria
          ? errHandler.quantity.message
          : ''
        }
      >
        <AMIInput
          name="quantity"
          placeholder={isRequired || extractRule(customRules,'docsShipmentValueRequired')?.value
          ? "Required"
          : ""
        }
          size="large"
          type="number"
          min="0"
          value={commodity.quantity.value}
          onChange={(event) => onChange('quantity', event)}
        />
      </AMIFormElement>

      <AMIFormElement
        label={`Value per Piece (${preferredCurrency.symbol})`}
        className="commodity__value"
        errorMessage={
          showErrors
          && !errHandler.value.criteria
          ? errHandler.value.message
          : ''
        }
      >
        <AMIInput
          name="value"
          placeholder={isRequired || extractRule(customRules,'docsShipmentValueRequired')?.value
          ? preferredCurrency.symbol + " Required"
          : preferredCurrency.symbol
        }
          size="large"
          type="number"
          min="0"
          value={commodity.unitPrice.value}
          onChange={(event) => onChange('unitPrice', event)}
          onBlur={(event) => onValuePerPieceBlur(event.target.value)}
        />
      </AMIFormElement>

      <Icon
        name="Copy"
        style={{
          position: 'absolute',
          right: '40px',
          bottom: '12px',
          height: '24px',
          cursor: 'pointer'
        }}
        onClick={() => onCopy(commodity)}
      />

      <Icon
        name="Trash"
        style={{
          position: 'absolute',
          right: '12px',
          bottom: '12px',
          height: '24px',
          cursor: 'pointer'
        }}
        onClick={onDelete}
      />

    </div>
  )
}

export default Commodity;