import React, { ChangeEvent, FocusEvent } from 'react';
import './Input.scss';

interface Props {
  name?: string,
  placeholder?: string,
  value?: string | number,
  id?: string,
  type?: string,
  min?: string,
  max?: string,
  integer?: boolean,
  disabled?: boolean,
  size?: 'large',
  style?: React.CSSProperties,
  className?: string,
  errorMessage?: string,
  readOnly?: boolean,
  refFn?: any,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void,
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
}

const AMIInput: React.FC<Props> = ({
  name,
  placeholder,
  value,
  id,
  type,
  min,
  max,
  integer,
  disabled,
  size,
  style,
  className,
  errorMessage,
  readOnly,
  refFn,
  onChange,
  onBlur,
  onFocus
}) => {

  const getClasses = () => {
    let classes = `${className} input `;

    if (errorMessage) {
      classes = classes.concat('input--error ');
    }

    if (disabled) {
      classes = classes.concat('input--disabled ');
    }
    if (size === 'large') {
      classes = classes.concat('input--large ');
    }

    return classes;
  }

  const getInputContainerClasses = () => {
    let classes = `input-container `
    if (name === "freight-charge") {
      classes = classes.concat('input-container--freight-charge')
    }
    return classes;
  }

  const inputContainerClasses = getInputContainerClasses();

  const inputClasses = getClasses();

  const handleChange = (event: any) => {
    onChange?.(event);
  }



  return (
    <div className={inputContainerClasses}>
      <input
        id={id}
        name={name}
        type={type}
        min={min}
        max={max}
        onKeyDown={(event) => {
          if(integer && event.key ==='.'){event.preventDefault();}}
        }
        onWheel={(event) => event.currentTarget.blur()}
        className={inputClasses}
        style={style}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        ref={refFn}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
      ></input>
      {errorMessage && <p className="input_error-message">{errorMessage}</p>}
    </div>
  )
}

export default AMIInput;