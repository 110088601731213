import React from 'react';
import { CodeBlock } from "react-code-blocks";
import { exampleErrorResponses, exampleSuccessResponse, headerCode } from './ApiTrackingCodeBlocks';
import ApiTableRow from '../api-table-row/ApiTableRow';
import { codeBlockTheme } from '../utilities';

const ApiTrackingPage: React.FC<{
  data: any
  searchValue: string
  currentApiElement: any
}> = ({
  data,
  searchValue,
  currentApiElement
}) => {

  return (
    <div className="api-tracking-page">
      <h2>Tracking</h2>
      <p>The tracking endpoint will return tracking information for a booking.</p>
      <div className="api-url-box">
        <strong>POST</strong> /public/v1/track/&#123;&#123;shipmentId&#125;&#125;/&#123;&#123;parcelNumber&#125;&#125;
      </div>

{/* Header Parameters */}
      <div className="api-grid">
        <h2 id="tracking-header-schema">Tracking Request Header Parameters</h2>

        <div className="api-grid__table">
          <div className="api-grid__table__head">
            <h3>Parameter</h3>
            <h3>Constraint / Description</h3>
          </div>

          <div className="api-grid__table__body">

            {data && data.headers?.map((row: any, index: number) => {
              return (
                <ApiTableRow
                  key={index + row.title}
                  rowInfo={row}
                  searchValue={searchValue}
                  currentApiElement={currentApiElement}
                ></ApiTableRow>
              )
            })}
          </div>
        </div>

{/* ResponseSuccess */}
          <h2 id="tracking-response-success-schema">Tracking Response Success</h2>

          <div className="api-grid__table">
            <div className="api-grid__table__head">
              <h3>Parameter</h3>
              <h3>Constraint / Description</h3>
            </div>

            <div className="api-grid__table__body">

            {data && data.responseSuccess?.map((row: any, index: number) => {
              return (
                <ApiTableRow
                  key={index + row.title}
                  rowInfo={row}
                  searchValue={searchValue}
                  currentApiElement={currentApiElement}
                ></ApiTableRow>
              )
            })}
          </div>
        </div>

{/* ResponseError */}
          <h2 id="tracking-response-error-schema">Tracking Response Errors</h2>

          <div className="api-grid__table__head">
            <h3>Error Code</h3>
            <h3>Constraint / Description</h3>
          </div>
          {data && data.responseError?.map((row: any, index: number) => {
            return (
              <React.Fragment key={index + row.title}>
                <div className="api-grid__table api-grid__table--error-response">
                  <div className="api-grid__table__body">
                    <ApiTableRow
                      rowInfo={row}
                      searchValue={searchValue}
                      currentApiElement={currentApiElement}
                    ></ApiTableRow>
                  </div>
                </div>
                <div className="api-grid__example api-grid__example--error-response">
                  {/* @ts-ignore */}
                  <CodeBlock
                    //@ts-ignore
                    text={exampleErrorResponses[row.title]}
                    language='javascript'
                    showLineNumbers={true}
                    theme={codeBlockTheme}
                  />
                </div>
              </React.Fragment>
            )
          })}

        <div className="api-grid__example api-grid__example--header">
          <h3>Example Header</h3>
          {/* @ts-ignore */}
          <CodeBlock
            //@ts-ignore
            text={headerCode}
            language='javascript'
            showLineNumbers={true}
            theme={codeBlockTheme}
          />
        </div>

        <div className="api-grid__example api-grid__example--success-response-tracking">
          <h3>Example Success Response</h3>
          {/* @ts-ignore */}
          <CodeBlock
            //@ts-ignore
            text={exampleSuccessResponse}
            language='javascript'
            showLineNumbers={true}
            theme={codeBlockTheme}
          />
        </div>
      </div>

    </div>
  )
}

export default ApiTrackingPage;