import React from 'react';
import './Description.scss';

const AMIDescription: React.FC<{
  columns: number
}> = ({
  columns,
  children
}) => {

  return (
    <div
      className="ami-description"
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`
      }}
    >
      {children}
    </div>
  )
}

export default AMIDescription;