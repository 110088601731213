import React, { useEffect, useState } from 'react';
import './TemplateModalCommodities.scss';
import Commodity from '../../../../single-booking/details-page/commodities-card/commodities/commodity/Commodity';
import { ImperialMetric } from '../../../../../features/bookingSlice';
import { Icon } from '../../../../../ui-components/UiComponents';
import { TemplateForm } from '../../../../../utilities/TemplateHandler';

const TemplateModalCommodities: React.FC<{
  template: TemplateForm;
  updateCommodities: (value: any) => void;
}> = ({
  template,
  updateCommodities
}) => {

  const data = template.commodities;
  const templatePieces = template.piecesSection.pieces;
  const value = +template.piecesSection.totalShipmentValue;

  const [listWeight, setListWeight] = useState(0);
  const [listValue, setListValue] = useState(0);
  const [weight, setWeight] = useState(0);

  useEffect(() => {
    let listWeight = 0;
    let listValue = 0;
    let weight = 0;

    for (const commodity of data) {
      if (commodity.weight.value) {
        listWeight += +commodity.weight.value;
      }

      listValue += +commodity.quantity.value * +commodity.unitPrice.value;
    }

    for (const piece of templatePieces) {
      weight += +piece.weight;
    }

    setListWeight(+listWeight.toFixed(2));
    setListValue(+listValue.toFixed(2));
    setWeight(+weight.toFixed(2));

  }, [data])

  const copyCommodity = (commodity: any, index: number) => {
    const commodityCopy = {...commodity};
    const commoditiesCopy = [...data];
    commoditiesCopy.splice(index + 1, 0, commodityCopy);
    updateCommodities(commoditiesCopy);
  }

  const deleteCommodity = (index: number) => {
    if (data.length > 1) {
      const commoditiesCopy = [...data];
      commoditiesCopy.splice(index, 1);
      updateCommodities(commoditiesCopy);
    }
  }

  const handleAddCommodity = () => {
    const commoditiesCopy = [...data, {
      commodityCode: '',
      description: '',
      weight: {
        value: '',
        unit: template.piecesSection.imperialMetric === ImperialMetric.IMPERIAL
          ? 'LB'
          : 'KG'
      },
      quantity: {
        value: '',
        unit: 'PCS'
      },
      unitPrice: {
        value: '',
        currency: 'USD'
      }
    }];

    updateCommodities(commoditiesCopy);
  }

  return (
    <div className="template-modal-commodities">
      {template.commodities.map((commodity: any, index: number) => {
        return (
          <div key={index}>
            <Commodity
              commodity={commodity}
              index={index}
              isRequired={false}
              templateCurrency={template.piecesSection.preferredCurrency}
              template={template}
              updateCommodities={updateCommodities}
              onCopy={() => copyCommodity(commodity, index)}
              onDelete={() => deleteCommodity(index)}
            ></Commodity>
          </div>
        )
      })}

      <div className="template-modal-commodities__sub-commodities-container">
        <div
          className="add-commodity-icon"
          onClick={handleAddCommodity}
        >
          <Icon name="Close" color="var(--primary)" />Add Commodity
        </div>

        <div className="template-modal-commodities__sub-commodities-container__totals-grid">
          <p><span>Weight:</span><br />{weight.toFixed(2)}{template.piecesSection.imperialMetric === ImperialMetric.IMPERIAL ? "lb" : "kg"}</p>
          <p><span>Value:</span><br />{template.piecesSection.preferredCurrency.symbol}{value.toFixed(2)}</p>
          <p className={
            listWeight === weight
              ? 'commodity-total'
              : 'commodity-total-red'
            }><span>List Weight:</span><br />{listWeight.toFixed(2)}{template.piecesSection.imperialMetric === ImperialMetric.IMPERIAL ? "lb" : "kg"}</p>
          <p className={
            +listValue === value
              ? 'commodity-total'
              : 'commodity-total-red'
            }><span>List Total Value:</span><br />{template.piecesSection.preferredCurrency.symbol}{listValue.toFixed(2)}</p>
        </div>

      </div>
    </div>
  )
}

export default TemplateModalCommodities;