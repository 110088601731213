import React, { useState } from 'react';
import { AMICheckbox, AMIFormElement, AMIInput, AMIPopover, AMISelect, Icon } from '../../../../../ui-components/UiComponents';
import { useAppSelector } from '../../../../../app/hooks';
import { appDataSelector } from '../../../../../features/appDataSlice';
import { reasonsForExport } from '../../../../single-booking/details-page/shipment-details/utilities';
import { selectTitleFormatter } from '../../../../../utilities/HelperUtilities';
import { TemplateForm } from '../../../../../utilities/TemplateHandler';

const TemplateModalShipmentDetails: React.FC<{
  template: TemplateForm;
  updateShipmentDetails: (field: string, value: any) => void;
}> = ({
  template,
  updateShipmentDetails
}) => {

  const appData = useAppSelector(appDataSelector);
  const data = template.shipment;

  const [isDdpPopoverVisible, setIsDdpPopoverVisible] = useState(false);

  return (
    <div className="template-modal-shipment-details">
      <AMIFormElement
        label="Reason for Import/Export"
      >
        <AMISelect
          name="reason-for-export"
          size="large"
          options={reasonsForExport}
          defaultValue={{
            title: selectTitleFormatter(data.reasonForExport),
            value: data.reasonForExport
          }}
          onChange={(event) => updateShipmentDetails("reasonForExport", event.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Country of Origin"
      >
        <AMISelect
          name="country-of-origin"
          size="large"
          isSearchable
          options={appData.countries}
          defaultValue={data.countryOfOrigin ? data.countryOfOrigin : template.countries.origin}
          onChange={(event) => updateShipmentDetails("countryOfOrigin", event)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="HAWB Reference"
      >
        <AMIInput
          name="hawb-ref"
          size="large"
          value={data.hawb}
          onChange={(event) => updateShipmentDetails("hawb", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Contents Description"
      >
        <AMIInput
          name="contents-description"
          size="large"
          value={data.contentDescription}
          onChange={(event) => updateShipmentDetails("contentDescription", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement className="shipment-details__form-element shipment-details__checkbox">
        <AMICheckbox
          text=""
          checked={data.ddp}
          onChange={() => updateShipmentDetails("ddp", !data.ddp)}
        >
          <div>
            Deliver DDP
            <span
              onMouseEnter={() => setIsDdpPopoverVisible(true)}
              onMouseLeave={() => setIsDdpPopoverVisible(false)}
            >
              <Icon
                name="CircleQuestion"
                color="#932682"
                style={{marginLeft: '4px'}}
              />
              <AMIPopover
                isVisible={isDdpPopoverVisible}
                style={{maxWidth: "500px"}}
              >
                <div>
                  <h4>DHL</h4>
                  <p>DDP administration is charged at £16. If the duties and taxes amount to be charged is over £800 then there is an additional charge of 2% of that amount minus the £16 fee, which is billed on a supplementary invoice.</p>
                  <h4>Non-DHL quotes</h4>
                  <p>Check this box to be invoiced separately the import customs charges due for this shipment.</p>
                </div>
              </AMIPopover>
            </span>
          </div>
        </AMICheckbox>
      </AMIFormElement>

      <AMIFormElement className="shipment-details__form-element shipment-details__checkbox">
        <AMICheckbox
          text="Insure Delivery"
          checked={data.insure}
          onChange={() => updateShipmentDetails("insure", !data.insure)}
        />
      </AMIFormElement>
    </div>
  )
}

export default TemplateModalShipmentDetails;