import React, {useState, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import Icon from '../icon/Icon';
import './DatePicker.scss';
import {
  getMonthDetails,
  todayTimestamp,
  isCurrentDay,
  getMonthString,
  formatDisabledDates,
  getLastFutureDay,
  calculateRealEndDate
} from './utility';
import dayjs from 'dayjs';
import { preventSpaceBarScrolling } from '../../utilities/HelperUtilities';

interface Day {
  date: number,
  day: number,
  dayString: string,
  month: number,
  calenderMonth: number,
  timestamp: number
}

const Mask: React.FC<{onClick: () => void}> = ({onClick}) => {
  return <div className="full-screen-mask" onClick={onClick}/>
}

const AMIDatePicker: React.FC<{
  size?: 'large',
  style?: React.CSSProperties,
  className?: string,
  id?: string,
  disabled?: boolean,
  disabledDates?: any[],
  numFutureDays?: number,
  datePicked?: number,
  allowPastDates?: boolean,
  allowWeekends?: boolean,
  placeholder?: string,
  onChange: (date: number) => void
}> = ({
  size,
  style,
  className,
  id,
  disabled,
  disabledDates,
  numFutureDays,
  datePicked,
  allowPastDates = false,
  allowWeekends = false,
  placeholder,
  onChange
}) => {

  const inputRef = useRef<HTMLInputElement>(null);

  const date = datePicked
    ? new Date(datePicked)
    : new Date();

  const [month, setStateMonth] = useState<any>(date.getMonth());
  const [year, setStateYear] = useState<any>(date.getFullYear());
  const [monthDetails, setMonthDetails] = useState<any>([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDay, setSelectedDay] = useState(todayTimestamp);
  const [formattedDisabledDates, setFormattedDisabledDates] = useState<any>();
  const [realNumFutureDays, setRealNumFutureDays] = useState<number>();

  useEffect(() => {
    setMonthDetails(getMonthDetails(year, month));
    if (disabledDates) {
      setFormattedDisabledDates(formatDisabledDates(disabledDates));
    }
    setRealNumFutureDays(calculateRealEndDate(disabledDates, numFutureDays));
    if (datePicked) {
      setDateToInput(datePicked);
    }
  }, [])

  useEffect(() => {
    setDateToInput(datePicked);
  }, [datePicked])

  const getClasses = () => {
    let classes = `${className} ami-date-picker__input `;

    if (disabled) {
      classes = classes.concat('ami-date-picker__input--disabled ');
    }

    if (size === 'large') {
      classes = classes.concat('ami-date-picker__input--large ');
    }

    return classes;
  }

  const inputClasses = getClasses();

  const isSelectedDay = (day: Day) => {
    return day.timestamp === selectedDay;
  }

  const setDateToInput = (timestamp: number | undefined) => {
    if (!timestamp && inputRef && inputRef.current) {
      inputRef.current.value = '';
      return;
    }

    if (timestamp && inputRef && inputRef.current) {
      inputRef.current.value = new Intl.DateTimeFormat('en-GB', { dateStyle: 'full'}).format(timestamp);
    }
  }

  const onDateClick = (day: Day, keyCode?: string) => {
    const isAConfirmKey = keyCode === 'Enter' || keyCode === 'NumpadEnter' || keyCode === 'Space';

    if (!keyCode || isAConfirmKey) {
      setSelectedDay(day.timestamp);
      setDateToInput(day.timestamp);
      onChange(day.timestamp);
      setShowDatePicker(false);
    }
  }

  const setYear = (offset: number) => {
    let letYear = year + offset;
    let letMonth = month;
    setStateYear(letYear);
    setMonthDetails(getMonthDetails(letYear, letMonth));
  }

  const setMonth = (offset: number) => {
    let letYear = year;
    let letMonth = month + offset;
    if (letMonth === -1) {
      letMonth = 11;
      letYear--;
    } else if (letMonth === 12) {
      letMonth = 0;
      letYear++;
    }
    setStateYear(letYear);
    setStateMonth(letMonth);
    setMonthDetails(getMonthDetails(letYear, letMonth));
  }

  const handleDatePickerClick = (keyCode?: string) => {
    if (!disabled) {
      const isAConfirmKey = keyCode === 'Enter' || keyCode === 'NumpadEnter' || keyCode === 'Space';
      if (!keyCode || isAConfirmKey) {
        if (datePicked) setSelectedDay(datePicked);
        setShowDatePicker(true);
      }
    }
  };

  const disablePreviousDay = (day: Day) => {
    if (allowPastDates) {
      return false;
    }

    if (dayjs(day.timestamp).isBefore(dayjs(), 'day')) {
      return true;
    }
  }

  const disableFutureDay = (day: Day) => {
    const isTodayOrAfter = day.timestamp >= new Date().getTime();
    const isAfterLastFutureDay = day.timestamp > getLastFutureDay(realNumFutureDays);
    return isTodayOrAfter && isAfterLastFutureDay
  }

  const disableWeekendDay = (day: Day) => {
    if (allowWeekends) {
      return false;
    }
    return day.dayString === 'Saturday' || day.dayString === 'Sunday';
  }

  const renderCalendar = () => {
    let days = monthDetails.map((day: Day, index: number) => {
      return (
        <div
          id={`calender-day-container-${day.date}-${day.calenderMonth}`}
          className={
            `calender-day-container `
            + (
                formattedDisabledDates?.includes(day.timestamp)
                || disablePreviousDay(day)
                || disableFutureDay(day)
                || disableWeekendDay(day)
                  ? ' disabled'
                  : ''
              )
            + (isCurrentDay(day) ? ' highlight' : '')
            + (isSelectedDay(day) ? ' highlight-pink' : '')
          }
          key={index}
        >
          <div className='calender-day-container__day'>
            <span
              onClick={() => onDateClick(day)}
              tabIndex={
                formattedDisabledDates?.includes(day.timestamp)
                || disablePreviousDay(day)
                || disableFutureDay(day)
                || disableWeekendDay(day)
                  ? undefined
                  : 0
              }
              onKeyDown={(event) => preventSpaceBarScrolling(event)}
              onKeyUp={(event) => onDateClick(day, event.code)}
            >
              {day.date}
            </span>
          </div>
        </div>
      )
    })

    return (
      <div className='calender-container'>
        <div className='calender-container__head'>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((d,i) => {
            return <div key={i} className='calender-container__head__name'>{d}</div>
          })}
        </div>
        <div className='calender-container__body'>
          {days}
        </div>
      </div>
    )
  }

  return (
    <div className='ami-date-picker'>
      <div
        className={inputClasses}
        id={id}
        onClick={(event) => handleDatePickerClick()}
        onKeyDown={(event) => preventSpaceBarScrolling(event)}
        onKeyUp={(event) => handleDatePickerClick(event.code)}
      >
        <input
          type="text"
          placeholder={placeholder}
          id="date-picker-input-element"
          style={style}
          ref={inputRef}
          readOnly={true}
          autoComplete="off"
        />
      </div>

      {showDatePicker
      && ReactDOM.createPortal(
        <Mask onClick={() => setShowDatePicker(false)} />,
        document.getElementById('full-screen-mask') as Element
      )
      }
      {showDatePicker ? (
        <div className='ami-date-picker__container'>
          <div className='ami-date-picker__container__head'>
            <div className='ami-date-picker__container__head__button'>
              <div className='ami-date-picker__container__head__button__inner' onClick={()=> setYear(-1)}>
                <Icon name="ChevronLeftDouble"/>
              </div>
            </div>
            <div className='ami-date-picker__container__head__button'>
              <div className='ami-date-picker__container__head__button__inner' id='ami-date-picker-back-month' onClick={()=> setMonth(-1)}>
                <Icon name="ChevronLeft"/>
              </div>
            </div>
            <div className='ami-date-picker__container__head__container'>
              <div className='ami-date-picker__container__head__container--year'>{year}</div>
              <div className='ami-date-picker__container__head__container--month'>{getMonthString(month)}</div>
            </div>
            <div className='ami-date-picker__container__head__button'>
              <div className='ami-date-picker__container__head__button__inner' id='ami-date-picker-forward-month' onClick={()=> setMonth(1)}>
              <Icon name="ChevronRight"/>
              </div>
            </div>
            <div className='ami-date-picker__container__head__button' onClick={()=> setYear(1)}>
              <div className='ami-date-picker__container__head__button__inner'>
              <Icon name="ChevronRightDouble"/>
              </div>
            </div>
          </div>
        <div className='ami-date-picker__container__body'>
            {renderCalendar()}
        </div>
      </div>
      ) : ''}
    </div>
  )
}

export default AMIDatePicker;