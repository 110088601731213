import React, { useState } from 'react';
import './WlBannerEdit.scss';
import { AMIAlert, AMIButton, AMIDatePicker, AMIFormElement, AMIInput, AMISelect } from '../../../../../../ui-components/UiComponents';
import { Banner } from '../../../../../../utilities/ContentRetrieverUtilities';
import dayjs from 'dayjs';

const WlBannerEdit: React.FC<{
  banner: Banner;
  save: (banner: Banner) => Promise<boolean>;
  close: () => void;
}> = ({
  banner,
  save,
  close
}) => {

  const [errorMessage, setErrorMessage] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [alert, setAlert] = useState({
    title: banner.title,
    variant: banner.config.announcementType,
    page: banner.page,
    displayFrom: banner.config.displayFrom,
    displayUntil: banner.config.displayUntil,
    content: banner.content
  })

  const onDisplayFromChange = (event: number) => {
    const date = dayjs(event).format('YYYY-MM-DD')
    setAlert({...alert, displayFrom: date})
  }

  const onDisplayUntilChange = (event: number) => {
    const date = dayjs(event).format('YYYY-MM-DD')
    setAlert({...alert, displayUntil: date})
  }

  const onSave = async() => {
    if (
      alert.title
      && alert.displayFrom
      && alert.displayUntil
      && alert.content
    ) {
      setIsSpinning(true);
      const success = await save({
        id: banner.id,
        title: alert.title,
        content: alert.content,
        config: {
          announcementType: alert.variant,
          displayFrom: alert.displayFrom,
          displayUntil: alert.displayUntil
        },
        page: alert.page
      });
      if (success) close();
      else {
        setErrorMessage("Banner failed to save, please try again.");
        setIsSpinning(false);
      }
    } else {
      setErrorMessage("Please populate all fields to continue.")
    }
  }

  return (
    <div className="wl-banner-edit">

      {errorMessage && (
        <AMIAlert
          variant="error"
          style={{marginBottom: "24px"}}
        >
          <p>{errorMessage}</p>
        </AMIAlert>
      )}

      <form>
        <AMIFormElement
          label="Title"
        >
          <AMIInput
            name="title"
            size="large"
            placeholder="Required"
            value={alert.title}
            onChange={(event) => setAlert({...alert, title: event.currentTarget.value})}
          />
        </AMIFormElement>

        <div>
          <h4>Preview</h4>
          <AMIAlert
            variant={alert.variant}
          >
            <>
              <p><strong>{alert.title}</strong></p>
              <p>{alert.content}</p>
            </>
          </AMIAlert>
        </div>

        <AMIFormElement
          label="Display Page"
        >
          <AMISelect
            name="page"
            size="large"
            hideClearButton
            defaultValue={{title: alert.page!, value: alert.page!}}
            options={[
              {title: 'home', value: 'home'},
              {title: 'quote', value: 'quote'}
            ]}
            onChange={(event) => setAlert({...alert, page: event.value})}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Banner Type"
          style={{gridColumn: "1"}}
        >
          <AMISelect
            name="page"
            size="large"
            hideClearButton
            defaultValue={{title: alert.variant, value: alert.variant}}
            options={[
              {title: 'warning', value: 'warning'},
              {title: 'info', value: 'info'}
            ]}
            onChange={(event) => setAlert({...alert, variant: event.value})}
          />
        </AMIFormElement>

        <div className="wl-banner-edit__dates">
          <AMIFormElement
            label="Display From"
          >
            <AMIDatePicker
              size="large"
              placeholder="Required"
              datePicked={+new Date(alert.displayFrom)}
              onChange={onDisplayFromChange}
            />
          </AMIFormElement>

          <AMIFormElement
            label="Display Until"
          >
            <AMIDatePicker
              size="large"
              placeholder="Required"
              datePicked={+new Date(alert.displayUntil)}
              onChange={onDisplayUntilChange}
            />
          </AMIFormElement>
        </div>

        <AMIFormElement
          label="Content"
          style={{gridColumn: "1/3"}}
        >
          <textarea
            placeholder="Required. Add content here ..."
            value={alert.content}
            onChange={(event) => setAlert({...alert, content: event.currentTarget.value})}
          >
          </textarea>
        </AMIFormElement>


      </form>

      <div className="wl-banner-edit__btns">
        <AMIButton
          size="large"
          onClick={close}
        >
          Cancel
        </AMIButton>
        <AMIButton
          size="large"
          variant="primary"
          isSpinning={isSpinning}
          onClick={onSave}
        >
          Save
        </AMIButton>
      </div>
    </div>
  )
}

export default WlBannerEdit;