import React from 'react';

const ApiAuthPage = () => {

  return (
    <div className="api-auth-page">
      <h2>Authentication</h2>
      <p>This endpoint is required to authenticate prior to calling the rating, booking or retrieve label API end points.</p>
      <p>While registering to use the API, AMI will provide you with a Client ID and Secret.</p>
      <p>Once you have secured the API credentials, the authentication end point is used to get an access token. This access token is used as credentials with each API transaction.</p>

      <h3>Integration Steps</h3>
      <p>To integrate with AMI&apos;s API you will need to follow these steps:</p>
      <ol>
        <li>Register a staging account and API credentials.</li>
        <li>Develop your integration solution for your system against the staging environment.</li>
        <li>Once you&apos;re happy your solution is working request live credentials from your technical integration contact.</li>
      </ol>

      <h3>Purpose</h3>
      <p>Use this endpoint to request the access token to authorize your application to access AMI end points - rating, booking and retrieve label.</p>

      <h3>End Points</h3>

      <p>Pleas contact AMI for authentication end points.</p>

      <h3>HTTP Status Codes</h3>

      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Message</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>200</td>
            <td>OK</td>
            <td>Success: Everything is working</td>
          </tr>
          <tr>
            <td>400</td>
            <td>Bad Request</td>
            <td>The request is not valid and should be followed with an error message </td>
          </tr>
          <tr>
            <td>401</td>
            <td>Unauthorized</td>
            <td>The request requires authentication</td>
          </tr>
          <tr>
            <td>503</td>
            <td>Internal Server Error</td>
            <td>The service/resource is not available. There are external component DOWN issues that the client will not be able to resolve.</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ApiAuthPage;