import type { RefObject } from "react";
import { useState, useEffect } from "react";

export const useIsIframeLoaded = (
  iFrameRef: RefObject<HTMLIFrameElement>
): any => {
  const [isIFrameLoaded, setIsIFrameLoaded] = useState<boolean>(false);
  const iFrameCurrent = iFrameRef.current;

  useEffect(() => {
    iFrameCurrent?.addEventListener('load', () => setIsIFrameLoaded(true));

    return () => {
      iFrameCurrent?.removeEventListener('load', () => setIsIFrameLoaded(true))
    };
  }, [iFrameCurrent]);

  return isIFrameLoaded;
}