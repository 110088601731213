import React from 'react';
import './CarrierContainer.scss';
import { getCarrierImage } from '../../../../utilities/HelperUtilities';

const CarrierContainerFedexOverweight = () => {

  return (
    <div className="carrier-container">
      <div className="carrier vertical-card">
        <div className="carrier__grid">
          <div className="carrier__grid__img-cont">
            <img
              src={getCarrierImage('fedex')}
              alt="Logo"
            />
          </div>

          <div className="carrier__grid__text-content" style={{width: '100%'}}>
            <div className="carrier__grid__text-content__item">
              <p style={{opacity: 1}}>Please contact the spotrate team to get a FedEx quote for this shipment:</p>
              <ul>
                <li style={{opacity: 1}}>0208 564 5000 option 1</li>
                <li><a href = "mailto: expressspotrates@airmenzies.com" style={{opacity: 1}}>expressspotrates@airmenzies.com</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CarrierContainerFedexOverweight;