import React, { useState } from 'react';
import { useAppDispatch } from '../../../../../app/hooks';
import { AMIMultiButton } from '../../../../../ui-components/UiComponents';
import { appDataActions, AttachOrUpload } from '../../../../../features/appDataSlice';
import { Booking, ShipmentType } from '../../../../../features/bookingSlice';
import UploadDocument from '../upload-document/UploadDocument';
import {extractRule} from "../../../../../utilities/RulesEngineUtilities";

const InvoiceCardOwnInvoice: React.FC<{
  invoiceData: any,
  booking: Booking,
  showErrors: boolean,
  errHandler: any,
  customRules: any
}> = ({
  invoiceData,
  booking,
  showErrors,
  errHandler,
  customRules
}) => {

  const dispatch = useAppDispatch();

  return (
    <>
      {/* DOCS */}
      {booking.shipmentType !== ShipmentType.NON_DOCS || !extractRule(customRules, 'eTrade')?.value
        &&
          <div>
              <p>Electronic Trade is currently not supported on this trade route.</p>
              <p>Please attach your invoice to the shipment.</p>
          </div>
      }

      {/* NON_DOCS */}
      {booking.shipmentType === ShipmentType.NON_DOCS && extractRule(customRules, 'eTrade')?.value &&
        extractRule(customRules, 'eTrade')?.value
        && (
        <>
          <p style={{marginBottom: '24px'}}>Please choose if you wish to attach your invoice to the shipment yourself or if you wish to upload your invoice to us:</p>
          <AMIMultiButton
            buttons={[
              {title: 'I will ATTACH to the shipment', value: AttachOrUpload.ATTACH_TO_SHIPMENT},
              {title: 'I want to UPLOAD the invoice', value: AttachOrUpload.UPLOAD}
            ]}
            selected={{
              title: invoiceData.attachOrUpload,
              value: invoiceData.attachOrUpload
            }}
            style={{
              maxWidth: '484px',
              marginBottom: '12px'
            }}
            onClick={(event: any) => dispatch(appDataActions.updateInvoiceDetail({
              field: 'attachOrUpload',
              value: event.value
            }))}
          />

          {invoiceData.attachOrUpload === AttachOrUpload.UPLOAD && (
            <div className="upload-invoice-container">
              <p>Please select your document type and then either drag and drop a document into the box below or click on Upload to select a file.</p>
              <br />
              <p>Invoices must match the details that you supplied above.</p>
              <br />
              <p>Document must be of type PDF, PNG, GIF, TIFF or JPEG and have a maximum file size of 2MB</p>

              <UploadDocument option="UPLOAD" />

              {showErrors
              && !errHandler.invoice.uploadedInvoice.criteria
              && <p
                style={{color: 'var(--error)'}}
              >{errHandler.invoice.uploadedInvoice.message}</p>}

            </div>
          )}
        </>
      )}
    </>
  )

}

export default InvoiceCardOwnInvoice;