import { Booking } from "../../../../features/bookingSlice";

export const times = [
  {title: '06:00', value: '06:00'},
  {title: '06:15', value: '06:15'},
  {title: '06:30', value: '06:30'},
  {title: '06:45', value: '06:45'},
  {title: '07:00', value: '07:00'},
  {title: '07:15', value: '07:15'},
  {title: '07:30', value: '07:30'},
  {title: '07:45', value: '07:45'},
  {title: '08:00', value: '08:00'},
  {title: '08:15', value: '08:15'},
  {title: '08:30', value: '08:30'},
  {title: '08:45', value: '08:45'},
  {title: '09:00', value: '09:00'},
  {title: '09:15', value: '09:15'},
  {title: '09:30', value: '09:30'},
  {title: '09:45', value: '09:45'},
  {title: '10:00', value: '10:00'},
  {title: '10:15', value: '10:15'},
  {title: '10:30', value: '10:30'},
  {title: '10:45', value: '10:45'},
  {title: '11:00', value: '11:00'},
  {title: '11:15', value: '11:15'},
  {title: '11:30', value: '11:30'},
  {title: '11:45', value: '11:45'},
  {title: '12:00', value: '12:00'},
  {title: '12:15', value: '12:15'},
  {title: '12:30', value: '12:30'},
  {title: '12:45', value: '12:45'},
  {title: '13:00', value: '13:00'},
  {title: '13:15', value: '13:15'},
  {title: '13:30', value: '13:30'},
  {title: '13:45', value: '13:45'},
  {title: '14:00', value: '14:00'},
  {title: '14:15', value: '14:15'},
  {title: '14:30', value: '14:30'},
  {title: '14:45', value: '14:45'},
  {title: '15:00', value: '15:00'},
  {title: '15:15', value: '15:15'},
  {title: '15:30', value: '15:30'},
  {title: '15:45', value: '15:45'},
  {title: '16:00', value: '16:00'},
  {title: '16:15', value: '16:15'},
  {title: '16:30', value: '16:30'},
  {title: '16:45', value: '16:45'},
  {title: '17:00', value: '17:00'},
  {title: '17:15', value: '17:15'},
  {title: '17:30', value: '17:30'},
  {title: '17:45', value: '17:45'},
  {title: '18:00', value: '18:00'},
  {title: '18:15', value: '18:15'},
  {title: '18:30', value: '18:30'},
  {title: '18:45', value: '18:45'},
  {title: '19:00', value: '19:00'}
]

export const checkCollectionTimesAreValid = (booking: Booking, timeWindow: number) => {
  let pickUpFromIndex = times.findIndex((time) => {
    return booking.collectionDetails.pickUpFrom === time.value
  });

  if (pickUpFromIndex === -1) {
    pickUpFromIndex = 0;
  }

  const pickUpToIndex = times.findIndex((time) => {
    return booking.collectionDetails.pickUpTo === time.value
  });
  const selectedTimes = times.slice(pickUpFromIndex, pickUpToIndex).map((time) => {
    return time.value;
  });

  let quotePickUpFromIndex = times.findIndex((time) => {
    return booking.selectedQuote.pickupEarliestTime === time.value
  });

  if (quotePickUpFromIndex === -1) {
    quotePickUpFromIndex = 0;
  }

  const quotePickUpToIndex = times.findIndex((time) => {
    return booking.selectedQuote.pickupLatestTime === time.value
  });
  const quoteTimes = times.slice(quotePickUpFromIndex, quotePickUpToIndex);

  let count = 0;
  for (const time of quoteTimes) {
    if (selectedTimes.includes(time.value)) {
      count++;
    }
  }

  return count < timeWindow ? true : false;
}