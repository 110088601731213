export const trackingData = `{
  "tracking": {
    "headers": [
      {
        "title": "party-id",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "A unique value assigned to each customer account."
        ]
      },
      {
        "title": "client-id",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "The client ID provided when registering for the API."
        ]
      },
      {
        "title": "x-api-key",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "The api key provided when registering for the API."
        ]
      },
      {
        "title": "authorization",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string(bearer-token)"
          },
          {
            "title": "Allowed Values",
            "content": "Bearer <generated access token>"
          }
        ],
        "descriptions": [
          "The client ID provided when registering for the API."
        ]
      },
      {
        "title": "Content-Type",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "application/json"
          }
        ],
        "descriptions": []
      }
    ],
    "responseSuccess": [
      {
        "title": "200 OK",
        "criteria": [
          {
            "title": "Type",
            "content": "object"
          }
        ],
        "descriptions": [
          "A successful response provides tracking information"
        ],
        "subRows": [
          {
            "title": "trackingNumber",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "The tracking number passed in."
            ]
          },
          {
            "title": "parcelNumber",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "The parcel number"
            ]
          },
          {
            "title": "consignmentNumber",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "The consignment number."
            ]
          },
          {
            "title": "deliveryAddress1",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Address line 1 of the delivery address."
            ]
          },
          {
            "title": "deliveryAddress2",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Address line 2 of the delivery address."
            ]
          },
          {
            "title": "deliveryCity",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Delivery city."
            ]
          },
          {
            "title": "deliveryCounty",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Delivery county."
            ]
          },
          {
            "title": "deliveryPostcode",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Delivery postal code."
            ]
          },
          {
            "title": "deliveryCountryCode",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Delivery country or territory code."
            ]
          },
          {
            "title": "trackingEvents",
            "criteria": [
              {
                "title": "Type",
                "content": "array"
              }
            ],
            "descriptions": [
              "An array of tracking event objects."
            ],
            "subRows": [
              {
                "title": "zonedDateTime",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Date and time of event update."
                ]
              },
              {
                "title": "code",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Event code"
                ]
              },
              {
                "title": "type",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Type of event"
                ]
              },
              {
                "title": "description",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Human readable description of event."
                ]
              },
              {
                "title": "locality",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Locality of the event."
                ]
              },
              {
                "title": "countryCode",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Country or territory code of the event."
                ]
              }
            ]
          }
        ]
      }
    ],
    "responseError": [
      {
        "title": "400",
        "descriptions": [
          "We hold no tracking information for that shipment"
        ]
      }
    ]
  }
}`