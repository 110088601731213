import React from "react";
import Icon from "../../ui-components/icon/Icon";
import './Step.scss';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { PageStatus } from "../../features/appDataSlice";

const Step: React.FC<{
  title: string,
  status: PageStatus | string | undefined,
  isActive: boolean,
  clickable: boolean,
  lines: boolean,
  onClick: () => void
}> = ({
  title,
  status,
  isActive,
  clickable,
  lines,
  onClick
}) => {

  const lineStyle = status === PageStatus.TODO ? {color: 'var(--textMid)'} : {color: 'var(--secondary)'};

  const {width} = useWindowDimensions();

  return (
    <>
      {lines
      &&  <div
            className="step__dashes"
            style={lineStyle}
          >------------
          </div>
      }

      <div
        onClick={onClick}
        style={
          clickable === false ? {pointerEvents: 'none'} : {}
        }
      >
        {isActive
        &&  <div className="step">
              <div className="step__title step__title--active">{title}</div>
              <Icon
                name="PlaneSolid"
                style={{
                  width: width < 901 ? '34' : '43px',
                  height: '30px',
                  fill: 'var(--primary)'
                }}
              />
            </div>
        }

        {!isActive
        && status !== PageStatus.COMPLETE
        &&  <div className="step">
              <div className="step__title step__title--todo">{title}</div>
              <Icon
                name="PlaneOutline"
                style={{
                  width: width < 901 ? '30' : '35.5px',
                  height: '30px',
                  fill: 'var(--textMid)'
                }}
              />
            </div>}

        {!isActive
        && status === PageStatus.COMPLETE
        &&  <div className="step">
              <div className="step__title step__title--complete">{title}</div>
              <Icon
                name="CircleCheck"
                style={{
                  width: width < 901 ? '28px' : '30px',
                  height: '30px',
                  fill: 'var(--secondary)'
                }}
              />
            </div>
        }
      </div>
    </>
  )
};

export default Step;