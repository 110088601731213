import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import ShipperDetails from "../pages/single-booking/details-page/shipper-details/ShipperDetails";
import { PackageLocation } from "../utilities/APIUtilities";
import { removeUnwantedFields } from "../utilities/HelperUtilities";

export interface Currency {
  title: string;
  value: string;
  symbol: string;
}

export enum ImperialMetric {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC'
}

export enum ShipmentType {
  NON_DOCS = 'NON_DOCS',
  DOCS = 'DOCS',
  ENVELOPE = 'ENVELOPE'
}

export enum Insure {
  INSURE = 'INSURE',
  DO_NOT_INSURE = 'DO_NOT_INSURE'
}
export interface Piece {
  weight: string;
  length: string;
  width: string;
  height: string;
}

export enum AddressType {
  BUSINESS = 1,
  RESIDENTIAL = 2
}

export enum TermsOfSale {
  DDP = 'DDP',
  DAP = 'DAP'
}

export interface CollectionDetails {
  pickUpFrom: string;
  pickUpTo: string;
  contactName: string;
  companyName: string;
  addressType: AddressType;
  packageLocation: PackageLocation;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  countyStateProvince: string;
  cityTown: string;
  postalCode: string;
  telephoneNumber: string;
  email: string;
  additionalInfo: string
}
export interface DropInDetails {
  id: string;
  openFrom: string;
  openTo: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  countyStateProvince: string;
  cityTown: string;
  postalCode: string;
  countryCode: string;
  telephoneNumber: string;
}

export interface CustomDropIn {
  address: {
    city: string,
    countryCode: string,
    postalCode: string,
    residential: boolean,
    stateOrCounty: string,
    streetLines: []
  };
  addressAncillaryDetail: {
    displayName: string
  };
  contactDetails: {
    telephone: string
  };
  storeHours: [];
  carrier: string
}

export interface ShipperDetails {
  contactName: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  countyStateProvince: string;
  cityTown: string;
  postalCode: string;
  country: Country;
  telephoneNumber: string;
  email: string;
  taxNumbers: [
    {
      type: string,
      value: string
    }
  ];
  exportComplianceStatement: {
    aes: string,
    b13AFilingOption: string,
    edn: string,
    ccn: string,
    exporterCode: string
  }
  generateECS: boolean;
}

export interface ConsigneeDetails {
  contactName: string;
  companyName: string;
  addressType: AddressType;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  countyStateProvince: string;
  cityTown: string;
  postalCode: string;
  telephoneNumber: string;
  email: string;
  sendEmail: boolean;
  taxNumbers: [
    {
      type: string,
      value: string
    }
  ];
  additionalInfo: string
}

export interface BrokerDetails {
  brokerSelected: boolean;
  contactName: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  countyStateProvince: string;
  cityTown: string;
  country: {
    title: string,
    value: string,
    tradeBloc: string,
    currencyCode: string,
    currencySymbol: string,
    eTrade_dhl_import: any,
    eTrade_dhl_export: any,
    eTrade_fdx_import: any,
    eTrade_fdx_export: any,
    eTrade_ups_import: any,
    eTrade_ups_export: any
  };
  postalCode: string;
  telephoneNumber: string;
  email: string;
  taxNumbers: [
    {
      type: string,
      value: string
    }
  ];
}

export interface QuoteObj {
  carrierProductCode: string;
  carrierServiceName: string;
  charges: {
    currency: string;
    optional?: {
      value: number;
      currency: string;
      type: string;
    }[];
    required: {
      value: number;
      currency: string;
      type: string;
    }[];
    value: number;
  };
  deliveryDateEstimate: string;
  discount: {
    amount: {
      value: number;
      currency: string;
    };
    rulesApplied: [];
  };
  pickupEarliestTime: string | null,
  pickupLatestTime: string | null,
  quoteId: string;
  rate: {
    value: number;
    currency: string;
  };
  sameDayCollectionCutOffTime?: number;
  serviceType: string;
  totalCost: {
    value: number;
    currency: string;
  };
  transitTimeEstimate?: any;
  transportMethod: TransportMethod;
  weight: {
    actual: {
      unit: string;
      value: number;
    };
    chargeable: {
      unit: string;
      value: number;
    };
  }
  vat: {
    currency: string,
    value: number
  }
}

export interface MappedPiece {
  dimensions: {
    length : number;
    width : number;
    height : number;
    unit : string
  };
  weight : {
    value : number;
    unit : string
  };
  description?: string | undefined
}

export interface BookingResult {
  alerts: {
    code: string;
    type: string;
    message: string;
  }[];
  amiReference: string;
  shipmentId: string;
  regionId: string;
  consolidated: string;
  consignmentDetailsList: {
    consignmentNumber: string;
    parcelNumbers: string[];
  }[];
  pickupConfirmationCode: string;
  pickupMessage: string;
  label: string;
}

export enum ReasonForExport {
  SOLD = 'SOLD',
  GIFT = 'GIFT',
  SAMPLE = 'SAMPLE',
  REPAIR_AND_RETURN = 'REPAIR_AND_RETURN',
  PERSONAL_EFFECTS = 'PERSONAL_EFFECTS',
  NOT_SOLD = 'NOT_SOLD'
}
export interface Country {
  title: string,
  value: string,
  tradeBloc: string,
  currencyCode: string,
  currencySymbol: string,
  eTrade_dhl_import: any,
  eTrade_dhl_export: any,
  eTrade_fdx_import: any,
  eTrade_fdx_export: any,
  eTrade_ups_import: any,
  eTrade_ups_export: any
}

export enum SpecialServiceType {
  ELECTRONIC_TRADE_DOCUMENTS = 'ELECTRONIC_TRADE_DOCUMENTS',
  INSURANCE = 'INSURANCE',
  DELIVERED_DUTY_PAID = 'DELIVERED_DUTY_PAID',
  UNKNOWN = 'UNKNOWN'
}

export enum ElectronicTradeDocumentType {
  COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE',
  PRO_FORMA_INVOICE = 'PRO_FORMA_INVOICE',
  CERTIFICATE_OF_ORIGIN = 'CERTIFICATE_OF_ORIGIN',
  CUSTOMS_DECLARATION = 'CUSTOMS_DECLARATION',
  UNKNOWN = 'UNKNOWN'
}

export enum ElectronicTradeDocumentFormatType {
  PDF = 'pdf',
  PNG = 'png',
  GIF = 'gif',
  TIFF = 'tiff',
  JPEG = 'jpg',
  JPG = 'jpg'
}

export enum TransportMethod {
  EXPRESS = "EXPRESS",
  ROAD = "ROAD",
  DOMESTIC = "DOMESTIC"
}
export interface Booking {
  origin: Country;
  originCityTown: string;
  originPostalCode: string;
  destination: Country;
  originResidential: boolean;
  destinationCityTown: string;
  destinationPostalCode: string;
  destinationResidential: boolean;
  readyDate: number;
  shipmentType: ShipmentType;
  pieces: Piece[];
  imperialMetric: ImperialMetric;
  totalShipmentValue: string;
  preferredCurrency: Currency;
  quotes: QuoteObj[];
  selectedQuote: QuoteObj;
  insure: boolean;
  hawb: string;
  contentDescription: string;
  countryOfOrigin: Country;
  reasonForExport: string;
  collectionDetails: CollectionDetails;
  dropInDetails: DropInDetails;
  shipperDetails: ShipperDetails;
  trueShipper: boolean;
  consigneeDetails: ConsigneeDetails;
  brokerDetails: BrokerDetails;
  preferences: {
    email: string;
    labelType: string;
  };
  termsCheck: boolean;
  dangerousGoodsCheck: boolean;
  courierInfoCheck: boolean;
  phytosanitaryCheck: boolean;
  specialService: SpecialServiceType;
  customsDetails: {
    amount: {
      value: string,
      currency: string;
    },
    invoiceDetails: {
      declarationStatement: string,
      declarationStatementRequested: {
        value: boolean,
        type: string
      },
      termsOfSale: TermsOfSale,
      taxDetails: [
        {
          type: string,
          party: string,
          value: string
        }
      ],
      exportReason: string,
      declaredCharges: {
        type: string,
        currency: string,
        value: string
      }[],
      cpc: string
    },
    importerOfRecordDetails: {
      deliveryAddress: boolean,
      contactDetails: {
        name: string,
        telephone: string
      },
      address: {
        organisation: string,
        countryCode: string,
        postalCode: string,
        streetLines: [
            string,
            string
        ],
        city: string,
        stateOrCounty: string
      }
    },
    exportDeclaration: {
      declarationNotes: any
    },
    commodities: {
      commodityCode: string,
      description: string,
      countryOfOrigin: string,
      quantity: {
        value: string,
        unit: string
      },
      unitPrice: {
        value: string,
        currency: string
      },
      weight: {
        value: string,
        unit: string
      }
    }[]
  },
  igBookingErrors: {
    code: string,
    message: string,
    fieldName: string
  }[],
  mappedPieces?: MappedPiece[] | any;
  bookingResult?: BookingResult;
  uploadedInvoice: {
    type: ElectronicTradeDocumentType,
    contentType: ElectronicTradeDocumentFormatType,
    documentContent: string,
    electronicTradeDocumentName?: string
  } | null;
  uploadedSignature: {
    signatureTitle: string,
    signatureRef: string,
    signatureImage: string,
    type: ElectronicTradeDocumentType
  } | null;
  freightCharge: string;
}

export const initialState: Booking = {
  origin: {
    title: '',
    value: '',
    tradeBloc: '',
    currencyCode: '',
    currencySymbol: '',
    eTrade_dhl_import: false,
    eTrade_dhl_export: false,
    eTrade_fdx_import: false,
    eTrade_fdx_export: false,
    eTrade_ups_import: false,
    eTrade_ups_export: false
  },
  originCityTown: '',
  originPostalCode: '',
  destination: {
    title: '',
    value: '',
    tradeBloc: '',
    currencyCode: '',
    currencySymbol: '',
    eTrade_dhl_import: false,
    eTrade_dhl_export: false,
    eTrade_fdx_import: false,
    eTrade_fdx_export: false,
    eTrade_ups_import: false,
    eTrade_ups_export: false
  },
  originResidential: false,
  destinationCityTown: '',
  destinationPostalCode: '',
  destinationResidential: false,
  readyDate: 0,
  shipmentType: ShipmentType.NON_DOCS,
  pieces: [
    {
      weight: '',
      length: '',
      width: '',
      height: ''
    }
  ],
  imperialMetric: ImperialMetric.IMPERIAL,
  totalShipmentValue: '',
  preferredCurrency: {
    title: '$ USD',
    value: 'USD',
    symbol: '$'
  },
  quotes: [],
  selectedQuote: {
    carrierProductCode: '',
    carrierServiceName: '',
    charges: {
      currency: '',
      optional: [{
        currency: '',
        type: '',
        value: 0
      }],
      required: [{
        currency: '',
        type: '',
        value: 0
      }],
      value: 0,
    },
    deliveryDateEstimate: '',
    discount: {
      amount: {
        value: 0,
        currency: '',
      },
      rulesApplied: [],
    },
    pickupEarliestTime: '',
    pickupLatestTime: '',
    quoteId: '',
    rate: {
      value: 0,
      currency: '',
    },
    sameDayCollectionCutOffTime: 0,
    serviceType: '',
    totalCost: {
      value: 0,
      currency: '',
    },
    transitTimeEstimate: 0,
    transportMethod: TransportMethod.EXPRESS,
    weight: {
      actual: {
        unit: '',
        value: 0,
      },
      chargeable: {
        unit: '',
        value: 0,
      },
    },
    vat: {
      currency: '',
      value: 0
    }
  },
  insure: false,
  hawb: '',
  contentDescription: '',
  countryOfOrigin: {
    title: '',
    value: '',
    tradeBloc: '',
    currencyCode: '',
    currencySymbol: '',
    eTrade_dhl_import: false,
    eTrade_dhl_export: false,
    eTrade_fdx_import: false,
    eTrade_fdx_export: false,
    eTrade_ups_import: false,
    eTrade_ups_export: false
  },
  reasonForExport: '',
  collectionDetails: {
    pickUpFrom: '',
    pickUpTo: '',
    contactName: '',
    companyName: '',
    addressType: AddressType.BUSINESS,
    packageLocation: PackageLocation.NONE,
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    countyStateProvince: '',
    postalCode: '',
    cityTown: '',
    telephoneNumber: '',
    email: '',
    additionalInfo: ''
  },
  dropInDetails: {
    id: '',
    openFrom: '',
    openTo: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    countyStateProvince: '',
    cityTown: '',
    postalCode: '',
    countryCode: '',
    telephoneNumber: ''
  },
  shipperDetails: {
    contactName: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    countyStateProvince: '',
    cityTown: '',
    postalCode: '',
    country: {
      title: '',
      value: '',
      tradeBloc: '',
      currencyCode: '',
      currencySymbol: '',
      eTrade_dhl_import: false,
      eTrade_dhl_export: false,
      eTrade_fdx_import: false,
      eTrade_fdx_export: false,
      eTrade_ups_import: false,
      eTrade_ups_export: false
    },
    telephoneNumber: '',
    email: '',
    taxNumbers: [
      {
        type: '',
        value: ''
      }
    ],
    exportComplianceStatement: {
      aes: '',
      b13AFilingOption: 'NOT_REQUIRED',
      edn: '',
      ccn: '',
      exporterCode: ''
    },
    generateECS: false
  },
  trueShipper: true,
  consigneeDetails: {
    contactName: '',
    companyName: '',
    addressType: AddressType.BUSINESS,
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    countyStateProvince: '',
    cityTown: '',
    postalCode: '',
    telephoneNumber: '',
    email: '',
    sendEmail: false,
    taxNumbers: [
      {
        type: '',
        value: ''
      }
    ],
    additionalInfo: ''
  },
  brokerDetails: {
    brokerSelected: false,
    contactName: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    countyStateProvince: '',
    cityTown: '',
    country: {
      title: '',
      value: '',
      tradeBloc: '',
      currencyCode: '',
      currencySymbol: '',
      eTrade_dhl_import: false,
      eTrade_dhl_export: false,
      eTrade_fdx_import: false,
      eTrade_fdx_export: false,
      eTrade_ups_import: false,
      eTrade_ups_export: false
    },
    postalCode: '',
    telephoneNumber: '',
    email: '',
    taxNumbers: [
      {
        type: '',
        value: ''
      }
    ],
  },
  preferences: {
    email: '',
    labelType: ''
  },
  termsCheck: false,
  dangerousGoodsCheck: false,
  courierInfoCheck: false,
  phytosanitaryCheck: false,
  specialService: SpecialServiceType.UNKNOWN,
  customsDetails: {
    amount: {
      value: '',
      currency: ''
    },
    exportDeclaration: {
      declarationNotes: []
    },
    invoiceDetails: {
      declarationStatement: '',
      declarationStatementRequested: {
        value: false,
        type: ''
      },
      termsOfSale: TermsOfSale.DAP,
      taxDetails: [
        {
          type: '',
          party: '',
          value: ''
        }
      ],
      exportReason: '',
      declaredCharges: [],
      cpc: ''
    },
    importerOfRecordDetails: {
      deliveryAddress: false,
      contactDetails: {
        name: '',
        telephone: ''
      },
      address: {
        organisation: '',
        countryCode: '',
        postalCode: '',
        streetLines: [
            '',
            ''
        ],
        city: '',
        stateOrCounty: ''
      }
    },
    commodities: [
      {
        commodityCode: '',
        description: '',
        countryOfOrigin: '',
        quantity: {
          value: '',
          unit: 'PCS'
        },
        unitPrice: {
          value: '',
          currency: ''
        },
        weight: {
          value: '',
          unit: ''
        }
      }
    ]
  },
  igBookingErrors: [],
  mappedPieces: [],
  bookingResult: undefined,
  uploadedInvoice: {
    type: ElectronicTradeDocumentType.COMMERCIAL_INVOICE,
    contentType: ElectronicTradeDocumentFormatType.PDF,
    documentContent: '',
    electronicTradeDocumentName: ''
  },
  uploadedSignature: {
    signatureTitle: 'string',
    signatureRef: 'string',
    signatureImage: '',
    type: ElectronicTradeDocumentType.COMMERCIAL_INVOICE
  },
  freightCharge: '',
}

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    updateField(state, action) {
      const field = action.payload.field;
      const value = action.payload.value;
      return {
        ...state,
        [field]: value
      }
    },
    updateCollectionDetail(state, action) {
      const field = action.payload.field;
      const value = action.payload.value;
      return {
        ...state,
        collectionDetails: {
          ...state.collectionDetails,
          [field]: value
        }
      }
    },
    setDropInLocationDetails(state, action) {
      return {
        ...state,
        dropInDetails: action.payload
      }
    },
    resetDropInDetails(state) {
      return {
        ...state,
        dropInDetails: {
          ...initialState.dropInDetails
        }
      }
    },
    updateShipperDetail(state, action) {
      const field = action.payload.field;
      const value = action.payload.value;
      return {
        ...state,
        shipperDetails: {
          ...state.shipperDetails,
          [field]: value
        }
      }
    },
    updateConsigneeDetail(state, action) {
      const field = action.payload.field;
      const value = action.payload.value;
      return {
        ...state,
        consigneeDetails: {
          ...state.consigneeDetails,
          [field]: value
        }
      }
    },
    updateBrokerDetail(state, action) {
      const field = action.payload.field;
      const value = action.payload.value;
      return {
        ...state,
        brokerDetails: {
          ...state.brokerDetails,
          [field]: value
        }
      }
    },
    updatePreferencesDetail(state, action) {
      const field = action.payload.field;
      const value = action.payload.value;
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [field]: value
        }
      }
    },
    addPiece(state, action) {
      state.pieces.push(action.payload);
    },
    setPieces(state, action) {
      state.pieces = action.payload;
    },
    setImperialMetric(state, action) {
      state.imperialMetric = action.payload;
    },
    setSelectedQuote(state, action) {
      state.selectedQuote = action.payload;
    },
    resetState() {
      return {...initialState};
    },
    addCommodity(state, action) {
      state.customsDetails.commodities.push(action.payload);
    },
    setCommodities(state, action) {
      state.customsDetails.commodities = action.payload;
    },
    setBookingResult(state, action) {
      state.bookingResult = action.payload;
    },
    updateUploadedInvoice(state, action) {
      state.uploadedInvoice = action.payload;
    },
    setCollectionDetails(state, action) {
      state.collectionDetails = {
        ...state.collectionDetails,
        ...action.payload
      }
    },
    updateUploadedSignature(state, action) {
      state.uploadedSignature = action.payload;
    },
    setShipperDetails(state, action) {
      state.shipperDetails = {
        ...state.shipperDetails,
        ...action.payload
      }
    },
    setConsigneeDetails(state, action) {
      state.consigneeDetails = {
        ...state.consigneeDetails,
        ...action.payload
      }
    },
    setBrokerDetails(state, action) {
      state.brokerDetails = {
        ...state.brokerDetails,
        ...action.payload
      }
    },
    addShipperTaxNumber(state, action) {
      state.shipperDetails.taxNumbers.push(action.payload);
    },
    updateShipperTaxNumbers(state, action) {
      state.shipperDetails.taxNumbers = action.payload;
    },
    addConsigneeTaxNumber(state, action) {
      state.consigneeDetails.taxNumbers.push(action.payload);
    },
    updateConsigneeTaxNumbers(state, action) {
      state.consigneeDetails.taxNumbers = action.payload;
    },
    addBrokerTaxNumber(state, action) {
      state.brokerDetails.taxNumbers.push(action.payload);
    },
    updateBrokerTaxNumbers(state, action) {
      state.brokerDetails.taxNumbers = action.payload;
    },
    updateFromObject(state, action) {
      return {...action.payload}
    },
    updateFromTemplate(state, action) {
      const merge = (target: any, source: any) => {
        for (const key of Object.keys(source)) {
          if (source[key] instanceof Object && target[key]) {
            Object.assign(source[key], merge(target[key], source[key]))
          }
        }
        Object.assign(target || {}, source);
        return target
      }
      const updatedState = merge(state, JSON.parse(JSON.stringify(action.payload)));
      removeUnwantedFields(updatedState);
      state = JSON.parse(JSON.stringify(updatedState));
    },
    updateIgBookingErrors(state, action) {
      state.igBookingErrors = action.payload
    },
    updateTermsOfSale(state, action) {
      state.customsDetails.invoiceDetails.termsOfSale = action.payload;
    },
    setDeclarationStatementRequestedValue(state) {
      state.customsDetails.invoiceDetails.declarationStatementRequested.value = !state.customsDetails.invoiceDetails.declarationStatementRequested.value
    },
    setDeclarationStatementRequestedType(state, action) {
      state.customsDetails.invoiceDetails.declarationStatementRequested.type = action.payload
    },
    updateInvoiceDetailField(state, action) {
      state.customsDetails = {
        ...state.customsDetails,
        invoiceDetails: {
          ...state.customsDetails.invoiceDetails,
          [action.payload.field]: action.payload.value
        }
      }
    },
    setInvoiceDeclarationStatement(state, action) {
      state.customsDetails.invoiceDetails.declarationStatement = action.payload
    },
    setEDN(state, action): void {
      state.shipperDetails.exportComplianceStatement.edn = action.payload;
    },
    setCCN(state, action) {
      state.shipperDetails.exportComplianceStatement.ccn = action.payload;
    },
    setB13AFilingOption(state, action) {
      state.shipperDetails.exportComplianceStatement.b13AFilingOption = action.payload;
    },
    setAES(state, action) {
      state.shipperDetails.exportComplianceStatement.aes = action.payload;
    },
    setExporterCode(state, action) {
      state.shipperDetails.exportComplianceStatement.exporterCode = action.payload;
    },
    resetCollectionAddressDetails(state) {
      state.collectionDetails = {
        ...state.collectionDetails,
        contactName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        countyStateProvince: '',
        telephoneNumber: '',
        email: '',
        additionalInfo: ''
      }
    },
    resetShipperAddressDetails(state) {
      state.shipperDetails = {
        ...state.shipperDetails,
        contactName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        countyStateProvince: '',
        telephoneNumber: '',
        email: '',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ]
      }
    },
    resetConsigneeAddressDetails(state) {
      state.consigneeDetails = {
        ...state.consigneeDetails,
        contactName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        countyStateProvince: '',
        telephoneNumber: '',
        email: '',
        sendEmail: false,
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ],
        additionalInfo: ''
      }
    },
    resetBrokerAddressDetails(state) {
      state.brokerDetails = {
        ...state.brokerDetails,
        contactName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        countyStateProvince: '',
        cityTown: '',
        country: {
          title: '',
          value: '',
          tradeBloc: '',
          currencyCode: '',
          currencySymbol: '',
          eTrade_dhl_import: false,
          eTrade_dhl_export: false,
          eTrade_fdx_import: false,
          eTrade_fdx_export: false,
          eTrade_ups_import: false,
          eTrade_ups_export: false
        },
        postalCode: '',
        telephoneNumber: '',
        email: '',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ]
      }
    },
    resetDropInAddressDetails(state) {
      state.dropInDetails = {
        id: '',
        openFrom: '',
        openTo: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        countyStateProvince: '',
        cityTown: '',
        postalCode: '',
        countryCode: '',
        telephoneNumber: ''
      }
    }
  }
})

export const bookingSelector = (state: RootState) => state.booking;
export const bookingActions = bookingSlice.actions;