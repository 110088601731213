import React, { useEffect, useState } from 'react';
import './CustomerPageMarkups.scss';
import { AMIAlert, AMIButton, AMIFormElement, AMIInput, AMIMultiButton, AMISpinner } from '../../../../../../ui-components/UiComponents';
import { useAppSelector } from '../../../../../../app/hooks';
import { customerDetailsSelector } from '../../../../../../features/customerSlice';
import { Distribution, MarkupType, PerShipmentType, blankMarkupState, checkForErrors, convertWeightBreaks, createMarkupObject, defaultWeightBreaks } from './Utils_CustomerPageMarkups';
import { getWlMarkup, setWlMarkup } from '../../../../../../utilities/APIUtilities';
import { appDataSelector } from '../../../../../../features/appDataSlice';
import WeightBreak from './weight-break/WeightBreak';

const CustomerPageMarkups: React.FC<{
  customer: any;
}> = ({
  customer
}) => {

  const apiConfig = useAppSelector(appDataSelector).apiConfig;
  const customerDetails = useAppSelector(customerDetailsSelector);
  const currency = customerDetails.countryOfResidence.currencySymbol;
  const weightValue = customerDetails.countryOfResidence.value === "US" ? 'lb' : 'kg';

  const [markupAlert, setMarkupAlert] = useState<string>('');
  const [placeMarkupAlert, setPlaceMarkupAlert] = useState<string>('');
  const [isSearching, setIsSearching] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccessfullySaved, setShowSuccessfullySaved] = useState(false);
  const [values, setValues] = useState(blankMarkupState)
  const [selections, setSelections] = useState({
    markupType: {title: "Percentage", value: MarkupType.PERCENTAGE},
    distribution: {title: "General", value: Distribution.GENERAL},
    perShipmentType: {title: "No", value: "NO"}
  })
  const [weightBreaks, setWeightBreaks] = useState<any>(defaultWeightBreaks)
  const [showErrors, setShowErrors] = useState(false);
  const [isEnabled, setIsEnabled] = useState({title: "Disable", value: false});

  useEffect(() => {
    getAndSetMarkups();
  }, [])

  const getAndSetMarkups = async() => {
    const markupResponse: any = await getWlMarkup(apiConfig, customer['tmff-party-id']);
    if (markupResponse?.status === 200) {
      const markupData = markupResponse.data[0]
      setIsEnabled(markupData.enabled ? {title: "Enable", value: true} : {title: "Disable", value: false})
      const { shipment, percentage, weight, markup, max, min, weights } = markupData;

      let distribution: Distribution;
      let markupType: MarkupType;

      const newSelections = {
        ...selections
      }

      if (weight) {
        newSelections.distribution = {title: 'Weight Break', value: Distribution.WEIGHT_BREAK};
        distribution = Distribution.WEIGHT_BREAK;
      } else {
        newSelections.distribution = {title: 'General', value: Distribution.GENERAL};
        distribution = Distribution.GENERAL;
      }

      if (percentage) {
        newSelections.markupType = {title: 'Percentage', value: MarkupType.PERCENTAGE};
        markupType = MarkupType.PERCENTAGE;
      } else {
        newSelections.markupType = {title: 'Flat Amount', value: MarkupType.FLAT_AMOUNT};
        markupType = MarkupType.FLAT_AMOUNT;
      }

      if (!shipment) {
        newSelections.perShipmentType = {title: 'No', value: 'NO'};

        let distributionObj;
        if (distribution === Distribution.GENERAL) {
          distributionObj = {
            markup,
            min: min ? min : null,
            max: max ? max : null
          }
        }
        if (distribution === Distribution.WEIGHT_BREAK) {
          setWeightBreaks({...weightBreaks, both: convertWeightBreaks(weights)});
          distributionObj = {
            min: min ? min : null,
            max: max ? max : null
          }
        }

        const newValues = (
          {
            ...values,
            both: {
              ...values.both,
              [markupType]: {
                ...values.both[markupType],
                [distribution]: distributionObj
              }
            }
          }
        )
        setValues(newValues);
      }

      if (shipment) {
        newSelections.perShipmentType = {title: 'Yes', value: 'YES'};

        let docsDistributionObj;
        let nonDocsDistributionObj;

        if (distribution === Distribution.GENERAL) {
          docsDistributionObj = {
            markup: markupData.shipments.docs.markup,
            min: markupData.shipments.docs.min ? markupData.shipments.docs.min : null,
            max: markupData.shipments.docs.max ? markupData.shipments.docs.max : null
          }
          nonDocsDistributionObj = {
            markup: markupData.shipments.nonDocs.markup,
            min: markupData.shipments.nonDocs.min ? markupData.shipments.nonDocs.min : null,
            max: markupData.shipments.nonDocs.max ? markupData.shipments.nonDocs.max : null
          }
        }

        if (distribution === Distribution.WEIGHT_BREAK) {
          docsDistributionObj = {
            min: markupData.shipments.docs.min ? markupData.shipments.docs.min : null,
            max: markupData.shipments.docs.max ? markupData.shipments.docs.max : null
          }
          nonDocsDistributionObj = {
            min: markupData.shipments.nonDocs.min ? markupData.shipments.nonDocs.min : null,
            max: markupData.shipments.nonDocs.max ? markupData.shipments.nonDocs.max : null
          }
          setWeightBreaks({
            ...weightBreaks,
            docs: convertWeightBreaks(markupData.shipments.docs.weights),
            nonDocs: convertWeightBreaks(markupData.shipments.nonDocs.weights)
          });
        }

        const newValues = {
          ...values,
          docs: {
            ...values.docs,
            [markupType]: {
              ...values.docs[markupType],
              [distribution]: docsDistributionObj
            }
          },
          nonDocs: {
            ...values.nonDocs,
            [markupType]: {
              ...values.nonDocs[markupType],
              [distribution]: nonDocsDistributionObj
            }
          }
        }
        setValues(newValues);
      }
      setSelections(newSelections);
    } else if (markupResponse.status === 404) {
      setMarkupAlert('No mark ups have yet been applied for this customer, they will not be able to get any quotes until you set one up.');
    } else {
      setMarkupAlert('We encountered an error retrieving the markups, please refresh the page to try again or contact customer services.');
    }
    setIsSearching(false);
  }

  const updateSelection = (field: string, value: any) => {
    setSelections({
      ...selections,
      [field]: value
    })
  }

  const onValuesChange = (perShipmentType: PerShipmentType, markupType: MarkupType, distribution: Distribution, field: string, value: string) => {
    setValues({
      ...values,
      [perShipmentType]: {
        ...values[perShipmentType],
        [markupType]: {
          ...values[perShipmentType][markupType],
          [distribution]: {
            ...values[perShipmentType][markupType][distribution],
            [field]: value
          }
        }
      }
    })
  }

  const onWeightBreakChange = (perShipmentType: PerShipmentType, field: 'upTo' | 'from' | 'markup', index: number, value: string) => {
    const weightBreaksShipmentType = [...weightBreaks[perShipmentType]];
    weightBreaksShipmentType[index][field] = value;

    if (weightBreaksShipmentType[index + 1] !== undefined && field === 'upTo') {
      weightBreaksShipmentType[index + 1].from = value;
    }

    setWeightBreaks({
      ...weightBreaks,
      [perShipmentType]: weightBreaksShipmentType
    });
  }

  const onSaveClick = async() => {
    setShowErrors(true);
    setShowSuccessfullySaved(false);
    setPlaceMarkupAlert('');
    setTimeout(() => saveMarkup(), 200);
  }

  const saveMarkup = async() => {
    const hasErrors = checkForErrors();
    if (!hasErrors) {
      setShowErrors(false);
      setPlaceMarkupAlert('');
      setShowSuccessfullySaved(false);
      setIsSaving(true);
      const weightUnit = weightValue === 'lb' ? 'LB' : 'KG';
      const requestBody = createMarkupObject(selections, values, customer['tmff-party-id'], weightUnit, weightBreaks, isEnabled.value);
      const response = await setWlMarkup(requestBody, apiConfig);
      if (response) {
        setShowSuccessfullySaved(true)
        setTimeout(() => setShowSuccessfullySaved(false), 5000);
      }
      if (!response) setPlaceMarkupAlert('Sorry, we could not update your markup at this time. Please try again or contact customer services.')
      setIsSaving(false);
    }
    if (hasErrors) setPlaceMarkupAlert('Please fix all highlighted errors.')
  }

  if (isSearching) return (
    <div className="customer-page-markups">
      <div className="customer-page__spinner">
        <AMISpinner />
      </div>
    </div>
  )

  return (
    <div className="customer-page-markups">
      <h4>Markups</h4>

      {markupAlert && (
        <AMIAlert
          variant="warning"
          style={{marginBottom: "24px"}}
        >{markupAlert}</AMIAlert>
      )}

      <p>Please note the markups applied is applicable on Freight charge only and exclude surcharges.</p>

      <div className="markups__radio-cont">
        <p>Markup type:</p>
        <AMIMultiButton
          buttons={[
            {title: "Percentage", value: MarkupType.PERCENTAGE},
            {title: "Flat Amount", value: MarkupType.FLAT_AMOUNT}
          ]}
          selected={selections.markupType}
          style={{
            maxWidth: "400px",
            marginBottom: "4px"
          }}
          onClick={(event: any) => updateSelection("markupType", event)}
        />

        <p>Markup distribution:</p>
        <AMIMultiButton
          buttons={[
            {title: "General", value: Distribution.GENERAL},
            {title: "Weight Break", value: Distribution.WEIGHT_BREAK}
          ]}
          selected={selections.distribution}
          style={{
            maxWidth: "400px",
            marginBottom: "4px"
          }}
          onClick={(event: any) => updateSelection("distribution", event)}
        />

        <p>Apply markup per shipment type?</p>
        <AMIMultiButton
          buttons={[
            {title: "No", value: "NO"},
            {title: "Yes", value: "YES"}
          ]}
          selected={selections.perShipmentType}
          style={{
            maxWidth: "400px",
            marginBottom: "4px"
          }}
          onClick={(event: any) => updateSelection("perShipmentType", event)}
        />
      </div>

      <hr />
{/* INPUT FIELDS */}

      <div className="markups__input-wrapper">

  {/* DOCS */}
        {selections.perShipmentType.value === "YES" && (
          <div>
            <h5>Document Shipments</h5>

            {/* DOCS - FLAT_AMOUNT */}
            {selections.markupType.value === MarkupType.FLAT_AMOUNT && (
              <>
                {/* DOCS - FLAT_AMOUNT - GENERAL */}
                {selections.distribution.value === Distribution.GENERAL && (
                  <div className="markups__input-cont">
                    <AMIFormElement
                      label={`Markup Increase ${currency}`}
                      errorMessage={showErrors && !+values.docs.flatAmount.general.markup ? "Required" : ""}
                    >
                      <AMIInput
                        value={values.docs.flatAmount.general.markup}
                        type="number"
                        onChange={(event) => onValuesChange(PerShipmentType.DOCS, MarkupType.FLAT_AMOUNT, Distribution.GENERAL, "markup", event.target.value)}
                      />
                    </AMIFormElement>
                  </div>
                )}

                {/* DOCS - FLAT_AMOUNT - WEIGHT_BREAK */}
                {selections.distribution.value === Distribution.WEIGHT_BREAK && (
                  <div className="markups__weight-break-cont">
                    {weightBreaks.docs.map((weightBreak: any, index: number) => {
                      return (
                        <WeightBreak
                          key={weightBreak.id}
                          weightValue={weightValue}
                          currency={currency}
                          weightBreak={weightBreak}
                          isFirst={index === 0}
                          isLast={index === weightBreaks.docs.length - 1}
                          shipmentType={PerShipmentType.DOCS}
                          markupType={MarkupType.FLAT_AMOUNT}
                          showErrors={showErrors}
                          onWeightBreakChange={(shipmentType: PerShipmentType, field: "upTo" | "from" | "markup", event: any) => onWeightBreakChange(shipmentType, field, index, event)}
                        />
                      )
                    })}
                  </div>
                )}
              </>
            )}

            {/* DOCS - PERCENTAGE */}
            {selections.markupType.value === MarkupType.PERCENTAGE && (
              <>
                {/* DOCS - PERCENTAGE - GENERAL */}
                {selections.distribution.value === Distribution.GENERAL && (
                  <div className="markups__input-cont">
                    <AMIFormElement
                      label={`Markup Increase (%)`}
                      errorMessage={showErrors && !+values.docs.percentage.general.markup ? "Required" : ""}
                    >
                      <AMIInput
                        value={values.docs.percentage.general.markup}
                        type="number"
                        onChange={(event) => onValuesChange(PerShipmentType.DOCS, MarkupType.PERCENTAGE, Distribution.GENERAL, "markup", event.target.value)}
                      />
                    </AMIFormElement>

                    <div className="markups__input-cont__new-line">
                      <AMIFormElement
                        label={`Minimum Uplift (${currency})`}
                        errorMessage={showErrors && !+values.docs.percentage.general.min ? "Required" : ""}
                      >
                        <AMIInput
                          value={values.docs.percentage.general.min}
                          type="number"
                          onChange={(event) => onValuesChange(PerShipmentType.DOCS, MarkupType.PERCENTAGE, Distribution.GENERAL, "min", event.target.value)}
                        />
                      </AMIFormElement>

                      <AMIFormElement
                        label={`Maximum Uplift (${currency})`}
                        errorMessage={showErrors && (
                          !+values.docs.percentage.general.max || +values.docs.percentage.general.max < +values.docs.percentage.general.min
                         ) ? "Required and > min" : ""}
                      >
                        <AMIInput
                          value={values.docs.percentage.general.max}
                          type="number"
                          onChange={(event) => onValuesChange(PerShipmentType.DOCS, MarkupType.PERCENTAGE, Distribution.GENERAL, "max", event.target.value)}
                        />
                      </AMIFormElement>
                    </div>
                  </div>
                )}

                {/* DOCS - PERCENTAGE - WEIGHT_BREAK */}
                {selections.distribution.value === Distribution.WEIGHT_BREAK && (
                  <div className="markups__weight-break-cont">
                    {weightBreaks.docs.map((weightBreak: any, index: number) => {
                      return (
                        <WeightBreak
                          key={weightBreak.id}
                          weightValue={weightValue}
                          currency={currency}
                          weightBreak={weightBreak}
                          isFirst={index === 0}
                          isLast={index === weightBreaks.docs.length - 1}
                          shipmentType={PerShipmentType.DOCS}
                          markupType={MarkupType.PERCENTAGE}
                          showErrors={showErrors}
                          onWeightBreakChange={(shipmentType: PerShipmentType, field: "upTo" | "from" | "markup", event: any) => onWeightBreakChange(shipmentType, field, index, event)}
                        />
                      )
                    })}

                    <div className="markups__weight-break-cont__new-line">
                      <AMIFormElement
                        label={`Minimum Uplift (${currency})`}
                        errorMessage={showErrors && !+values.docs.percentage.weightBreak.min ? "Required" : ""}
                      >
                        <AMIInput
                          value={values.docs.percentage.weightBreak.min}
                          type="number"
                          onChange={(event) => onValuesChange(PerShipmentType.DOCS, MarkupType.PERCENTAGE, Distribution.WEIGHT_BREAK, "min", event.target.value)}
                        />
                      </AMIFormElement>

                      <AMIFormElement
                        label={`Maximum Uplift (${currency})`}
                        errorMessage={showErrors && (
                          !+values.docs.percentage.weightBreak.max
                          || +values.docs.percentage.weightBreak.max < +values.docs.percentage.weightBreak.min
                         ) ? "Required > min" : ""}
                      >
                        <AMIInput
                          value={values.docs.percentage.weightBreak.max}
                          type="number"
                          onChange={(event) => onValuesChange(PerShipmentType.DOCS, MarkupType.PERCENTAGE, Distribution.WEIGHT_BREAK, "max", event.target.value)}
                        />
                      </AMIFormElement>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}

  {/* NON_DOCS */}
        {selections.perShipmentType.value === "YES" && (
          <div>
            <h5>Non Document Shipments</h5>

            {/* NON_DOCS - FLAT_AMOUNT */}
            {selections.markupType.value === MarkupType.FLAT_AMOUNT && (
              <>
                {/* NON_DOCS - FLAT_AMOUNT - GENERAL */}
                {selections.distribution.value === Distribution.GENERAL && (
                  <div className="markups__input-cont">
                    <AMIFormElement
                      label={`Markup Increase ${currency}`}
                      errorMessage={showErrors && !+values.nonDocs.flatAmount.general.markup ? "Required" : ""}
                    >
                      <AMIInput
                        value={values.nonDocs.flatAmount.general.markup}
                        type="number"
                        onChange={(event) => onValuesChange(PerShipmentType.NON_DOCS, MarkupType.FLAT_AMOUNT, Distribution.GENERAL, "markup", event.target.value)}
                      />
                    </AMIFormElement>
                  </div>
                )}

                {/* NON_DOCS - FLAT_AMOUNT - WEIGHT_BREAK */}
                {selections.distribution.value === Distribution.WEIGHT_BREAK && (
                  <div className="markups__weight-break-cont">
                    {weightBreaks.nonDocs.map((weightBreak: any, index: number) => {
                      return (
                        <WeightBreak
                          key={weightBreak.id}
                          weightValue={weightValue}
                          currency={currency}
                          weightBreak={weightBreak}
                          isFirst={index === 0}
                          isLast={index === weightBreaks.docs.length - 1}
                          shipmentType={PerShipmentType.NON_DOCS}
                          markupType={MarkupType.FLAT_AMOUNT}
                          showErrors={showErrors}
                          onWeightBreakChange={(shipmentType: PerShipmentType, field: "upTo" | "from" | "markup", event: any) => onWeightBreakChange(shipmentType, field, index, event)}
                        />
                      )
                    })}
                  </div>
                )}
              </>
            )}

            {/* NON_DOCS - PERCENTAGE */}
            {selections.markupType.value === MarkupType.PERCENTAGE && (
              <>
                {/* NON_DOCS - PERCENTAGE - GENERAL */}
                {selections.distribution.value === Distribution.GENERAL && (
                  <div className="markups__input-cont">
                    <AMIFormElement
                      label={`Markup Increase (%)`}
                      errorMessage={showErrors && !+values.nonDocs.percentage.general.markup ? "Required" : ""}
                    >
                      <AMIInput
                        value={values.nonDocs.percentage.general.markup}
                        type="number"
                        onChange={(event) => onValuesChange(PerShipmentType.NON_DOCS, MarkupType.PERCENTAGE, Distribution.GENERAL, "markup", event.target.value)}
                      />
                    </AMIFormElement>

                    <div className="markups__input-cont__new-line">
                      <AMIFormElement
                        label={`Minimum Uplift (${currency})`}
                        errorMessage={showErrors && !+values.nonDocs.percentage.general.min ? "Required" : ""}
                      >
                        <AMIInput
                          value={values.nonDocs.percentage.general.min}
                          type="number"
                          onChange={(event) => onValuesChange(PerShipmentType.NON_DOCS, MarkupType.PERCENTAGE, Distribution.GENERAL, "min", event.target.value)}
                        />
                      </AMIFormElement>

                      <AMIFormElement
                        label={`Maximum Uplift (${currency})`}
                        errorMessage={showErrors && (
                          !+values.nonDocs.percentage.general.max
                          || +values.nonDocs.percentage.general.max < +values.nonDocs.percentage.general.min
                         ) ? "Required and > min" : ""}
                      >
                        <AMIInput
                          value={values.nonDocs.percentage.general.max}
                          type="number"
                          onChange={(event) => onValuesChange(PerShipmentType.NON_DOCS, MarkupType.PERCENTAGE, Distribution.GENERAL, "max", event.target.value)}
                        />
                      </AMIFormElement>
                    </div>
                  </div>
                )}

                {/* NON_DOCS - PERCENTAGE - WEIGHT_BREAK */}
                {selections.distribution.value === Distribution.WEIGHT_BREAK && (
                  <div className="markups__weight-break-cont">
                    {weightBreaks.nonDocs.map((weightBreak: any, index: number) => {
                      return (
                        <WeightBreak
                          key={weightBreak.id}
                          weightValue={weightValue}
                          currency={currency}
                          weightBreak={weightBreak}
                          isFirst={index === 0}
                          isLast={index === weightBreaks.docs.length - 1}
                          shipmentType={PerShipmentType.NON_DOCS}
                          markupType={MarkupType.PERCENTAGE}
                          showErrors={showErrors}
                          onWeightBreakChange={(shipmentType: PerShipmentType, field: "upTo" | "from" | "markup", event: any) => onWeightBreakChange(shipmentType, field, index, event)}
                        />
                      )
                    })}

                    <div className="markups__weight-break-cont__new-line">
                      <AMIFormElement
                        label={`Minimum Uplift (${currency})`}
                        errorMessage={showErrors && !+values.nonDocs.percentage.weightBreak.min ? "Required" : ""}
                      >
                        <AMIInput
                          value={values.nonDocs.percentage.weightBreak.min}
                          type="number"
                          onChange={(event) => onValuesChange(PerShipmentType.NON_DOCS, MarkupType.PERCENTAGE, Distribution.WEIGHT_BREAK, "min", event.target.value)}
                        />
                      </AMIFormElement>

                      <AMIFormElement
                        label={`Maximum Uplift (${currency})`}
                        errorMessage={showErrors && (
                          !+values.nonDocs.percentage.weightBreak.max
                          || +values.nonDocs.percentage.weightBreak.max < +values.nonDocs.percentage.weightBreak.min
                         ) ? "Required > min" : ""}
                      >
                        <AMIInput
                          value={values.nonDocs.percentage.weightBreak.max}
                          type="number"
                          onChange={(event) => onValuesChange(PerShipmentType.NON_DOCS, MarkupType.PERCENTAGE, Distribution.WEIGHT_BREAK, "max", event.target.value)}
                        />
                      </AMIFormElement>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}

  {/* DOCS & NON_DOCS */}
        {selections.perShipmentType.value === "NO" && (
          <div>
            <h5>Docs & Non Document Shipments</h5>

            {/* DOCS & NON_DOCS - FLAT_AMOUNT */}
            {selections.markupType.value === MarkupType.FLAT_AMOUNT && (
              <>
                {/* DOCS & NON_DOCS - FLAT_AMOUNT - GENERAL */}
                {selections.distribution.value === Distribution.GENERAL && (
                  <div className="markups__input-cont">
                    <AMIFormElement
                      label={`Markup Increase ${currency}`}
                      errorMessage={showErrors && !+values.both.flatAmount.general.markup ? "Required" : ""}
                    >
                      <AMIInput
                        value={values.both.flatAmount.general.markup}
                        type="number"
                        onChange={(event) => onValuesChange(PerShipmentType.BOTH, MarkupType.FLAT_AMOUNT, Distribution.GENERAL, "markup", event.target.value)}
                      />
                    </AMIFormElement>
                  </div>
                )}

                {/* DOCS & NON_DOCS - FLAT_AMOUNT - WEIGHT_BREAK */}
                {selections.distribution.value === Distribution.WEIGHT_BREAK && (
                  <div className="markups__weight-break-cont">
                    {weightBreaks.both.map((weightBreak: any, index: number) => {
                      return (
                        <WeightBreak
                          key={weightBreak.id}
                          weightValue={weightValue}
                          currency={currency}
                          weightBreak={weightBreak}
                          isFirst={index === 0}
                          isLast={index === weightBreaks.both.length - 1}
                          shipmentType={PerShipmentType.BOTH}
                          markupType={MarkupType.FLAT_AMOUNT}
                          showErrors={showErrors}
                          onWeightBreakChange={(shipmentType: PerShipmentType, field: "upTo" | "from" | "markup", event: any) => onWeightBreakChange(shipmentType, field, index, event)}
                        />
                      )
                    })}
                  </div>
                )}
              </>
            )}

            {/* DOCS & NON_DOCS - PERCENTAGE */}
            {selections.markupType.value === MarkupType.PERCENTAGE && (
              <>
                {/* DOCS & NON_DOCS - PERCENTAGE - GENERAL */}
                {selections.distribution.value === Distribution.GENERAL && (
                  <div className="markups__input-cont">
                    <AMIFormElement
                      label={`Markup Increase (%)`}
                      errorMessage={showErrors && !+values.both.percentage.general.markup ? "Required" : ""}
                    >
                      <AMIInput
                        value={values.both.percentage.general.markup}
                        type="number"
                        onChange={(event) => onValuesChange(PerShipmentType.BOTH, MarkupType.PERCENTAGE, Distribution.GENERAL, "markup", event.target.value)}
                      />
                    </AMIFormElement>

                    <div className="markups__input-cont__new-line">
                      <AMIFormElement
                        label={`Minimum Uplift (${currency})`}
                        errorMessage={showErrors && !+values.both.percentage.general.min ? "Required" : ""}
                      >
                        <AMIInput
                          value={values.both.percentage.general.min}
                          type="number"
                          onChange={(event) => onValuesChange(PerShipmentType.BOTH, MarkupType.PERCENTAGE, Distribution.GENERAL, "min", event.target.value)}
                        />
                      </AMIFormElement>

                      <AMIFormElement
                        label={`Maximum Uplift (${currency})`}
                        errorMessage={showErrors && (
                          !+values.both.percentage.general.max
                          || +values.both.percentage.general.max < +values.both.percentage.general.min
                        ) ? "Required and > min" : ""}
                      >
                        <AMIInput
                          value={values.both.percentage.general?.max}
                          type="number"
                          onChange={(event) => onValuesChange(PerShipmentType.BOTH, MarkupType.PERCENTAGE, Distribution.GENERAL, "max", event.target.value)}
                        />
                      </AMIFormElement>
                    </div>
                  </div>
                )}

                {/* DOCS & NON_DOCS - PERCENTAGE - WEIGHT_BREAK */}
                {selections.distribution.value === Distribution.WEIGHT_BREAK && (
                  <div className="markups__weight-break-cont">
                    {weightBreaks.both.map((weightBreak: any, index: number) => {
                      return (
                        <WeightBreak
                          key={weightBreak.id}
                          weightValue={weightValue}
                          currency={currency}
                          weightBreak={weightBreak}
                          isFirst={index === 0}
                          isLast={index === weightBreaks.both.length - 1}
                          shipmentType={PerShipmentType.BOTH}
                          markupType={MarkupType.PERCENTAGE}
                          showErrors={showErrors}
                          onWeightBreakChange={(shipmentType: PerShipmentType, field: "upTo" | "from" | "markup", event: any) => onWeightBreakChange(shipmentType, field, index, event)}
                        />
                      )
                    })}

                    <div className="markups__weight-break-cont__new-line">
                      <AMIFormElement
                        label={`Minimum Uplift (${currency})`}
                        errorMessage={showErrors && !+values.both.percentage.weightBreak.min ? "Required" : ""}
                      >
                        <AMIInput
                          value={values.both.percentage.weightBreak.min}
                          type="number"
                          onChange={(event) => onValuesChange(PerShipmentType.BOTH, MarkupType.PERCENTAGE, Distribution.WEIGHT_BREAK, "min", event.target.value)}
                        />
                      </AMIFormElement>

                      <AMIFormElement
                        label={`Maximum Uplift (${currency})`}
                        errorMessage={showErrors && (
                          !+values.both.percentage.weightBreak.max
                          || +values.both.percentage.weightBreak.max < +values.both.percentage.weightBreak.min
                         ) ? "Required > min" : ""}
                      >
                        <AMIInput
                          value={values.both.percentage.weightBreak.max}
                          type="number"
                          onChange={(event) => onValuesChange(PerShipmentType.BOTH, MarkupType.PERCENTAGE, Distribution.WEIGHT_BREAK, "max", event.target.value)}
                        />
                      </AMIFormElement>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>

      <div className="customer-page-markups__save-btns">
        <AMIMultiButton
          buttons={[
            {title: "Disable", value: false},
            {title: "Enable", value: true}
          ]}
          selected={isEnabled}
          style={{
            height: "24px",
            maxWidth: "400px",
            minWidth: "200px",
            marginBottom: "4px"
          }}
          onClick={(event: any) => setIsEnabled(event)}
        />

        <AMIButton
          variant="primary"
          size="large"
          isSpinning={isSaving}
          onClick={onSaveClick}
          >Save</AMIButton>
      </div>

       {placeMarkupAlert && <AMIAlert variant="error" style={{marginTop: "24px"}}>{placeMarkupAlert}</AMIAlert>}
       {showSuccessfullySaved && <AMIAlert variant="info" style={{marginTop: "24px"}}>Markup saved</AMIAlert>}
    </div>
  )
}

export default CustomerPageMarkups;