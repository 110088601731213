import React, { useEffect, useState } from 'react';
import { AMISpinner } from '../../../../../ui-components/UiComponents';
import { getCarrierImage, getCarrierName } from '../../../../../utilities/HelperUtilities';
import { Booking, QuoteObj, TransportMethod } from '../../../../../features/bookingSlice';
import { retrieveCourierInfoContent } from '../../../../../utilities/ContentRetrieverUtilities';
import {
  getCarrierTermsAndConditions,
  getCourierInformation
} from '../../../../../utilities/CourierInfo';
import { Link } from 'react-router-dom'

const QuoteCourierInfoModal: React.FC<{
  quoteDetails: QuoteObj
  booking: Booking
  customer: any
}> = ({
  quoteDetails,
  booking,
  customer
}) => {

  const [courierInfoContent, setCourierInfoContent] = useState("");
  const [isSpinning, setIsSpinning] = useState<boolean>(true);

  const [carrierTsAndCs, setCarrierTsAndCs] = useState<any>();

  useEffect(() => {
    setCarrierTsAndCs(getCarrierTermsAndConditions(booking, quoteDetails.quoteId));
  }, [booking])

  useEffect(() => {
    retrieveCourierInfoContent(booking, customer, quoteDetails.quoteId)
      .then((result: any) => {
        if (result.constructor !== Array) setCourierInfoContent(result)
        else setCourierInfoContent(getCourierInformation(customer, booking, quoteDetails.quoteId, result).content)
        setIsSpinning(false)
      })
  }, [])

  return (
    <>
      <img
      src={getCarrierImage(quoteDetails.quoteId)}
      alt="Logo"
    />
    {isSpinning && <AMISpinner className="faq-content-spinner"/>}
    {!isSpinning && (
      <>
        <div
          dangerouslySetInnerHTML={{__html: courierInfoContent}}
          style={{marginBottom: "24px"}}
        />
        {quoteDetails.transportMethod === TransportMethod.ROAD
          && getCarrierName(quoteDetails.quoteId) === "dhl" && (
            <p>The following prohibitions are applicable only to road (ESI product) traffic: goods subject to excise taxes (tobacco, alcoholic beverages) & goods subject to government controls (drugs, medical devices and sanitary products, cosmetics, skincare and hygiene products, goods subject to phytosanitary or veterinary border controls, products for human consumption of all types, even being of non-animal origin, animal feeds.</p>
          )
        }
        {carrierTsAndCs && (
          <p>For more information, please check our
            <Link
              to={carrierTsAndCs}
              rel="noreferrer"
              target="_blank"
              download
            > terms and conditions</Link>
          </p>
        )}
      </>
    )}
    </>
  )
}

export default QuoteCourierInfoModal;