import React, { useEffect } from 'react';
import Icon from '../../../ui-components/icon/Icon';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import './BulkBookingFinishPage.scss';
import {manifestActions} from '../../../features/manifestSlice';
import {useAppDispatch} from "../../../app/hooks";

const FinishPage = () => {

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(manifestActions.resetManifestState());
    }
  }, [])

  const {width} = useWindowDimensions();

  return (
    <div className="bulk-booking-finish-page">
      <div className="bulk-booking-finish-page__content">
          {width < 501 && <Icon
            name="CircleCheck"
            style={
              {
                fill: 'var(--success)',
                height: '123px',
                width: '100%',
              }
            }
          />}
        <h1
          className="bulk-booking-finish-page__content__title"
          style={{color: 'var(--success)'}}
        >Success</h1>
        <div
          className="bulk-booking-finish-page__content__divide"
          style={{background: 'var(--success)'}}
        ></div>
        <div className="bulk-booking-finish-page__content__text-cont">
          {width > 500 && <Icon
            name="CircleCheck"
            style={
              {
                fill: 'var(--success)',
                height: '123px',
                width: '123px'
              }
            }
          />}
          <div>
            <p>Your bookings have successfully been uploaded to your chosen carrier</p>
            <p>We will email the results to you with labels attached.</p>
            <p>You may safely navigate away from this page.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinishPage;