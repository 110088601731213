import React from 'react';
import { Route } from 'react-router-dom';
import WhiteLabelBookingsPage from './bookings/WlBookingsPage';
import WhiteLabelCustomisePage from './white-label-customise-page/WhiteLabelCustomisePage';
import WhiteLabelCustomersPage from './white-label-customers-page/WhiteLabelCustomersPage';
import WhiteLabelContentPage from './white-label-content-page/WhiteLabelContentPage';

const WhiteLabelPage = () => {

  return (
    <main>
      <Route path="/account/white-label/bookings">
        <WhiteLabelBookingsPage />
      </Route>

      <Route path="/account/white-label/customise">
        <WhiteLabelCustomisePage />
      </Route>

      <Route path="/account/white-label/customers">
        <WhiteLabelCustomersPage />
      </Route>

      <Route path="/account/white-label/content">
        <WhiteLabelContentPage />
      </Route>
    </main>
  )
}

export default WhiteLabelPage;