import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../app/store";
import { Country } from "./bookingSlice";
import {Buffer} from "buffer";
import { Template } from "../utilities/TemplateHandler";

export interface User {
  username: string;
  password: string;
}

export interface Preferences {
  address: Address,
  lengthWidthHeight: LengthWidthHeight
}

interface LengthWidthHeight {
  length: number,
  width: number,
  height: number
}

interface Address {
  organisation: string,
  addressLine1: string,
  addressLine2?: string,
  city: string,
  stateOrCounty?: string,
  postalCode?: string,
  countryCode: string,
  telephone: string
}

interface CustomerState {
  customerDetails: CustomerDetails,
  preferences: Preferences
}

export enum QuoteFilterService {
  ALL = 'ALL',
  ROAD = 'ROAD',
  EXPRESS = 'EXPRESS'
}

export enum QuoteFilterType {
  ALL = 'ALL',
  COLLECTED = 'COLLECTED',
  DROP_IN = 'DROP_IN'
}

export enum QuoteFilterCarrier {
  ALL = 'ALL',
  DHL = 'dhl',
  FEDEX = 'fedex',
  UPS = 'ups'
}

export interface QuoteFilters {
  service: QuoteFilterService
  type: QuoteFilterType,
  carrier: QuoteFilterCarrier
  time: string
}

let customerAddress = {
  organisation: '',
  addressLine1: '',
  addressLine2: '',
  postalCode: '',
  city: '',
  stateOrCounty: '',
  countryCode: '',
  telephone: ''
}

let customerLengthWidthHeight = {
  length: 0,
  width: 0,
  height: 0
}

let customerPreferences: Preferences = {
  address: customerAddress,
  lengthWidthHeight: customerLengthWidthHeight
}

export interface WhiteLabel {
  isParent: boolean,
  parentPartyId: string,
  children: string[],
  address: {
    streetLines: string[],
    city: string,
    state: string,
    postalCode: string,
    countryCode: string
  }
}

export interface CustomerDetails {
  creditCheck: {
    credit: boolean,
    tmffPartyId: string,
    branchId: string,
    whiteLabel?: {
      isParent: boolean,
      parentPartyId: string,
      children: string[],
      address: {
        streetLines: string[],
        city: string,
        state: string,
        postalCode: string,
        countryCode: string
      }
    }
  },
  auth: {
    token: string,
    expiration: string,
    accessToken: string,
    masterToken: string
  },
  message: string
  loginErrorStatus: number
  customerCountryCode: string,
  countryOfResidence: Country
  homeCurrency: string,
  logonEmail: string,
  savedAddresses: any[],
  quoteFilters: QuoteFilters,
  templates: Template[]
}

const initialState: CustomerState = {
  customerDetails: {
    creditCheck: {
      credit: false,
      tmffPartyId: '',
      branchId: ''
    },
    auth: {
      token: '',
      expiration: '',
      accessToken: '',
      masterToken: ''
    },
    message: '',
    loginErrorStatus: 0,
    customerCountryCode: '',
    countryOfResidence: {
      title: '',
      value: '',
      tradeBloc: '',
      currencyCode: '',
      currencySymbol: '',
      eTrade_dhl_import: false,
      eTrade_dhl_export: false,
      eTrade_fdx_import: false,
      eTrade_fdx_export: false,
      eTrade_ups_import: false,
      eTrade_ups_export: false
    },
    homeCurrency: '',
    logonEmail: '',
    savedAddresses: [],
    quoteFilters: {
      service: QuoteFilterService.ALL,
      type: QuoteFilterType.ALL,
      carrier: QuoteFilterCarrier.ALL,
      time: 'ALL'
    },
    templates: []
  },
  preferences: customerPreferences,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    logOut() {
      return {...initialState}
    },
    updateCountryOfResidence(state, action){
      const value = action.payload;
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          countryOfResidence: value
        }
      }
    },
    updateCountryOfResidenceCurrency(state, action){
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          countryOfResidence: {
            ...state.customerDetails.countryOfResidence,
            currencyCode: action.payload
          }
        }
      }
    },
    updateField(state, action) {
      const field = action.payload.field;
      const value = action.payload.value;
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          [field]: value
        }
      }
    },
    setSavedAddresses(state, action) {
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          savedAddresses: action.payload
        }
      }
    },
    addSavedAddress(state, action) {
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          savedAddresses: [
            ...state.customerDetails.savedAddresses,
            action.payload
          ]
        }
      }
    },
    deleteSavedAddress(state, action) {
      const filteredAddresses = state.customerDetails.savedAddresses.filter((address: any) => {
        return address.id !== action.payload;
      })

      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          savedAddresses: filteredAddresses
        }
      }
    },
    updateSavedAddress(state, action) {
      const addressesCopy = [...state.customerDetails.savedAddresses];
      const index = addressesCopy.findIndex(e => e.id === action.payload.id);
      addressesCopy[index] = action.payload;

      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          savedAddresses: [
            ...addressesCopy
          ]
        }
      }
    },
    updateQuoteFilters(state, action) {
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          quoteFilters: {
            ...state.customerDetails.quoteFilters,
            [action.payload.block]: action.payload.value
          }
        }
      }
    },
    setTemplates(state, action) {
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          templates: action.payload
        }
      }
    },
    addTemplate(state, action) {
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          templates: [
            ...state.customerDetails.templates,
            action.payload
          ]
        }
      }
    },
    updateTemplate(state, action) {
      const newTemplatesArr = state.customerDetails.templates.filter((template: any) => template.id !== action.payload.id);
      newTemplatesArr.push(action.payload);
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          templates: newTemplatesArr
        }
      }
    },
    deleteTemplateById(state, action) {
      const templates = JSON.parse(JSON.stringify(state.customerDetails.templates));
      const updatedTemplates = templates.filter((el: any) => el.id !== action.payload);
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          templates: updatedTemplates
        }
      }
    },
    clearTemplates(state) {
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          templates: []
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.customerDetails = action.payload;
      });
  }
});

export const login = createAsyncThunk (
  "customer/login",
  async(user: User) => {
    const requestBody = {
      email: user.username,
      password: user.password
    };
    let returnedData: CustomerDetails = {...initialState.customerDetails};

    await axios.post(
      `${process.env.REACT_APP_NEBE_API_URL}/v2/nebe-auth`,
      requestBody
    ).then((response) => {
      try {
        response.data.auth['accessToken'] = Buffer
          .from(response.data.auth['accessToken'], 'base64')
          .toString('utf-8');

      } catch (error) {console.error(error)}

      returnedData = {
        ...returnedData,
        ...response.data
      };

      returnedData.auth.masterToken = response.headers["master-token"];

    }, (error) => {
      if (error.response) {
        returnedData = {
          ...initialState.customerDetails,
          loginErrorStatus: error.response.status
        };
      } else {
        returnedData = {...initialState.customerDetails}
      }
      console.error(error)
    })
    return returnedData;
  });

export const customerDetailsSelector = (state: RootState) => state.customer.customerDetails;
export const preferenceSelector = (state: RootState) => state.customer.preferences;
export const customerActions = customerSlice.actions;
