import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { AMICheckbox, AMIFormElement, AMIInput, AMISelect } from '../../../../ui-components/UiComponents';
import TaxNumber from '../../../../components/tax-numbers/TaxNumbers';
import { appDataActions, appDataSelector } from '../../../../features/appDataSlice';
import { Booking, bookingActions, bookingSelector } from '../../../../features/bookingSlice';
import { trimTelephone } from '../../../../utilities/HelperUtilities';
import SavedAddressSelect, { DetailSection } from '../../../../components/saved-address-select/SavedAddressSelect';
import { compareAddresses, getAndSetWLAddress } from '../utilities';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { getTradeRoute, TradeRoute } from '../../../../utilities/RulesEngineUtilities';
import { extractRule } from "../../../../utilities/RulesEngineUtilities";

const ConsigneeDetails: React.FC<{
  errHandler: any;
  customRules: any;
}> = ({
  errHandler,
  customRules
}) => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const appData = useAppSelector(appDataSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const showErrors = appData.showDetailsPageErrors;
  const taxNumbers = booking.consigneeDetails.taxNumbers;
  const branchId = customer.creditCheck.branchId;

  const [savedAddressSnapshot, setSavedAddressSnapshot] = useState<any>();
  const [disableSaveAddressCheckbox, setDisableSavedAddressCheckbox] = useState(false);

  useEffect(() => {
    setDisableSavedAddressCheckbox(compareAddresses(
      booking.consigneeDetails,
      booking.destination,
      customer.creditCheck.branchId,
      savedAddressSnapshot
    ))
  }, [booking.consigneeDetails]);

  useEffect(() => {
    getAndSetWLAddress('DESTINATION', customer, booking, dispatch);
  }, [])

  const changeConsigneeDetails = (field: string, event: any) => {
    let value = event.value;
    if (field === 'telephoneNumber') {
      value = trimTelephone(value);
    }
    dispatch(bookingActions.updateConsigneeDetail({
      field,
      value: value
    }))
  }

  const handleAddTaxNumber = () => {
    dispatch(bookingActions.addConsigneeTaxNumber(
      {
        type: '',
        value: ''
      }
    ));
  }

  const updateTaxNumbers = (event: any) => {
    dispatch(bookingActions.updateConsigneeTaxNumbers(
      [...event]
    ));
  }

  const deleteTaxNumber = (index: number) => {
    if (taxNumbers.length > 1) {
      const taxNumbersCopy = [...taxNumbers];
      taxNumbersCopy.splice(index, 1);
      dispatch(bookingActions.updateConsigneeTaxNumbers(taxNumbersCopy));
    }
  }

  const changePostalCode = (event: any) => {
    dispatch(bookingActions.updateField({
      field: 'destinationPostalCode',
      value: event.target.value
    }))
  }

  const getAddressLine3Text = (booking: Booking) => {
    if (booking.destination.value === "SA") return "Short Address Code/National Address"
    else return "Address Line 3"
  }

  return (
    <div
      className="consignee-details horizontal-card"
      id="consignee-details"
    >

    <p className="horizontal-card__title">Consignee Details</p>
    <p className="details-page__address-type">
      {booking.destinationResidential ? "Residential" : "Commercial"}
    </p>

    <SavedAddressSelect
        section={DetailSection.CONSIGNEE_DETAILS}
        filters={['cityTown', 'postalCode']}
        onChange={(address) => setSavedAddressSnapshot(address)}
    ></SavedAddressSelect>

    <AMIFormElement
      label="Full Contact Name"
      errorMessage={
        showErrors
        && !errHandler.consigneeDetails.contactName.criteria
        ? errHandler.consigneeDetails.contactName.message
        : ''
      }
    >
      <AMIInput
        name="consigneeContactName"
        placeholder="Required"
        size="large"
        value={booking.consigneeDetails.contactName}
        onChange={(event) => changeConsigneeDetails('contactName', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Company Name"
      errorMessage={
        showErrors
        && !errHandler.consigneeDetails.companyName.criteria
        ? errHandler.consigneeDetails.companyName.message
        : ''
      }
    >
      <AMIInput
        name="consigneeCompanyName"
        placeholder="Required"
        size="large"
        value={booking.consigneeDetails.companyName}
        onChange={(event) => changeConsigneeDetails('companyName', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Country"
      className="details-page__country"
    >
      <AMIInput
        name="destinationCountry"
        placeholder="Required"
        size="large"
        disabled
        value={booking.destination.title}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Address Line 1"
      errorMessage={
        showErrors
        && !errHandler.consigneeDetails.addressLine1.criteria
        ? errHandler.consigneeDetails.addressLine1.message
        : ''
      }
    >
      <AMIInput
        name="consigneeAddressLine1"
        placeholder="Required"
        size="large"
        value={booking.consigneeDetails.addressLine1}
        onChange={(event) => changeConsigneeDetails('addressLine1', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Address Line 2"
      errorMessage={
        showErrors
        && !errHandler.consigneeDetails.addressLine2.criteria
        ? errHandler.consigneeDetails.addressLine2.message
        : ''
      }
    >
      <AMIInput
        name="consigneeAddressLine2"
        size="large"
        value={booking.consigneeDetails.addressLine2}
        onChange={(event) => changeConsigneeDetails('addressLine2', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement
      label={getAddressLine3Text(booking)}
      errorMessage={
        showErrors
        && !errHandler.consigneeDetails.addressLine3.criteria
        ? errHandler.consigneeDetails.addressLine3.message
        : ''
      }
    >
      <AMIInput
        name="consigneeAddressLine3"
        size="large"
        value={booking.consigneeDetails.addressLine3}
        onChange={(event) => changeConsigneeDetails('addressLine3', event.target)}
      />
      {booking.destination.value === "SA" &&
        <p><a
            href='https://splonline.com.sa/en/national-address-1/'
            target="_blank"
            rel="noreferrer"
        > Click here for more information on the &quot;Short Address Code&quot;</a>
        </p>
      }
    </AMIFormElement>

    <AMIFormElement
      label="City/Town"
      errorMessage={
        showErrors
        && !errHandler.consigneeDetails.cityTown.criteria
        ? errHandler.consigneeDetails.cityTown.message
        : ''
      }
    >
      <AMIInput
        name="consigneeCityTown"
        placeholder="Required"
        size="large"
        value={booking.destinationCityTown}
        disabled
      />
    </AMIFormElement>

    {appData.destinationCounties.length === 0
      && !extractRule(customRules, 'upsCneeCountyStateHidden')?.value
      &&(
      <AMIFormElement
        label="County/State/Province"
        errorMessage={
          showErrors
          && !errHandler.consigneeDetails.countyStateProvince.criteria
          ? errHandler.consigneeDetails.countyStateProvince.message
          : ''
        }
      >
        <AMIInput
          name="consigneeCountyStateProvince"
          size="large"

          value={booking.consigneeDetails.countyStateProvince}
          onChange={(event) => changeConsigneeDetails('countyStateProvince', event.target)}
        />
      </AMIFormElement>
    )}

    {appData.destinationCounties.length > 0
      && !extractRule(customRules, 'upsCneeCountyStateHidden')?.value
      && (
      <AMIFormElement
        label="County/State/Province"
        errorMessage={
          showErrors
          && !errHandler.consigneeDetails.countyStateProvince.criteria
          ? errHandler.consigneeDetails.countyStateProvince.message
          : ''
        }
      >
        <AMISelect
          name="consigneeCountyStateProvince"
          placeholder="Required"
          size="large"
          isSearchable

          options={appData.destinationCounties}
          onChange={changeConsigneeDetails.bind(null, 'countyStateProvince')}
          defaultValue={appData.destinationCounties.find(item => {
            return item.value === booking.consigneeDetails.countyStateProvince
          })}
        />
      </AMIFormElement>
    )}

    <AMIFormElement
      label="Postal/Zip Code"
      errorMessage={
        showErrors
        && !errHandler.consigneeDetails.postalCode.criteria
        ? errHandler.consigneeDetails.postalCode.message
        : ''
      }
    >
      <AMIInput
        name="consigneePostalCode"
        placeholder={appData.destinationPostalCodeRegex ? "Required" : ""}
        size="large"
        disabled={!!appData.destinationPostalCodeRegex}
        value={booking.destinationPostalCode}
        onChange={(event) => changePostalCode(event)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Telephone Number"
      errorMessage={
        showErrors
        && !errHandler.consigneeDetails.telephoneNumber.criteria
        ? errHandler.consigneeDetails.telephoneNumber.message
        : ''
      }
    >
      <AMIInput
        name="consigneeTelephoneNumber"
        placeholder="Required"
        size="large"
        value={booking.consigneeDetails.telephoneNumber}
        onChange={(event) => changeConsigneeDetails('telephoneNumber', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement
      label="Email"
      errorMessage={
        showErrors
        && !errHandler.consigneeDetails.email.criteria
        ? errHandler.consigneeDetails.email.message
        : ''
      }
    >
      <AMIInput
        name="consigneeEmail"
        placeholder="Required"
        size="large"
        type="email"
        value={booking.consigneeDetails.email}
        onChange={(event) => changeConsigneeDetails('email', event.target)}
      />
    </AMIFormElement>

    {getTradeRoute(customer, booking) !== TradeRoute.DOMESTIC && (
      <>
        {taxNumbers.map((element: any, index: number) => {
            return (
              <div className="tax-numbers-wrapper" key={index}>
                <TaxNumber
                  element={element}
                  index={index}
                  allElements={taxNumbers}
                  booking={booking}
                  detailsContainer="consigneeDetails"
                  customRules={customRules}
                  updateElements={(event) => updateTaxNumbers(event)}
                  onDelete={() => deleteTaxNumber(index)}
                />
              </div>
            )
          })}

        <p className="tax-numbers-add-text" onClick={handleAddTaxNumber}>+ Add another tax number</p>
      </>
    )}


    <AMIFormElement
      label="Additional Information / Special Instructions"
      style={{gridColumn: '1 / 3'}}
      errorMessage={
        showErrors
        && !errHandler.consigneeDetails.additionalInfo.criteria
        ? errHandler.consigneeDetails.additionalInfo.message
        : ''
      }
    >
      <AMIInput
        name="consigneeAdditionalInfo"
        size="large"
        value={booking.consigneeDetails.additionalInfo}
        onChange={(event) => changeConsigneeDetails('additionalInfo', event.target)}
      />
    </AMIFormElement>

    <AMIFormElement className="save-address__checkbox">
      <AMICheckbox
        text=""
        disabled={disableSaveAddressCheckbox || !branchId}
        checked={appData.saveConsigneeAddress}
        onChange={() => dispatch(appDataActions.setConsigneeSaveAddressCheckbox())}
      >
        {branchId && <p>Save this address for future use?</p>}
        {!branchId && <p>Save this address for future use? (Please contact <a href="/contact" target="_blank" rel="noreferrer">customer services</a> to enable address book features)</p>}
      </AMICheckbox>
    </AMIFormElement>

    </div>
  )
}

export default ConsigneeDetails;