import React, { useState } from 'react';
import { AMIFormElement, AMIInput, AMISelect, AMISpinner } from '../../../../../ui-components/UiComponents';
import TaxNumber from '../../../../../components/tax-numbers/TaxNumbers';
import { addTaxNumber, deleteTaxNumber } from '../utils';
import { useAppSelector } from '../../../../../app/hooks';
import { appDataSelector } from '../../../../../features/appDataSlice';
import { TemplateForm } from '../../../../../utilities/TemplateHandler';
import { getCountryInfo } from '../../../../../utilities/APIUtilities';
import { formatCounties } from '../../../../../utilities/HelperUtilities';

const TemplateModalBrokerDetails: React.FC<{
  template: TemplateForm;
  updateBrokerDetails: (field: string, value: any) => void;
}> = ({
  template,
  updateBrokerDetails
}) => {

  const appData = useAppSelector(appDataSelector);
  const data = template.broker;
  const taxNumbers = data.taxNumbers;

  const [handlingCountryInfo, setHandlingCountryInfo] = useState(false);
  const [counties, setCounties] = useState<any>([]);

  const handleCountryInfo = async(country: any) => {
    setHandlingCountryInfo(true);
    const countryInfo = await getCountryInfo(appData.apiConfig, country.value);
    if (countryInfo?.countiesStates?.length > 0) {
      setCounties(formatCounties(countryInfo.countiesStates));
    }
    updateBrokerDetails('country', country)
    setHandlingCountryInfo(false);
  }

  if (handlingCountryInfo) {
    return (
      <div className="template-modal-spinner">
        <AMISpinner />
        <p>Fetching country info...</p>
      </div>
    )
  }

  return (
    <div className="template-modal-broker-details">
      <AMIFormElement label="Full Contact Name">
        <AMIInput
          name="brokerContactName"
          size="large"
          value={data.contactName}
          onChange={(event) => updateBrokerDetails("contactName", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Company Name">
        <AMIInput
          name="brokerCompanyName"
          size="large"
          value={data.companyName}
          onChange={(event) => updateBrokerDetails("companyName", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Country"
        className="template-modal__country"
      >
        <AMISelect
          name="brokerCountry"
          size="large"
          isSearchable
          defaultValue={data.country}
          options={appData.countries}
          onChange={(event) => handleCountryInfo(event)}
        />
      </AMIFormElement>

      <AMIFormElement label="Address Line 1">
        <AMIInput
          name="brokerAddressLine1"
          size="large"
          value={data.addressLine1}
          onChange={(event) => updateBrokerDetails("addressLine1", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Address Line 2">
        <AMIInput
          name="brokerAddressLine2"
          size="large"
          value={data.addressLine2}
          onChange={(event) => updateBrokerDetails("addressLine2", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Address Line 3">
        <AMIInput
          name="brokerAddressLine3"
          size="large"
          value={data.addressLine3}
          onChange={(event) => updateBrokerDetails("addressLine3", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="City/Town">
        <AMIInput
          name="brokerCityTown"
          size="large"
          value={data.cityTown}
          onChange={(event) => updateBrokerDetails("cityTown", event.target.value)}
        />
      </AMIFormElement>

      {counties.length === 0 && (
        <AMIFormElement label="County/State/Province">
          <AMIInput
            name="brokerCountyStateProvince"
            size="large"
            value={data.countyStateProvince}
            onChange={(event) => updateBrokerDetails('countyStateProvince', event.target.value)}
          />
        </AMIFormElement>
      )}

      {counties.length > 0 && (
        <AMIFormElement label="County/State/Province">
          <AMISelect
            name="brokerCountyStateProvince"
            size="large"
            isSearchable
            options={counties}
            onChange={(event) => updateBrokerDetails('countyStateProvince', event.value)}
            defaultValue={counties.find((item: any) => {
              return item.value === data.countyStateProvince
            })}
          />
        </AMIFormElement>
      )}

      <AMIFormElement label="Postal/Zip Code">
        <AMIInput
          name="brokerPostalCode"
          size="large"
          value={data.postalCode}
          onChange={(event) => updateBrokerDetails("brokerDetails", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Telephone Number">
        <AMIInput
          name="brokerTelephoneNumber"
          size="large"
          type="number"
          value={data.telephoneNumber}
          onChange={(event) => updateBrokerDetails("telephoneNumber", event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Email">
        <AMIInput
          name="brokerEmail"
          size="large"
          type="email"
          value={data.email}
          onChange={(event) => updateBrokerDetails("email", event.target.value)}
        />
      </AMIFormElement>

      {taxNumbers.map((element: any, index: number) => {
        return (
          <div className="tax-numbers-wrapper" key={index}>
            <TaxNumber
              element={element}
              index={index}
              allElements={taxNumbers}
              booking={template}
              detailsContainer="broker"
              updateElements={(event) => updateBrokerDetails("taxNumbers", event)}
              onDelete={() => updateBrokerDetails("taxNumbers", deleteTaxNumber(taxNumbers, index))}
            />
          </div>
        )
      })}

      {<p className="tax-numbers-add-text" onClick={() => updateBrokerDetails("taxNumbers", addTaxNumber(taxNumbers))}>+ Add another tax number</p>}

    </div>
  )

}

export default TemplateModalBrokerDetails;