import React, { useEffect, useState } from 'react';
import './Alert.scss';
import Icon from '../icon/Icon';

interface props {
  variant: 'error' | 'warning' | 'complex-error' | 'info',
  style?: React.CSSProperties,
  className?: string,
  id?: string
}

const AMIAlert: React.FC<props> = ({
  variant,
  style,
  className,
  id,
  children
}) => {

  const [classNames, setClassNames] = useState<string>('alert');

  useEffect(() => {
    setClassNames(getClassNames());
  }, [variant])

  const getClassNames = () => {
    let classnames = `${className} alert `;

    if (variant.includes('error')) {
      classnames = classnames.concat(`alert--error`);
    }
    if (variant === 'warning') {
      classnames = classnames.concat(`alert--${variant}`);
    }
    if (variant === 'info') {
      classnames = classnames.concat(`alert--${variant}`)
    }
    return classnames;
  };

  const getFill = () => {
    if (variant.includes('error')) return 'var(--error)'
    else if (variant.includes('info')) return 'var(--success)'
    else return 'var(--warning)';
  }

  return (
    <div
      className={classNames}
      id={id}
      style={style}
    >
      {variant === 'info' &&
        <Icon
          name="Express"
          style={{
            margin: '12px',
            fill: getFill(),
            height: '22px',
            width: '22px'
          }}
        />
      }

      {(variant === 'error' || variant === 'warning') &&
        <Icon
          name="CircleExclamation"
          style={{
            margin: '12px',
            fill: getFill(),
            height: '22px',
            width: '22px'
          }}
        />
      }

      {(variant === 'error' || variant === 'warning' || variant === 'info') &&
        <span className="alert__text">{children}</span>
      }
      {variant === 'complex-error' &&
        <div>{children}</div>
      }
    </div>
  )
};

export default AMIAlert;