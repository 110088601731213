import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import './Manifest.scss';
import AMISteps, { ActivePage } from "./steps/Steps"
import UploadPage from './upload-page/UploadPage';
import OptionsPage from './options-page/OptionsPage';
import FinishPage from './finish-page/FinishPage';
import { manifestActions } from '../../features/manifestSlice';
import {useAppDispatch} from "../../app/hooks";
import ReviewPage from './review-page/ReviewPage';

const Manifest = () => {

  const dispatch = useAppDispatch();
  const [activePage, setActivePage] = useState(ActivePage.UPLOAD_PAGE);

  const handleStepChange = (event: ActivePage) => {
    setActivePage(event);
  };

  useEffect(() => {
    return () => {
      dispatch(manifestActions.resetManifestState());
    }
  }, [])

  return (
    <div className="manifest-page">
      <Prompt
        message={(location, action) => {
          let message: boolean | string = true;
          if (action === 'POP') {
            message = 'Are you sure you want to leave this page? All progress will be lost and you will be signed out.'
          }
          return message;
        }}
      />
      <AMISteps stepChange={handleStepChange} activePage={activePage}/>

      {activePage === ActivePage.UPLOAD_PAGE && <UploadPage changeStep={handleStepChange}/>}
      {activePage === ActivePage.REVIEW_PAGE && <ReviewPage changeStep={handleStepChange} />}
      {activePage === ActivePage.OPTIONS_PAGE && <OptionsPage changeStep={handleStepChange} />}
      {activePage === ActivePage.FINISH_PAGE && <FinishPage />}

    </div>
  )
};

export default Manifest;