import React from 'react';
import './MultiButton.scss';
import { preventSpaceBarScrolling } from '../../utilities/HelperUtilities';

export interface MultiButtonOption {
  title: any,
  value: any
}

const AMIMultiButton: React.FC<{
  buttons: MultiButtonOption[],
  style?: React.CSSProperties,
  selected: MultiButtonOption,
  onClick: (value: MultiButtonOption) => void
}> = ({
  buttons,
  style,
  selected,
  onClick
}) => {

  const getButtonStyle = (button: MultiButtonOption, index: number) => {
    let updatedStyle = {...style};
    const totalButtons = buttons.length;

    if (index === 0) {
      updatedStyle = {
        ...updatedStyle,
        borderRadius: '3.5px 0px 0px 3.5px'
      };
    }

    if (index === totalButtons - 1) {
      updatedStyle = {
        ...updatedStyle,
        borderRadius: '0px 3.5px 3.5px 0px',
        marginLeft: '-1px'
      };
    }

    if (index !== 0 && index !== totalButtons -1) {
      updatedStyle = {
        ...updatedStyle,
        borderRadius: '0px',
        marginLeft: '-1px'
      };
    }

    if (button.value === selected.value) {
      updatedStyle = {
        ...updatedStyle,
        backgroundColor: 'var(--secondary)',
        color: 'var(--textLight)'
      }
    }

    return updatedStyle;
  }

  const onKeyUp = (button: any, keyCode: string, event: any) => {
    event.preventDefault();
    const isAConfirmKey = keyCode === 'Enter' || keyCode === 'NumpadEnter' || keyCode === 'Space';
    if (!keyCode || isAConfirmKey) onClick(button);
  }

  return (
    <div
      className="multi-button"
      style={style}
    >
      {buttons.map((button, index) => {
        return (
          <div
            style={getButtonStyle(button, index)}
            onClick={() => onClick(button)}
            key={index}
            id={`multi-button-${name}`}
            tabIndex={0}
            onKeyDown={(event) => preventSpaceBarScrolling(event)}
            onKeyUp={(event) => onKeyUp(button, event.code, event)}
          >{button.title}</div>
        )
      })}
    </div>
  )
}

export default AMIMultiButton;