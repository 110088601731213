import React from 'react';
import { AMICheckbox, AMIFormElement, AMIInput, AMISelect, Icon } from '../../../../../ui-components/UiComponents';
import { TemplateForm } from '../../../../../utilities/TemplateHandler';

const TemplateModalGeneral: React.FC<{
  template: TemplateForm;
  templateName: string;
  updateGeneral: (field: string, value: any) => void;
}> = ({
  template,
  templateName,
  updateGeneral
}) => {

  return (
    <div className="template-modal-general">

      <div
        style={{
          marginBottom: "24px",
          color: "var(--secondary)"
        }}
      >
        <Icon
          name="CircleExclamation"
          color="var(--secondary)"
          style={{marginRight: "4px"}}
        />
        Please fill in any or all fields within this template modal to create a template. The only required field is the <strong>Template Name</strong> so feel free to miss fields.
      </div>

      <AMIFormElement label="Template Name">
        <AMIInput
          name="template-name"
          placeholder="Required"
          style={{width: "50%"}}
          size="large"
          value={templateName}
          onChange={(event) => updateGeneral('name', event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement label="Variant">
        <AMISelect
          name="template-variant"
          size="large"
          style={{width: "50%"}}
          options={[
            {title: "Single Booking", value: "SINGLE"},
            {title: "Bulk Booking", value: "BULK"}
          ]}
          defaultValue={{title: "Single Booking", value: "SINGLE"}}
          disabled
          hideClearButton
        />
      </AMIFormElement>

      <AMICheckbox
        checked={template.isDefault}
        text="Make this my default template for the selected variant"
        onChange={() => updateGeneral("isDefault", !template.isDefault)}
      />
    </div>
  )
}

export default TemplateModalGeneral;