import React from "react";
import Icon from "../../../../ui-components/icon/Icon";
import './BulkBookingStep.scss';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

const Step: React.FC<{
  title: string,
  status: 'todo' | 'active' | 'complete' | string | undefined,
  clickable: boolean,
  lines: boolean,
  onClick: () => void
}> = ({
  title,
  status,
  clickable,
  lines,
  onClick
}) => {

  const lineStyle = status === 'todo' ? {color: 'var(--textMid)'} : {color: 'var(--secondary)'};

  const {width} = useWindowDimensions();

  return (
    <>
      {lines
      &&  <div
            className="bulk-booking-step__dashes"
            style={lineStyle}
          >------------
          </div>
      }

      <div
        onClick={onClick}
        style={
          clickable === false ? {pointerEvents: 'none'} : {}
        }
      >
        {status === 'todo'
        &&  <div className="bulk-booking-step bulk-booking-step-todo">
              <div className="bulk-booking-step__title bulk-booking-step__title--todo">{title}</div>
              <Icon
                name="PlaneOutline"
                style={{
                  width: width < 901 ? '30' : '35.5px',
                  height: '30px',
                  fill: 'var(--textMid)'
                }}
              />
            </div>}

        {status === 'active'
        &&  <div className="bulk-booking-step bulk-booking-step-active">
              <div className="bulk-booking-step__title bulk-booking-step__title--active">{title}</div>
              <Icon
                name="PlaneSolid"
                style={{
                  width: width < 901 ? '34' : '43px',
                  height: '30px',
                  fill: 'var(--primary)'
                }}
              />
            </div>
        }

        {status === 'complete'
        &&  <div className="bulk-booking-step bulk-booking-step-complete">
              <div className="bulk-booking-step__title bulk-booking-step__title--complete">{title}</div>
              <Icon
                name="CircleCheck"
                style={{
                  width: width < 901 ? '28px' : '30px',
                  height: '30px',
                  fill: 'var(--secondary)'
                }}
              />
            </div>
        }
      </div>
    </>
  )
};

export default Step;