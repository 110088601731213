import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { AMIAlert, AMIButton, AMIModal } from '../../ui-components/UiComponents';
import { customerActions, customerDetailsSelector } from '../../features/customerSlice';
import { refreshAuth } from '../../utilities/APIUtilities';
import dayjs from 'dayjs';

let showModal: any;
let logout: any;
let interval: any;
let refreshAccessToken: any;

const InactivityModal = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const customer = useAppSelector(customerDetailsSelector);

  const [isVisible, setIsVisible] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [inactiveTime, setInactiveTime] = useState(0)

  useEffect(() => {
    handleTimer();

    return () => {
      cancelTimer();
    }
  }, []);

  showModal = () => {
    setIsVisible(true);
  }

  logout = () => {
    history.push('/auth');
    window.location.reload();
  }

  refreshAccessToken = async() => {
    clearInterval(interval);
    const response = await refreshAuth(customer);
    if (response) {
      const expiration = dayjs().add(1, 'h').toISOString();
      await dispatch(customerActions.updateField({
        field: 'auth',
        value: {
          ...customer.auth,
          accessToken: response.data.auth.accessToken,
          expiration: expiration
        }
      }))
      handleTimer(expiration);
    } else {
      handleTimer();
    }

    return response;
  }

  const handleTimer = (expiration: string = customer.auth.expiration) => {
    interval = setInterval(() => {
      setInactiveTime(dayjs().diff(dayjs(expiration), 'm') + 59);
      const remainingMins = dayjs(expiration).diff(dayjs(), 'm') + 1;
      if (remainingMins <= 5) {
        showModal(true);
      }

      if (remainingMins <= 0) {
        logout();
      }
    }, 1000);
  }

  const cancelTimer = () => {
    clearInterval(interval);
  }

  const onContinueInactivityModal = async() => {
    setRefreshError(false);
    setIsSpinning(true);
    const success: any = await refreshAccessToken();
    setIsSpinning(false);

    if (success) {
      setIsVisible(false);
    } else {
      setErrorCount(errorCount + 1);
      if (errorCount >= 5) {
        history.push('/auth');
      }
      setRefreshError(true);
    }
  }

  return (
    <>
      {isVisible &&
        <AMIModal
          title="Attention"
          width="50%"
          close={onContinueInactivityModal}
          buttons={[<AMIButton
            key={Math.random()}
            variant='default'
            size="large"
            onClick={logout}
          >Logout
          </AMIButton>,
          <AMIButton
            key={Math.random()}
            variant='primary'
            size="large"
            isSpinning={isSpinning}
            onClick={onContinueInactivityModal}
          >Continue
          </AMIButton>
        ]}
        >
          You have been inactive for {inactiveTime} minutes, please press continue or logout
          {refreshError &&
            <AMIAlert
              variant="error"
              style={{marginTop: '12px'}}
            >
              Error: Please press continue or logout and try again
            </AMIAlert>
          }
        </AMIModal>}
    </>

  )
}

export { logout, showModal, refreshAccessToken };
export default InactivityModal;