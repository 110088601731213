import React from 'react'
import './Table.scss';

const AMITable: React.FC<{
  variant?: 'plain' | 'styled',
  style?: React.CSSProperties,
  className?: string,
  id?: string
}> = ({
  children,
  variant,
  style,
  className,
  id
}) => {

  let tableClass: string = 'plain-table';
  if (variant === 'styled') {
    tableClass = 'styled-table';
  }

  return (
    <table
      className={className + ' ' + tableClass}
      id={id}
      style={style}
    >
      {children}
    </table>
  )
};

export default AMITable;