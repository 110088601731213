import React, { useEffect, useState } from 'react';
import './SavedAddressSelect.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AMIFormElement, AMISelect } from '../../ui-components/UiComponents';
import { appDataSelector } from '../../features/appDataSlice';
import { bookingActions, bookingSelector } from '../../features/bookingSlice';
import { customerDetailsSelector } from '../../features/customerSlice';
import { createCountryObject } from '../../utilities/HelperUtilities';

export enum DetailSection {
  COLLECTION_DETAILS = 'collectionDetails',
  SHIPPER_DETAILS = 'shipperDetails',
  CONSIGNEE_DETAILS = 'consigneeDetails',
  BROKER_DETAILS = 'brokerDetails',
}

const SavedAddressSelect: React.FC<{
  section: DetailSection,
  filters?: string[],
  onChange?: (event: any) => void
}> = ({
  section,
  filters,
  onChange
}) => {


  const booking = useAppSelector(bookingSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const appData = useAppSelector(appDataSelector);
  const dispatch = useAppDispatch();

  const [filteredAddresses, setFilteredAddresses] = useState<any>([]);
  const [unfilteredAddresses, setUnfilteredAddresses] = useState<any>();
  const [addressOptions, setAddressOptions] = useState<any>([]);

  useEffect(() => {
    mapAddresses();
  }, [customer.savedAddresses]);

  useEffect(() => {
    if (unfilteredAddresses) {
      filterAddresses();
    }
  }, [unfilteredAddresses]);

  useEffect(() => {
    setAddressOptions(populateAddressOptions);
  }, [filteredAddresses]);

  const filterAddresses = () => {
    let filteredAddresses = [...unfilteredAddresses];

    if (filters && filters.length > 0) {
      if (filters.includes('cityTown')) {
        filteredAddresses = filteredAddresses.filter((address: any) => {
          return address.cityTown.toLowerCase().replace(/\s/g,'') === booking[section].cityTown.toLowerCase().replace(/\s/g,'');
        })
      }

      if (filters.includes('postalCode')) {
        filteredAddresses = filteredAddresses.filter((address: any) => {
          return address.postalCode.toLowerCase().replace(/\s/g,'') === booking[section].postalCode.toLowerCase().replace(/\s/g,'');
        })
      }
    }

    setFilteredAddresses(filteredAddresses);
  }

  const mapAddresses = () => {
    const mappedAddresses = customer.savedAddresses.map((e: any) => {
      const {address, contactDetails, notificationDetails} = e.userDetails;
      return {
        branchId: e.branchId,
        id: e.id,
        contactName: contactDetails.name,
        companyName: address.organisation,
        addressLine1: address.streetLines[0],
        addressLine2: address.streetLines[1] ? address.streetLines[1] : '',
        addressLine3: address.streetLines[2] ? address.streetLines[2] : '',
        countyStateProvince: address.stateOrCounty,
        cityTown: address.city,
        postalCode: address.postalCode,
        country: createCountryObject(appData.countries, address.countryCode),
        telephoneNumber: contactDetails.telephone,
        email: notificationDetails.email
      }
    })
    setUnfilteredAddresses(mappedAddresses);
  }

  const handleSelect = (event: any) => {
    const address = event.value;

    switch (section) {
      case DetailSection.COLLECTION_DETAILS:
        dispatch(bookingActions.setCollectionDetails(address));
        break;
      case DetailSection.SHIPPER_DETAILS:
        dispatch(bookingActions.setShipperDetails(address));
        break;
      case DetailSection.CONSIGNEE_DETAILS:
        dispatch(bookingActions.setConsigneeDetails(address));
        break;
      case DetailSection.BROKER_DETAILS:
        dispatch(bookingActions.setBrokerDetails(address));
        break;
    }

    if (onChange) {
      onChange(address);
    }
  }

  const populateAddressOptions = () => {
    const addressOptions = filteredAddresses.map((address: any) => {
      return {
        title: `${address.contactName}, ${address.companyName}, ${address.addressLine1}, ${address.addressLine2 ? address.addressLine2 + ',' : ''} ${address.addressLine3 ? address.addressLine3 + ',' : ''} ${address.cityTown}, ${address.country.value}, ${address.postalCode}`,
        value: address
      }
    })

    return customer.creditCheck.branchId ? addressOptions : [{title: 'Please contact customer services to enable address book features', value: null, disabled: true}]
  }

  return (
    <div className="saved-address-select">
      <AMIFormElement label="Use a saved address?">
        <AMISelect
          name="saved-address-select"
          size="large"
          isSearchable
          options={addressOptions.length > 0 ? addressOptions : [{title: 'No saved addresses match the criteria', value: null, disabled: true}]}
          onChange={handleSelect}
        />
      </AMIFormElement>
    </div>
  )
}

export default SavedAddressSelect;