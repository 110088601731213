export const defaultColors = {
  primary: '#85AED9',
  secondary: "#1f1f1f",
  tertiary: "#949494",
  success: '#219653',
  error: '#EB5757',
  warning: '#F2C94C',
  text: '#333333',
  textMid: '#999999',
  textLight: '#FFFFFF',
  disabledTodo: "#dedede",
  background: '#FFFFFF',
  backgroundDark: '#333333',
  backgroundHover: "#d1d1d1"
}

export const amiColors = {
  primary: '#932682',
  secondary: "#003B78",
  tertiary: "#85AED9",
  success: '#219653',
  error: '#EB5757',
  warning: '#F2C94C',
  text: '#333333',
  textMid: '#999999',
  textLight: '#FFFFFF',
  disabledTodo: "#EBEBEB",
  background: '#FFFFFF',
  backgroundDark: '#333333',
  backgroundHover: "#bb85b348"
}

export const setDocumentColors = (colors: any) => {
  document.documentElement.style.setProperty('--primary', colors.primary);
  document.documentElement.style.setProperty('--secondary', colors.secondary);
  document.documentElement.style.setProperty('--tertiary', colors.tertiary);
  document.documentElement.style.setProperty('--success', colors.success);
  document.documentElement.style.setProperty('--error', colors.error);
  document.documentElement.style.setProperty('--warning', colors.warning);
  document.documentElement.style.setProperty('--text', colors.text);
  document.documentElement.style.setProperty('--textMid', colors.textMid);
  document.documentElement.style.setProperty('--textLight', colors.textLight);
  document.documentElement.style.setProperty('--disabledTodo', colors.disabledTodo);
  document.documentElement.style.setProperty('--background', colors.background);
  document.documentElement.style.setProperty('--backgroundDark', colors.backgroundDark);
  document.documentElement.style.setProperty('--backgroundHover', colors.backgroundHover);
}

export interface IndirectCustomerData {
  address: {
    addr1: string;
    addr2: string;
    addr3: string;
    city: string;
    country: string;
    "postal-code": string;
    state: string;
  },
  "booking-allowed": boolean;
  "is-white-label-parent": boolean;
  "party-full-name": string;
  "site-code": string;
  "tmff-party-id": string;
  "white-label": boolean;
  "white-label-parent": string;
}