import React from 'react';
import './FormElement.scss';

const AMIFormElement: React.FC<{
  label?: any,
  errorMessage?: string,
  id?: string,
  className?: string,
  style?: React.CSSProperties,
}> = ({
  label,
  errorMessage,
  id,
  className,
  style,
  children,
}) => {

  const getClasses = () => {
    let classes = `${className} ami-form-element `;

    if (errorMessage) {
      classes = classes.concat('ami-form-element--error ')
    }

    return classes;
  }

  let formElementClasses = getClasses();

  return (
    <div
      id={id}
      className={formElementClasses}
      style={style}
    >
      <div className="ami-form-element__label">{label}</div>
      {children}
      <p className="ami-form-element__error-message">{errorMessage}</p>
    </div>
  )
}

export default AMIFormElement;