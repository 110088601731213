import React, { useState } from 'react';
import { Icon } from '../../../ui-components/UiComponents';

const CopyRoute: React.FC<{route: string}> = ({route}) => {

  const [isCopied, setIsCopied] = useState(false);

  const onCopyClick = () => {
    navigator.clipboard.writeText(route);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  }

  return (
    <div
      className="copy-route"
      style={isCopied ? {color: 'var(--success)'} : {color: 'inherit'}}
      onClick={onCopyClick}
    >
      {!isCopied && (
        <Icon
          name="Copy"
          style={{
            transform: "translateY(3px) scale(0.8)",
            marginRight: "2px"
          }}
        />
      )}
      {isCopied && (
        <Icon
          name="CircleCheck"
          style={{
            transform: "translateY(3px) scale(0.9)",
            marginRight: "2px",
            fill: "var(--success)"
          }}
        />
      )}
      {isCopied && "Copied to clipboard"}
      {!isCopied && "Copy route"}
    </div>
  )
}

export default CopyRoute;