import { Template } from "../../../utilities/TemplateHandler";
import { CustomerDetails } from "../../../features/customerSlice";

export function createTemplateRequestBody(customer: CustomerDetails, templates: Template[]) {
  return {
    item: {
      partyId: customer.creditCheck.tmffPartyId,
      templates
    }
  }
}