import React from 'react';
import Icon from '../icon/Icon';
import './Popover.scss';

const AMIPopover: React.FC<{
  isVisible: boolean,
  position?: 'top' | 'bottom',
  style?: React.CSSProperties,
  id?: string
}> = ({
  isVisible,
  position,
  style,
  id,
  children
}) => {

  let popoverStyle = {
    ...style,
    opacity: isVisible ? 1 : 0,
    height: isVisible ? 'auto' : '0px',
    width: isVisible ? 'auto' : '0px',
    transform: 'translate(50%, -100%)'
  }

  if (position === 'bottom') {
    popoverStyle = {
      ...popoverStyle,
      transform: `translate(50%, 0px)`,
    }
  }

  return  (
    <div
      className="popover"
      id={id}
      style={popoverStyle}
    >
      {children}
    </div>
  )
}

export default AMIPopover;