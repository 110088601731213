import dayjs from 'dayjs';
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

const daysMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const monthMap = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const oneDay = 60 * 60 * 24 * 1000;
export const todayTimestamp = Date.now() - (Date.now() % oneDay) + (new Date().getTimezoneOffset() * 1000 * 60);

const getDayDetails = (args: any) => {
  let date = args.index - args.firstDay; 
  let day = args.index%7;
  let prevMonth = args.month - 1;
  let prevYear = args.year;
  if (prevMonth < 0) {
      prevMonth = 11;
      prevYear--;
  }
  let prevMonthNumberOfDays = getNumberOfDays(prevYear, prevMonth);
  let _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
  let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
  let calenderMonth = date < 0 ? args.month -1 : date >= args.numberOfDays ? args.month + 1 : args.month;
  if (calenderMonth < 0) {
    calenderMonth = 11;
  }

  let timestamp = new Date(args.year, calenderMonth, _date).getTime();
  return {
      date: _date,
      day,
      month,
      calenderMonth,
      timestamp,
      dayString: daysMap[day]
  }
}

export const getMonthDetails = (year: any, month: any) => {
  let firstDay = (new Date(year, month)).getDay();
  let numberOfDays = getNumberOfDays(year, month);
  let monthArray = [];
  let rows = 6;
  let currentDay = null;
  let index = 0; 
  let cols = 7;

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) { 
      currentDay = getDayDetails({
        index,
        numberOfDays,
        firstDay,
        year,
        month
      });
      monthArray.push(currentDay);
      index++;
    }
  }
  return monthArray;
}

const getNumberOfDays = (year: any, month: any) => {
  const numDays = 40 - new Date(year, month, 40).getDate();
  return numDays;
}

export const isCurrentDay = (day: any) => {
  return day.timestamp === todayTimestamp;
}

export const getMonthString = (month: any): string => {
  return monthMap[Math.max(Math.min(11, month), 0)] || 'Month';
}

export const getLastFutureDay = (numDays: number | undefined): any => {
  if (numDays) {
    return todayTimestamp + (numDays * oneDay);
  }
}

export const calculateRealEndDate = (disabledDates: any[] | undefined, numFutureDays: number | undefined): number => {
  let days = numFutureDays ? numFutureDays : 0;
  let date = dayjs();

  let i = 0;
  while (i < days) {
    i++;
    if (disabledDates?.find(el => dayjs(el).isSame(date, 'day'))) {
      days += 1;
    }

    if (date.day() === 6) {
      days += 2;
      date = date.add(2, 'day')
    } else if (date.day() === 0) {
      days += 1;
      date = date.add(1, 'day')
    } else {
      date = date.add(1, 'day')
    }
  }
  return days;
}

export const formatDisabledDates = (dates: any): number[] | undefined => {
  return dates.map((date: number) => new Date(date).getTime());
}

export function isNotAfterDate(dateString: any)  {
  return Date.now() < new Date(dateString).valueOf();
}

