import { Booking } from "../features/bookingSlice";
import { CustomerDetails } from "../features/customerSlice";
import { getCarrierName } from "./HelperUtilities";
import { getTradeRoute, TradeRoute } from "./RulesEngineUtilities";

export interface CourierInformation {
  name: string,
  content: string
}

const findCourierInformation = (name: string, courierInfoArray: any[]) => {
  return courierInfoArray.find((el: any) => {
   return el.name === name;
  }) as CourierInformation;
}

export const getCourierInformation = (customer: CustomerDetails, booking: Booking, quoteId: string, courierInfoContent: any) => {
  const carrier = getCarrierName(quoteId);
  const tradeRoute = getTradeRoute(customer, booking);

  let courierInformation = courierInfoContent[0];

  if (carrier === 'fedex' && tradeRoute === TradeRoute.DOMESTIC) {
    courierInformation = (findCourierInformation('FEDEX_DOMESTIC', courierInfoContent));
  }

  if (carrier === 'fedex' && tradeRoute === TradeRoute.EXPORT) {
    courierInformation = (findCourierInformation('FEDEX_EXPORT', courierInfoContent));
  }

  if (carrier === 'dhl' && tradeRoute === TradeRoute.EXPORT) {
    courierInformation = (findCourierInformation('DHL_EXPORT', courierInfoContent));
  }

  if (carrier === 'dhl' && (
    tradeRoute === TradeRoute.IMPORT
    || tradeRoute === TradeRoute.CROSSTRADE
  )) {
    courierInformation = (findCourierInformation('DHL_IMPORT_CROSSTRADE', courierInfoContent));
  }
  return courierInformation;
}

export const getCarrierTermsAndConditions = (booking: Booking, quoteId: string) => {
  const carrier = getCarrierName(quoteId);
  if (booking.origin.value === "AU") {
    switch (carrier) {
      case 'dhl' :
        return '/DHL_Global_Terms_and_Conditions.pdf';
      case 'ups' :
        return '/UPS_AU_2023_TCs_pg_27.pdf';
      default :
        return false;
    }
  }
}