import React from 'react';
import { AMIAlert, AMIFormElement, AMIInput, AMIMultiButton, AMISelect, Icon } from '../../../../../ui-components/UiComponents';
import { useAppSelector } from '../../../../../app/hooks';
import { appDataSelector } from '../../../../../features/appDataSlice';
import { customerDetailsSelector } from '../../../../../features/customerSlice';
import { formatShipmentTypeForDisplay } from '../../../../../utilities/HelperUtilities';
import { ImperialMetric, Piece, ShipmentType } from '../../../../../features/bookingSlice';
import { getTotalWeight } from '../../../../single-booking/home-page/utils';
import { TemplateForm } from '../../../../../utilities/TemplateHandler';
import TemplatePiece from './TemplatePiece';

const TemplateModalPieces: React.FC<{
  template: TemplateForm;
  updatePiecesSection: (field: string, value: any) => void;
}> = ({
  template,
  updatePiecesSection
}) => {

  const appData = useAppSelector(appDataSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const data = template.piecesSection;

  const copyPiece = (piece: any, index: number) => {
    const pieceCopy = {...piece};
    const piecesCopy = [...data.pieces];
    piecesCopy.splice(index + 1, 0, pieceCopy);
    updatePiecesSection("pieces", piecesCopy);
  }

  const deletePiece = (index: number) => {
    if (data.pieces.length > 1) {
      const piecesCopy = [...data.pieces];
      piecesCopy.splice(index, 1);
      updatePiecesSection("pieces", piecesCopy);
    }
  }

  const handleAddPiece = () => {
    const pieces = [...data.pieces];
    pieces.push(
      {
        weight: '',
        length: '',
        width: '',
        height: '',
        value: ''
      } as Piece,
    );
    updatePiecesSection("pieces", pieces)
  }

  return (
    <div className="template-modal-general">
      <p className="shipment-type__header">Shipment Type:</p>

      <AMIMultiButton
        buttons={customer.customerCountryCode === "GB" ? (
          [
            {title: formatShipmentTypeForDisplay(ShipmentType.NON_DOCS), value: ShipmentType.NON_DOCS},
            {title: formatShipmentTypeForDisplay(ShipmentType.DOCS), value: ShipmentType.DOCS},
          ]
        ) : (
          [
            {title: formatShipmentTypeForDisplay(ShipmentType.NON_DOCS), value: ShipmentType.NON_DOCS},
            {title: formatShipmentTypeForDisplay(ShipmentType.DOCS), value: ShipmentType.DOCS},
            {title: formatShipmentTypeForDisplay(ShipmentType.ENVELOPE), value: ShipmentType.ENVELOPE},
          ]
        )}
        selected={{title: formatShipmentTypeForDisplay(data.shipmentType), value: data.shipmentType}}
        style={{
          width: '300px',
          marginBottom: '12px'
        }}
        onClick={(event: any) => updatePiecesSection("shipmentType", event.value)}
      >
      </AMIMultiButton>

      <AMIFormElement label="Total Shipment Value">
        <AMIInput
          name="total-value"
          type="number"
          size="large"
          min="0"
          value={data.totalShipmentValue}
          onChange={(event) => updatePiecesSection("totalShipmentValue", event.target.value)}
        />
      </AMIFormElement>

      <div className="shipment-type__currency-picker">
        <AMISelect
          name="currency-picker"
          tabIndex={-1}
          options={appData.currencies}
          defaultValue={data.preferredCurrency}
          isSearchable
          hideClearButton
          onChange={(event) => updatePiecesSection("preferredCurrency", event)}
        ></AMISelect>
      </div>
      {data.shipmentType !== ShipmentType.ENVELOPE && data.pieces.map((piece: any, index: number) => {
        return (
          <div key={index}>
            <div className="shipment-type__piece-counter">Piece {index + 1}</div>
            <TemplatePiece
              template={template}
              piece={piece}
              index={index}
              isLastPiece={data.pieces.length === index + 1}
              isOnlyPiece={data.pieces.length === 1}
              shipmentType={data.shipmentType}
              onCopy={() => copyPiece(piece, index)}
              onDelete={() => deletePiece(index)}
              onUpdate={(pieces) => updatePiecesSection("pieces", pieces)}
            ></TemplatePiece>
          </div>
        )
      })}

      {data.shipmentType === ShipmentType.ENVELOPE
      && (
        <TemplatePiece
          template={template}
          piece={data.pieces[0]}
          index={0}
          isLastPiece={true}
          isOnlyPiece={data.pieces.length === 1}
          shipmentType={data.shipmentType}
          onCopy={() => null}
          onDelete={() => null}
          onUpdate={(pieces) => updatePiecesSection("pieces", pieces)}
        ></TemplatePiece>
      )}

      {data.pieces.length > 30 && (
        <AMIAlert variant="warning" style={{marginBottom: '24px'}}>FedEx will only allow shipments of up to 30 parcels, please split into multiple booking requests if you wish to get a quote for their services.</AMIAlert>
      )}

      <p className="shipment-type__piece-totals">Pieces: <strong>{data.pieces.length}</strong>
        <br /> Total weight: <strong>{getTotalWeight(data.pieces)}{data.imperialMetric === ImperialMetric.IMPERIAL ? 'lb' : 'kg'}</strong>
      </p>

      {data.pieces.length < 300
        && data.shipmentType !== ShipmentType.ENVELOPE
        && <div
          className="shipment-type__add-piece"
          onClick={handleAddPiece}
        >
          <Icon name="Close" color="var(--primary)" /> Add Another Piece
        </div>
      }
    </div>
  )
}

export default TemplateModalPieces;