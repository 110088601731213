import React from 'react';
import './Spinner.scss';

interface Props {
  style?: React.CSSProperties,
  className?: string,
  variant?: 'white' | 'multi-color'
}

const AMISpinner: React.FC<Props> = ({
  variant,
  style,
  className
}) => {

  let whiteClass = '';
  if (variant === 'white') {
    whiteClass = ' spinner__circle--white';
  }

  return (
    <div
      id="spinner"
      className={className + ' spinner'}
      style={style}
    >
      <div
        id="circle"
        className={"spinner__circle spinner__circle--darkblue" + whiteClass}
      ></div>
      <div className={"spinner__circle spinner__circle--lightblue" + whiteClass}></div>
      <div className={"spinner__circle spinner__circle--pink" + whiteClass}></div>
    </div>
  )
};

export default AMISpinner;