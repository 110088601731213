import React, { useEffect, useState } from 'react';
import './ReviewPage.scss';
import { AMIButton, AMICheckbox, AMIModal, AMITable, Icon } from '../../../ui-components/UiComponents';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { manifestActions, manifestSelector } from '../../../features/manifestSlice';
import { ActivePage } from '../steps/Steps';

const ReviewPage: React.FC<{changeStep: (arg0: any) => void}> = ({changeStep}) => {

  const dispatch = useAppDispatch();
  const manifest = useAppSelector(manifestSelector);
  const csvData = manifest.csvData as any;

  const [selectedRowIndexes, setSelectedRowIndexes] = useState<any[]>([]);
  const [btnVisible, setBtnVisible] = useState<boolean>(true);
  const [rowToView, setRowToView] = useState <any>(null);

  useEffect(() => {
    updateBtnDisplay();
    window.addEventListener('scroll', updateBtnDisplay);
    window.addEventListener('resize', updateBtnDisplay);

    return () => {
      window.removeEventListener('resize', updateBtnDisplay);
      window.removeEventListener('resize', updateBtnDisplay);
    }
  }, [])

  const updateBtnDisplay = () => {
    const backButton = document.getElementById('review-page-back-button') as HTMLElement;

    const elementIsVisibleInViewport = (el: HTMLElement, partiallyVisible = false) => {
      const { top, left, bottom, right } = el.getBoundingClientRect();
      const { innerHeight, innerWidth } = window;
      return partiallyVisible
        ? ((top > 0 && top < innerHeight) ||
            (bottom > 0 && bottom < innerHeight)) &&
            ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
        : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
    };

    if (backButton && elementIsVisibleInViewport(backButton)) setBtnVisible(false);
    else setBtnVisible(true);
  }

  const onTrashClick = (rowIndex: number) => {
    const updatedData = csvData.filter((el: any, index: number) => {
      return index !== rowIndex;
    })
    dispatch(manifestActions.saveCsvData(updatedData));
  }

  const onViewPieceClick = (row: any) => {
    const keys = Object.keys(row);
    const values = Object.values(row);
    const rowInfo = [];
    for (const key of keys) {
      const index = keys.indexOf(key);
      rowInfo.push({header: key, value: values[index]})
    }
    setRowToView(rowInfo);
  }

  const onCheck = (index: number) => {
    if (!selectedRowIndexes.includes(index)) setSelectedRowIndexes([...selectedRowIndexes, index]);
    else setSelectedRowIndexes([...selectedRowIndexes].filter(el => el !== index));
  }

  const onMultiEditDeleteClick = () => {
    const updatedData = csvData.filter((el: any, index: number) => {
      return !selectedRowIndexes.includes(index);
    })

    dispatch(manifestActions.saveCsvData(updatedData));
    onSelectAllClick(true);
  }

  const onSelectAllClick = (unselectAll?: boolean) => {
    if (selectedRowIndexes.length === csvData.length || unselectAll) setSelectedRowIndexes([]);
    else {
      const indexes = csvData.map((el: any, index: number) => {
        return index;
      })
      setSelectedRowIndexes(indexes);
    }
  }

  const onBackClick = () => {
    changeStep(ActivePage.UPLOAD_PAGE);
  }

  const onContinueClick = () => {
    changeStep(ActivePage.OPTIONS_PAGE);
  }

  const onExportClick = () => {
    let rows = [['SHIPMENT_REFERENCE',	'SHIPMENT_TYPE',	'ITEM_NAME',	'VALUE',	'WEIGHT',	'LENGTH',	'WIDTH',	'HEIGHT',	'ORGANISATION',	'NAME',	'ADDRESS_LINE_1',	'ADDRESS_LINE_2',	'CITY/TOWN',	'COUNTY/STATE/PROVINCE',	'POSTAL_CODE',	'COUNTRY_CODE',	'TELEPHONE',	'EMAIL',	'DDP',	'INSURANCE',	'SHIPPER_TAX_TYPE',	'SHIPPER_TAX_NO',	'CNEE_TAX_TYPE',	'CNEE_TAX_NO',	'COMMODITY_CODE',	'COMMODITY_DESCRIPTION',	'COMMODITY_WEIGHT',	'COMMODITY_PIECES',	'COMMODITY_VALUE_PIECE',	'INVOICE_NUMBER',	'INVOICE_DATE']];
    for (const row of csvData) {
      rows.push(Object.values(row));
    }

    const csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  return (
    <div className="review-page">

      {selectedRowIndexes && selectedRowIndexes.length > 0 && (
        <div className="review-page__multi-select-banner">
          <p><span>{selectedRowIndexes.length}</span> parcel{selectedRowIndexes.length > 1 ? "s" : ""} selected</p>
          <p><strong>Multi Select</strong></p>
          <div
            className="review-page__multi-select-banner__icon"
            onClick={onMultiEditDeleteClick}
          >
            <Icon name="Trash" color="white"/>
          </div>
        </div>
      )}

      <AMITable
        className="review-page__table"
        id="review-page-table"
        variant="styled"
      >
        <thead>
          <tr>
            <th>
            <div className="review-page__table__checkbox review-page__table__checkbox--header">
              <AMICheckbox
                text=""
                onChange={() => onSelectAllClick(false)}
                checked={selectedRowIndexes.length === csvData.length}
              />
            </div>
            </th>
            <th>SHIPMENT_REFERENCE</th>
            <th>ITEM_NAME</th>
            <th>ORGANISATION</th>
            <th>POSTAL_CODE</th>
            <th>COUNTRY</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {csvData.map((row: any, index: number) => {
            return (
              <tr key={row.shipment_reference + index.toString()}>
                <td>
                  <div className="review-page__table__checkbox">
                    <AMICheckbox
                      text=""
                      onChange={() => onCheck(index)}
                      checked={selectedRowIndexes.includes(index)}
                    />
                  </div>
                </td>
                <td>{row.shipment_reference}</td>
                <td>{row.item_name}</td>
                <td>{row.organisation}</td>
                <td>{row.postal_code}</td>
                <td>{row.country_code}</td>
                <td className="review-page__table__icons">
                  <div
                    className="review-page__table__icons__icon"
                    onClick={() => onViewPieceClick(row)}
                  >
                    <Icon name="Eye" />
                  </div>
                  <div
                    className="review-page__table__icons__icon"
                    onClick={() => onTrashClick(index)}
                  >
                    <Icon name="Trash" />
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </AMITable>

      <div className="review-page__btn-container">
      <AMIButton
          variant="default"
          size="large"
          onClick={onExportClick}
        >Export CSV</AMIButton>

        <AMIButton
          id="review-page-back-button"
          variant="secondary"
          size="large"
          onClick={onBackClick}
        >Back</AMIButton>

        <AMIButton
          variant="primary"
          size="large"
          onClick={onContinueClick}
        >Continue</AMIButton>
      </div>

      {btnVisible && (<div className="review-page__btn-container--collapsed">
        <AMIButton
          variant="default"
          size="large"
          onClick={onExportClick}
        >
          <Icon
            name="Download"
            style={{
              transform: "translate(3px, 3px)"
            }}
          />
        </AMIButton>

        <AMIButton
          variant="secondary"
          size="large"
          onClick={onBackClick}
        >
          <Icon
            name="ChevronLeft"
            color="white"
            style={{
              transform: "translate(3px, 3px)"
            }}
          />
        </AMIButton>

        <AMIButton
          variant="primary"
          size="large"
          onClick={onContinueClick}
        >
          <Icon
            name="ChevronRight"
            color="white"
            style={{
              transform: "translate(3px, 3px)"
            }}
          />
        </AMIButton>
      </div>)}

      {rowToView && (
        <AMIModal
          className="review-page__modal__table"
          title={rowToView[0].value}
          width="50%"
          close={() => setRowToView(null)}
          buttons={[
            <AMIButton
              key={Math.random()}
              variant='default'
              size="large"
              onClick={() => setRowToView(null)}
            >Close
            </AMIButton>
          ]}
        >
          {
            <AMITable variant="styled">
              <thead>
                <tr>
                  <th>Header</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {rowToView.map((el: any) => {
                  return (
                    <tr key={el.header}>
                      <td>{el.header}</td>
                      <td>{el.value}</td>
                    </tr>
                  )
                })}
              </tbody>
            </AMITable>
          }
        </AMIModal>
      )}
    </div>
  )
}

export default ReviewPage;