import React, { useEffect, useRef, useState } from 'react';
import './WhiteLabelCustomisePage.scss';
import { AMIAlert, AMIButton, AMISpinner } from '../../../../ui-components/UiComponents';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { appDataActions, appDataSelector } from '../../../../features/appDataSlice';
import WhiteLabelUpload from './white-label-upload/WhiteLabelUpload';
import { getThemeData, setThemeData, setWlImage } from '../../../../utilities/APIUtilities';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { amiColors, defaultColors, setDocumentColors } from '../WhiteLabelUtils';
import { getS3ImageUrl } from '../../../../utilities/HelperUtilities';

const WhiteLabelCustomisePage = () => {

  const dispatch = useAppDispatch();
  const apiConfig = useAppSelector(appDataSelector).apiConfig;
  const customer = useAppSelector(customerDetailsSelector);
  const { previewMode, temporaryBanner, temporaryLogo, temporaryColors, existingTheme } = useAppSelector(appDataSelector);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isFetching, setIsFetching] = useState(true);

  const primaryColorRef = useRef<any>();
  const secondaryColorRef = useRef<any>();
  const tertiaryColorRef = useRef<any>();
  const successColorRef = useRef<any>();
  const errorColorRef = useRef<any>();
  const warningColorRef = useRef<any>();
  const textColorRef = useRef<any>();
  const textMidColorRef = useRef<any>();
  const textLightColorRef = useRef<any>();
  const disabledTodoColorRef = useRef<any>();
  const backgroundColorRef = useRef<any>();
  const backgroundDarkColorRef = useRef<any>();
  const backgroundHoverColorRef = useRef<any>();

  const [colors, setColors] = useState(temporaryColors ? temporaryColors : defaultColors)

  useEffect(() => {
    if (!previewMode) getPreferences();
    if (previewMode) setIsFetching(false);
  }, [])

  const getImage = async(id: string) => {
    if (id) return await getS3ImageUrl(customer, id);
    else return '';
  }

  const getPreferences = async() => {
    setIsFetching(true);
    const themeResponse: any = await getThemeData(apiConfig);
    if (themeResponse?.data?.Item) {
      const colourScheme = themeResponse.data.Item.colourScheme;
      const bannerUuid = themeResponse.data.Item.bannerImageUuid;
      const logoUuid = themeResponse.data.Item.logoUuid;
      const logoUrl = await getImage(logoUuid);
      const bannerUrl = await getImage(bannerUuid);

      dispatch(appDataActions.setExistingTheme({
        ...themeResponse.data.Item,
        logoUrl,
        bannerUrl
      }));
      setColors({...colourScheme});
      dispatch(appDataActions.setTemporaryLogoUrl(logoUrl));
      dispatch(appDataActions.setTemporaryBannerUrl(bannerUrl));
    }
    setIsFetching(false);
  }

  const onColorClick = (ref: any) => {
    ref.current.click();
  }

  const onColorChange = (event: any, variant: string) => {
    setColors({
      ...colors,
      [variant]: event.target.value
    })
  }

  const setTempTheme = () => {
    dispatch(appDataActions.setPreviewMode(true));
    dispatch(appDataActions.setTemporaryColors(colors));
    setDocumentColors(colors);
  }

  const revertTempTheme = () => {
    dispatch(appDataActions.setPreviewMode(false));
    setDocumentColors(amiColors);
    const colourScheme = existingTheme.colourScheme;
    setColors(colourScheme);
    dispatch(appDataActions.setTemporaryLogo({
      name: '',
      content: '',
      url: existingTheme.logoUrl
    }))
    dispatch(appDataActions.setTemporaryBanner({
      name: '',
      content: '',
      url: existingTheme.bannerUrl
    }))
  }

  const saveImage = async(image: any, type: 'banner' | 'logo') => {
    let uuid = null;
    const extension = image.name.split('.')[1]?.toLowerCase();
    const response: any = await setWlImage(image.content, extension, apiConfig, type);
    if (response?.status === 200) uuid = `${customer.creditCheck.tmffPartyId}_${type}`;
    return uuid;
  }

  const saveChanges = async() => {
    setErrorMessage('');
    setIsSaving(true);

    let logoUuid = null;
    let bannerUuid = null;
    if (temporaryLogo.content) logoUuid = await saveImage(temporaryLogo, 'logo');
    if (temporaryBanner.content) bannerUuid = await saveImage(temporaryBanner, 'banner');
    if (
      (temporaryLogo.content && !logoUuid)
      || (temporaryBanner.content && !bannerUuid)
    ) {
      setErrorMessage('An error ocurred while saving your theme images. Please try again.');
      setIsSaving(false);
      return;
    }

    const themeResponse: any = await setThemeData(
      {
        item: {
          parentPartyId: customer.creditCheck.tmffPartyId,
          bannerImageUuid: temporaryBanner.content ? bannerUuid : existingTheme.bannerImageUuid,
          logoUuid: temporaryLogo.content ? logoUuid : existingTheme.logoUuid,
          colourScheme: colors,
          labelPreferences: {
              size: 'A5',
              type: 'thermal'
          }
        }
      },
      apiConfig,
      customer.creditCheck.tmffPartyId
    );
    if (themeResponse) {
      setSuccessMessage('Theme successfully saved.');
      revertTempTheme();
    }
    if (!themeResponse) setErrorMessage('An error ocurred while saving your theme. Please try again.');
    setIsSaving(false);
  }


  const onMobilePreviewClick = () => {
    setTempTheme()
    const element = document.getElementById('preview-box');
    element && element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  if (isFetching) return (
    <div className="customise-page">
      <AMISpinner />
    </div>
  )

  return (
    <div className="customise-page">
      <h2>Customise</h2>

      <div className="customise-page__content">
        <h3>Colours</h3>
        <p className="bottom-margin-24">Please choose your desired colour for each of the below colour options. Only colours marked &quot;Click to change&quot; are editable.</p>

        <p><strong>Primary Colour</strong></p>
        <div className="customise-page__content__color-cont">
          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.primary ? colors.primary : "var(--primary)"}}
            onClick={() => onColorClick(primaryColorRef)}
          >
            Click to change
          </div>
          <input
            ref={primaryColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "primary")}
            className="customise-page__picker"
          />

          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.primary ? `color-mix(in srgb, ${colors.primary}, white 25%)` : "var(--primary75)"}}
          />

          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.primary ? `color-mix(in srgb, ${colors.primary}, white 50%)` : "var(--primary50)"}}
          />
        </div>
        <p className="bottom-margin-24">The Primary Colour will be used for the main actionable events on your pages.</p>

        <p><strong>Secondary Colour</strong></p>
        <div className="customise-page__content__color-cont">
          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.secondary ? colors.secondary : "var(--secondary)"}}
            onClick={() => onColorClick(secondaryColorRef)}
          >
            Click to change
          </div>
          <input
            ref={secondaryColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "secondary")}
            className="customise-page__picker"
          />

          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.secondary ? `color-mix(in srgb, ${colors.secondary}, white 25%)` : "var(--secondary75)"}}
          />

          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.secondary ? `color-mix(in srgb, ${colors.secondary}, white 50%)` : "var(--secondary50)"}}
          />
        </div>
        <p className="bottom-margin-24">The Secondary Colour will be used for events of secondary importance.</p>

        <p><strong>Tertiary Colour</strong></p>
        <div className="customise-page__content__color-cont">
          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.tertiary ? colors.tertiary : "var(--tertiary)"}}
            onClick={() => onColorClick(tertiaryColorRef)}
          >
            Click to change
          </div>
          <input
            ref={tertiaryColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "tertiary")}
            className="customise-page__picker"
          />

          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.tertiary ? `color-mix(in srgb, ${colors.tertiary}, white 25%)` : "var(--tertiary75)"}}
          />

          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.tertiary ? `color-mix(in srgb, ${colors.tertiary}, white 50%)` : "var(--tertiary50)"}}
          />
        </div>
        <p className="bottom-margin-24">The Tertiary colour will be used for breaking up white space such as the header and the footer</p>

        <p><strong>Success Colour</strong></p>
        <div className="customise-page__content__color-cont">
          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.success ? colors.success : "var(--success)"}}
            onClick={() => onColorClick(successColorRef)}
          >
            Click to change
          </div>
          <input
            ref={successColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "success")}
            className="customise-page__picker"
          />
          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.success ? `color-mix(in srgb, ${colors.success}, white 50%)` : "var(--success50)"}}
          />
        </div>
        <p className="bottom-margin-24">Colour for successful actions/results</p>

        <p><strong>Error Colour</strong></p>
        <div className="customise-page__content__color-cont">
          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.error ? colors.error : "var(--error)"}}
            onClick={() => onColorClick(errorColorRef)}
          >
            Click to change
          </div>
          <input
            ref={errorColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "error")}
            className="customise-page__picker"
          />
          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.error ? `color-mix(in srgb, ${colors.error}, white 50%)` : "var(--error50)"}}
          />
        </div>
        <p className="bottom-margin-24">Colour for Error messages/validation</p>

        <p><strong>Warning Colour</strong></p>
        <div className="customise-page__content__color-cont">
          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.warning ? colors.warning : "var(--warning)"}}
            onClick={() => onColorClick(warningColorRef)}
          >
            Click to change
          </div>
          <input
            ref={warningColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "warning")}
            className="customise-page__picker"
          />
          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.warning ? `color-mix(in srgb, ${colors.warning}, white 50%)` : "var(--warning50)"}}
          />
        </div>
        <p className="bottom-margin-24">Colour for warning messages to customers</p>

        <p><strong>Dark/Mid/Light Text Colour</strong></p>
        <div className="customise-page__content__color-cont">
          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.text ? colors.text : "var(--text)"}}
            onClick={() => onColorClick(textColorRef)}
          >
            Click to change
          </div>
          <input
            ref={textColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "text")}
            className="customise-page__picker"
          />
          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.textMid ? colors.textMid : "var(--textMid)"}}
            onClick={() => onColorClick(textMidColorRef)}
          >
            Click to change
          </div>
          <input
            ref={textMidColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "textMid")}
            className="customise-page__picker"
          />
          <div
            className="customise-page__content__color-cont__color-box"
            style={{
              backgroundColor: colors.textLight ? colors.textLight : "var(--textLight)",
              border: "1px solid var(--textMid)"
            }}
            onClick={() => onColorClick(textLightColorRef)}
          >
            <p style={{color: "var(--text)"}}>Click to change</p>
          </div>
          <input
            ref={textLightColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "textLight")}
            className="customise-page__picker"
          />
        </div>
        <p className="bottom-margin-24">Text colours are editable but we advise to keep them similar to the default.</p>

        <p><strong>Disabled Element/Still todo Colour</strong></p>
        <div className="customise-page__content__color-cont">
          <div
            className="customise-page__content__color-cont__color-box"
            style={{backgroundColor: colors.disabledTodo ? colors.disabledTodo : "var(--disabledTodo)"}}
            onClick={() => onColorClick(disabledTodoColorRef)}
          >
           <p>Click to change</p>
          </div>
          <input
            ref={disabledTodoColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "disabledTodo")}
            className="customise-page__picker"
          />
        </div>
        <p className="bottom-margin-24">Colour for disabled elements and items still to do</p>

        <p><strong>Background Colour</strong></p>
        <div className="customise-page__content__color-cont">
          <div
            className="customise-page__content__color-cont__color-box"
            style={{
              backgroundColor: colors.background ? colors.background : "var(--background)",
              border: "1px solid var(--textMid)"
            }}
            onClick={() => onColorClick(backgroundColorRef)}
          >
            <p>Click to change</p>
          </div>
          <input
            ref={backgroundColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "background")}
            className="customise-page__picker"
          />
        </div>
        <p className="bottom-margin-24">Colour for the main background/modals/tables throughout the site. White or close to white is recommended</p>

        <p><strong>Dark Background Colour</strong></p>
        <div className="customise-page__content__color-cont">
          <div
            className="customise-page__content__color-cont__color-box"
            style={{
              backgroundColor: colors.backgroundDark ? colors.backgroundDark : "var(--backgroundDark)",
              border: "1px solid var(--textMid)"
            }}
            onClick={() => onColorClick(backgroundDarkColorRef)}
          >
            Click to change
          </div>
          <input
            ref={backgroundDarkColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "backgroundDark")}
            className="customise-page__picker"
          />
        </div>
        <p className="bottom-margin-24">Mainly used for the footer and blurring the background. A dark colour is recommended.</p>

        <p><strong>Hover Background Colour</strong></p>
        <div className="customise-page__content__color-cont">
          <div
            className="customise-page__content__color-cont__color-box"
            style={{
              backgroundColor: colors.backgroundHover ? colors.backgroundHover : "var(--backgroundHover)"
            }}
            onClick={() => onColorClick(backgroundHoverColorRef)}
          >
            Click to change
          </div>
          <input
            ref={backgroundHoverColorRef}
            type="color"
            onChange={(event) => onColorChange(event, "backgroundHover")}
            className="customise-page__picker"
          />
        </div>
        <p className="bottom-margin-24">Used in menus to indicate the currently hovered element</p>
      </div>

      <div className="customise-page__upload-cont">
        <WhiteLabelUpload
          title="Logo"
          blurb="Must be .png image. Max file size 500kb. Width will be cropped to 185px."
          variant="LOGO"
          url={existingTheme.logoUrl}
        />

        <WhiteLabelUpload
          title="Landing Page Banner Image"
          blurb="Must be .png, .gif, .tiff, .jpeg, .jpg. Max file size 1.2mb. Max suggested width of 1800px. Height will be cropped to 305px."
          variant="BANNER"
          url={existingTheme.bannerUrl}
        />
      </div>

      <div className="mobile-slider" onClick={onMobilePreviewClick}>
        <p>Preview</p>
      </div>

      <div className="preview-box vertical-card" id="preview-box">
        <h3>Preview</h3>
        <p>Click Preview below to temporarily apply your changes.</p>
        <p>Navigate the site as normal to see how your changes look throughout.</p>
        <p>Return to this page when you are done to further configure, revert, or save the changes.</p>

        <div className="preview-box__btns">
          <AMIButton
            variant="secondary"
            onClick={setTempTheme}
          >
            Preview
          </AMIButton>

          {previewMode && (
            <AMIButton
              onClick={revertTempTheme}
            >Revert</AMIButton>
          )}

          {previewMode && (
            <AMIButton
              variant="primary"
              isSpinning={isSaving}
              onClick={saveChanges}
            >Save</AMIButton>
          )}

          {successMessage && <AMIAlert variant="info">{successMessage}</AMIAlert>}
          {errorMessage && <AMIAlert variant="error">{errorMessage}</AMIAlert>}
        </div>
      </div>
    </div>
  )
}

export default WhiteLabelCustomisePage;