import React from 'react';
import { CodeBlock } from "react-code-blocks";
import ApiTableRow from '../api-table-row/ApiTableRow';
import { codeBlockTheme } from '../utilities';
import { bodyCode, exampleErrorResponses, exampleSuccessResponse, headerCode } from './ApiBookingCodeBlocks';

const ApiBookingPage: React.FC<{
  data: any
  searchValue: string
  currentApiElement: any
}> = ({
  data,
  searchValue,
  currentApiElement
}) => {

  return (
    <div className="api-booking-page">
      <h2>Booking</h2>
      <p>To Generate a booking for a shipment, you will need to pass in the quote ID and the shipment details.</p>
      <p>When calling our createBooking end point it&apos;s important the shipment dimensions , origin and destination matches what was provided on the quote request.</p>

      <div className="api-url-box">
        <strong>POST</strong> /public/v1/booking
      </div>

{/* Header Parameters */}
      <div className="api-grid">
        <h2 id="booking-header-schema">Booking Request Header Parameters</h2>

        <div className="api-grid__table">
          <div className="api-grid__table__head">
            <h3>Parameter</h3>
            <h3>Constraint / Description</h3>
          </div>

          <div className="api-grid__table__body">

            {data && data.headers?.map((row: any, index: number) => {
              return (
                <ApiTableRow
                  key={index + row.title}
                  rowInfo={row}
                  searchValue={searchValue}
                  currentApiElement={currentApiElement}
                ></ApiTableRow>
              )
            })}
          </div>
        </div>

{/* Body */}
        <h2 id="booking-body-schema">Booking Request Body Schema</h2>

        <div className="api-grid__table">
          <div className="api-grid__table__head">
            <h3>Parameter</h3>
            <h3>Constraint / Description</h3>
          </div>

          <div className="api-grid__table__body">

            {data && data.body?.map((row: any, index: number) => {
              return (
                <ApiTableRow
                  key={index + row.title}
                  rowInfo={row}
                  searchValue={searchValue}
                  currentApiElement={currentApiElement}
                ></ApiTableRow>
              )
            })}
          </div>
        </div>

{/* ResponseSuccess */}
        <h2 id="booking-response-success-schema">Booking Response Success</h2>

        <div className="api-grid__table">
          <div className="api-grid__table__head">
            <h3>Parameter</h3>
            <h3>Constraint / Description</h3>
          </div>

          <div className="api-grid__table__body">

            {data && data.responseSuccess?.map((row: any, index: number) => {
              return (
                <ApiTableRow
                  key={index + row.title}
                  rowInfo={row}
                  searchValue={searchValue}
                  currentApiElement={currentApiElement}
                ></ApiTableRow>
              )
            })}
          </div>
        </div>

{/* ResponseError */}
        <h2 id="booking-response-error-schema">Booking Response Errors</h2>

        <div className="api-grid__table__head">
          <h3>Error Code</h3>
          <h3>Constraint / Description</h3>
        </div>
        {data && data.responseError?.map((row: any, index: number) => {
          return (
            <React.Fragment key={index + row.title}>
              <div className="api-grid__table api-grid__table--error-response">
                <div className="api-grid__table__body">
                  <ApiTableRow
                    rowInfo={row}
                    searchValue={searchValue}
                    currentApiElement={currentApiElement}
                  ></ApiTableRow>
                </div>
              </div>
              <div className="api-grid__example api-grid__example--error-response">
                {/* @ts-ignore */}
                <CodeBlock
                  //@ts-ignore
                  text={exampleErrorResponses[row.title]}
                  language='javascript'
                  showLineNumbers={true}
                  theme={codeBlockTheme}
                />
              </div>
            </React.Fragment>
          )
        })}


        <div className="api-grid__example api-grid__example--header">
          <h3>Example Header</h3>
          {/* @ts-ignore */}
          <CodeBlock
            //@ts-ignore
            text={headerCode}
            language='javascript'
            showLineNumbers={true}
            theme={codeBlockTheme}
          />
        </div>

        <div className="api-grid__example api-grid__example--body">
          <h3>Example Body</h3>
          {/* @ts-ignore */}
          <CodeBlock
            //@ts-ignore
            text={bodyCode}
            language='javascript'
            showLineNumbers={true}
            theme={codeBlockTheme}
          />
        </div>

        <div className="api-grid__example api-grid__example--success-response">
          <h3>Example Success Response</h3>
          {/* @ts-ignore */}
          <CodeBlock
            //@ts-ignore
            text={exampleSuccessResponse}
            language='javascript'
            showLineNumbers={true}
            theme={codeBlockTheme}
          />
        </div>
      </div>
    </div>
  )
}

export default ApiBookingPage;