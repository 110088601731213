import React, { useEffect, useRef, useState } from 'react';
import './TemplateModal.scss';
import { AMIAlert, AMIButton, AMIModal, AMISpinner, Icon } from '../../../../ui-components/UiComponents';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { appDataSelector } from '../../../../features/appDataSlice';
import { customerActions, customerDetailsSelector } from '../../../../features/customerSlice';
import TemplateModalGeneral from './template-modal-general/TemplateModalGeneral';
import TemplateModalShipmentDetails from './template-modal-shipment-details/TemplateModalShipmentDetails';
import TemplateModalCollectionDetails from './template-modal-collection-details/TemplateModalCollectionDetails';
import TemplateModalShipperDetails from './template-modal-shipper-details/TemplateModalShipperDetails';
import TemplateModalConsigneeDetails from './template-modal-consignee-details/TemplateModalConsigneeDetails';
import TemplateModalBrokerDetails from './template-modal-broker-details/TemplateModalBrokerDetails';
import TemplateModalCommodities from './template-modal-commodities/TemplateModalCommodities';
import TemplateModalPreferences from './template-modal-preferences/TemplateModalPreferences';
import { BLANK_FORM_TEMPLATE, convertFormToTemplate, convertTemplateToForm, Template, TemplateForm } from '../../../../utilities/TemplateHandler';
import TemplateModalPieces from './template-modal-pieces/TemplateModalPieces';
import TemplateModalCountries from './template-modal-countries/TemplateModalCountries';
import { setTemplateData } from '../../../../utilities/APIUtilities';
import { createTemplateRequestBody } from '../utils';
import { clearDefaultTemplates } from './utils';

const TemplateModal: React.FC<{
  existingTemplate?: any,
  close: () => any
}> = ({
  existingTemplate,
  close
}) => {

  const appData = useAppSelector(appDataSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const dispatch = useAppDispatch();
  const alertRef = useRef<any>(null);

  const [template, setTemplate] = useState<any>({});
  const [isSaving, setIsSaving] = useState(false);
  const [showSavingErrors, setShowSavingErrors] = useState(false);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (existingTemplate) setTemplate(convertTemplateToForm(existingTemplate));
    else populateNewTemplate();
  }, [])

  const populateNewTemplate = () => {
    let newTemplate = JSON.parse(JSON.stringify(BLANK_FORM_TEMPLATE));
    let currencyCode = customer.countryOfResidence?.currencyCode;
    let currency = appData.currencies.filter((currency: any) => {
      return currency.value === currencyCode;
    })[0];
    newTemplate.piecesSection.preferredCurrency = currency,
    setTemplate(newTemplate);
  }

  const onSave = async() => {
    setShowSavingErrors(false);
    setShowValidationErrors(false);
    setIsSaving(true);

    if (!template.name) {
      setShowValidationErrors(true);
      setIsSaving(false);
      return;
    }

    const convertedTemplate = convertFormToTemplate(template);
    const requestBody = createTemplateRequestBody(customer, []);

    let existingTemplates: Template[] = JSON.parse(JSON.stringify(customer.templates));
    if (existingTemplate) {
      existingTemplates = existingTemplates.filter(template => template.id !== convertedTemplate.id);
      if (convertedTemplate.isDefault) existingTemplates = clearDefaultTemplates(existingTemplates);
      existingTemplates.unshift(convertedTemplate);
      requestBody.item.templates = existingTemplates;
    } else {
      if (convertedTemplate.isDefault) existingTemplates = clearDefaultTemplates(existingTemplates);
      requestBody.item.templates = [
        ...existingTemplates,
        convertedTemplate
      ]
    }

    const response = await setTemplateData(requestBody, appData.apiConfig)
    if (response?.status === 200) {
      dispatch(customerActions.setTemplates(requestBody.item.templates));
    } else {
      window.alert('An error occurred while trying to save this template. Please try again.')
    }

    if (!showSavingErrors && !showValidationErrors) {
      setShowSavingErrors(false);
      setShowValidationErrors(false);
      setIsSaving(false);
      close();
    }
  }

  const updateGeneral =(field: string, value: any) => {
    setTemplate((prev: TemplateForm) => {
      return {
        ...prev,
        [field]: value
      }
    })
  }

  const updateCountries =(field: string, value: any) => {
    setTemplate((prev: TemplateForm) => {
      return {
        ...prev,
        countries: {
          ...prev.countries,
          [field]: value
        }
      }
    })
  }

  const updatePiecesSection =(field: string, value: any) => {
    setTemplate((prev: TemplateForm) => {
      return {
        ...prev,
        piecesSection: {
          ...prev.piecesSection,
          [field]: value
        }
      }
    })
  }

  const updateShipmentDetails =(field: string, value: any) => {
    setTemplate((prev: TemplateForm) => {
      return {
        ...prev,
        shipment: {
          ...prev.shipment,
          [field]: value
        }
      }
    })
  }

  const updateCollectionDetails =(field: string, value: any) => {
    setTemplate((prev: TemplateForm) => {
      return {
        ...prev,
        collection: {
          ...prev.collection,
          [field]: value
        }
      }
    })
  }

  const updateShipperDetails =(field: string, value: any) => {
    setTemplate((prev: TemplateForm) => {
      return {
        ...prev,
        shipper: {
          ...prev.shipper,
          [field]: value
        }
      }
    })
  }

  const updateConsigneeDetails =(field: string, value: any) => {
    setTemplate((prev: TemplateForm) => {
      return {
        ...prev,
        consignee: {
          ...prev.consignee,
          [field]: value
        }
      }
    })
  }

  const updateBrokerDetails =(field: string, value: any) => {
    setTemplate((prev: TemplateForm) => {
      return {
        ...prev,
        broker: {
          ...prev.broker,
          [field]: value
        }
      }
    })
  }

  const updateCommodities =(value: any) => {
    setTemplate((prev: TemplateForm) => {
      return {
        ...prev,
        commodities: value
      }
    })
  }

  const updatePreferences =(field: string, value: any) => {
    setTemplate((prev: TemplateForm) => {
      return {
        ...prev,
        preferences: {
          ...prev.preferences,
          [field]: value
        }
      }
    })
  }

  return (
    <AMIModal
      className="template-modal"
      title={
        existingTemplate
          ? "Edit Template"
          : "Add Template"
      }
      width={'80%'}
      buttons={[
        <AMIButton
          key={Math.random()}
          variant="secondary"
          size="large"
          disabled={activeTab === 0}
          onClick={() => setActiveTab(activeTab - 1)}
        >
          <Icon
            name="ChevronLeftDouble"
            style={{
              fill: "white",
              marginRight: "2px",
              transform: "translateY(2px)"
            }}
          />
          Back
        </AMIButton>,
        <AMIButton
          key={Math.random()}
          variant="secondary"
          size="large"
          disabled={activeTab > 8}
          onClick={() => setActiveTab(activeTab + 1)}
        >
          Next
          <Icon
            name="ChevronRightDouble"
            style={{
              fill: "white",
              marginLeft: "4px",
              transform: "translateY(2px)"
            }}
          />
        </AMIButton>,
        <AMIButton
          key={Math.random()}
          variant="primary"
          size="large"
          isSpinning={isSaving}
          onClick={onSave}
        >Save
        </AMIButton>
      ]}
      close={close}
    >
      {!template && <div className="template-modal__spinner"><AMISpinner /></div>}
      {template && (
        <form className="template-modal__form">
          <div className="template-modal__template-steps">
            <p
              onClick={() => setActiveTab(0)}
              className={activeTab === 0 ? "template-modal__template-steps__step template-modal__template-steps__step--active" : "template-modal__template-steps__step"}
            >General</p>
            <p
              onClick={() => setActiveTab(1)}
              className={activeTab === 1 ? "template-modal__template-steps__step template-modal__template-steps__step--active" : "template-modal__template-steps__step"}
            >Countries</p>
            <p
              onClick={() => setActiveTab(2)}
              className={activeTab === 2 ? "template-modal__template-steps__step template-modal__template-steps__step--active" : "template-modal__template-steps__step"}
            >Pieces</p>
            <p
              onClick={() => setActiveTab(3)}
              className={activeTab === 3 ? "template-modal__template-steps__step template-modal__template-steps__step--active" : "template-modal__template-steps__step"}
            >Shipment</p>
            <p
              onClick={() => setActiveTab(4)}
              className={activeTab === 4 ? "template-modal__template-steps__step template-modal__template-steps__step--active" : "template-modal__template-steps__step"}
            >Collection</p>
            <p
              onClick={() => setActiveTab(5)}
              className={activeTab === 5 ? "template-modal__template-steps__step template-modal__template-steps__step--active" : "template-modal__template-steps__step"}
            >Shipper</p>
            <p
              onClick={() => setActiveTab(6)}
              className={activeTab === 6 ? "template-modal__template-steps__step template-modal__template-steps__step--active" : "template-modal__template-steps__step"}
            >Consignee</p>
            <p
              onClick={() => setActiveTab(7)}
              className={activeTab === 7 ? "template-modal__template-steps__step template-modal__template-steps__step--active" : "template-modal__template-steps__step"}
            >Broker</p>
            <p
              onClick={() => setActiveTab(8)}
              className={activeTab === 8 ? "template-modal__template-steps__step template-modal__template-steps__step--active" : "template-modal__template-steps__step"}
            >Commodities</p>
            <p
              onClick={() => setActiveTab(9)}
              className={activeTab === 9 ? "template-modal__template-steps__step template-modal__template-steps__step--active" : "template-modal__template-steps__step"}
            >Preferences</p>
          </div>

          <hr style={{border: "1px solid var(--disabledTodo)"}}/>

          {activeTab === 0 && (
            <>
              <h2>General Information</h2>
              <TemplateModalGeneral
                template={template}
                templateName={template.name}
                updateGeneral={updateGeneral}
              ></TemplateModalGeneral>
            </>
          )}

          {activeTab === 1 && (
            <>
              <h2>Origin / Destination</h2>
              <TemplateModalCountries
                template={template}
                updateCountries={updateCountries}
              ></TemplateModalCountries>
            </>
          )}

          {activeTab === 2 && (
            <>
              <h2>Pieces and Types</h2>
              <TemplateModalPieces
                template={template}
                updatePiecesSection={updatePiecesSection}
              ></TemplateModalPieces>
            </>
          )}

          {activeTab === 3 && (
            <>
              <h2>Shipment Information</h2>
              <TemplateModalShipmentDetails
                template={template}
                updateShipmentDetails={updateShipmentDetails}
              ></TemplateModalShipmentDetails>
            </>
          )}

          {activeTab === 4 && (
            <>
              <h2>Collection Details</h2>
              <TemplateModalCollectionDetails
                template={template}
                updateCollectionDetails={updateCollectionDetails}
              ></TemplateModalCollectionDetails>
            </>
          )}

          {activeTab === 5 && (
            <>
              <h2>Shipper Details</h2>
              <TemplateModalShipperDetails
                template={template}
                updateShipperDetails={updateShipperDetails}
              ></TemplateModalShipperDetails>
            </>
          )}

          {activeTab === 6 && (
            <>
              <h2>Consignee Details</h2>
              <TemplateModalConsigneeDetails
                template={template}
                updateConsigneeDetails={updateConsigneeDetails}
              ></TemplateModalConsigneeDetails>
            </>
          )}

          {activeTab === 7 && (
            <>
              <h2>Broker Details</h2>
              <TemplateModalBrokerDetails
                template={template}
                updateBrokerDetails={updateBrokerDetails}
              ></TemplateModalBrokerDetails>
            </>
          )}

          {activeTab === 8 && (
            <>
              <h2>Commodity Information</h2>
              <TemplateModalCommodities
                template={template}
                updateCommodities={updateCommodities}
              ></TemplateModalCommodities>
            </>
          )}

          {activeTab === 9 && (
            <>
              <h2>Preferences</h2>
              <TemplateModalPreferences
                template={template}
                updatePreferences={updatePreferences}
              ></TemplateModalPreferences>
            </>
          )}

        </form>
      )}

      {showValidationErrors && (
        <div
          ref={alertRef}
          style={{marginTop: "24px"}}
        >
          <AMIAlert variant="error">
            <p>Please check all required fields have been populated.</p>
          </AMIAlert>
        </div>
      )}

      {showSavingErrors && (
        <div
          ref={alertRef}
          style={{marginTop: "24px"}}
        >
          <AMIAlert variant="error">
            <p>An error ocurred while trying to save this template, please try again or contact AMI directly.</p>
          </AMIAlert>
        </div>
      )}
    </AMIModal>
  )
}

export default TemplateModal;