import React, { useEffect, useState } from 'react';
import {
  AMIFormElement,
  AMIInput,
  AMISelect,
  Icon
} from '../../../../../ui-components/UiComponents';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { bookingActions, ImperialMetric, ShipmentType } from '../../../../../features/bookingSlice';
import { appDataActions, appDataSelector } from '../../../../../features/appDataSlice';
import {customerDetailsSelector} from "../../../../../features/customerSlice";
import { TemplateForm } from '../../../../../utilities/TemplateHandler';

const TemplatePiece: React.FC<{
  template: TemplateForm;
  piece: any,
  index: number,
  isLastPiece: boolean,
  isOnlyPiece: boolean,
  shipmentType: string,
  onCopy: (pieceValues: any) => void,
  onDelete: () => void,
  onUpdate: (pieces: any) => void
}> = ({
  template,
  piece,
  index,
  isLastPiece,
  isOnlyPiece,
  shipmentType,
  onCopy,
  onDelete,
  onUpdate
}) => {

  const dispatch = useAppDispatch();
  const appData = useAppSelector(appDataSelector);
  const showErrors = appData.showHomePageErrors;
  const customer = useAppSelector(customerDetailsSelector);
  const piecesSection = template.piecesSection;
  const pieces = piecesSection.pieces;

  const [weight, setWeight] = useState<any>('');
  const [length, setLength] = useState<any>('');
  const [width, setWidth] = useState<any>('');
  const [height, setHeight] = useState<any>('');

  useEffect(() => {
    setWeight(piece.weight);
    setLength(piece.length);
    setWidth(piece.width);
    setHeight(piece.height);
  }, [pieces[index].weight])

  const updatePieces = (updatedPiece: any) => {
    const piecesCopy = [...pieces];
    piecesCopy.splice(index, 1, updatedPiece);
    onUpdate(piecesCopy);
  }

  const onClear = () => {
    setWeight('');
    setHeight('');
    setWidth('');
    setLength('');

    const updatedPiece = {
      ...piece,
      weight: '',
      height: '',
      width: '',
      length: ''
    }

    updatePieces(updatedPiece)
  }

  const onblurField = (event: any, field: string) => {
    const number = +event.target.value;
    const rounded = Math.round((number + Number.EPSILON) * 100) / 100;
    const updatedPiece = {
      ...piece,
      [field]: rounded.toString()
    }
    updatePieces(updatedPiece);

    switch(field) {
      case 'weight':
        setWeight(rounded.toString());
        break;
      case 'length':
        setLength(rounded.toString());
        break;
      case 'width':
        setWidth(rounded.toString());
        break;
      case 'height':
        setHeight(rounded.toString());
        break;
    }
  }

  const rules = {
    weight: {
      validation: shipmentType !== ShipmentType.ENVELOPE
        ? piece.weight >= 0.1
        : true,
      validationMessage: 'Required',
      disable: shipmentType === ShipmentType.ENVELOPE,
      overwriteValue: piecesSection.imperialMetric === ImperialMetric.IMPERIAL
        ? customer.countryOfResidence.value === "AU" ? '1.1' : '0.5'
        : customer.countryOfResidence.value === "AU" ? '0.5' : '0.2'
    },
    length: {
      validation: shipmentType !== ShipmentType.ENVELOPE
        ? piece.length >= 1
        : true,
      validationMessage: 'Required',
      disable: shipmentType === ShipmentType.ENVELOPE,
      overwriteValue: piecesSection.imperialMetric === ImperialMetric.IMPERIAL
      ? '12'
      : '30'
    },
    width: {
      validation: shipmentType !== ShipmentType.ENVELOPE
        ? piece.width >= 1
        : true,
      validationMessage: 'Required',
      disable: shipmentType === ShipmentType.ENVELOPE,
      overwriteValue: piecesSection.imperialMetric === ImperialMetric.IMPERIAL
      ? '9'
      : '22'
    },
    height: {
      validation: shipmentType !== ShipmentType.ENVELOPE
        ? piece.height >= 1
        : true,
      validationMessage: 'Required',
      disable: shipmentType === ShipmentType.ENVELOPE,
      overwriteValue: piecesSection.imperialMetric === ImperialMetric.IMPERIAL
      ? '1'
      : '2.5'
    }
  }

  const handleImperialMetricChange = (event: any) => {
    dispatch(bookingActions.setImperialMetric(event.value));
    dispatch(appDataActions.setIsImperialMetricOverwritten(true));
  }

  return (
    <div className="shipment-piece">
      <div style={{position: 'relative'}}>
        <AMIFormElement
          label='Weight'
          errorMessage={
            showErrors
            && !rules.weight.validation
            ? rules.weight.validationMessage
            : ''
          }
        >
          <AMIInput
            name="weight"
            placeholder="Required"
            size="large"
            className="shipment-piece__input"
            type="number"
            min="0"
            max="999"
            value={
              shipmentType === ShipmentType.ENVELOPE
              ? rules.weight.overwriteValue
              : weight
            }
            disabled={rules.weight.disable}
            onChange={(event) => setWeight(event.target.value)}
            onBlur={(event) => onblurField(event, 'weight')}
          />

        </AMIFormElement>

        {isLastPiece && <AMISelect
          name="weight-picker"
          tabIndex={-1}
          options={[
            {
              title: "lb / in",
              value: ImperialMetric.IMPERIAL
            },
            {
              title: "kg / cm",
              value: ImperialMetric.METRIC
            }
          ]}
          defaultValue={{
            title: piecesSection.imperialMetric === ImperialMetric.IMPERIAL ? "lb / in" : "kg / cm",
            value: piecesSection.imperialMetric
          }}
          style={{
            border: 'none',
            width: '75px',
            transform: 'translateX(-7px)'
          }}
          hideClearButton
          onChange={(event: any) => handleImperialMetricChange(event)}
        ></AMISelect>}
      </div>

      <AMIFormElement
        label="Length"
        errorMessage={
          showErrors
          && !rules.length.validation
          ? rules.length.validationMessage
          : ''
        }
      >
        <AMIInput
          name="length"
          placeholder="Required"
          size="large"
          className="shipment-piece__input"
          type="number"
          min="0"
          max="999"
          value={
            shipmentType === ShipmentType.ENVELOPE
            ? rules.length.overwriteValue
            : length
          }
          disabled={rules.length.disable}
          onChange={(event) => setLength(event.target.value)}
          onBlur={(event) => onblurField(event, 'length')}
        />

      </AMIFormElement>

      <AMIFormElement
        label="Width"
        errorMessage={
          showErrors
          && !rules.width.validation
          ? rules.width.validationMessage
          : ''
        }
      >
        <AMIInput
          name="width"
          placeholder="Required"
          size="large"
          className="shipment-piece__input"
          type="number"
          min="0"
          max="999"
          value={
            shipmentType === ShipmentType.ENVELOPE
            ? rules.width.overwriteValue
            : width
          }
          disabled={rules.width.disable}
          onChange={(event) => setWidth(event.target.value)}
          onBlur={(event) => onblurField(event, 'width')}
        />

      </AMIFormElement>

      <AMIFormElement
        label="Height"
        errorMessage={
          showErrors
          && !rules.height.validation
          ? rules.height.validationMessage
          : ''
        }
      >
        <AMIInput
          name="height"
          placeholder="Required"
          size="large"
          className="shipment-piece__input"
          type="number"
          min="0"
          max="999"
          value={
            shipmentType === ShipmentType.ENVELOPE
            ? rules.height.overwriteValue
            : height
          }
          disabled={rules.height.disable}
          onChange={(event) => setHeight(event.target.value)}
          onBlur={(event) => onblurField(event, 'height')}
        />

      </AMIFormElement>

      {shipmentType !== ShipmentType.ENVELOPE
        && (
          <div className="shipment-piece__icon-container">
            <Icon
              name="Copy"
              style={{
                marginTop: '36px',
                marginRight: '8px',
                cursor: pieces.length < 300 ? 'pointer' : 'default',
                pointerEvents: pieces.length < 300 ? 'all' : 'none',
                minWidth: '18px',
                opacity: pieces.length < 300 ? '1' : '.3'
              }}
              onClick={() => onCopy(piece)}
            />

            <Icon
              name="Trash"
              style={{
                marginTop: '36px',
                marginRight: '0px',
                cursor: 'pointer',
                minWidth: '18px'
              }}
              onClick={isOnlyPiece ? onClear : onDelete}
            />
          </div>
        )
      }
    </div>
  )
}

export default TemplatePiece;