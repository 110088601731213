import { selectTitleFormatter } from "../../../../utilities/HelperUtilities";
import { ReasonForExport } from "../../../../features/bookingSlice";

export const reasonsForExport = [
  {
    title: selectTitleFormatter(ReasonForExport.SOLD),
    value: ReasonForExport.SOLD
  },
  {
    title: selectTitleFormatter(ReasonForExport.GIFT),
    value: ReasonForExport.GIFT
  },
  {
    title: selectTitleFormatter(ReasonForExport.SAMPLE),
    value: ReasonForExport.SAMPLE
  },
  {
    title: selectTitleFormatter(ReasonForExport.REPAIR_AND_RETURN),
    value: ReasonForExport.REPAIR_AND_RETURN
  },
  {
    title: selectTitleFormatter(ReasonForExport.PERSONAL_EFFECTS),
    value: ReasonForExport.PERSONAL_EFFECTS
  },
  {
    title: selectTitleFormatter(ReasonForExport.NOT_SOLD),
    value: ReasonForExport.NOT_SOLD
  },
]