import React from 'react';
import './Radio.scss';

const AMIRadio: React.FC<{
  id?: string,
  className?: string,
  style?: React.CSSProperties,
  checked: boolean,
  text: string,
  disabled?: boolean,
  onChange: (event: any) => void
}> = ({
  id,
  className,
  style,
  checked,
  text,
  disabled,
  onChange,
  children
}) => {

  const onCheck = (event: any) => {
    if (!disabled) {
      onChange(event);
    }
  }

  return (
    <div
      id={id}
      className={
        disabled
          ? `ami-radio ami-radio--disabled ${className}`
          : `ami-radio ami-radio ${className}`
      }
      style={style}
    >{text ? text : children}
      <input
        type="radio"
        checked={checked}
        onChange={() => null}
      />
      <span onClick={(event) => onCheck(event)} className="dot"></span>
    </div>
  )
}

export default AMIRadio;