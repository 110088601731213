import axios from 'axios';
import { bookingActions, ElectronicTradeDocumentFormatType, ElectronicTradeDocumentType, ImperialMetric, SpecialServiceType } from '../features/bookingSlice';
import { CustomerDetails } from '../features/customerSlice';
import { refreshAccessToken } from '../components/inactivity-modal/InactivityModal';
import { ApiConfig } from '../features/appDataSlice';
import { Banner } from './ContentRetrieverUtilities';

export enum PackageLocation {
  FRONT = 'FRONT',
  NONE = 'NONE',
  REAR = 'REAR',
  SIDE = 'SIDE'
}
export interface BookingRequestBody {
  quoteId: string,
  customerAccountNumber: string,
  // ready date plus pickUpFrom
  shipmentDateTime: string,
  customerCloseTime: string,
  senderDetails: {
    contactDetails: {
      name: string,
      telephone: string
    },
    address: {
      organisation: string,
      countryCode: string,
      postalCode: string,
      streetLines: string[],
      city: string,
      stateOrCounty: string | null,
    },
    notificationDetails: {
      email: string,
      mobile: string
    }
  },
  collectionDetails: {
    contactDetails: {
      name: string,
      telephone: string
    },
    address: {
      organisation: string,
      countryCode: string,
      postalCode: string,
      streetLines: string[],
      city: string,
      stateOrCounty: string | null,
      residential: boolean
    },
    notificationDetails: {
      email: string,
      mobile: string
    }
  } | null,
  deliveryDetails: {
    contactDetails: {
      name: string,
      telephone: string
    },
    address: {
      organisation: string,
      countryCode: string,
      postalCode: string,
      streetLines: string[],
      city: string,
      stateOrCounty: string | null,
      residential: boolean
    },
    notificationDetails: {
      email: string,
      mobile: string
    }
  },
  packageLocation: PackageLocation,
  parcels?: [
    {
      dimensions: {
        length: number,
        width: number,
        height: number,
        unit: string
      },
      weight: {
        value: number,
        unit: string
      },
      description?: string | undefined
    }
  ],
  customerShippingReference: string,
  contentDescription: string,
  deliveryInstructions: string,
  label: {
      type: string,
      size: string
  },
  shipmentType: string,
  remarks: string,
  bookingConfirmationEmail: string,
  amiCarrierServiceName: string,
  specialServices: SpecialServiceType[] | null;
  customsDetails: {
    exportComplianceStatement: string | null;
    b13AFilingOption: string | null;
    statementType: string | null;
    amount: {
      value: number,
      currency: string;
    },
    invoiceDetails: {
      declarationNotes?: string,
      declarationStatement: string | null,
      declarationStatementRequested?: {
        value: boolean,
        type: string
      },
      termsOfSale?: string,
      taxDetails?: [
        {
          type: string,
          party: string,
          value: string
        }
      ],
      exportReason: any,
      declaredCharges: [],
      invoiceDate: string | null,
      invoiceNumber: string | null,
      cpc: string | null
    },
    electronicTradeDetails?: {
      uploadedDocuments: {
        type: ElectronicTradeDocumentType,
        contentType: ElectronicTradeDocumentFormatType,
        documentContent: string
      }[]
    } | null,
    importerOfRecordDetails: {
      deliveryAddress: boolean,
      contactDetails: {
        name: string,
        telephone: string
      },
      address: {
        organisation: string,
        countryCode: string,
        postalCode: string,
        streetLines: string[],
        city: string,
        stateOrCounty: string | null,
      }
    },
    commodities: {
      commodityCode: string | null,
      description: string | null,
      countryOfOrigin: string | null,
      quantity: {
        value: string,
        unit: string
      } | null,
      unitPrice: {
        value: string,
        currency: string
      } | null,
      weight: {
        value: string,
        unit: string
      } | null
    }[] | null,
    declaredValue: number | null
  },
  brokerDetails: {
    contactDetails: {
      name: string,
      telephone: string
    },
    address: {
      organisation: string,
      countryCode: string,
      postalCode: string,
      streetLines: string[],
      city: string,
      stateOrCounty: string | null,
    }
  } | null,
  logonEmail: string,
  unitOfCurrency: string,
  unitOfMeasurement: ImperialMetric
}

export async function retrieveQuotesCall(requestBody: any, config: ApiConfig) {
  refreshAccessToken();
  let quotes;
  let quoteError;
  await axios.post(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/quote`,
    requestBody,
    config
  ).then((response: any) => {
    quotes = response.data;
  }, (error) => {
    console.error('quote error', error)
    quoteError = error.message;
  })

  return quoteError ? quoteError : quotes;
}

export interface BookingHistoryItem {
  alerts?: [];
  amiReference: string;
  carrierName: string;
  consignmentDetailsList: {
    consignmentNumber?: string;
    parcelNumbers: string[];
  }[],
  consolidated?: boolean;
  customerShippingReference: string;
  deliveryDetails: {
    address: {
      city: string;
      countryCode: string;
      organisation: string;
      postalCode?: string;
      residential: boolean;
      stateOrCounty: string | null,
      streetLines?: [string];
    };
    contactDetails?: {
      companyName: string;
      contactName: string;
    };
    importerOfRecord: boolean;
    notificationDetails?: {};
  };
  pickupConfirmationCode?: string;
  pickupMessage?: string;
  regionId: string;
  shipmentDate: Date;
  shipmentId: string;
}

export async function getBookingHistory(config: ApiConfig, customer: CustomerDetails) {
  refreshAccessToken();
  let bookingHistory: BookingHistoryItem[] = [];
  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/retrieve-bookings/${customer.creditCheck.tmffPartyId}`,
    config
  ).then((response: any) => {
    bookingHistory = response.data;
  }, (error) => {
    console.error('bookingHistory error', error)
  })

  return bookingHistory as BookingHistoryItem[];
}

export async function getShipmentTracking(
  config: ApiConfig,
  customer: CustomerDetails,
  shipment: BookingHistoryItem
) {
  refreshAccessToken();
  let trackingInfo: any = [];
  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/tracking/${customer.creditCheck.tmffPartyId}/${shipment.amiReference}/${shipment.shipmentId}`,
    config
  ).then((response: any) => {
    trackingInfo = response.data;
  }, (error) => {
    console.error('bookingHistory error', error)
  })

  return trackingInfo;
}

export async function bookingIGCall(requestBody: any, config: ApiConfig, dispatch: any) {
  refreshAccessToken();
  let bookingResponse;
  await axios.post(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/booking`,
    requestBody,
    config
  ).then((response: any) => {
    bookingResponse = response.data;
    dispatch(bookingActions.setBookingResult(response.data));
  }, (error) => {
    bookingResponse = error.response.data;
    console.error('booking error', error)
  })

  return bookingResponse;
}

export async function getLabelCall(config: ApiConfig, customer: CustomerDetails, amiRef: string) {
  refreshAccessToken();
  let label: {
    type?: 'text/plain' | 'application/pdf',
    data?: any,
    labelReady?: boolean
  } = {};

  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/label?customerAccountNumber=${customer.creditCheck.tmffPartyId}&amiReference=${amiRef}`,
    config
    ).then((response: any) => {
      label = {
        type: response.headers['content-type'],
        data: response.data,
        labelReady: response.status === 200
      }
    }, (error) => {
      console.error('get label error', error)
    })
  return label;
}

export async function getDocumentCall(config: ApiConfig, customer: CustomerDetails, amiRef: string) {
  refreshAccessToken();
  let document: {
    type?: 'text/plain' | 'application/pdf',
    data?: any,
    documentReady?: boolean
  } = {};

  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v4/document?partyId=${customer.creditCheck.tmffPartyId}&amiReference=${amiRef}`,
    config
    ).then((response: any) => {
      document = {
        type: response.headers['content-type'],
        data: response.data,
        documentReady: response.status === 200
      }
    }, (error) => {
      console.error('get document error', error)
    })
  return document;
}

export async function getLocations(requestBody: any, carrier: string, config: ApiConfig) {
  refreshAccessToken();
  let locations: any;
  await axios.post(
    `${process.env.REACT_APP_NEBE_API_URL}/v4/locations/${carrier}`,
    requestBody,
    config
  ).then((response: any) => {
    locations = response.data;
  }, (error) => {
    console.error('locations error', error)
  })
  return locations;
}

export async function refreshAuth(customer: any) {
  let response;

  const config = {
    headers: {
      "Content-Type": "application/json",
      "authorization": `Bearer ${customer.auth.accessToken}`
    }
  };

  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/nebe-auth-refresh`,
    config
  ).then((resp: any) => {
    response = resp;
  }, (error) => {
    response = false;
    console.error('Refresh Error', error);
  })

  return response as any;
}

export async function getCountries(config: ApiConfig) {
  let countries;
  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/reference-data`,
    config
  ).then((response: any) => {
    countries = response.data.countries;
  }, (error) => {
    console.error('get label error', error)
  })
  return countries;
}

export async function getCountryInfo(config: ApiConfig, countryCode: string) {
  let returnedData;
  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/reference-data?countryCode=${countryCode}`,
    config
  ).then((response: any) => {
    returnedData = response.data;
  }, (error) => {
    console.error('countryCode error: ', error)
  })
  return returnedData as any;
}

export async function getCurrencyData(config: ApiConfig, currencyCode: any) {
  let url;
  let returnedData;
  if (currencyCode !== null) {
    url = `${process.env.REACT_APP_NEBE_API_URL}/v2/reference/currency?currencyCode=${currencyCode}`
  } else {
    url = `${process.env.REACT_APP_NEBE_API_URL}/v2/reference/currency`
  }
  await axios.get(
    url,
    config
  ).then((response: any) => {
    returnedData = response.data;
  }, (error) => {
    console.error('currency data error: ', error)
  });
  return returnedData as any;
}

export async function getCurrencyConversionRate(config: ApiConfig, currencyA: string, currencyB: string) {
  let returnedData;
  await axios.post(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/convert-currency`,
    {
      "currencyA": currencyA,
      "currencyB": currencyB
    },
    config
  ).then((response: any) => {
    returnedData = response.data;
  }, (error) => {
    console.error('currency conversion rate error: ', error)
  })
  return returnedData as any;
}

export async function getAddresses(branchId: string, config: ApiConfig) {
  let addresses: any;
  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/customer-address?branchId=${branchId}`,
    config
  ).then((response: any) => {
    addresses = response.data;
  }, (error) => {
    console.error('getAddresses error', error)
  })
  return addresses;
}

export async function getAddressesByCountry(branchId: string, config: ApiConfig, countryCode: string) {
  let addresses: any;
  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/customer-address?branchId=${branchId}&countryCode=${countryCode}`,
    config
  ).then((response: any) => {
    addresses = response.data;
  }, (error) => {
    console.error('getAddressesByCountry error', error)
  })
  return addresses;
}

export async function addAddress(requestBody: any, config: ApiConfig) {
  let id;
  await axios.post(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/customer-address`,
    requestBody,
    config
  ).then((response: any) => {
    id = response.data.id;
  }, (error) => {
    console.error('addAddress error', error)
  })
  return id;
}

export async function deleteAddress(addressId: string, config: ApiConfig) {
  let resp;
  await axios.delete(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/customer-address?id=${addressId}`,
    config
  ).then((response: any) => {
    resp = response;
  }, (error) => {
    console.error('deleteAddress error', error)
  })
  return resp;
}

export async function updateAddress(requestBody: any, config: ApiConfig) {
  let resp;
  await axios.put(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/customer-address`,
    requestBody,
    config
  ).then((response: any) => {
    resp = response.data;
  }, (error) => {
    console.error('updateAddress error', error)
  })
  return resp;
}

export async function placeManifestBooking(requestBody: any, config: ApiConfig, customer: any) {
  let response;
  await axios.post(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/bulk-booking?countryCode=${customer.customerCountryCode}`,
    requestBody,
    config
  ).then(resp => {response = resp})
  .catch(err => {
    response = false;
    console.error('Manifest booking error: ', err)
  });
  return response;
}

export async function logError(requestBody: any, config: ApiConfig) {
  await axios.post(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/logging`,
    requestBody,
    config
  ).then(resp => {console.log(resp)})
  .catch(err => {
    console.error('Logging error: ', err)
  });
}

export async function getThemeData(config: ApiConfig) {
  let response;
  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/theme`,
    config
  ).then(resp => response = resp)
  .catch(err => {
    console.error('getThemeData error: ', err)
  });
  return response;
}

export async function setThemeData(requestBody: any, config: ApiConfig, parentPartyId: string) {
  let response;
  await axios.put(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/theme?parentPartyId=${parentPartyId}`,
    requestBody,
    config
  ).then(resp => response = resp)
  .catch(err => {
    console.error('putThemeData error: ', err)
  });
  return response;
}

export async function getTemplateData(config: ApiConfig) {
  let templates: any = [];
  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/template`,
    config
  ).then(resp => {
    templates = resp.data?.Item.templates
  })
  .catch(err => {
    console.error('getTemplateData error: ', err)
  });
  return templates;
}

export async function setTemplateData(requestBody: any, config: ApiConfig) {
  let response: any;
  await axios.put(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/template`,
    requestBody,
    config
  ).then(resp => response = resp)
  .catch(err => {
    console.error('putTemplateData error: ', err)
  });
  return response;
}

export async function getChildCustomerData(config: ApiConfig, parentPartyId: string, childPartyId: string) {
  let response;
  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/child-customer-data?parentPartyId=${parentPartyId}&childPartyId=${childPartyId}`,
    config
  ).then(resp => response = resp)
  .catch(err => {
    console.error('getChildCustomerData error: ', err)
  });
  return response;
}

export async function getWlMarkup(config: ApiConfig, childPartyId: string) {
  let response;
  await axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/markup/${childPartyId}`,
    config
  )
  .then(resp => response = resp)
  .catch(err => {
    response = err.response
    console.error('getWlMarkup error: ', err)
  });
  return response;
}

export async function setWlMarkup(requestBody: any, config: ApiConfig) {
  let response;
  await axios.post(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/markup`,
    requestBody,
    config
  ).then(resp => response = resp)
  .catch(err => {
    console.error('setWlMarkup error: ', err)
  });
  return response;
}

export async function setWlImage(requestBody: any, extension: string, config: ApiConfig, type: 'banner' | 'logo') {
  let response;
  await axios.put(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/manage-content?type=${type}&fileType=${extension}`,
    requestBody,
    config
  ).then(resp => response = resp)
  .catch(err => {
    console.error('setWlImage error: ', err)
  });
  return response;
}

export async function getWlBookings(config: ApiConfig, childBookingIds: any) {
  const requestBody = {
    "childBookingIds": childBookingIds
  }
  let response;
  await axios.post(
    `${process.env.REACT_APP_NEBE_API_URL}/v3/retrieve-white-label-child-bookings`,
    requestBody,
    config
  ).then(resp => response = resp.data)
  .catch(err => {
    console.error('getWlBookings error: ', err)
  });
  return response;
}

export async function setWlBanners(
  config: ApiConfig,
  countryCode: string,
  page: 'home' | 'quote',
  parentPartyId: string,
  banners: Banner[]
) {
  let response;
  await axios.post(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/manage-content?countryOfOrigin=${countryCode}&page=announcement-banner-${page}-page-${parentPartyId}&parentPartyId=${parentPartyId}`,
    banners,
    config
  ).then(resp => response = resp.data)
  .catch(err => {
    console.error('setWlBanners error: ', err)
    response = err.response.data
  });
  return response;
}