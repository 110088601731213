import { ActivePage } from "./Steps";

export const calcStepsVisualState = (activePage: ActivePage, storeSteps: any) => {
  const existingSteps = [...storeSteps];
  let newSteps;

  if (
      activePage === 'UPLOAD_PAGE'
      && existingSteps[1].status === 'todo'
      || existingSteps[1].status === 'complete'
    ) {
    newSteps = [
      {
        name: 'UPLOAD_PAGE',
        status: 'active',
        clickable: false
      },
      {
        name: 'REVIEW_PAGE',
        status: 'todo',
        clickable: false
      },
      {
        name: 'OPTIONS_PAGE',
        status: 'todo',
        clickable: false
      },
      {
        name: 'FINISH_PAGE',
        status: 'todo',
        clickable: false
      }
    ]
  }

  if (
      activePage === 'UPLOAD_PAGE'
      && existingSteps[1].status === 'active'
    ) {
    newSteps = [
      {
        name: 'UPLOAD_PAGE',
        status: 'active',
        clickable: false
      },
      {
        name: 'REVIEW_PAGE',
        status: 'todo',
        clickable: true
      },
      {
        name: 'OPTIONS_PAGE',
        status: 'todo',
        clickable: true
      },
      {
        name: 'FINISH_PAGE',
        status: 'todo',
        clickable: false
      }
    ]
  }

  if (activePage === 'REVIEW_PAGE') {
    newSteps = [
      {
        name: 'UPLOAD_PAGE',
        status: 'complete',
        clickable: true
      },
      {
        name: 'REVIEW_PAGE',
        status: 'active',
        clickable: false
      },
      {
        name: 'OPTIONS_PAGE',
        status: 'todo',
        clickable: false
      },
      {
        name: 'FINISH_PAGE',
        status: 'todo',
        clickable: false
      }
    ]
  }

  if (
    activePage === 'REVIEW_PAGE'
    && existingSteps[2].status === 'active'
    ) {
    newSteps = [
      {
        name: 'UPLOAD_PAGE',
        status: 'complete',
        clickable: true
      },
      {
        name: 'REVIEW_PAGE',
        status: 'active',
        clickable: true
      },
      {
        name: 'OPTIONS_PAGE',
        status: 'todo',
        clickable: true
      },
      {
        name: 'FINISH_PAGE',
        status: 'todo',
        clickable: false
      }
    ]
  }

  if (activePage === 'OPTIONS_PAGE') {
    newSteps = [
      {
        name: 'UPLOAD_PAGE',
        status: 'complete',
        clickable: true
      },
      {
        name: 'REVIEW_PAGE',
        status: 'complete',
        clickable: true
      },
      {
        name: 'OPTIONS_PAGE',
        status: 'active',
        clickable: false
      },
      {
        name: 'FINISH_PAGE',
        status: 'todo',
        clickable: false
      }
    ]
  }

  if (activePage === 'FINISH_PAGE') {
    newSteps = [
      {
        name: 'UPLOAD_PAGE',
        status: 'complete',
        clickable: true
      },
      {
        name: 'REVIEW_PAGE',
        status: 'complete',
        clickable: false
      },
      {
        name: 'OPTIONS_PAGE',
        status: 'complete',
        clickable: false
      },
      {
        name: 'FINISH_PAGE',
        status: 'active',
        clickable: false
      }
    ]
  }
  return newSteps;
}