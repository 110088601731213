import React, { useEffect } from 'react';
import { countryCodes, currencyCodes } from './utilities';
import { AMITable } from '../../../ui-components/UiComponents';
import { useLocation } from 'react-router-dom';
import { scrollToElement } from '../../../utilities/HelperUtilities';
import { taxNumberTypes } from '../../single-booking/details-page/utilities';

export const ReferenceDataPage = () => {

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) scrollToElement(hash.slice(1));
  })

  return (
    <div className="reference-data-page">
      <div id="postal-codes">
        <p
          style={{
            background: "var(--tertiary)",
            color: "var(--textLight)",
            fontWeight: "bold",
            padding: "8px"
          }}
        >Postal Code Formats</p>
        <a href="/postal_code_formats.csv" download>Download postal code formats CSV</a>
      </div>


      <AMITable
        id="currencies"
        variant="styled"
        style={{marginTop: "48px", marginBottom: "24px"}}
      >
        <thead>
          <tr>
            <th>Currency</th>
            <th>Code</th>
          </tr>
        </thead>

        <tbody>
          {currencyCodes.sort((a: any, b: any) => {return a.name.localeCompare(b.name)}).map((el: any) => {
            return (
              <tr key={el.code}>
                <td>{el.name}</td>
                <td>{el.code}</td>
              </tr>
            )
          })}
        </tbody>
      </AMITable>

      <AMITable
        id="country-codes"
        variant="styled"
        style={{marginBottom: "24px"}}
      >
        <thead>
          <tr>
            <th>Country</th>
            <th>Code</th>
          </tr>
        </thead>

        <tbody>
          {countryCodes.sort((a: any, b: any) => {return a.name.localeCompare(b.name)}).map((el: any) => {
            return (
              <tr key={el.code}>
                <td>{el.name}</td>
                <td>{el.code}</td>
              </tr>
            )
          })}
        </tbody>
      </AMITable>

      <AMITable
        id="tax-number-types"
        variant="styled"
        style={{marginBottom: "24px"}}
      >
        <thead>
          <tr>
            <th>Tax Number Type</th>
            <th>Code</th>
          </tr>
        </thead>

        <tbody>
          {taxNumberTypes.map((el: any) => {
            return (
              <tr key={el.value}>
                <td>{el.title}</td>
                <td>{el.value}</td>
              </tr>
            )
          })}
        </tbody>
      </AMITable>
    </div>
  )
}

export default ReferenceDataPage;