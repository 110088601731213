import React from 'react';
import './WlBookingModal.scss';
import { AMIModal } from '../../../../../../ui-components/UiComponents';
import dayjs from 'dayjs';
import { WlBookingHistoryItem } from '../../WlBookingsPage-Utils';
import getSymbolFromCurrency from 'currency-symbol-map';

const WlBookingModal: React.FC<{
  booking: WlBookingHistoryItem,
  closeModal: () => void
}> = ({
  booking,
  closeModal
}) => {

  return (
    <AMIModal
      title={"Booking " + booking.customerShippingReference}
      width="80%"
      close={closeModal}
    >
      <div className="wl-booking-modal">
        <img
          src={`/images/${booking.carrierName}.png`}
          alt="Logo"
          className="wl-booking-modal__carrier-logo"
        />

        <div className="wl-booking-modal__booking-info">
          <p><span>Booking Date:</span><br/>{dayjs(booking.shipmentDate).format('DD/MM/YYYY')}</p>
          <p><span>Your Ref:</span><br/>{booking.customerShippingReference}</p>
          <p><span>AMI Ref:</span><br/>{booking.amiReference}</p>
          <p><span>Carrier Ref:</span><br/>{booking.carrierShippingReference}</p>
          <p><span>Company:</span><br/>{booking.companyName}</p>
        </div>

        <h3>Markup</h3>
        <div className="wl-booking-modal__markup">
          <div>
            <p>AMI Price</p>
            <p>Shipper Price</p>
            <p><strong>Difference</strong></p>
          </div>
          <div className="wl-booking-modal__markup__divide"></div>
          <div>
            <p>{getSymbolFromCurrency(booking.directCustomerCost.currency)}{booking.directCustomerCost.value}</p>
            <p>{getSymbolFromCurrency(booking.indirectCustomerCost.currency)}{booking.indirectCustomerCost.value}</p>
            <p><strong>{getSymbolFromCurrency(booking.markup.currency)}{booking.markup.value}</strong></p>
          </div>
        </div>
      </div>
    </AMIModal>
  )
}

export default WlBookingModal;