export const headerCode = `headers: {
  "party-id": "GBXRIBT001",
  "client-id": "6789aa",
  "x-api-key": \`\${apiKey}\`,
  "authorization": \`Bearer \${accessToken}\`,
  "Content-Type": "application/json"
}`

export const exampleSuccessResponse = `[
{
  "trackingNumber": "abc123",
  "parcelNumber": "abc123",
  "consignmentNumber": "abc123",
  "deliveryAddress1": "12 Some Street",
  "deliveryAddress2": "Line 2",
  "deliveryCity": "New York",
  "deliveryCounty": "New York",
  "deliveryPostcode": "12345",
  "deliveryCountryCode": "US",
  "trackingEvents": [
      {
          "zonedDateTime": "2024-09-25T08:59:00Z",
          "code": null,
          "type": "IT",
          "description": "On the way",
          "locality": "TN",
          "countryCode": "US"
      },
      {
          "zonedDateTime": "2024-09-25T06:26:00Z",
          "code": null,
          "type": "IT",
          "description": "On the way",
          "locality": "TN",
          "countryCode": "US"
      },
      {
          "zonedDateTime": "2024-09-25T04:50:00Z",
          "code": null,
          "type": "AR",
          "description": "Arrived at FedEx hub",
          "locality": "TN",
          "countryCode": "US"
      },
      {
          "zonedDateTime": "2024-09-25T01:37:00Z",
          "code": null,
          "type": "DP",
          "description": "Left FedEx origin facility",
          "locality": "MI",
          "countryCode": "US"
      },
      {
          "zonedDateTime": "2024-09-24T18:33:00Z",
          "code": null,
          "type": "PU",
          "description": "Picked up",
          "locality": "MI",
          "countryCode": "US"
      },
      {
          "zonedDateTime": "2024-09-23T14:26:33Z",
          "code": null,
          "type": "OC",
          "description": "Shipment information sent to FedEx",
          "locality": null,
          "countryCode": null
      }
    ]
  }
]`

export const exampleErrorResponses = {
  400: `{
    "id": "{{random guid}}",
    "message": "No booking data found for shipment id '{{shipmentId}}'."
  }`
}