import React, { useEffect } from 'react';
import './BulkBookingSteps.scss';
import Step from './step/Step';
import { manifestSelector, manifestActions } from '../../../features/manifestSlice';
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { calcStepsVisualState } from './utility';

export enum ActivePage {
  UPLOAD_PAGE = 'UPLOAD_PAGE',
  REVIEW_PAGE = 'REVIEW_PAGE',
  OPTIONS_PAGE = 'OPTIONS_PAGE',
  FINISH_PAGE = 'FINISH_PAGE'
}

const AMISteps: React.FC<{
  stepChange: (arg0: ActivePage) => void,
  activePage: ActivePage
}> = ({
  stepChange,
  activePage
}) => {

  const dispatch = useAppDispatch();
  const storeSteps = useAppSelector(manifestSelector).steps;

  useEffect(() => {
    const newStepsState = calcStepsVisualState(activePage, storeSteps);
    dispatch(manifestActions.updateSteps(newStepsState));
  }, [activePage])

  const handleStepChange = (page: ActivePage) => {
    stepChange(page);
  };

  const steps = [
    {
      title: 'Upload',
      status: storeSteps[0].status,
      clickable: storeSteps[0].clickable,
      lines: false,
      page: ActivePage.UPLOAD_PAGE
    },
    {
      title: 'Review',
      status: storeSteps[1].status,
      clickable: storeSteps[1].clickable,
      lines: true,
      page: ActivePage.REVIEW_PAGE
    },
    {
      title: 'Options',
      status: storeSteps[2].status,
      clickable: storeSteps[2].clickable,
      lines: true,
      page: ActivePage.OPTIONS_PAGE
    },
    {
      title: 'Finish',
      status: storeSteps[3].status,
      clickable: storeSteps[3].clickable,
      lines: true,
      page: ActivePage.FINISH_PAGE
    },
  ]

  return (
    <div className="bulk-booking-steps">
      {steps.map((step) => {
        return (
          <Step
            key={step.title}
            title={step.title}
            status={step.status}
            clickable={step.clickable}
            lines={step.lines}
            onClick={handleStepChange.bind(null, step.page)}
          />
        )
      })}
    </div>
  )
};

export default AMISteps;