import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { AMIPopConfirm, AMISpinner, Icon } from '../../../../ui-components/UiComponents';
import { customerActions, customerDetailsSelector } from '../../../../features/customerSlice';
import { deleteAddress } from '../../../../utilities/APIUtilities';
import AddEditAddressModal from '../add-edit-address-modal/AddEditAddressModal';
import './AddressTableLine.scss';
import { appDataSelector } from '../../../../features/appDataSlice';

const AddressTableLine: React.FC<{
  savedAddress: any,
  index: number
}> = ({
  savedAddress,
  index
}) => {

  const address = savedAddress.userDetails.address
  const contactDetails = savedAddress.userDetails.contactDetails;
  const customer = useAppSelector(customerDetailsSelector);
  const dispatch = useAppDispatch();
  const apiConfig = useAppSelector(appDataSelector).apiConfig;

  const popConfirmTarget = document.getElementById('delete-address-icon');

  const [isDeleting, setIsDeleting] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeletePopConfirm, setShowDeletePopConfirm] = useState(false);

  const onDelete = async() => {
    setShowDeletePopConfirm(false);
    setIsDeleting(true);
    const response: any = await deleteAddress(savedAddress.id, apiConfig);

    if (response?.data?.message === 'OK') {
      dispatch(customerActions.deleteSavedAddress(savedAddress.id))
    }
    setIsDeleting(false);
  }

  const onEdit = () => {
    setShowEditModal(true);
  }

  return (
    <div
      className="account-addresses__address"
      key={index}
    >
      <p className={isDeleting ? "account-addresses__address__disabled" : ""}>
        {contactDetails.name}, <strong>{address.organisation}</strong>, {address.streetLines[0]}, {address.streetLines[1] ? address.streetLines[1] + ',' : ''} {address.streetLines[2] ? address.streetLines[2] + ',' : ''} {address.city}, {address.countryCode}, {address.postalCode}
      </p>
      <div className={
        isDeleting
          ? "account-addresses__address__icons account-addresses__address__disabled"
          : "account-addresses__address__icons"
      }>
        <Icon
          name="Edit"
          style={{
            width: '16px',
            marginRight: '8px'
          }}
          onClick={() => onEdit()}
        />
        <Icon
          id="delete-address-icon"
          name="Trash"
          style={{width: '16px'}}
          onClick={() => setShowDeletePopConfirm(true)}
        />

        <AMIPopConfirm
          parentElement={popConfirmTarget}
          isVisible={showDeletePopConfirm}
          position="centerScreen"
          onCancel={() => setShowDeletePopConfirm(false)}
          onConfirm={() => onDelete()}
        >
          <p>Please confirm you wish to delete this address.</p>
        </AMIPopConfirm>
      </div>
      {isDeleting && (
        <div className="account-addresses__address__spinner">
          <AMISpinner
            style={{
              height: '32px',
              width: '32px'
            }}
          />
        </div>
      )}

      {showEditModal && (
        <AddEditAddressModal
          existingAddress={savedAddress}
          close={() => setShowEditModal(false)}
        ></AddEditAddressModal>
      )}
    </div>
  )
}

export default AddressTableLine;