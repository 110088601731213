export const headerCode = `headers: {
  "party-id": "GBXRIBT001",
  "client-id": "6789aa",
  "x-api-key": "EdX2C9Yeku3dVLSaYe2RI2iBnFXoSEgB11gsOwSX",
  "authorization": \`Bearer \${accessToken}\`,
  "Content-Type": "application/json"
}`

export const bodyCode = `body: {
  shipmentDateTime: "2023-07-28",
  customerAccountNumber: "abc123",
  shipmentType: "NON_DOCS",
  senderDetails: {
    address: {
      countryCode: "US",
      postalCode: "90310",
      city: "New York",
      residential: true
    }
  },
  deliveryDetails: {
    address: {
      countryCode: "FR",
      postalCode: "13004",
      city: "Marseille",
      residential: true
    }
  },
  declaredValue: 123.50,
  declaredValueCurrency: "USD",
  parcels: [
    {
      dimensions: {
        length: 5,
        width: 5,
        height: 5,
        unit: "IN"
      },
      weight: {
        value: 10,
        unit: "LB"
      }
    }
  ]
}`

export const exampleSuccessResponse = `[
  {
    "quoteId": "dhl-9fc425d4-1d39-4b84-95fe-a2acf07f139q",
    "carrierServiceName": "DHL-UK-EXP-12-ND-COL",
    "carrierProductCode": "EXPRESS 12:00 NONDOC",
    "serviceType": "COLLECTED",
    "transportMethod": "EXPRESS",
    "weight": {
      "chargeable": {
        "value": 5,
        "unit": "KG"
      },
      "actual": {
        "value": 5,
        "unit": "KG"
      }
    },
    "transitTimeEstimate": "1",
    "deliveryDateEstimate": "2023-08-10T12:00:00",
    "sameDayCollectionCutOffTime": "14:00:00",
    "pickupEarliestTime": "09:30:00",
    "pickupLatestTime": "16:00:00",
    "discount": {
      "amount": {
        "value": 7.45,
        "currency": "GBP"
      },
      "rulesApplied": [
        {
          "type": "CT_TM_FC_TZ_WB",
          "value": "15.00",
          "discount": {
            "type": "PERCENTAGE",
            "value": 15
          }
        }
      ]
    },
    "charges": {
      "value": 26.57,
      "currency": "GBP",
      "required": [
        {
          "value": 10,
          "currency": "GBP",
          "type": "PREMIUM_12_00",
          "vat": null
        },
        {
          "value": 1,
          "currency": "GBP",
          "type": "EMERGENCY_SITUATION",
          "vat": null
        },
        {
          "value": 15.57,
          "currency": "GBP",
          "type": "FUEL",
          "vat": null
        }
      ],
      "optional": [
        {
          "value": 4,
          "currency": "GBP",
          "type": "LITHIUM_ION_PI966",
          "vat": null
        },
        {
          "value": 4,
          "currency": "GBP",
          "type": "LITHIUM_METAL_PI969",
          "vat": null
        },
        {
          "value": 12,
          "currency": "GBP",
          "type": "INSURANCE",
          "vat": null
        },
        {
          "value": 16,
          "currency": "GBP",
          "type": "DELIVERED_DUTY_PAID",
          "vat": null
        }
      ]
    },
    "totalCost": {
      "value": 68.79,
      "currency": "GBP"
    },
    "rate": {
      "value": 49.67,
      "currency": "GBP"
    },
    "vat": {
      "value": 0,
      "currency": "GBP"
    }
  }
]`

export const exampleErrorResponses = {
  1001: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1001",
        "message": "must not be null",
        "fieldName": "shipmentDateTime",
        "value": null
      }
    ]
  }`,
  1002: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1002",
        "message": "must be in the format",
        "format": "YYYY-MM-DDThh:mm:ssZ",
        "fieldName": "shipmentDateTime",
        "value": ""
      }
    ]
  }

  {
    "message": "Bad Request",
    "errors": [
      {
        "code": "1002",
        "message": "must be in the format",
        "format": "YYYY-MM-DDThh:mm:ssZ",
        "fieldName": "shipmentDateTime",
        "value": "<invalid value>"
      }
    ]
  }`,
  1003: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1003",
        "message": "shipment date cannot be in the past or more than 7 days in the future",
        "fieldName": "shipmentDateTime",
        "value": "2023-01-01T01-01-01.0000"
      }
    ]
  }`,
  1011: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1011",
        "message": "must not be null",
        "fieldName": "customerAccountNumber",
        "value": null
      }
    ]
  }`,
  1012: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1012",
        "message": "length must be between 1 and 11",
        "fieldName": "customerAccountNumber",
        "value": "GB1212"
      }
    ]
  }`,
  1021: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1021",
        "message": "must not be null",
        "fieldName": "shipmentType",
        "value": null
      }
    ]
  }`,
  1022: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1022",
        "message": "must be one of",
        "values": ["NON_DOCS", "DOCS"]
        "fieldName": "shipmentType",
        "value": "<invalid value>"
      }
    ]
  }`,
  1031: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1031",
        "message": "must not be null",
        "fieldName": "senderDetails",
        "value": null
      }
    ]
  }`,
  1041: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1041",
        "message": "must not be null",
        "fieldName": "senderDetails.address",
        "value": null
      }
    ]
  }`,
  1051: `{
    "message": "Bad Request",
      {
        "code": "1051",
        "message": "must not be null",
        "fieldName": "senderDetails.address.countryCode",
        "value": null
      }
    ]
  }`,
  1052: `{
    "message": "Bad Request",
      {
        "code": "1052",
        "message": "must be one of",
        "values": [<all valid country codes>]
        "fieldName": "senderDetails.address.countryCode",
        "value": "<invalid value>"
      }
    ]
  }`,
  1061: `{
    "message": "Bad Request",
      {
        "code": "1061",
        "message": "must not be null",
        "fieldName": "senderDetails.address.city",
        "value": null
      }
    ]
  }`,
  1062: `{
    "message": "Bad Request",
      {
        "code": "1062",
        "message": "must be in the format",
        "format": "^\\d{5}([ \\-]\\d{4})?$"
        "fieldName": "senderDetails.address.postalCode",
        "value": ""
      }
    ]
  }`,
  1071: `{
    "message": "Bad Request",
      {
        "code": "1071",
        "message": "must not be null",
        "fieldName": "senderDetails.address.city",
        "value": null
      }
    ]
  }`,
  1072: `{
    "message": "Bad Request",
      {
        "code": "1072",
        "message": "length must be between 1 and 30",
        "fieldName": "senderDetails.address.city",
        "value": "<invalid value>"
      }
    ]
  }`,
  1081: `{
    "message": "Bad Request",
      {
        "code": "1081",
        "message": "must not be null",
        "fieldName": "senderDetails.address.residential",
        "value": null
      }
    ]
  }`,
  1082: `{
    "message": "Bad Request",
      {
        "code": "1082",
        "message": "must be 'true' or 'false'",
        "fieldName": "senderDetails.address.residential",
        "value": "<invalid value>"
      }
    ]
  }`,
  1091: `{
    "message": "Bad Request",
      {
        "code": "1091",
        "message": "must not be null",
        "fieldName": "deliveryDetails",
        "value": null
      }
    ]
  }`,
  1101: `{
    "message": "Bad Request",
      {
        "code": "1101",
        "message": "must not be null",
        "fieldName": "deliveryDetails.address",
        "value": null
      }
    ]
  }`,
  1111: `{
    "message": "Bad Request",
      {
        "code": "1111",
        "message": "must not be null",
        "fieldName": "deliveryDetails.address.countryCode",
        "value": null
      }
    ]
  }`,
  1112: `{
    "message": "Bad Request",
      {
        "code": "1112",
        "message": "must be one of",
        "values": [<all valid country codes>]
        "fieldName": "deliveryDetails.address.countryCode",
        "value": "<invalid value>"
      }
    ]
  }`,
  1121: `{
    "message": "Bad Request",
      {
        "code": "1121",
        "message": "must not be null",
        "fieldName": "deliveryDetails.address.city",
        "value": null
      }
    ]
  }`,
  1122: `{
    "message": "Bad Request",
      {
        "code": "1122",
        "message": "must be in the format",
        "format": "^\\d{5}([ \\-]\\d{4})?$"
        "fieldName": "deliveryDetails.address.postalCode",
        "value": ""
      }
    ]
  }`,
  1131: `{
    "message": "Bad Request",
      {
        "code": "1131",
        "message": "must not be null",
        "fieldName": "deliveryDetails.address.city",
        "value": null
      }
    ]
  }`,
  1132: `{
    "message": "Bad Request",
      {
        "code": "1132",
        "message": "length must be between 1 and 30",
        "fieldName": "deliveryDetails.address.city",
        "value": "<invalid value>"
      }
    ]
  }`,
  1141: `{
    "message": "Bad Request",
      {
        "code": "1141",
        "message": "must not be null",
        "fieldName": "deliveryDetails.address.residential",
        "value": null
      }
    ]
  }`,
  1142: `{
    "message": "Bad Request",
      {
        "code": "1142",
        "message": "must be 'true' or 'false'",
        "fieldName": "deliveryDetails.address.residential",
        "value": "<invalid value>"
      }
    ]
  }`,
  1151: `{
    "message": "Bad Request",
      {
        "code": "1151",
        "message": "must not be null",
        "fieldName": "parcels",
        "value": null
      }
    ]
  }`,
  1152: `{
    "message": "Bad Request",
      {
        "code": "1152",
        "message": "must not be empty",
        "fieldName": "parcels",
        "value": []
      }
    ]
  }`,
  1161: `{
    "message": "Bad Request",
      {
        "code": "1161",
        "message": "must not be null",
        "fieldName": "parcels[0].dimensions",
        "value": null
      }
    ]
  }`,
  1171: `{
    "message": "Bad Request",
      {
        "code": "1171",
        "message": "must not be null",
        "fieldName": "parcels[0].dimensions.length",
        "value": null
      }
    ]
  }`,
  1172: `{
    "message": "Bad Request",
      {
        "code": "1172",
        "message": "must be a number equal to or greater than 1",
        "fieldName": "parcels[0].dimensions.length",
        "value": null
      }
    ]
  }`,
  1173: `{
    "message": "Bad Request",
      {
        "code": "1173",
        "message": "must be upto 2 digits after the decimal",
        "fieldName": "parcels[0].dimensions.length",
        "value": null
      }
    ]
  }`,
  1181: `{
    "message": "Bad Request",
      {
        "code": "1181",
        "message": "must not be null",
        "fieldName": "parcels[0].dimensions.width",
        "value": null
      }
    ]
  }`,
  1182: `{
    "message": "Bad Request",
      {
        "code": "1182",
        "message": "must be a number equal to or greater than 1",
        "fieldName": "parcels[0].dimensions.width",
        "value": null
      }
    ]
  }`,
  1183: `{
    "message": "Bad Request",
      {
        "code": "1173",
        "message": "must be upto 2 digits after the decimal",
        "fieldName": "parcels[0].dimensions.width",
        "value": null
      }
    ]
  }`,
  1191: `{
    "message": "Bad Request",
      {
        "code": "1181",
        "message": "must not be null",
        "fieldName": "parcels[0].dimensions.height",
        "value": null
      }
    ]
  }`,
  1192: `{
    "message": "Bad Request",
      {
        "code": "1192",
        "message": "must be a number equal to or greater than 1",
        "fieldName": "parcels[0].dimensions.height",
        "value": "<invalid value"
      }
    ]
  }`,
  1193: `{
    "message": "Bad Request",
      {
        "code": "1193",
        "message": "must be upto 2 digits after the decimal",
        "fieldName": "parcels[0].dimensions.height",
        "value": "<invalid value"
      }
    ]
  }`,
  1201: `{
    "message": "Bad Request",
      {
        "code": "1201",
        "message": "must not be null",
        "fieldName": "parcels[0].dimensions.unit",
        "value": null
      }
    ]
  }`,
  1202: `{
    "message": "Bad Request",
      {
        "Error Code": "1202",
        "message": "must be one of",
        "values": ["CM", "IN"]
        "fieldName": "parcels.dimensions.unit",
        "value": null
      }
    ]
  }`,
  1211: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1211",
        "message": "must not be null",
        "fieldName": "parcels[0].weight",
        "value": null
      }
    ]
  }`,
  1212: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1212",
        "message": "parcel units must be either all metric or all imperial.",
        "fieldName": "parcels",
        "value": null
      }
    ]
  }`,
  1213: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1213",
        "message": "must be upto 2 digits after the decimal",
        "fieldName": "parcels[0].weight",
        "value": null
      }
    ]
  }`,
  1221: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1221",
        "message": "must not be null",
        "fieldName": "parcels[0].weight.value",
        "value": null
      }
  ]
  }`,
  1222: `{
    "message": "Bad Request",
    "errors":
      {
        "Error Code": "1222",
        "message": "must be greater than 0",
        "fieldName": "parcels[0].weight.value",
        "value": "<invalid value>"
      }
    ]
  }`,
  1231: `{
    "message": "Bad Request",
    "errors": [
      {
        "code": "1231",
        "message": "must not be null",
        "fieldName": "parcels[0].weight.unit",
        "value": null
      }
    ]
  }`,
  1232: `{
    "message": "Bad Request",
    "errors": [
      {
        "Error Code": "1232",
        "message": "must be one of",
        "values": ["KG", "LB"]
        "fieldName": "parcels[0].weight.unit",
        "value": "<invalid value>"
      }
    ]
  }`
}