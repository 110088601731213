import React from 'react';
import './Badge.scss';

const Badge: React.FC<{children?: any}> = ({children}) => {

  return (
    <div className="badge">{children}</div>
  )
}

export default Badge;