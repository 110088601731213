import React, { useEffect, useRef } from 'react';
import './Option.scss';

export interface AMISelectOption {
  title: string,
  value: string | number,
  disabled?: boolean
}

const AMIOption: React.FC<{
  value: AMISelectOption,
  isActive?: boolean,
  disabled?: boolean | undefined,
  style?: React.CSSProperties,
  onSelect?: (event:any) => void
}> = ({
  value,
  isActive,
  disabled,
  style,
  onSelect,
  children
}) => {

  const optionElement = useRef<any>();

  const getClassName = () => {
    let className = 'ami-select-option ';

    if (disabled) {
      className = className.concat('ami-select-option--disabled ')
    }
    return className;
  }

  const className = getClassName();

  const onOptionSelect = () => {
    if (!disabled) {
      onSelect?.(value);
    }
  }

  useEffect(() => {
    if (isActive) {
      optionElement.current.scrollIntoView({block: 'nearest'});
    }
  }, [isActive])

  return (
    <option
      ref={optionElement}
      className={className}
      style={isActive ? {background: 'var(--primary50)'} : style}
      onClick={onOptionSelect}
    >{children}</option>
  )
}

export default AMIOption;