import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { AMIInput, AMISpinner } from '../../ui-components/UiComponents';
import { customerDetailsSelector } from '../../features/customerSlice';
import { BookingHistoryItem, getBookingHistory } from '../../utilities/APIUtilities';
import './TrackingPage.scss';
import TrackingTable from './tracking-table/TrackingTable';
import dayjs from 'dayjs';
import { appDataActions, appDataSelector } from '../../features/appDataSlice';

const TrackingPage = () => {

  const customer = useAppSelector(customerDetailsSelector);
  const apiConfig = useAppSelector(appDataSelector).apiConfig;
  const [bookingHistory, setBookingHistory] = useState<BookingHistoryItem[]>([]);
  const [unfilteredBookingHistory, setUnfilteredBookingHistory] = useState<BookingHistoryItem[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => {
    let bookingHistoryData: BookingHistoryItem[] = [];
    const fetchData = async() => {
      bookingHistoryData = await getBookingHistory(apiConfig, customer);

      setBookingHistory(bookingHistoryData);
      setUnfilteredBookingHistory(bookingHistoryData);
      setIsFetching(false);
    }
    fetchData().catch(console.error)
  }, []);

  const onFilterChange = (event: any) => {
    const searchValue = event.target.value
    let filterResult = unfilteredBookingHistory.filter((booking: BookingHistoryItem) => {
      return (
        dayjs(booking.shipmentDate).format('DD/MM/YYYY').includes(searchValue)
        || booking.shipmentId.toLowerCase().includes(searchValue.toLowerCase())
        || booking.amiReference?.toLowerCase().includes(searchValue.toLowerCase())
        || booking.customerShippingReference?.toLowerCase().includes(searchValue.toLowerCase())
        || booking.deliveryDetails.address.organisation.toLowerCase().includes(searchValue.toLowerCase())
        || booking.deliveryDetails.address.city.toLowerCase().includes(searchValue.toLowerCase())
        || booking.deliveryDetails.address.countryCode.toLowerCase().includes(searchValue.toLowerCase())
      )
    })
    setBookingHistory(filterResult);
  }

  return (
    <div className="tracking-page">
      <div className="tracking-page__tracking-card__bookings-card">
        <p className="card-header">Booking History</p>

        <AMIInput
          name="tracking-page__tracking-card__bookings-card__tracking-filter"
          placeholder='Filter...'
          className="tracking-filter"
          onChange={onFilterChange}
        />

        {isFetching && <AMISpinner className="tracking-page__tracking-card__bookings-card__tracking-filter__spinner" />}

        {!isFetching
        && unfilteredBookingHistory.length !== 0
        && <TrackingTable data={bookingHistory} />
        }

        {!isFetching
        && unfilteredBookingHistory.length === 0
        && <h4>We could not find any bookings. If you have previously placed bookings then please contact customer service.</h4>
        }

      </div>
    </div>
  )
}

export default TrackingPage;