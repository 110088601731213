import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { customerActions, customerDetailsSelector } from '../../features/customerSlice';
import './AccountPage.scss';
import { appDataSelector } from '../../features/appDataSlice';
import AccountTemplates from './account-templates/AccountTemplates';
import { Route, useHistory, useLocation } from 'react-router-dom';
import WhiteLabelPage from './white-label/WhiteLabelPage';
import AccountAddresses from './account-addresses/AccountAddresses';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ReactComponent as LogoutIcon } from '../../ui-components/icon/svgs/logout.svg';

const AccountPage = () => {

  const customer = useAppSelector(customerDetailsSelector);
  const appData = useAppSelector(appDataSelector);
  const dispatch = useAppDispatch();
  const location = useLocation().pathname;
  const history = useHistory();
  const { width } = useWindowDimensions();

  const [hideSubMenu, setHideSubMenu] = useState(false);
  const [pageTitle, setPageTitle] = useState<string>('Addresses');

  useEffect(() => {
    if (location.includes('addresses')) setPageTitle('Addresses');
    if (location.includes('white-label')) setPageTitle('White Label');
  }, [])

  const onItemClick = (page: 'ADDRESSES' | 'WHITE_LABEL') => {
    setHideSubMenu(false);
    switch (page) {
      case 'ADDRESSES':
        setPageTitle('Addresses');
        history.push('/account/addresses');
        break;
      case 'WHITE_LABEL':
        setPageTitle('White Label');
        history.push('/account/white-label/bookings');
        break;
    }
  }

  const  onSubItemClick = (event: any, subPage: 'bookings' | 'customise' | 'customers' | 'content') => {
    event?.stopPropagation();
    history.push(`/account/white-label/${subPage}`);
    if (width < 700) setHideSubMenu(true);
  }

  const handleLogoutClick = () => {
    dispatch(customerActions.logOut());
    history.push('/auth');
  }

  return (
    <div className="account-page">
      <h1>{pageTitle}</h1>

      <nav className="nav-links">
        <div
          className={
            location === "/account/addresses"
              ? "nav-item nav-item--active"
              : "nav-item"
          }
          onClick={() => onItemClick("ADDRESSES")}
        >Addresses</div>

        {customer.creditCheck.whiteLabel && customer.creditCheck.whiteLabel.isParent && (
          <div
            className={
              location.includes("/account/white-label") && !hideSubMenu
                ? "nav-item nav-item--active"
                : "nav-item"
            }
            onClick={() => onItemClick("WHITE_LABEL")}
          >
            White Label
            <div className={
                location.includes("/account/white-label") && !hideSubMenu
                  ? "nav-item__sub-menu--active"
                  : "nav-item__sub-menu"
              }
              >
                <div
                  className={location.includes("/white-label/bookings")
                    ? "nav-item__sub-menu__item nav-item__sub-menu__item--active"
                    : "nav-item__sub-menu__item"
                  }
                  onClick={(event) => onSubItemClick(event, "bookings")}
                >
                  Bookings
                </div>
                <div
                  className={location.includes("/white-label/customise")
                    ? "nav-item__sub-menu__item nav-item__sub-menu__item--active"
                    : "nav-item__sub-menu__item"
                  }
                  onClick={(event) => onSubItemClick(event, "customise")}
                >
                  Customise
                </div>
                <div
                  className={location.includes("/white-label/customers")
                    ? "nav-item__sub-menu__item nav-item__sub-menu__item--active"
                    : "nav-item__sub-menu__item"
                  }
                  onClick={(event) => onSubItemClick(event, "customers")}
                >
                  Customers
                </div>
                <div
                  className={location.includes("/white-label/content")
                    ? "nav-item__sub-menu__item nav-item__sub-menu__item--active"
                    : "nav-item__sub-menu__item"
                  }
                  onClick={(event) => onSubItemClick(event, "content")}
                >
                  Content
                </div>
              </div>
          </div>
        )}

        <div
          className={
            location.includes("/account/templates")
              ? "nav-item nav-item--active"
              : "nav-item nav-item"
          }
          onClick={() => history.push('/account/templates')}
        >Templates</div>
        <div
          className={
            location.includes("/account/preferences")
              ? "nav-item nav-item--active"
              : "nav-item nav-item--disabled"
          }
          onClick={() => history.push('/account/preferences')}
        >Preferences</div>

        <div
          className="nav-item nav-item--logout"
          onClick={handleLogoutClick}
        >
          <LogoutIcon
            width="18px"
          /> Logout
        </div>
      </nav>

      <Route path="/account/addresses">
        <AccountAddresses />
      </Route>

      <Route path="/account/white-label">
        <WhiteLabelPage />
      </Route>

      <Route path="/account/templates">
        <AccountTemplates />
      </Route>

    </div>
  )
};

export default AccountPage;