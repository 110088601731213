import React, { useEffect, useState } from 'react';
import './TaxNumbers.scss';
import { AMIFormElement, AMIInput, AMISelect, Icon } from '../../ui-components/UiComponents';
import { Booking } from '../../features/bookingSlice';
import { generateTaxNumberTypeTitle, taxNumberTypes } from '../../pages/single-booking/details-page/utilities';
import { extractRule } from '../../utilities/RulesEngineUtilities';
import { TemplateForm } from '../../utilities/TemplateHandler';

const TaxNumber: React.FC<{
  element: any,
  index: number,
  allElements: any,
  booking: any,
  detailsContainer: 'shipperDetails' | 'consigneeDetails' | 'brokerDetails' | 'shipper' | 'consignee' | 'broker',
  customRules?: any,
  updateElements: (updatedElements: any) => void,
  onDelete: () => void
}> = ({
  element,
  index,
  allElements,
  booking,
  detailsContainer,
  customRules,
  updateElements,
  onDelete
}) => {

  const [isEoriNumber, setIsEoriNumber] = useState(false);
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    let options = taxNumberTypes;
    if (detailsContainer === 'shipperDetails') options = taxNumberTypes.filter(el => el.value !== 'DAN');
    setOptions(options);
  }, [])

  const onElementChange = (field: string, event: any) => {
    const updatedElement = {
      ...element,
      [field]: event
    }
    const elementsCopy = [...allElements];
    elementsCopy.splice(index, 1, updatedElement);
    updateElements(elementsCopy);
  }

  const checkTaxNumberType = () => {
    const eoriRequired = extractRule(customRules, 'shipperEori')?.value;

    const isEoriTaxNumber = (
      index === 0
      && eoriRequired
      && detailsContainer === 'shipperDetails'
    )

    setIsEoriNumber(isEoriTaxNumber);
  }

  useEffect(() => {
    if (JSON.stringify(customRules) !== '{}' && JSON.stringify(customRules) !== undefined) checkTaxNumberType();
  }, [customRules]);

  return (
    <div className="tax-number-wrapper">
      {isEoriNumber && (
        <>
          <AMIFormElement label="Tax Number Type">
            <AMISelect
              name="taxNumberType"
              placeholder="Required"
              size="large"
              defaultValue={{
                title: generateTaxNumberTypeTitle(booking[detailsContainer].taxNumbers[index].type),
                value: booking[detailsContainer].taxNumbers[index].type
              }}
              disabled
              options={taxNumberTypes}
            />
          </AMIFormElement>

          <AMIFormElement label="Tax Number">
            <AMIInput
              name="shipperTaxNumber"
              placeholder="Required"
              size="large"
              value={element.value}
              onChange={(event) => onElementChange('value', event.target.value)}
            />
          </AMIFormElement>

          {index !== 0 && <Icon
            name="Trash"
            style={{
              marginTop: '36px',
              marginRight: '0px',
              cursor: 'pointer',
              minWidth: '18px'
            }}
            onClick={onDelete}
          />}
        </>
      )}

      {!isEoriNumber && (
          <>
            <AMIFormElement
              label="Tax Number Type"
              errorMessage={
                element.value && !element.type
                ? "Required"
                : ""
              }
            >
              <AMISelect
                name="taxNumberType"
                placeholder={element.value ? "Required" : ""}
                size="large"
                defaultValue={{
                  title: generateTaxNumberTypeTitle(booking[detailsContainer].taxNumbers[index].type),
                  value: booking[detailsContainer].taxNumbers[index].type
                }}
                options={options}
                onChange={(event) => onElementChange('type', event.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label={"Tax Number"}
            >
              <AMIInput
                name="shipperTaxNumber"
                size="large"
                value={element.value}
                onChange={(event) => onElementChange('value', event.target.value)}
              />
            </AMIFormElement>

            {index !== 0 && <Icon
              name="Trash"
              style={{
                marginTop: '36px',
                marginRight: '0px',
                cursor: 'pointer',
                minWidth: '18px'
              }}
              onClick={onDelete}
            />}
          </>
        )
      }
    </div>
  )
}

export default TaxNumber;