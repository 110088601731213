import React, { useState, useEffect } from 'react';
import { useCSVReader } from 'react-papaparse';
import Papa from 'papaparse';
import AMIAlert from '../../../ui-components/alert/Alert';
import AMIButton from '../../../ui-components/button/Button';
import Icon from '../../../ui-components/icon/Icon';
import AMIInfo from '../../../ui-components/info-box/InfoBox';
import { ActivePage } from '../steps/Steps';
import './UploadPage.scss';
import { domesticGuidelines, internationalGuidelines, domesticCsvValidation, mapCsvData, groupByShipmentReference, csvGroupedValidation, resetValidationResult, csvUploading, csvSuccess, csvError, csvUploadStateEnum, internationalCsvValidation, csvDomesticDefault, csvInternationalDefault } from './utility';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {manifestActions} from '../../../features/manifestSlice';
import {useAppDispatch} from "../../../app/hooks";
import AMIModal from '../../../ui-components/modal/Modal';

const UploadPage: React.FC<{changeStep: (arg0: ActivePage) => void}> = ({changeStep}) => {

  const dispatch = useAppDispatch();
  const { CSVReader } = useCSVReader();
  const { width } = useWindowDimensions();

  const [domesticUploadState, setDomesticUploadState] = useState('DEFAULT');
  const [domesticButtonContent, setDomesticButtonContent] = useState<any>(csvDomesticDefault);
  const [internationalUploadState, setInternationalUploadState] = useState('DEFAULT');
  const [internationalButtonContent, setInternationalButtonContent] = useState<any>(csvInternationalDefault);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [inDomesticDropZone, setInDomesticDropZone] = useState(false);
  const [inInternationalDropZone, setInInternationalDropZone] = useState(false);
  const [file, setFile] = useState<any>();
  const [isDomesticGuidelinesModalVisible, setIsDomesticGuidelinesModalVisible] = useState<boolean>(false);
  const [isInternationalGuidelinesModalVisible, setIsInternationalGuidelinesModalVisible] = useState<boolean>(false);

  useEffect(() => {
    switch(domesticUploadState) {
      case 'DEFAULT':
        setDomesticButtonContent(csvDomesticDefault);
        break;
      case 'UPLOADING':
        setDomesticButtonContent(csvUploading);
        break;
      case 'SUCCESS':
        setDomesticButtonContent(csvSuccess);
        break;
      case 'ERROR':
        setDomesticButtonContent(csvError);
    }
  }, [domesticUploadState]);

  useEffect(() => {
    switch(internationalUploadState) {
      case 'DEFAULT':
        setInternationalButtonContent(csvInternationalDefault);
        break;
      case 'UPLOADING':
        setInternationalButtonContent(csvUploading);
        break;
      case 'SUCCESS':
        setInternationalButtonContent(csvSuccess);
        break;
      case 'ERROR':
        setInternationalButtonContent(csvError);
    }
  }, [internationalUploadState]);

  const handleFileLoad = (event: any, type: 'DOMESTIC' | 'INTERNATIONAL') => {
    const data: string[] = event.data;
    type === 'DOMESTIC' ? setDomesticUploadState(csvUploadStateEnum.UPLOADING) : setInternationalUploadState(csvUploadStateEnum.UPLOADING);
    dispatch(manifestActions.resetManifestState());
    resetValidationResult();

    if (type === 'DOMESTIC') domesticCsvValidation(data);
    else internationalCsvValidation(data);

    const mappedData = mapCsvData(data);

    const groupedData = groupByShipmentReference(mappedData);
    const groupedDataValResult = csvGroupedValidation(groupedData);

    if (groupedDataValResult.status === csvUploadStateEnum.SUCCESS) {
      setErrorMessages([]);
      type === 'DOMESTIC' ? setDomesticUploadState('SUCCESS') : setInternationalUploadState('SUCCESS');
      setTimeout(() => saveData(data), 1500);
      dispatch(manifestActions.setIsInternational(type === 'INTERNATIONAL'));
    } else handleError(groupedDataValResult.errors, type);
  }

  const handleOnError = (err: any, type: 'DOMESTIC' | 'INTERNATIONAL') => {
    const errors = err.map((error: any) => {
      return error.message;
    })
    handleError(errors, type);
  };

  const handleError = (errors: string[], type: 'DOMESTIC' | 'INTERNATIONAL') => {
    type === 'DOMESTIC' ? setDomesticUploadState(csvUploadStateEnum.ERROR) : setInternationalUploadState(csvUploadStateEnum.ERROR);
    setTimeout(() => {
      type === 'DOMESTIC' ? setDomesticUploadState(csvUploadStateEnum.DEFAULT) : setInternationalUploadState(csvUploadStateEnum.DEFAULT)
    }, 1500);
    setErrorMessages(errors)
  };

  const saveData = (data: any) => {
    const mappedData = mapCsvData(data);
    dispatch(manifestActions.saveCsvData(mappedData));
    changeStep(ActivePage.REVIEW_PAGE);
  }

  const handleDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragLeave = (event: any, button: 'DOMESTIC' | 'INTERNATIONAL') => {
    event.preventDefault();
    event.stopPropagation();
    if (button === 'DOMESTIC') setInDomesticDropZone(false);
    else setInInternationalDropZone(false);
  };

  const handleDragOver = (event: any, button: 'DOMESTIC' | 'INTERNATIONAL') => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
    if (button === 'DOMESTIC') setInDomesticDropZone(true);
    else setInInternationalDropZone(true);
  };

  const handleDrop = (event: any, type: 'DOMESTIC' | 'INTERNATIONAL') => {
    event.preventDefault();
    event.stopPropagation();
    let file = [...event.dataTransfer.files][0];

    if (file) {
      setFile(file);
      event.dataTransfer.clearData();
      if (type === 'DOMESTIC') setInDomesticDropZone(false);
      else setInInternationalDropZone(false);
    }

    Papa.parse(file, {
      header: false,
      skipEmptyLines: true,
      complete: function(results: any) {
        handleFileLoad(results, type);
      }
    });
  };

  const toggleDomesticGuidelinesModal = () => {
    setIsDomesticGuidelinesModalVisible(!isDomesticGuidelinesModalVisible)
  }

  const toggleInternationalGuidelinesModal = () => {
    setIsInternationalGuidelinesModalVisible(!isInternationalGuidelinesModalVisible)
  }

  return (
    <div className="upload-page">

      {domesticUploadState === csvUploadStateEnum.UPLOADING && <div className="mask"></div>}

      <div className="upload-page__content">

        <p className="upload-page__labels">Download or view the manifest file template:</p>

        <div className="upload-page__btns-container">
          <div>
            <a href='domestic_manifest_template.csv' download>
              <AMIButton
                size="large"
                onClick={() => null}
                style={{width: '207px'}}
              >
                <Icon name="Download" />
                Domestic
              </AMIButton>
            </a>

            <AMIButton
              size="small"
              onClick={toggleDomesticGuidelinesModal}
              style={{width: '50%', marginTop: '8px'}}
            >
              Guidelines
            </AMIButton>
          </div>

          <div>
            <a href='international_manifest_template.csv' download>
              <AMIButton
                size="large"
                onClick={() => null}
                style={{width: '207px'}}
              >
                <Icon name="Download" />
                International
              </AMIButton>
            </a>

            <AMIButton
              size="small"
              onClick={toggleInternationalGuidelinesModal}
              style={{width: '50%', marginTop: '8px'}}
            >
              Guidelines
            </AMIButton>

          </div>
        </div>

        {isDomesticGuidelinesModalVisible && <AMIModal
          title="CSV Domestic Guidelines"
          width="80%"
          close={toggleDomesticGuidelinesModal}
        >
          <div className="guidelines-modal-content">
            {domesticGuidelines.map(guideline => {
              return (
                <div
                  key={guideline.title}
                  className="guidelines-modal-content__guideline"
                >
                  <p>{guideline.title}</p>
                  <div>
                    <div className="guidelines-modal-content__guideline__titles">
                      <p>Mandatory:</p>
                      {guideline.format && <p>Format:</p>}
                      {guideline.unit && <p>Unit:</p>}
                      <p>Description:</p>
                    </div>

                    <div className="guidelines-modal-content__guideline__values">
                      <p>{guideline.mandatory}</p>
                      {guideline.format && <p dangerouslySetInnerHTML={{__html: guideline.format}}></p>}
                      {guideline.unit && <p>{guideline.unit}</p>}
                      <p>{guideline.description}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </AMIModal>
        }

        {isInternationalGuidelinesModalVisible && <AMIModal
          title="CSV International Guidelines"
          width="80%"
          close={toggleInternationalGuidelinesModal}
        >
          <div className="guidelines-modal-content">
            {internationalGuidelines.map(guideline => {
              return (
                <div
                  key={guideline.title}
                  className="guidelines-modal-content__guideline"
                >
                  <p>{guideline.title}</p>
                  <div>
                    <div className="guidelines-modal-content__guideline__titles">
                      <p>Mandatory:</p>
                      {guideline.format && <p>Format:</p>}
                      {guideline.unit && <p>Unit:</p>}
                      <p>Description:</p>
                    </div>

                    <div className="guidelines-modal-content__guideline__values">
                      <p>{guideline.mandatory}</p>
                      {guideline.format && <p dangerouslySetInnerHTML={{__html: guideline.format}}></p>}
                      {guideline.unit && <p>{guideline.unit}</p>}
                      <p>{guideline.description}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </AMIModal>
        }

        <p className="upload-page__labels">Upload the populated CSV:</p>

        <div className="upload-page__btns-container">
          <div className="upload-page__btn-container">
            <div>
              <CSVReader
                onUploadAccepted={(event: any) => handleFileLoad(event, 'DOMESTIC')}
                onError={(event: any) => handleOnError(event, 'DOMESTIC')}
                noDrag
                config={{skipEmptyLines: true}}
                style={{}}
              >
                {({
                  getRootProps
                }: any) => (
                  <AMIButton
                    size="oversized"
                    variant="secondary"
                    style={
                      width < 501 ? {
                        width: `${width/100*90}px`,
                        height: '189px',
                        backgroundColor: domesticButtonContent.color ? domesticButtonContent.color : 'var(--secondary)',
                        transition: 'none'
                      }
                      : {
                          width: '207px',
                          backgroundColor: domesticButtonContent.color ? domesticButtonContent.color : 'var(--secondary)',
                          transition: 'none'
                        }
                    }
                    {...getRootProps()}
                  >
                    <div>
                      {domesticButtonContent.text}
                    </div>
                    <Icon
                      name="Upload"
                      style={{
                        width: '89px',
                        height: '89px',
                        fill: 'var(--textLight)'}}
                    />
                  </AMIButton>
                )}
              </CSVReader>
              </div>

            <div
              className={
                inDomesticDropZone
                  ? 'drag-drop-zone inside-drag-area'
                  : 'drag-drop-zone'
              }
              onDrop={e => handleDrop(e, 'DOMESTIC')}
              onDragOver={e => handleDragOver(e, "DOMESTIC")}
              onDragEnter={e => handleDragEnter(e)}
              onDragLeave={e => handleDragLeave(e, "DOMESTIC")}
            >
             Drag CSV here to upload
            </div>
          </div>

          <div className="upload-page__btn-container">
            <div>
              <CSVReader
                onUploadAccepted={(event: any) => handleFileLoad(event, 'INTERNATIONAL')}
                onError={(event: any) => handleOnError(event, 'INTERNATIONAL')}
                noDrag
                config={{skipEmptyLines: true}}
                style={{}}
              >
                {({
                  getRootProps
                }: any) => (
                  <AMIButton
                    size="oversized"
                    variant="primary"
                    style={
                      width < 501 ? {
                        width: `${width/100*90}px`,
                        height: '189px',
                        backgroundColor: internationalButtonContent.color ? internationalButtonContent.color : 'var(--primary)',
                        transition: 'none'
                      }
                      : {
                          width: '207px',
                          backgroundColor: internationalButtonContent.color ? internationalButtonContent.color : 'var(--primary)',
                          transition: 'none'
                        }
                    }
                    {...getRootProps()}
                  >
                    <div>
                      {internationalButtonContent.text}
                    </div>
                    <Icon
                      name="Upload"
                      style={{
                        width: '89px',
                        height: '89px',
                        fill: 'var(--textLight)'}}
                    />
                  </AMIButton>
                )}
              </CSVReader>
              </div>

            <div
              className={
                inInternationalDropZone
                ? 'drag-drop-zone inside-drag-area'
                : 'drag-drop-zone'
              }
              onDrop={e => handleDrop(e,'INTERNATIONAL')}
              onDragOver={e => handleDragOver(e, "INTERNATIONAL")}
              onDragEnter={e => handleDragEnter(e)}
              onDragLeave={e => handleDragLeave(e, "INTERNATIONAL")}
            >
             Drag CSV here to upload
            </div>

          </div>
        </div>

        {file && <p className="dropped-file">{file.name}</p>}

        {errorMessages
        && errorMessages.map((error, index) => {
          return (
            <AMIAlert
              variant="error"
              key={index}
              style={{marginBottom: '12px'}}
            >{error}</AMIAlert>
          )
        })
        }

      </div>

      <div className="upload-page__info">
        <AMIInfo title="How To">
          <p>To make a bulk booking, we require a CSV (Comma Separated Value) file to be uploaded.</p>
          <p>A template file can be downloaded through the <strong>Download</strong> button. This file can be populated, saved and then uploaded to us by pressing the <strong>Upload CSV</strong> button or through dragging the file into the space provided.</p>
          <p>Assistance with completing the CSV file can be found through the <strong>Guidelines</strong> button.</p>
          <p>You will not be able to progress from this screen if the uploaded file does not match the template.</p>
        </AMIInfo>
      </div>
    </div>
  )
};

export default UploadPage;